
import oPropertyDetails from '@/components/organisms/property/PropertyDetails.vue';
import { EventBus } from '@/functions/eventBus';

interface Data {
    isDesktop: boolean;
}

export default {
    components: {
        oPropertyDetails,
    },

    data(): Data {
        return {
            isDesktop: EventBus.isDesktop,
        };
    },
};
