import State, { Widget } from './interface';

export default {
    /**
     * Return all widgets.
     *
     * @param {State} state
     * @return {string[]}
     */
    widgets(state: State): Widget[] {
        return state.widgets;
    },

    /**
     * Return current widget.
     *
     * @param {State} state
     * @return {string}
     */
    current(state: State): string {
        return state.current;
    },
};
