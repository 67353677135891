
import { PropType } from 'vue';
import tracking from '@/functions/tracking';

interface Data {
    brokerId: string;
}

export default {
    name: 'm-button',
    props: {
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        buttonText: {
            type: String as PropType<string>,
            default: () => '',
        },
        gaAction: {
            type: String as PropType<string>,
            default: () => '',
        },
        gaCategory: {
            type: String as PropType<string>,
            default: () => '',
        },
        gaLabel: {
            type: String as PropType<string>,
            default: () => '',
        },
        gaIndgangsmetode: {
            type: String as PropType<string>,
            default: () => '',
        },
        icon: {
            type: String as PropType<string>,
            default: () => '',
        },
        iconPosition: {
            type: String as PropType<string>,
            default: () => 'right',
        },
        loading: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        url: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            brokerId: '',
        };
    },

    computed: {
        /**
         * Check if the component has an icon.
         *
         * @return {boolean}
         */
        hasIcon(): boolean {
            return !!this.icon;
        },

        /**
         * Check if the component is disabled.
         *
         * @return {boolean}
         */
        isDisabled(): boolean {
            return this.disabled !== false;
        },

        /**
         * Check if the component is loading.
         *
         * @return {boolean}
         */
        isLoading(): boolean {
            return this.loading !== false;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-button--${this.theme}` : '';
        },
    },

    mounted() {
        if (window.homeEstimateBaseData) {
            this.brokerId = window.homeEstimateBaseData.brokerId;
        }
    },

    methods: {
        /**
         * Handle click event on button.
         *
         * @return {void}
         */
        onClick(): void {
            if (this.url === '#bestilboligskoen') {
                tracking.trackRawEvent({
                    event: 'trackForm',
                    eventData: {
                        category: 'Formular',
                        action: 'Salgsvurdering indgang',
                        label: 'Salgsvurdering',
                        formularIndgang: 'Boligskoen CTA modul',
                        formularStepnavn: 'Klik paa modul',
                        maeglerId: this.brokerId ?? '',
                    },
                });
                return;
            }

            // Category must always be present
            if (this.gaCategory === '') return;

            if (this.gaIndgangsmetode !== '') {
                tracking.trackRawEvent({
                    event: 'trackForm',
                    eventData: {
                        category: this.gaCategory,
                        action: this.gaAction,
                        label: this.gaLabel,
                        formularIndgang: this.gaIndgangsmetode,
                    },
                });
                return;
            }

            let but = this.$refs.button as HTMLAnchorElement;
            let trackingLabel =
                this.gaLabel !== '' ? this.gaLabel : but?.innerText ?? '';
            tracking.track(
                'trackCta',
                this.gaCategory,
                this.gaAction,
                trackingLabel,
            );
        },
    },
};
