
import { PropType } from 'vue';
import Api from '@/functions/api';

interface Stats {
    text: string;
    type: string;
}

interface ShowingStats {
    type: string;
    days: number;
    text: string;
    visibleCount: number;
    hidePageViews: boolean;
}

interface Data {
    currentIndex: number;
    isVisible: boolean;
    lowerLimits: {
        [downloads: string]: number;
        favorites: number;
    };
    showingStatsReady: boolean;
    statsList: Stats[];
    statsListReady: boolean;
}

export default {
    props: {
        list: {
            type: Array as PropType<Stats[]>,
            default: () => [],
        },
        propertyId: {
            type: String as PropType<string>,
            default: () => '',
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
        showingStats: {
            type: Object as PropType<ShowingStats>,
            default: () => ({
                type: '',
                days: 0,
                text: '',
                visibleCount: 0,
                hidePageViews: false,
            }),
        },
    },

    data(): Data {
        return {
            currentIndex: 0,
            isVisible: false,
            showingStatsReady: true,
            statsList: this.list,
            statsListReady: true,
            lowerLimits: {
                downloads: 0,
                favorites: 0,
            },
        };
    },

    mounted(): void {
        const statsPromise: Promise<Stats[]> = new Promise((resolve) => {
            if (this.statsList.length) {
                this.statsListReady = false;
                Api.getPresentationFavouritsAndDocuments({
                    propertyId: this.propertyId,
                    brokerId: this.brokerId,
                }).then((response) => {
                    const newStatistics = [] as Stats[];
                    this.statsList.forEach((obj) => {
                        if (
                            response.result[obj.type] >
                            this.lowerLimits[obj.type]
                        ) {
                            const newObj = { ...obj };
                            if (obj.type === 'downloads') {
                                newObj.text = obj.text.replace(
                                    '#salgsopstillinger#',
                                    response.result.downloads.toString(),
                                );
                            }
                            if (obj.type === 'favorites') {
                                newObj.text = obj.text.replace(
                                    '#favorit#',
                                    response.result.favorites.toString(),
                                );
                            }
                            newStatistics.push(newObj);
                        }
                    });
                    resolve(newStatistics);
                });
            } else {
                resolve([]);
            }
        });

        const showingsPromise: Promise<Stats[]> = new Promise((resolve) => {
            if (!this.showingStats.hidePageViews) {
                this.showingStatsReady = false;
                Api.getGaPageViews({
                    propertyId: this.propertyId,
                    brokerId: this.brokerId,
                    days: this.showingStats.days,
                }).then((response) => {
                    if (response.result >= this.showingStats.visibleCount) {
                        const obj = {
                            text: this.showingStats.text.replace(
                                '#visninger#',
                                response.result.toString(),
                            ),
                            type: this.showingStats.type,
                        };
                        resolve([obj]);
                    }
                    resolve([]);
                });
            } else {
                resolve([]);
            }
        });

        Promise.all([showingsPromise, statsPromise]).then(
            ([showings, stats]: [Stats[], Stats[]]) => {
                this.statsList = [...showings, ...stats];
                if ([...showings, ...stats].length) {
                    this.isVisible = true;
                    setInterval(this.updateIndex, 4000);
                }
            },
        );
    },

    methods: {
        updateIndex() {
            this.currentIndex = (this.currentIndex + 1) % this.statsList.length;
        },
    },
};
