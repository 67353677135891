
import { PropType } from 'vue';
import tracking from '@/functions/tracking';

interface IntersectionItem {
    id: string;
    ratio: number;
}

interface AnchorData {
    icon?: string;
    link: string;
    text: string;
    trackingName: string;
    trackingCategoryName: string;
    sortorder: string;
}

interface Data {
    anchors: AnchorData[];
    intersections: IntersectionItem[];
    propertySection: HTMLElement | null;
}

export default {
    props: {
        trackingName: {
            type: String as PropType<string>,
            default: () => '',
        },
        trackingCategoryName: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            anchors: [],
            intersections: [],
            propertySection: null,
        };
    },

    computed: {
        activeAnchor(): { id: string; ratio: number } {
            const sorted = this.intersections.sort(
                (a: IntersectionItem, b: IntersectionItem) => a.ratio - b.ratio,
            );

            let retVal = { id: '', ratio: 0 };
            if (sorted.length > 0) {
                retVal = {
                    id: sorted[0].id,
                    ratio: sorted[0].ratio,
                };
            }

            return retVal;
        },
    },

    mounted(): void {
        const propertySectionElement =
            document.querySelector('.o-propertySection');

        if (!propertySectionElement) return;

        this.propertySection = propertySectionElement;

        window?.addEventListener('scroll', this.scrollEvent, { passive: true });

        //select all sections to get anchor data an then push this data to sticky anchors

        document
            .querySelectorAll('[data-anchor-text]')
            .forEach((section: HTMLDivElement) => {
                if (section.hasAttribute('id')) {
                    const anchorData: AnchorData = {
                        icon: section.getAttribute('data-anchor-icon') ?? '',
                        link: section.getAttribute('id') ?? '',
                        text: section.getAttribute('data-anchor-text') ?? '',
                        trackingName: this.$props.trackingName ?? '',
                        trackingCategoryName:
                            this.$props.trackingCategoryName ?? '',
                        sortorder:
                            section.getAttribute('data-sort-order') ?? '100',
                    };
                    this.anchors.push(anchorData);
                }
            });

        this.anchors = this.anchors.sort(
            (a: AnchorData, b: AnchorData) =>
                parseInt(a.sortorder) - parseInt(b.sortorder),
        );

        this.$nextTick(() => {
            this.handleIntersections();
        });
    },

    watch: {
        intersections: {
            handler() {
                const scrollParent = document.querySelector(
                    '.o-moduleStickyAnchors__buttons',
                );

                const allAnchors =
                    scrollParent.querySelectorAll('a[aria-label]');

                allAnchors.forEach((anchor) => {
                    anchor.classList.remove('active');
                });

                const { id } = this.activeAnchor;

                if (!id) {
                    return;
                }

                if (id) {
                    const activeAnchor = scrollParent.querySelector(
                        `a[href="#${id}"]`,
                    ) as HTMLAnchorElement;

                    if (activeAnchor) {
                        activeAnchor.classList.add('active');
                        scrollParent.scrollTo({
                            left: activeAnchor.offsetLeft - 150,
                            behavior: 'smooth',
                        });
                    }
                }
            },
        },
    },

    methods: {
        scrollEvent(): void {
            const bounds = (
                this.propertySection as HTMLElement
            ).getBoundingClientRect();
            const stickyAnchorsElement = this.$refs
                .stickyAnchors as HTMLDivElement;

            if (bounds.height * -1 > bounds.y) {
                stickyAnchorsElement.classList.add('show');
            } else {
                stickyAnchorsElement.classList.remove('show');
            }
        },
        trackAnchor(index: number): void {
            let anchor = this.anchors[index];
            if (
                anchor.trackingName !== '' &&
                anchor.trackingCategoryName !== '' &&
                anchor.text !== ''
            ) {
                tracking.track(
                    anchor.trackingName,
                    anchor.trackingCategoryName,
                    'Sticky Anchor',
                    anchor.text,
                );
            }
        },

        observerCallback(entries: IntersectionObserverEntry[]) {
            entries.forEach((entry: IntersectionObserverEntry, i) => {
                if (entry.isIntersecting) {
                    this.intersections.push({
                        id: entry.target.id,
                        ratio: entry.intersectionRatio,
                    });
                } else {
                    this.intersections = this.intersections.filter(
                        (obj: IntersectionItem) => obj.id !== entry.target.id,
                    );
                }
            });
        },

        /**
         * Start intersection observer.
         *
         * @return {void}
         */
        handleIntersections(): void {
            const isPropertyPresentationPage =
                document.querySelector('.t-property');

            if (!isPropertyPresentationPage) return;

            if ('IntersectionObserver' in window) {
                document
                    .querySelectorAll('[data-anchor-text]')
                    .forEach((i: HTMLDivElement) => {
                        const expr = i.getBoundingClientRect().height;
                        let threshold: number | number[] = 1;
                        switch (true) {
                            case expr > 900:
                                threshold = 0.2;
                                break;
                            case expr > 600:
                                threshold = 0.4;
                                break;
                            case expr > 300:
                                threshold = 0.6;
                                break;
                            default:
                                threshold = 1;
                        }

                        // console.log(i);
                        // console.log(i.getBoundingClientRect().height);
                        // console.log('threshold', threshold);
                        const observer = new IntersectionObserver(
                            (entries: IntersectionObserverEntry[]) => {
                                this.observerCallback(entries);
                            },
                            {
                                root: null,
                                rootMargin: '0px',
                                threshold: threshold,
                            },
                        );
                        observer.observe(i);
                    });
            }
        },
    },
};
