import { debounce } from 'lodash';
import { ActionContext } from 'vuex';
import { QueryNames } from '@/enums/querynames.enum';
import {
    DEBOUNCE,
    BOUNDS,
    LATITUDE,
    LONGITUDE,
    ZOOM,
} from '@/constants/search.const';
import Api from '@/functions/api';
import { EventBus } from '@/functions/eventBus';
import Query from '@/functions/query';
import Coordinates from '@/interfaces/coordinates.interface';
import Filter from '@/interfaces/filter.interface';
import PropertiesListResponse from '@/interfaces/responses/propertiesListResponse.interface';
import PropertiesMapResponse from '@/interfaces/responses/propertiesMapResponse.interface';

export default {
    /**
     * Fetch list of properties for list view.
     *
     * @param {ActionContext} context
     * @param {boolean} ignorePolygon
     * @return {void}
     */
    fetchListResults: debounce(
        ({ dispatch, commit, state }: any, ignorePolygon: boolean) => {
            dispatch('addFilters');
            Query.update(QueryNames.order, state.order);

            commit('setValue', { key: 'listIsCanceled', value: false });
            commit('setValue', { key: 'listIsLoading', value: true });
            commit('setValue', { key: 'listResults', value: [] });
            commit('setValue', { key: 'page', value: 1 });
            commit('setValue', { key: 'totalCount', value: 0 });
            commit(
                'setPropertiesListCancelToken',
                Api.getPropertiesListCancelToken(
                    state.propertiesListCancelToken,
                ),
            );

            let promise = Api.getPropertiesList({
                area: state.area,
                bounds: ignorePolygon ? [] : state.bounds,
                filters: state.filters,
                order: state.order,
                page: state.page,
                query: state.query,
                propertiesListCancelToken: state.propertiesListCancelToken,
            })
                .then((response: PropertiesListResponse) => {
                    commit('setPropertiesListCancelToken', null);
                    commit('setValue', {
                        key: 'listResults',
                        value: response.data,
                    });
                    commit('setValue', {
                        key: 'totalCount',
                        value: response.totalCount,
                    });
                })
                .catch((e) => {
                    if (e.code === 'ERR_CANCELED') {
                        commit('setValue', {
                            key: 'listIsCanceled',
                            value: true,
                        });
                    }
                })
                .finally(() => {
                    commit('setValue', { key: 'initialLoad', value: false });
                    commit('setValue', { key: 'listIsLoading', value: false });
                });
        },
        DEBOUNCE,
    ),

    /**
     * Fetch list of properties for the map view.
     *
     * @param {ActionContext} context
     * @param {boolean} ignorePolygon
     * @return {void}
     */
    fetchMapResults: debounce(
        (
            { commit, state }: ActionContext<any, any>,
            ignorePolygon: boolean,
        ) => {
            commit('setValue', { key: 'mapIsLoading', value: true });
            commit('setValue', { key: 'mapResults', value: [] });
            commit(
                'setPropertiesMapCancelToken',
                Api.getPropertiesMapCancelToken(state.propertiesMapCancelToken),
            );

            let promise = Api.getPropertiesMap({
                area: state.area,
                bounds: ignorePolygon ? [] : state.bounds,
                filters: state.filters,
                order: state.order,
                query: state.query,
                zoom: ignorePolygon ? ZOOM : state.zoom,
                propertiesMapCancelToken: state.propertiesMapCancelToken,
            })
                .then((response: PropertiesMapResponse) => {
                    commit('setPropertiesMapCancelToken', null);
                    commit('setValue', {
                        key: 'mapResults',
                        value: response.results,
                    });
                })
                .catch(() => {
                    // console.log(e);
                })
                .finally(() => {
                    commit('setValue', { key: 'initialLoad', value: false });
                    commit('setValue', { key: 'mapIsLoading', value: false });
                });
        },
        DEBOUNCE,
    ),

    /**
     * Fetch results for both views.
     *
     * @param {ActionContext} context
     * @param {boolean} ignorePolygon
     * @return {void}
     */
    fetchResults(
        { dispatch, state }: ActionContext<any, any>,
        ignorePolygon: boolean = false,
    ): void {
        if (state.filtersLoaded) {
            dispatch('fetchListResults', ignorePolygon);
            dispatch('fetchMapResults', ignorePolygon);
        }
    },

    /**
     * Get the next page of results.
     *
     * @param {ActionContext} context
     * @param {boolean} ignorePolygon
     * @return {void}
     */
    fetchNextPage(
        { commit, state }: ActionContext<any, any>,
        ignorePolygon: boolean = false,
    ): void {
        commit('setValue', { key: 'listIsLoading', value: true });
        commit('setValue', { key: 'page', value: (state.page += 1) });

        let promise = Api.getPropertiesList({
            area: state.area,
            bounds: ignorePolygon ? [] : state.bounds,
            filters: state.filters,
            order: state.order,
            page: state.page,
            query: state.query,
        })
            .then((response: PropertiesListResponse) => {
                commit('setValue', {
                    key: 'listResults',
                    value: [...state.listResults, ...response.data],
                });
            })
            .finally(() => {
                commit('setValue', { key: 'listIsLoading', value: false });
            });
    },

    /**
     * Set value of filtersLoaded.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    filtersLoaded({ commit, dispatch, state }: ActionContext<any, any>): void {
        if (
            state.latitude === LATITUDE &&
            state.longitude === LONGITUDE &&
            state.zoom === ZOOM
        ) {
            commit('setValue', { key: 'fitProperties', value: true });
        } else {
            commit('setValue', { key: 'fitProperties', value: false });
        }

        commit('setValue', { key: 'filtersLoaded', value: true });
        dispatch('fetchResults');
    },

    /**
     * Remove a filter from the list.
     *
     * @param {ActionContext} context
     * @param {Filter} payload
     * @return {void}
     */
    removeFilter(
        { commit, dispatch }: ActionContext<any, any>,
        payload: Filter,
    ): void {
        commit('removeFilter', payload);

        if (payload.shortName) {
            Query.remove(payload.shortName);
        }

        dispatch('fetchResults');
    },

    /**
     * Add a filter from the list.
     *
     * @param {any} state
     * @param {Filter} payload
     * @return {void}
     */
    addFilter({ commit, dispatch }: any, payload: Filter): void {
        commit('addFilter', payload);

        if (payload.shortName && payload.value) {
            Query.append(payload.shortName, payload.value);
        }

        dispatch('fetchResults');
    },

    /**
     * Add all filters to query.
     *
     * @param {any} state
     * @return {void}
     */
    addFilters({ state }: any): void {
        Query.removeAll(Object.values(QueryNames));

        state.filters.forEach((filter: any) => {
            Query.append(filter.shortName, filter.value);
        });
    },

    /**
     * Reset all filters.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    resetFilters({ commit, dispatch }: ActionContext<any, any>): void {
        commit('setValue', { key: 'filters', value: [] });
        Query.removeAll(Object.values(QueryNames));
        dispatch('fetchResults');
    },

    /**
     * Reset all filters.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    resetFiltersAndZoom({ commit }: ActionContext<any, any>): void {
        commit('setValue', { key: 'area', value: [] });
        commit('setValue', { key: 'bounds', value: BOUNDS });
        commit('setValue', { key: 'filters', value: [] });
        commit('setValue', { key: 'latitude', value: LATITUDE });
        commit('setValue', { key: 'longitude', value: LONGITUDE });
        commit('setValue', { key: 'query', value: '' });
        commit('setValue', { key: 'zoom', value: ZOOM });

        Query.removeAll(Object.values(QueryNames));
    },

    /**
     * Set value of activeProperty.
     *
     * @param {ActionContext} context
     * @param {string | null} value
     * @return {void}
     */
    setActiveProperty(
        { commit }: ActionContext<any, any>,
        value: string | null,
    ): void {
        if (!EventBus.isDesktop) {
            return;
        }

        commit('setValue', { key: 'activeProperty', value });
    },

    /**
     * Set value of area.
     *
     * @param {ActionContext} context
     * @param {number[][] | null} value
     * @return {void}
     */
    setArea(
        { commit, dispatch }: ActionContext<any, any>,
        value: number[][] | null,
    ): void {
        commit('setValue', { key: 'area', value });
        commit('setValue', { key: 'query', value: '' });
        dispatch('fetchResults');
    },

    /**
     * Set value of bounds.
     *
     * @param {ActionContext} context
     * @param {number[][]} value
     * @return {void}
     */
    setBounds(
        { commit, dispatch }: ActionContext<any, any>,
        value: number[][],
    ): void {
        commit('setValue', { key: 'bounds', value });
        dispatch('fetchResults');
    },

    /**
     * Set value of latitude.
     *
     * @param {ActionContext} context
     * @param {number} value
     * @return {void}
     */
    setLatitude({ commit }: ActionContext<any, any>, value: number): void {
        commit('setValue', { key: 'latitude', value });

        Query.update(QueryNames.latitude, value.toFixed(7));
    },

    /**
     * Set value of longitude.
     *
     * @param {ActionContext} context
     * @param {number} value
     * @return {void}
     */
    setLongitude({ commit }: ActionContext<any, any>, value: number): void {
        commit('setValue', { key: 'longitude', value });

        Query.update(QueryNames.longitude, value.toFixed(7));
    },

    /**
     * Set value of mapHasInteraction.
     *
     * @param {ActionContext} context
     * @param {boolean} value
     * @return {void}
     */
    setMapHasInteraction(
        { commit, dispatch }: ActionContext<any, any>,
        value: boolean,
    ): void {
        commit('setValue', { key: 'mapHasInteraction', value });

        if (value === false) {
            dispatch('fetchResults', true);
        }
    },

    /**
     * Set value of order.
     *
     * @param {ActionContext} context
     * @param {string} value
     * @return {void}
     */
    setOrder(
        { commit, dispatch }: ActionContext<any, any>,
        value: string,
    ): void {
        commit('setValue', { key: 'order', value });
        dispatch('fetchResults');

        if (value === 'relevant') {
            Query.remove(QueryNames.order);
        } else {
            Query.update(QueryNames.order, value);
        }
    },

    /**
     * Set value of query.
     *
     * @param {ActionContext} context
     * @param {String} value
     * @return {Void}
     */
    setQuery(
        { commit, dispatch }: ActionContext<any, any>,
        value: string,
    ): void {
        commit('setValue', { key: 'query', value });
        dispatch('fetchResults', true);

        Query.update(QueryNames.query, value);
    },

    /**
     * Set value of users location.
     *
     * @param {ActionContext} context
     * @param {Coordinates} value
     * @return {void}
     */
    setUserLocation(
        { commit }: ActionContext<any, any>,
        value: Coordinates,
    ): void {
        commit('setValue', { key: 'userLocation', value });
    },

    /**
     * Set value of view.
     *
     * @param {ActionContext} context
     * @param {string} value
     * @return {void}
     */
    setView(
        { commit, dispatch }: ActionContext<any, any>,
        value: string,
    ): void {
        commit('setValue', { key: 'view', value });
        dispatch('fetchResults');
    },

    /**
     * Set value of view.
     *
     * @param {any} state
     * @param {string} value
     * @return {void}
     */
    setFilter({ commit, dispatch }: any, payload: Filter): void {
        commit('addFilter', payload);
        dispatch('fetchResults');
    },

    /**
     * Set value of map zoom.
     *
     * @param {ActionContext} context
     * @param {number} value
     * @return {void}
     */
    setZoom({ commit }: ActionContext<any, any>, value: number): void {
        commit('setValue', { key: 'zoom', value });

        Query.update(QueryNames.zoom, value.toFixed(2));
    },

    /**
     * Toggle the current view.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    toggleView({ dispatch, state }: ActionContext<any, any>): void {
        if (state.view === 'list') {
            dispatch('setView', 'map');
        } else {
            dispatch('setView', 'list');
        }
    },

    /**
     * Toggle a filter in the list.
     *
     * @param {ActionContext} context
     * @param {Filter} payload
     * @return {void}
     */
    toggleFilter({ dispatch, state }: any, payload: Filter): void {
        if (
            state.filters.some(
                (filter: Filter) =>
                    filter.key === payload.key &&
                    filter.value === payload.value,
            )
        ) {
            dispatch('removeFilter', payload);
        } else {
            dispatch('addFilter', payload);
        }

        dispatch('fetchResults');
    },

    /**
     * Update a filter in the list.
     *
     * @param {ActionContext} context
     * @param {Filter} payload
     * @return {void}
     */
    updateFilter(
        { commit, dispatch, state }: ActionContext<any, any>,
        payload: Filter,
    ): void {
        if (
            state.filters.find((filter: Filter) => filter.key === payload.key)
        ) {
            commit('updateFilter', payload);
        } else {
            commit('addFilter', payload);
        }

        if (payload.shortName) {
            Query.update(payload.shortName, payload.value);
        }

        dispatch('fetchResults');
    },

    /**
     * Clear filters.
     *
     * @return {void}
     */
    clearFilters({ commit }: any): void {
        commit('clearFilters');
    },

    setIsDrawingArea({ commit }: any, payload: boolean): void {
        commit('setIsDrawingArea', payload);
    },
};
