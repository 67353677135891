import State from './interface';
import MapAddress from '../../interfaces/mapAddress.interface';

export default {
    /**
     * Get address object.
     *
     * @param {State} state
     * @return {string | null}
     */
    address(state: State): MapAddress | null {
        if (!state.dawa.data) return null;
        return {
            geometry: {
                coordinates: [state.dawa.data.x, state.dawa.data.y],
                type: 'Point',
            },
            properties: {
                label: state.dawa.tekst,
            },
            type: 'Feature',
        };
    },
    /**
     * Get state of addressId.
     *
     * @param {State} state
     * @return {string | null}
     */
    addressId(state: State): string | null {
        return state.dawa.data?.id;
    },

    /**
     * Get state of areaname.
     *
     * @param {State} state
     * @return {string | null}
     */
    areaName(state: State): string | null {
        return state.dawa.data?.postnrnavn;
    },

    /**
     * Check if modal should be shown.
     *
     * @param {State} state
     * @return {boolean}
     */
    show(state: State): boolean {
        return state.show;
    },

    /**
     * Get type value.
     *
     * @param {State} state
     * @return {string}
     */
    type(state: State): string {
        return state.type;
    },

    /**
     * Get coordinates of the property
     *
     * @param {State} state
     * @return {string | null}
     */
    propertyCoordinates(state: State): number[] | null {
        if (!state.dawa.data) return null;
        return [state.dawa.data.x, state.dawa.data.y];
    },
    /**
     * Get state of zip code.
     *
     * @param {State} state
     * @return {string | null}
     */
    zipCode(state: State): string | null {
        return state.dawa.data?.postnr;
    },
};
