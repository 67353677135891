
import { PropType } from 'vue';

export default {
    name: 'm-tag',
    props: {
        checked: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        title: {
            type: String as PropType<string>,
            default: () => '',
        },
        value: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    computed: {
        /**
         * Check if component is checked.
         *
         * @return {boolean}
         */
        isChecked(): boolean {
            if (this.checked !== undefined) {
                return this.checked;
            }

            return this.value !== false;
        },

        isDisabled(): boolean {
            return this.disabled !== false;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-tag--${this.theme}` : '';
        },
    },

    methods: {
        /**
         * Handle click event.
         *
         * @return {void}
         */
        onClick(): void {
            this.$emit('input', !this.value);
        },
    },
};
