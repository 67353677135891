import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_good_table = _resolveComponent("vue-good-table")!

  return (_openBlock(), _createBlock(_component_vue_good_table, {
    columns: $options.formatColumns,
    rows: $options.formatRows,
    "style-class": "vgt-table",
    "search-options": {
            enabled: true,
            skipDiacritics: true,
            searchFn: $options.searchTable,
            placeholder: 'Søg i tabellen',
        },
    "pagination-options": {
            enabled: true,
            perPageDropdown: [10, 20, 50, 100],
            rowsPerPageLabel: 'Vis linjer',
            nextLabel: 'Næste',
            prevLabel: 'Forrige',
        }
  }, null, 8 /* PROPS */, ["columns", "rows", "search-options"]))
}