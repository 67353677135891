
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/functions/eventBus';
import Format from '@/functions/format';
import Broker from '@/interfaces/broker.interface';

interface IData {
    showPhoneLink: boolean;
}

export default {
    props: {
        data: {
            type: Object as PropType<Broker>,
            default: () => ({
                address: '',
                brokerId: '',
                employeeId: '',
                city: '',
                email: '',
                hasPicture: '',
                imageUrl: '',
                name: '',
                phone: '',
                storeName: '',
                url: '',
                zipCode: '',
            }),
        },
        flexible: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        isLink: {
            type: Boolean as PropType<boolean>,
            default: () => true,
        },
    },

    data(): IData {
        return {
            showPhoneLink: true,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),

        formattedUrl(): string {
            return this.data?.imageUrl
                && this.data?.imageUrl.includes('deviceid')
                ? Format.formatMindworkingUrl(
                    this.data?.imageUrl ?? '',
                    '300',
                    '',
                )
                : this.data?.imageUrl ?? '';
        },
    },

    mounted(): void {
        EventBus.$on('app.resize', this.calculateShowPhoneLink);
        this.calculateShowPhoneLink();
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        calculateShowPhoneLink(): void {
            this.showPhoneLink = !EventBus.isDesktop;
        },

        showBroker(url: string): void {
            if (this.isLink) {
                window.location.href = `/ejendomsmaegler/${url}/`;
            }
        },
    },
};
