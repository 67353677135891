
import { PropType } from 'vue';
import ProductCardInterface from '@/interfaces/productCard.interface';
import ProductCard from '@/components/molecules/ProductCard.vue';

export default {
    components: {
        ProductCard,
    },
    props: {
        cardType: {
            type: String,
        },
        header: {
            type: String,
            default: () => '',
        },
        items: {
            type: Array as PropType<ProductCardInterface[]>,
            default: () => [],
        },
    },
};
