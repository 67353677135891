
import { PropType } from 'vue';
import ProductCard from '@/interfaces/productCard.interface';

export default {
    name: 'm-product-card',
    props: {
        product: {
            type: Object as PropType<ProductCard>,
            default: () => ({
                headline: 'Placeholder header',
                text: 'Placeholder',
                icon: undefined,
            }),
        },
    },
};
