
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import oSpeechBubble from '@/components/organisms/SpeechBubble.vue';
import tracking from '@/functions/tracking';

export interface Texts {
    stepHeadline: string;
    explainerText?: string;
    infoHeadline?: string;
    infoDescription?: string;
    infoOpenText?: string;
    ariaLabel?: string;
}
export default {
    components: {
        oSpeechBubble,
    },
    props: {
        brokerLinkTextLineBreak: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        showSpeechBubble: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        // speechBubble: {
        //     type: Object as PropType<oSpeechBubble>,
        //     default: () => ({
        //         backgroundColor: '',
        //         borderColor: '',
        //     }),
        // },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                stepHeadline: '',
                explainerText: '',
                infoHeadline: '',
                infoDescription: '',
                infoOpenText: '',
                ariaLabel: '',
            }),
        },
        step: {
            type: Number as PropType<number>,
            default: () => 0,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),

        /**
         * Check if estimate explainer modal is to be shown.
         *
         * @return {boolean}
         */
        showEstimateTeaserInfo(): boolean {
            return this.show && this.type === `estimateExplainer-${this.step}`;
        },
    },
    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),
        /**
         * Open modal
         *
         * @return {void}
         */
        onClickEstimateTeaserInfo(): void {
            tracking.trackRawEvent({
                event: 'trackBoligskoen',
                eventData: {
                    category: 'Boligskoen',
                    action: 'klik paa info tekst',
                    label: 'Taleboble',
                    formularStepnavn: this.$props.texts.stepHeadline,
                },
            });
            this.openModal(`estimateExplainer-${this.step}`);
        },
    },
};
