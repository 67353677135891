/* eslint-disable */
export enum QueryNames {
    amenityValue = 'herlighedsvaerdi',
    energyLevel = 'energiniveau',
    exteriorCondition = 'udvendigstand',
    interiorCondition = 'indvendigstand',
    latitude = 'la',
    location = 'beliggenhed',
    longitude = 'lo',
    order = 'o',
    query = 'q',
    zoom = 'z',
    radius = 'rad',
}
