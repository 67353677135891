
import { PropType } from 'vue';

interface Data {
    currentIndex: number;
    lastKnownScrollPosition: number;
    ticking: boolean;
}

export default {
    props: {
        size: {
            type: Number as PropType<number>,
            default: () => 0,
        },
    },

    data(): Data {
        return {
            currentIndex: 0,
            lastKnownScrollPosition: 0,
            ticking: false,
        };
    },

    mounted(): void {
        const hash = document.location.hash.replace('#', '');
        if (hash) {
            const el = document.querySelector(
                `div[id="${hash}"]`,
            ) as HTMLDivElement;
            if (el) el.scrollIntoView();
        }
    },

    methods: {
        swipe(): void {
            const swipeWrapper = this.$root.$refs
                .customerStoriesSwipe as HTMLDivElement;
            this.lastKnownScrollPosition = swipeWrapper.scrollLeft;

            if (!this.ticking) {
                window.requestAnimationFrame(() => {
                    this.closeHiddenTexts();
                    this.ticking = false;
                });

                this.ticking = true;
            }
        },
        /**
         * Increase index.
         *
         * @return {void}
         */
        next(): void {
            this.currentIndex = (this.currentIndex + 1) % this.size;
            this.scroll();
        },

        /**
         * Decrease index.
         *
         * @return {void}
         */
        prev(): void {
            if (this.currentIndex > 0) {
                this.currentIndex -= 1;
            } else {
                this.currentIndex = this.size - 1;
            }
            this.scroll();
        },

        closeHiddenTexts(): void {
            const wrapper = this.$root.$refs.customerStories as HTMLDivElement;
            const allHiddenTexts = [
                ...Array.from(
                    wrapper.querySelectorAll(
                        '.o-hiddenText > button[type="button"].isRevealed',
                    ),
                ),
            ] as HTMLButtonElement[];

            allHiddenTexts.forEach((element: HTMLButtonElement) => {
                element.click();
            });
        },

        /**
         * Scroll to current index.
         *
         * @return {void}
         */
        scroll(): void {
            const elem = document.getElementById(
                `customerStory-${this.currentIndex}`,
            ) as HTMLDivElement;
            const wrapper = this.$root.$refs.customerStories as HTMLDivElement;
            wrapper.style.transform = `translateX(-${elem.clientWidth * this.currentIndex}px)`;
            this.closeHiddenTexts();
        },
    },
};
