
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { EventBus } from '@/functions/eventBus';
import Broker from '@/interfaces/broker.interface';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';

interface Data {
    currentDirection: string;
    currentSlide: number;
    isMultiline: boolean;
    uuid: string;
}

export default {
    props: {
        index: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        broker: {
            type: Object as PropType<Broker>,
            default: () => ({
                address: '',
                brokerId: '',
                employeeId: '',
                city: '',
                email: '',
                hasPicture: false,
                imageUrl: '',
                name: '',
                phone: '',
                storeName: '',
                url: '',
                zipCode: '',
            }),
        },
        isPin: {
            type: Boolean as PropType<boolean | null>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            currentSlide: 0,
            isMultiline: false,
            uuid: '',
        };
    },

    computed: {
        formattedUrl(): string {
            return this.broker?.imageUrl &&
                this.broker?.imageUrl.includes('deviceid')
                ? Format.formatMindworkingUrl(
                      this.broker?.imageUrl ?? '',
                      '700',
                      '',
                  )
                : this.broker?.imageUrl ?? '';
        },
    },

    mounted(): void {
        this.uuid = `guid-${uuidv4()}`;
        EventBus.$on('app.resize', this.calculateHeight);
        this.calculateHeight();
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    methods: {
        ...mapActions({
            setActiveBroker: 'brokerSearch/setActiveBroker',
        }),
        /**
         * Calculate if street name is multiline.
         *
         * @return {void}
         */
        calculateHeight(): void {
            const element = this.$refs.street as HTMLSpanElement;

            if (element !== undefined) {
                const elementHeight = element.offsetHeight;
                const elementLineHeight = parseInt(
                    window
                        .getComputedStyle(element, null)
                        .getPropertyValue('line-height'),
                    10,
                );

                this.isMultiline = elementHeight > elementLineHeight;
            }
        },

        onClick(): void {
            let previewText = this.isPin ? 'Pin paa kort' : 'Liste';
            tracking.track(
                'trackMaeglersoegning',
                'Maeglersoegning',
                `Maegler preview klik - ${previewText}`,
                this.broker?.brokerId ?? 'Ukendt maegler ID',
            );
        },
    },
};
