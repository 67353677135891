
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import tracking from '@/functions/tracking';

export interface Texts {
    stepHeadline: string;
    brokerCardText?: string;
}
export default {
    components: {},
    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                stepHeadline: '',
                brokerCardText: '',
            }),
        },
        image: {
            type: String as PropType<string>,
            default: () => '',
        },
        step: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        activeStepName: {
            type: String as PropType<string>,
            default: () => '',
        },
        brokerId: {
            type: String as PropType<string>,
            default: () => '',
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),

        /**
         * Check if estimate explainer modal is to be shown.
         *
         * @return {boolean}
         */
        showEstimateTeaserInfo(): boolean {
            return this.show && this.type === `estimateExplainer-${this.step}`;
        },
    },
    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),
        /**
         * Open modal
         *
         * @return {void}
         */
        openContactBrokerCurtain() {
            this.$emit('clickOrderEstimate');
            this.openModal('brokerContact');
            this.setTrackingEntrance(`Boligskoen flow ${this.activeStepName}`);

            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Salgsvurdering indgang',
                    label: 'Salgsvurdering',
                    formularIndgang: `Boligskoen flow ${this.activeStepName}`,
                    formularStepnavn: 'Klik paa modul',
                    maeglerId: this.brokerId,
                },
            });
        },
    },
};
