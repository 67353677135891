import actions from './brokerSearch/actions';
import getters from './brokerSearch/getters';
import mutations from './brokerSearch/mutations';
import state from './brokerSearch/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
