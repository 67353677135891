import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: "navigation",
  class: "o-panel"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  class: "o-panel__nav",
  role: "group",
  "aria-label": "Vælg den ønskede handling"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = {
  key: "getPhoneCall",
  class: "o-panel"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: "getPhoneCall1",
  class: "o-subPanel"
}
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "o-fields" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = {
  key: "sendMessage",
  class: "o-panel"
}
const _hoisted_17 = ["textContent"]
const _hoisted_18 = {
  key: "sendMessage1",
  class: "o-subPanel"
}
const _hoisted_19 = ["textContent"]
const _hoisted_20 = { class: "o-fields" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = ["textContent"]
const _hoisted_23 = ["textContent"]
const _hoisted_24 = {
  key: "orderEvaluation",
  class: "o-panel"
}
const _hoisted_25 = ["textContent"]
const _hoisted_26 = {
  key: "orderEvaluation1",
  class: "o-subPanel"
}
const _hoisted_27 = ["textContent"]
const _hoisted_28 = { class: "o-fields" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = ["textContent"]
const _hoisted_31 = ["textContent"]
const _hoisted_32 = {
  key: "buyerAdvice",
  class: "o-panel"
}
const _hoisted_33 = ["textContent"]
const _hoisted_34 = {
  key: "buyerAdvice1",
  class: "o-subPanel"
}
const _hoisted_35 = ["textContent"]
const _hoisted_36 = { class: "o-fields" }
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = ["textContent"]
const _hoisted_39 = ["textContent"]
const _hoisted_40 = {
  key: "successMessage",
  class: "o-panel"
}
const _hoisted_41 = ["textContent"]
const _hoisted_42 = { class: "a-confirmationNotice" }
const _hoisted_43 = { class: "a-confirmationNotice__checkmark" }
const _hoisted_44 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_broker_map_card = _resolveComponent("o-broker-map-card")!
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_o_overlay_promo_box = _resolveComponent("o-overlay-promo-box")!
  const _component_portal = _resolveComponent("portal")!

  return ($options.isVisible)
    ? (_openBlock(), _createBlock(_component_portal, {
        key: 0,
        to: "modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: `o-panel--${$data.currentDirection}`
          }, {
            default: _withCtx(() => [
              (!_ctx.currentPanel)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("h2", {
                      class: "a-heading3 o-panel__title",
                      textContent: _toDisplayString($props.texts.panelHeadline)
                    }, null, 8 /* PROPS */, _hoisted_2),
                    _createVNode(_component_o_broker_map_card, {
                      data: $props.broker,
                      "is-link": false,
                      class: "o-panel__brokerCard"
                    }, null, 8 /* PROPS */, ["data"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "o-panel__navItem a-heading3",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.nextPanel('getPhoneCall', true)))
                      }, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString($props.texts.getCallMenuName)
                        }, null, 8 /* PROPS */, _hoisted_4),
                        _createVNode(_component_a_icon, {
                          name: "chevron_right",
                          class: "o-panel__icon"
                        })
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        class: "o-panel__navItem a-heading3",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.nextPanel('sendMessage', true)))
                      }, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString($props.texts.sendMessageMenuName)
                        }, null, 8 /* PROPS */, _hoisted_5),
                        _createVNode(_component_a_icon, {
                          name: "chevron_right",
                          class: "o-panel__icon"
                        })
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        class: "o-panel__navItem a-heading3",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.nextPanel('orderEvaluation', true)))
                      }, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString($props.texts.orderEvaluationMenuName)
                        }, null, 8 /* PROPS */, _hoisted_6),
                        _createVNode(_component_a_icon, {
                          name: "chevron_right",
                          class: "o-panel__icon"
                        })
                      ]),
                      _createElementVNode("button", {
                        type: "button",
                        class: "o-panel__navItem a-heading3",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.nextPanel('buyerAdvice', true)))
                      }, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString($props.texts.buyerAdviceMenuName)
                        }, null, 8 /* PROPS */, _hoisted_7),
                        _createVNode(_component_a_icon, {
                          name: "chevron_right",
                          class: "o-panel__icon"
                        })
                      ])
                    ])
                  ]))
                : (_ctx.currentPanel === 'getPhoneCall')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("h2", {
                        class: "a-heading3 o-panel__title",
                        textContent: _toDisplayString($props.texts.getCallHeadingText)
                      }, null, 8 /* PROPS */, _hoisted_9),
                      _createVNode(_component_o_broker_map_card, {
                        data: $props.broker,
                        "is-link": false,
                        class: "o-panel__brokerCard"
                      }, null, 8 /* PROPS */, ["data"]),
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.currentSubPanel === 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createElementVNode("p", {
                                  class: "a-paragraph o-subPanel__description",
                                  textContent: _toDisplayString($props.texts.getCallDescriptionText)
                                }, null, 8 /* PROPS */, _hoisted_11),
                                _createElementVNode("div", _hoisted_12, [
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.name,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.name) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.name,
                                    label: `${$props.texts.form.name}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.phone,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.form.phone) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.phone,
                                    label: `${$props.texts.form.phone}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_textarea, {
                                    modelValue: $data.form.message,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.form.message) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.message,
                                    label: $props.texts.form.message
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
                                ]),
                                ($props.consents && $props.consents.contactConsent)
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "a-small a-small--allowBreak o-fields__field",
                                      innerHTML: $props.consents.contactConsent.purposeText
                                    }, null, 8 /* PROPS */, _hoisted_13))
                                  : _createCommentVNode("v-if", true),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                }, [
                                  (!_ctx.fixedPanel)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        type: "button",
                                        class: "m-button m-button--transparent",
                                        onClick: _cache[7] || (_cache[7] = ($event: any) => ($options.previousPanel('')))
                                      }, [
                                        _createVNode(_component_a_icon, {
                                          class: "m-button__icon",
                                          name: "arrow_back"
                                        }),
                                        _createElementVNode("span", {
                                          class: "m-button__text",
                                          textContent: _toDisplayString($props.texts.backButton)
                                        }, null, 8 /* PROPS */, _hoisted_14)
                                      ]))
                                    : _createCommentVNode("v-if", true),
                                  _createElementVNode("button", {
                                    type: "submit",
                                    class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                    onClick: _cache[8] || (_cache[8] = ($event: any) => ($options.onSubmit('callMe')))
                                  }, [
                                    _createElementVNode("span", {
                                      class: "m-button__text",
                                      textContent: _toDisplayString($props.texts.getCallSubmitButtonText)
                                    }, null, 8 /* PROPS */, _hoisted_15)
                                  ], 2 /* CLASS */)
                                ], 2 /* CLASS */)
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], undefined, true),
                        _: 1 /* STABLE */
                      })
                    ]))
                  : (_ctx.currentPanel === 'sendMessage')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("h2", {
                          class: "a-heading3 o-panel__title",
                          textContent: _toDisplayString($props.texts.sendMessageHeadingText)
                        }, null, 8 /* PROPS */, _hoisted_17),
                        _createVNode(_component_o_broker_map_card, {
                          data: $props.broker,
                          "is-link": false,
                          class: "o-panel__brokerCard"
                        }, null, 8 /* PROPS */, ["data"]),
                        _createVNode(_Transition, {
                          name: "fade",
                          mode: "out-in"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.currentSubPanel === 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                  _createElementVNode("p", {
                                    class: "a-paragraph o-subPanel__description",
                                    textContent: _toDisplayString($props.texts.sendMessageDescriptionText)
                                  }, null, 8 /* PROPS */, _hoisted_19),
                                  _createElementVNode("div", _hoisted_20, [
                                    _createVNode(_component_m_textarea, {
                                      modelValue: $data.form.message,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.form.message) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.message,
                                      label: `${$props.texts.form.message}*`
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                    _createVNode(_component_m_input, {
                                      modelValue: $data.form.name,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($data.form.name) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.name,
                                      label: `${$props.texts.form.name}*`
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                    _createVNode(_component_m_input, {
                                      modelValue: $data.form.mail,
                                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.form.mail) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.mail,
                                      label: $props.texts.form.mail
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                    _createVNode(_component_m_input, {
                                      modelValue: $data.form.phone,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($data.form.phone) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.phone,
                                      label: `${$props.texts.form.phone}*`
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
                                  ]),
                                  ($props.consents && $props.consents.contactConsent)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "a-small a-small--allowBreak o-fields__field",
                                        innerHTML: $props.consents.contactConsent.purposeText
                                      }, null, 8 /* PROPS */, _hoisted_21))
                                    : _createCommentVNode("v-if", true),
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                  }, [
                                    (!_ctx.fixedPanel)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          type: "button",
                                          class: "m-button m-button--transparent",
                                          onClick: _cache[13] || (_cache[13] = ($event: any) => ($options.previousPanel('')))
                                        }, [
                                          _createVNode(_component_a_icon, {
                                            class: "m-button__icon",
                                            name: "arrow_back"
                                          }),
                                          _createElementVNode("span", {
                                            class: "m-button__text",
                                            textContent: _toDisplayString($props.texts.backButton)
                                          }, null, 8 /* PROPS */, _hoisted_22)
                                        ]))
                                      : _createCommentVNode("v-if", true),
                                    _createElementVNode("button", {
                                      type: "submit",
                                      class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                      onClick: _cache[14] || (_cache[14] = ($event: any) => ($options.onSubmit('sendMessage')))
                                    }, [
                                      _createElementVNode("span", {
                                        class: "m-button__text",
                                        textContent: _toDisplayString($props.texts.sendMessageSubmitButtonText)
                                      }, null, 8 /* PROPS */, _hoisted_23)
                                    ], 2 /* CLASS */)
                                  ], 2 /* CLASS */)
                                ]))
                              : _createCommentVNode("v-if", true)
                          ], undefined, true),
                          _: 1 /* STABLE */
                        })
                      ]))
                    : (_ctx.currentPanel === 'orderEvaluation')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createElementVNode("h2", {
                            class: "a-heading3 o-panel__title",
                            textContent: _toDisplayString($props.texts.orderEvaluationHeadingText)
                          }, null, 8 /* PROPS */, _hoisted_25),
                          _createVNode(_component_o_broker_map_card, {
                            data: $props.broker,
                            "is-link": false,
                            class: "o-panel__brokerCard"
                          }, null, 8 /* PROPS */, ["data"]),
                          _createVNode(_Transition, {
                            name: "fade",
                            mode: "out-in"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.currentSubPanel === 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                    _createElementVNode("p", {
                                      class: "a-paragraph o-subPanel__description",
                                      textContent: _toDisplayString($props.texts.orderEvaluationDescriptionText)
                                    }, null, 8 /* PROPS */, _hoisted_27),
                                    _createElementVNode("div", _hoisted_28, [
                                      _createVNode(_component_m_input, {
                                        value: $data.form.address,
                                        class: "o-fields__field",
                                        name: "address‌_search",
                                        error: $data.errors.address,
                                        label: `${$props.texts.form.address}*`,
                                        type: "dawa",
                                        onDawa: $options.enableSubmit,
                                        onDawaReset: $options.disableSubmit,
                                        onInput: $options.setAddressInfo,
                                        onFocus: _cache[15] || (_cache[15] = ($event: any) => ($options.setIsFocused(true))),
                                        onBlur: _cache[16] || (_cache[16] = ($event: any) => ($options.setIsFocused(false)))
                                      }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
                                      _createVNode(_component_m_input, {
                                        modelValue: $data.form.name,
                                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($data.form.name) = $event)),
                                        class: "o-fields__field",
                                        name: "name",
                                        error: $data.errors.name,
                                        label: `${$props.texts.form.name}*`
                                      }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                      _createVNode(_component_m_input, {
                                        modelValue: $data.form.phone,
                                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($data.form.phone) = $event)),
                                        class: "o-fields__field",
                                        name: "phone",
                                        error: $data.errors.phone,
                                        label: `${$props.texts.form.phone}*`
                                      }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                      _createVNode(_component_m_input, {
                                        modelValue: $data.form.mail,
                                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($data.form.mail) = $event)),
                                        class: "o-fields__field",
                                        name: "mail",
                                        error: $data.errors.mail,
                                        label: `${$props.texts.form.mail}`
                                      }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
                                    ]),
                                    ($props.consents && $props.consents.salesValuationConsent)
                                      ? (_openBlock(), _createElementBlock("div", {
                                          key: 0,
                                          class: "a-small a-small--allowBreak o-fields__field",
                                          innerHTML: $props.consents.salesValuationConsent.purposeText
                                        }, null, 8 /* PROPS */, _hoisted_29))
                                      : _createCommentVNode("v-if", true),
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                    }, [
                                      (!_ctx.fixedPanel)
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            type: "button",
                                            class: "m-button m-button--transparent",
                                            onClick: _cache[20] || (_cache[20] = ($event: any) => ($options.previousPanel('')))
                                          }, [
                                            _createVNode(_component_a_icon, {
                                              class: "m-button__icon",
                                              name: "arrow_back"
                                            }),
                                            _createElementVNode("span", {
                                              class: "m-button__text",
                                              textContent: _toDisplayString($props.texts.backButton)
                                            }, null, 8 /* PROPS */, _hoisted_30)
                                          ]))
                                        : _createCommentVNode("v-if", true),
                                      _createElementVNode("button", {
                                        type: "submit",
                                        class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                        onClick: _cache[21] || (_cache[21] = ($event: any) => ($options.onSubmit('orderEvaluation')))
                                      }, [
                                        _createElementVNode("span", {
                                          class: "m-button__text",
                                          textContent: _toDisplayString(
                                        $props.texts.orderEvaluationSubmitButtonText
                                    )
                                        }, null, 8 /* PROPS */, _hoisted_31)
                                      ], 2 /* CLASS */)
                                    ], 2 /* CLASS */)
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ], undefined, true),
                            _: 1 /* STABLE */
                          })
                        ]))
                      : (_ctx.currentPanel === 'buyerAdvice')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createElementVNode("h2", {
                              class: "a-heading3 o-panel__title",
                              textContent: _toDisplayString($props.texts.buyerAdviceHeadingText)
                            }, null, 8 /* PROPS */, _hoisted_33),
                            _createVNode(_component_o_broker_map_card, {
                              data: $props.broker,
                              "is-link": false,
                              class: "o-panel__brokerCard"
                            }, null, 8 /* PROPS */, ["data"]),
                            _createVNode(_Transition, {
                              name: "fade",
                              mode: "out-in"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.currentSubPanel === 1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                      _createElementVNode("p", {
                                        class: "a-paragraph o-subPanel__description",
                                        textContent: _toDisplayString($props.texts.buyerAdviceDescriptionText)
                                      }, null, 8 /* PROPS */, _hoisted_35),
                                      _createElementVNode("div", _hoisted_36, [
                                        _createVNode(_component_m_input, {
                                          modelValue: $data.form.name,
                                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($data.form.name) = $event)),
                                          class: "o-fields__field",
                                          name: "name",
                                          error: $data.errors.name,
                                          label: `${$props.texts.form.name}*`
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                        _createVNode(_component_m_input, {
                                          modelValue: $data.form.phone,
                                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($data.form.phone) = $event)),
                                          class: "o-fields__field",
                                          name: "phone",
                                          error: $data.errors.phone,
                                          label: `${$props.texts.form.phone}*`
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                        _createVNode(_component_m_input, {
                                          modelValue: $data.form.mail,
                                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($data.form.mail) = $event)),
                                          class: "o-fields__field",
                                          name: "mail",
                                          error: $data.errors.mail,
                                          label: `${$props.texts.form.mail}*`
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                        _createVNode(_component_m_textarea, {
                                          modelValue: $data.form.message,
                                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($data.form.message) = $event)),
                                          class: "o-fields__field",
                                          error: $data.errors.message,
                                          label: $props.texts.form.message
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
                                      ]),
                                      ($props.consents && $props.consents.buyerAdviceConsent)
                                        ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: "a-small a-small--allowBreak o-fields__field",
                                            innerHTML: $props.consents.buyerAdviceConsent.purposeText
                                          }, null, 8 /* PROPS */, _hoisted_37))
                                        : _createCommentVNode("v-if", true),
                                      _createElementVNode("div", {
                                        class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                      }, [
                                        (!_ctx.fixedPanel)
                                          ? (_openBlock(), _createElementBlock("button", {
                                              key: 0,
                                              type: "button",
                                              class: "m-button m-button--transparent",
                                              onClick: _cache[26] || (_cache[26] = ($event: any) => ($options.previousPanel('')))
                                            }, [
                                              _createVNode(_component_a_icon, {
                                                class: "m-button__icon",
                                                name: "arrow_back"
                                              }),
                                              _createElementVNode("span", {
                                                class: "m-button__text",
                                                textContent: _toDisplayString($props.texts.backButton)
                                              }, null, 8 /* PROPS */, _hoisted_38)
                                            ]))
                                          : _createCommentVNode("v-if", true),
                                        _createElementVNode("button", {
                                          type: "submit",
                                          class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                          onClick: _cache[27] || (_cache[27] = ($event: any) => ($options.onSubmit('buyerAdvice')))
                                        }, [
                                          _createElementVNode("span", {
                                            class: "m-button__text",
                                            textContent: _toDisplayString($props.texts.buyerAdviceSubmitButtonText)
                                          }, null, 8 /* PROPS */, _hoisted_39)
                                        ], 2 /* CLASS */)
                                      ], 2 /* CLASS */)
                                    ]))
                                  : _createCommentVNode("v-if", true)
                              ], undefined, true),
                              _: 1 /* STABLE */
                            })
                          ]))
                        : (_ctx.currentPanel === 'successMessage')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                              _createElementVNode("h2", {
                                class: "a-heading3 o-panel__title",
                                textContent: _toDisplayString($props.texts.formReceiptSuccessHeadingText)
                              }, null, 8 /* PROPS */, _hoisted_41),
                              _createVNode(_component_o_broker_map_card, {
                                data: $props.broker,
                                "is-link": false,
                                class: "o-panel__brokerCard"
                              }, null, 8 /* PROPS */, ["data"]),
                              _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("div", _hoisted_43, [
                                  _createVNode(_component_a_icon, { name: "check" })
                                ]),
                                _createElementVNode("span", {
                                  class: "a-label",
                                  textContent: _toDisplayString($props.texts.formReceiptSuccessMessageText)
                                }, null, 8 /* PROPS */, _hoisted_44)
                              ]),
                              ($data.activePromoBoxes === 'getPhoneCall')
                                ? (_openBlock(), _createBlock(_component_o_overlay_promo_box, {
                                    key: 0,
                                    "promo-box": _ctx.$props.promoBoxesGetPhoneCall
                                  }, null, 8 /* PROPS */, ["promo-box"]))
                                : _createCommentVNode("v-if", true),
                              ($data.activePromoBoxes === 'sendMessage')
                                ? (_openBlock(), _createBlock(_component_o_overlay_promo_box, {
                                    key: 1,
                                    "promo-box": _ctx.$props.promoBoxesSendMessage
                                  }, null, 8 /* PROPS */, ["promo-box"]))
                                : _createCommentVNode("v-if", true),
                              ($data.activePromoBoxes === 'orderEvaluation')
                                ? (_openBlock(), _createBlock(_component_o_overlay_promo_box, {
                                    key: 2,
                                    "promo-box": _ctx.$props.promoBoxesOrderEvaluation
                                  }, null, 8 /* PROPS */, ["promo-box"]))
                                : _createCommentVNode("v-if", true),
                              ($data.activePromoBoxes === 'buyerAdvice')
                                ? (_openBlock(), _createBlock(_component_o_overlay_promo_box, {
                                    key: 3,
                                    "promo-box": _ctx.$props.promoBoxesBuyerAdvice
                                  }, null, 8 /* PROPS */, ["promo-box"]))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : _createCommentVNode("v-if", true)
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["name"])
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}