import { ActionContext } from 'vuex';
import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';
import { FILTER_DEFAULTS } from '@/constants/search.const';

export default {
    /**
     * Close the overlay.
     *
     * @param {ActionContext} state
     * @return {void}
     */
    close({ commit }: ActionContext<any, State>): void {
        commit('close');
    },

    /**
     * Open the overlay.
     *
     * @param {ActionContext} state
     * @param {string} panel
     * @return {void}
     */
    open({ commit, dispatch }: ActionContext<any, State>, panel: string): void {
        commit('setCurrentPanel', panel);
        commit('setCurrentSubPanel', 1);
        dispatch('modal/open', 'buyerDirectory', { root: true });
    },

    /**
     * Change the current panel.
     *
     * @param {ActionContext} state
     * @param {string} value
     * @return {void}
     */
    setCurrentPanel(
        { commit }: ActionContext<any, State>,
        value: string,
    ): void {
        commit('setCurrentPanel', value);
    },

    /**
     * Change the current sub panel.
     *
     * @param {ActionContext} state
     * @param {number} panel
     * @return {void}
     */
    setCurrentSubPanel(
        { commit }: ActionContext<any, State>,
        value: number,
    ): void {
        commit('setCurrentSubPanel', value);
    },

    reset({ commit }: ActionContext<any, State>): void {
        commit('resetTags');
        commit('setPrice', [
            FILTER_DEFAULTS.priceMin,
            FILTER_DEFAULTS.priceMax,
        ]);
        commit('setSize', [
            FILTER_DEFAULTS.houseAreaMin,
            FILTER_DEFAULTS.houseAreaMax,
        ]);
        commit('setZipcode', '');
        commit('setComment', '');
        commit('setName', '');
    },

    setTags({ commit }: ActionContext<any, State>, payload: KeyValue): void {
        commit('setTags', payload);
    },

    setPrice(
        { commit }: ActionContext<any, State>,
        payload: [number, number],
    ): void {
        commit('setPrice', payload);
    },

    setSize(
        { commit }: ActionContext<any, State>,
        payload: [number, number],
    ): void {
        commit('setSize', payload);
    },

    setZipcode({ commit }: ActionContext<any, State>, payload: string): void {
        commit('setZipcode', payload);
    },

    setComment({ commit }: ActionContext<any, State>, payload: string): void {
        commit('setComment', payload);
    },

    setName({ commit }: ActionContext<any, State>, payload: string): void {
        commit('setName', payload);
    },
};
