import KeyValue from '@/interfaces/keyValue.interface';
import State from '@/store/brokerSearch/interface';

export default {
    /**
     * Set value.
     *
     * @param {State} state
     * @param {KeyValue} payload
     * @return {void}
     */
    setValue(state: State, payload: KeyValue): void {
        // Vue.set(state, payload.key, payload.value);

        state[payload.key] = payload.value;
    },
};
