
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/functions/eventBus';
import tracking from '@/functions/tracking';

interface Data {
    activeItem: number | null;
    isMounted: boolean;
    showUserAccountMobileMenu: boolean;
}

export default {
    props: {
        currentParent: {
            type: Number as PropType<number>,
            default: 0,
        },
    },

    data(): Data {
        return {
            activeItem: null,
            isMounted: false,
            showUserAccountMobileMenu: false,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
        }),
    },

    beforeMount(): void {
        if (this.currentParent) {
            this.activeItem = this.currentParent;
        }
    },

    mounted(): void {
        this.isMounted = true;

        EventBus.$on('app.resize', () => {
            if (!EventBus.isDesktop) {
                this.hideMobileUserAccountMenu();
            }
        });
    },

    methods: {
        ...mapActions({
            close: 'modal/close',
        }),

        /**
         * Handle click event on parent link.
         *
         * @param {any} event
         * @return {void}
         */
        onToggle(event: any): void {
            let target = event.target.closest('.o-navigation__actions');
            let closestId = target.getAttribute('id');
            let targetIsOpen = target.classList.contains('isOpen');
            targetIsOpen
                ? (this.activeItem = null)
                : (this.activeItem = closestId);
        },

        onMenuItemClick(event: any): void {
            const parentName = event.target.dataset.trackparentname;
            if (parentName !== undefined) {
                tracking.track(
                    'trackHovedMenu',
                    'Hovedmenu',
                    parentName,
                    event.target.textContent.trim(),
                );
            } else {
                tracking.track(
                    'trackHovedMenu',
                    'Hovedmenu',
                    event.target.textContent.trim(),
                    '',
                );
            }
        },

        hideMobileUserAccountMenu(): void {
            this.showUserAccountMobileMenu = false;
        },

        onMobileUserAccountClick(event: TouchEvent | MouseEvent): void {
            this.showUserAccountMobileMenu = !this.showUserAccountMobileMenu;
            event.preventDefault();
            event.stopPropagation();
        },
    },
};
