import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return ($data.isVisible)
    ? (_openBlock(), _createBlock(_TransitionGroup, {
        key: 0,
        class: "o-propertyViews",
        name: "fade",
        mode: "out-in",
        tag: "ul",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.statsList, (item, itemIndex) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (itemIndex === $data.currentIndex)
                ? (_openBlock(), _createElementBlock("li", {
                    key: itemIndex,
                    class: "a-label o-propertyViews__item"
                  }, [
                    _createVNode(_component_a_icon, {
                      class: "o-propertyViews__itemIcon",
                      name: item.type
                    }, null, 8 /* PROPS */, ["name"]),
                    _createElementVNode("span", {
                      textContent: _toDisplayString(item.text)
                    }, null, 8 /* PROPS */, _hoisted_1)
                  ]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}