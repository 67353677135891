
export default {
    name: 'a-energy-label',
    props: {
        name: {
            type: String,
            required: true,
            default: () => '',
        },
    },

    computed: {
        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.name ? `a-energyLabel--${this.name.toLowerCase()}` : '';
        },
    },
};
