import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "o-employeeShopPanelCard" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "o-employeeShopPanelCard__wrapper" }
const _hoisted_5 = { class: "o-employeeShopPanelCard__portrait" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "o-employeeShopPanelCard__content a-label" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["href", "textContent"]
const _hoisted_11 = { key: 2 }
const _hoisted_12 = ["href", "textContent"]
const _hoisted_13 = { key: 3 }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      ($props.employee.type !== '' && $props.employee.type !== 'Medarbejder')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "o-employeeShopPanelCard__tag a_label",
            textContent: _toDisplayString($props.employee.type)
          }, null, 8 /* PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", {
        class: "a-lead",
        textContent: _toDisplayString($props.employee.name)
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: $options.formattedUrl,
            class: "o-employeeShopPanelCard__image",
            alt: "Billede af mægleren"
          }, null, 8 /* PROPS */, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          ($props.employee.title)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                textContent: _toDisplayString($props.employee.title)
              }, null, 8 /* PROPS */, _hoisted_8))
            : _createCommentVNode("v-if", true),
          ($props.employee.phone)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("a", {
                  href: `tel:${$props.employee.phone}`,
                  class: "a-anchor",
                  textContent: _toDisplayString($props.employee.phone)
                }, null, 8 /* PROPS */, _hoisted_10)
              ]))
            : _createCommentVNode("v-if", true),
          ($props.employee.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("a", {
                  href: `mailto:${$props.employee.email}`,
                  class: "a-anchor",
                  textContent: _toDisplayString($props.employee.email)
                }, null, 8 /* PROPS */, _hoisted_12)
              ]))
            : _createCommentVNode("v-if", true),
          ($props.employee.linkedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("a", {
                  href: $props.employee.linkedIn,
                  class: "a-anchor",
                  target: "_blank",
                  rel: "noopener"
                }, "LinkedIn", 8 /* PROPS */, _hoisted_14)
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ])
  ]))
}