
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/functions/eventBus';
import oBrokerContactOverlay from '@/components/organisms/broker/BrokerContactOverlay.vue';
import oBrokerButton from '@/components/organisms/broker/BrokerButton.vue';
import tracking from '@/functions/tracking';

export interface IData {
    isOpeningHoursVisible: boolean;
}

export default {
    components: {
        oBrokerContactOverlay,
        oBrokerButton,
    },

    props: {
        hasVideo: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): IData {
        return {
            isOpeningHoursVisible: false,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),
    },

    mounted(): void {
        EventBus.$on('app.click', () => {
            if (this.isOpeningHoursVisible) {
                const overlay = this.$refs
                    .openingHoursOverlay as HTMLDivElement;
                overlay.classList.remove('o-brokerHero__openingHours--visible');
                this.isOpeningHoursVisible = false;
                const expandable = this.$refs.expandable as HTMLDivElement;
                expandable.classList.remove('isOpen');
            }
        });
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        /**
         * Handle "click" event on blue video button.
         *
         * @return {void}
         */
        onShowVideo(): void {
            this.openModal('popup');
        },

        toggleOpeningHours(e: Event): void {
            e.stopPropagation();
            const overlay = this.$root.$refs
                .openingHoursOverlay as HTMLDivElement;
            const expandable = this.$root.$refs
                .openingHoursExpandable as HTMLDivElement;
            overlay.classList.toggle('o-brokerHero__openingHours--visible');
            expandable.classList.toggle('isOpen');
            this.isOpeningHoursVisible = !this.isOpeningHoursVisible;

            if (expandable.classList.contains('isOpen')) {
                tracking.track(
                    'trackButiksside',
                    'Butiksside',
                    'Accordion åbnet',
                    'Aabningstider',
                );
            }
        },
    },
};
