import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "o-propertyCard__wrapper" }
const _hoisted_4 = { class: "o-propertyCard__imageWrapper" }
const _hoisted_5 = {
  key: 0,
  class: "a-small o-propertyCard__tag",
  textContent: 'Anden mægler'
}
const _hoisted_6 = {
  key: 1,
  class: "a-small o-propertyCard__tag o-propertyCard__tag--liebhaver",
  textContent: 'Liebhaver'
}
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "o-propertyCard__content" }
const _hoisted_10 = { class: "a-small o-propertyCard__text" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = {
  key: 0,
  class: "a-small o-propertyCard__label"
}
const _hoisted_13 = {
  key: 1,
  class: "a-small o-propertyCard__label"
}
const _hoisted_14 = {
  key: 2,
  class: "a-small o-propertyCard__label"
}
const _hoisted_15 = { class: "a-label o-propertyCard__address" }
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]
const _hoisted_18 = {
  key: 0,
  class: "o-propertyCard__label o-propertyCard__label--underSale"
}
const _hoisted_19 = {
  key: 1,
  class: "o-propertyCard__facts"
}
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_energy_label = _resolveComponent("a-energy-label")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["o-propertyCard", { isSold: $props.property.isSold }])
  }, [
    ($props.total)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "o-propertyCard__propertyCount",
          textContent: _toDisplayString(`${$props.subindex + 1} af ${$props.total} boliger`)
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("a", {
      href: $props.property.url,
      target: "_blank",
      class: _normalizeClass(["o-propertyCard__link", { hasOpenHouse: $props.property.openHouse }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          ($options.isExternal)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5))
            : _createCommentVNode("v-if", true),
          (
                            !$options.isExternal &&
                            $props.property.luxurious &&
                            !$props.property.isSold
                        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_6))
            : _createCommentVNode("v-if", true),
          (!$options.isExternal && $props.property.isSold && $options.soldDate !== '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "a-small o-propertyCard__tag",
                textContent: _toDisplayString($options.soldDate)
              }, null, 8 /* PROPS */, _hoisted_7))
            : _createCommentVNode("v-if", true),
          _createElementVNode("img", {
            src: $options.image,
            onerror: "this.onerror=null;this.style.opacity='0';",
            class: "o-propertyCard__image",
            alt: "",
            role: "presentation"
          }, null, 8 /* PROPS */, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", {
              textContent: _toDisplayString($props.property.type)
            }, null, 8 /* PROPS */, _hoisted_11),
            ($props.property.isSold)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Solgt "))
              : ($props.property.isNew)
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, " Nyhed! "))
                : ($props.property.hasNewPrice)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Ny pris! "))
                  : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", {
              textContent: _toDisplayString($props.property.address)
            }, null, 8 /* PROPS */, _hoisted_16),
            _createElementVNode("p", {
              textContent: _toDisplayString(`${$props.property.zipCode} ${$props.property.city}`)
            }, null, 8 /* PROPS */, _hoisted_17)
          ]),
          ($props.property.isUnderSale)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Købsaftale underskrevet! "))
            : ($props.property.factsMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.property.factsMobile, (fact, factIndex) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (fact.name === 'EnergyLabel')
                        ? (_openBlock(), _createBlock(_component_a_energy_label, {
                            key: `label-${factIndex}`,
                            name: fact.value,
                            class: "o-propertyCard__fact"
                          }, null, 8 /* PROPS */, ["name"]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: `span-${factIndex}`,
                            class: "a-label o-propertyCard__fact",
                            innerHTML: fact.value
                          }, null, 8 /* PROPS */, _hoisted_20))
                    ], 64 /* STABLE_FRAGMENT */))
                  }), 256 /* UNKEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true),
          _createCommentVNode(" OUT OF SCOPE UNTIL LAUNCH "),
          _createCommentVNode(" <div class=\"o-propertyCard__icon\">\n                        <button class=\"o-propertyCard__favorite\">\n                            <a-icon name=\"star_outline\" />\n                        </button>\n                    </div> ")
        ])
      ]),
      ($props.property.openHouse)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "a-label o-propertyCard__openHouse",
            textContent: _toDisplayString($options.openHouseFormatted)
          }, null, 8 /* PROPS */, _hoisted_21))
        : _createCommentVNode("v-if", true)
    ], 10 /* CLASS, PROPS */, _hoisted_2)
  ], 2 /* CLASS */))
}