import { ActionContext } from 'vuex';
import Dawa from '../../interfaces/dawa.interface';

export default {
    /**
     * Close the overlay.
     *
     * @param {ActionContext} state
     * @return {void}
     */
    close({ commit }: ActionContext<any, any>): void {
        document.body.style.overflow = '';
        commit('setShow', false);
    },

    /**
     * Open the overlay.
     *
     * @param {ActionContext} state
     * @param {string} panel
     * @return {void}
     */
    open({ commit }: ActionContext<any, any>, animate: boolean = true): void {
        document.body.style.overflow = 'hidden';

        if (!animate) {
            document.body.classList.add('no-flow-animation');
        }

        commit('setShow', true);
    },
    /**
     * Set dawa information
     *
     * @param {ActionContext} state
     * @param {<Dawa>} dawa
     * @return {void}
     */
    setDawaInformation({ commit }: ActionContext<any, any>, dawa: Dawa): void {
        commit('setDawa', dawa);
    },
};
