
import { EventBus } from '@/functions/eventBus';
import oSearchHeader from './search/SearchHeader.vue';
import { mapGetters } from 'vuex';

interface Data {
    isMounted: boolean;
    showUserAccountMenu: boolean;
}

export default {
    components: {
        oSearchHeader,
    },

    computed: {
        ...mapGetters({
            filters: 'search/filters',
        }),
    },

    data(): Data {
        return {
            isMounted: false,
            showUserAccountMenu: false,
        };
    },

    mounted(): void {
        this.isMounted = true;

        const dropDown = window.document.querySelector(
            '#profileDropdown',
        ) as HTMLUListElement;

        if (dropDown !== undefined) {
            EventBus.$on('app.click', (event: TouchEvent | MouseEvent) => {
                const closestElement = (event.target as HTMLElement)?.closest(
                    '.o-header__profileDropdown',
                ) as Element;

                if (closestElement !== null) {
                    return;
                }

                this.hideUserAccountMenu();
            });

            EventBus.$on('app.resize', () => {
                if (!EventBus.isDesktop) {
                    this.hideUserAccountMenu();
                }
            });
        }
    },

    beforeUnmount(): void {
        EventBus.$off('app.click');
        EventBus.$off('app.resize');
    },

    methods: {
        /**
         * Open the navigation.
         *
         * @return {void}
         */
        openNavigation(): void {
            this.$store.dispatch('modal/open', 'navigation');
        },

        hideUserAccountMenu(): void {
            this.showUserAccountMenu = false;
        },

        onClick(event: TouchEvent | MouseEvent): void {
            this.showUserAccountMenu = !this.showUserAccountMenu;
            event.preventDefault();
            event.stopPropagation();
        },
    },
};
