
import { PropType } from 'vue';

interface TableData {
    col1: string;
    col2: string;
    col3: string;
    col4: string;
    col5: string;
}

export default {
    name: 'm-advanced-table',
    components: {
        // eslint-disable-next-line global-require
        'vue-good-table': require('vue-good-table').VueGoodTable,
    },
    props: {
        tableData: {
            type: String as PropType<string>,
            default: '',
        },
    },

    computed: {
        formatColumns() {
            const header = JSON.parse(this.tableData)[0] as TableData;
            return Object.values(header).map(
                (label: string, index: number) => ({
                    label,
                    sortable: true,
                    type: 'string',
                    field: `col${index + 1}`,
                    firstSortType: 'desc',
                }),
            );
        },
        formatRows() {
            const data = JSON.parse(this.tableData) as TableData[];
            const rows = data.slice(1, data.length - 1);
            return rows;
        },
    },

    methods: {
        searchTable(
            row: string,
            col: string,
            cellValue: string,
            searchTerm: string,
        ) {
            return cellValue.toLowerCase().includes(searchTerm.toLowerCase());
        },
    },
};
