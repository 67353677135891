import Property from '../interfaces/property.interface';
import PropertyDocument from '../interfaces/propertyDocument.interface';
import PromoBox from '../interfaces/promoBox.interface';

interface RawProperty {
    address: string;
    city: string;
    documents: PropertyDocument[];
    factsDesktop: {
        label: string;
        name: string;
        value: string;
    }[];
    factsMobile: {
        label: string;
        name: string;
        value: string;
    }[];
    fallbackImageUrl: string;
    hasNewPrice?: boolean;
    images: string[];
    isDanbolig: boolean;
    isNew: boolean;
    isSold: boolean;
    isUnderSale: boolean;
    luxurious?: boolean;
    openHouse?: string;
    openHouseSignupRequired?: boolean;
    price: number;
    propertyId: string;
    propertySize: number;
    soldDate?: Date;
    spotText?: string;
    type: string;
    url: string;
    zipCode: number;
}

interface RawPromoBox {
    backgroundColor: string;
    bodyText?: string;
    ctaButton?: {
        ctaType: string;
        gaAction: string;
        gaCategory: string;
        gaIndgangsmetode: string;
        gaLabel: string;
        icon?: {
            name: string;
        };
        lineBreak: boolean;
        link?: {
            name: string;
            target: string;
            url: string;
        };
    };
    heading?: string;
    illustration?: {
        altText: string;
        center: string[];
        mediaCrops: string[];
        name: string;
        relativeUrl: string;
        umbracoHeight: string;
        umbracoWidth: string;
        url: string;
    };
    isClickable: boolean;
}

export type RawTypes =
    | {
          responseType: 'promo';
          data: RawPromoBox;
      }
    | {
          responseType: 'propery';
          data: RawProperty;
      };

export type ResponseTypes =
    | {
          responseType: 'promo';
          data: PromoBox;
      }
    | {
          responseType: 'property';
          data: Property;
      };

interface RawResponse {
    data: Array<RawTypes>;
    totalCount: number;
}

export default class PropertiesListModel {
    // results: Property[] = [];
    data: Array<ResponseTypes> = [];

    totalCount: number = 0;

    /**
     * @constructor
     * @param {RawResponse} response
     */
    constructor(response: RawResponse) {
        this.data = response.data.map((item: RawTypes): ResponseTypes => {
            if (item.responseType === 'promo') {
                return {
                    data: {
                        backgroundColor: item.data.backgroundColor,
                        bodyText: item.data.bodyText,
                        ctaButton: {
                            ctaType: item.data.ctaButton?.ctaType ?? '',
                            gaAction: item.data.ctaButton?.gaAction ?? '',
                            gaCategory: item.data.ctaButton?.gaCategory ?? '',
                            gaIndgangsmetode:
                                item.data.ctaButton?.gaIndgangsmetode ?? '',
                            gaLabel: item.data.ctaButton?.gaLabel ?? '',
                            icon: {
                                name: item.data.ctaButton?.icon?.name ?? '',
                            },
                            lineBreak: item.data.ctaButton?.lineBreak ?? false,
                            link: {
                                name: item.data.ctaButton?.link?.name ?? '',
                                target: item.data.ctaButton?.link?.target ?? '',
                                url: item.data.ctaButton?.link?.url ?? '',
                            },
                        },
                        heading: item.data.heading,
                        illustration: {
                            altText: item.data.illustration?.altText ?? '',
                            center: item.data.illustration?.center ?? [],
                            mediaCrops:
                                item.data.illustration?.mediaCrops ?? [],
                            name: item.data.illustration?.name ?? '',
                            relativeUrl:
                                item.data.illustration?.relativeUrl ?? '',
                            umbracoHeight:
                                item.data.illustration?.umbracoHeight ?? '0',
                            umbracoWidth:
                                item.data.illustration?.umbracoWidth ?? '0',
                            url: item.data.illustration?.url ?? '',
                        },
                        isClickable: item.data.isClickable ?? true,
                    },
                    responseType: 'promo',
                };
            }

            return {
                data: {
                    address: item.data.address,
                    city: item.data.city,
                    documents: item.data.documents,
                    factsDesktop: item.data.factsDesktop,
                    factsMobile: item.data.factsMobile,
                    fallbackImageUrl: item.data.fallbackImageUrl,
                    hasNewPrice: item.data.hasNewPrice,
                    images: item.data.images,
                    isDanbolig: item.data.isDanbolig,
                    isNew: item.data.isNew,
                    isSold: item.data.isSold,
                    isUnderSale: item.data.isUnderSale,
                    luxurious: item.data.luxurious,
                    openHouse: item.data.openHouse ?? undefined,
                    openHouseSignupRequired:
                        item.data.openHouseSignupRequired ?? false,
                    price: item.data.price,
                    propertyId: item.data.propertyId,
                    propertySize: item.data.propertySize,
                    soldDate: item.data.soldDate,
                    spotText: item.data.spotText,
                    type: item.data.type,
                    url: item.data.url,
                    zipCode: item.data.zipCode,
                },
                responseType: 'property',
            };
        });

        this.totalCount = response.totalCount ?? 0;
    }
}
