import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$slots.default)
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        onClick: $options.onClick,
        openNavigation: $options.openNavigation,
        showUserAccountMenu: $data.showUserAccountMenu,
        isMounted: $data.isMounted,
        hasFilters: _ctx.filters.length > 0
      })
    : _createCommentVNode("v-if", true)
}