import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$slots.default)
      ? _renderSlot(_ctx.$slots, "default", {
          key: 0,
          next: $options.next,
          prev: $options.prev,
          swipe: $options.swipe,
          currentIndex: $data.currentIndex
        })
      : _createCommentVNode("v-if", true)
  ]))
}