
import { PropType } from 'vue';
import VueSlider from 'vue-slider-component';
import { EventBus } from '@/functions/eventBus';

export interface Data {
    isLoading: boolean;
    sliderValue: number;
    interval: number;
    min: number;
    max: number;
    median: number;
    remaining: number;
    sliderHandle: HTMLDivElement | null;
    sliderParent: HTMLDivElement | null;
    dialRotation: number;
    handleRotation: number;
    activeDialItem: number;
    dialAnimation: boolean;
}

export interface Configuration {
    sliderLabelMinus4: string;
    sliderLabelMinus2: string;
    sliderLabel0: string;
    sliderLabelPlus2: string;
    sliderLabelPlus4: string;
}

export default {
    components: {
        VueSlider,
    },

    props: {
        sliderInstance: {
            type: String as PropType<string>,
            default: () => '',
        },
        sliderLabels: {
            type: Object as PropType<Configuration>,
            default: () => ({
                sliderLabelMinus4: '',
                sliderLabelMinus2: '',
                sliderLabel0: '',
                sliderLabelPlus2: '',
                sliderLabelPlus4: '',
            }),
        },
        value: {
            type: Number as PropType<number>,
            default: () => 50,
        },
    },

    data(): Data {
        return {
            isLoading: false,
            sliderValue: this.$props.value,
            interval: 1,
            min: 0,
            max: 100,
            median: 50,
            remaining: 50,
            sliderHandle: null,
            sliderParent: null,
            dialRotation: 0,
            handleRotation: 0,
            activeDialItem: 6,
            dialAnimation: false,
        };
    },
    computed: {
        isMobile(): Boolean {
            return !EventBus.isDesktop;
        },
    },

    mounted(): void {
        this.changeSliderValue(this.sliderValue, false, true);
    },

    methods: {
        /**
         * Sets new slider value on left/right arrow
         *
         * @return {void}
         */
        setHandleOnKeys(e: KeyboardEvent): void {
            if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
                if (this.sliderValue > this.min) {
                    this.sliderValue -= 10;
                }
            } else if (e.key === 'ArrowRight' || e.key === 'ArrowUp') {
                if (this.sliderValue < this.max) {
                    this.sliderValue += 10;
                }
            }

            this.setHandleRotation(this.sliderValue, true);
            this.setDialRotation(this.sliderValue);
            this.setRemaining(this.sliderValue, false);
        },

        /**
         * Sets the rotation of the visible draghandle
         *
         * @return {void}
         */
        setHandleRotation(payload: number, drag: boolean = true): void {
            const totalRotationDegrees = 25.5;
            const maxStepAsIndex = 11 - 1;
            const activeDialItemAsIndex = this.activeDialItem - 1;
            const calc = () => {
                if (payload === 0) {
                    return totalRotationDegrees / 2 - totalRotationDegrees;
                }
                return (
                    totalRotationDegrees / (100 / payload) -
                    totalRotationDegrees / 2
                );
            };
            if (drag) {
                this.handleRotation = calc();
            } else {
                this.handleRotation =
                    (activeDialItemAsIndex - maxStepAsIndex / 2) *
                    (totalRotationDegrees / maxStepAsIndex);
            }
        },

        /**
         * Sets the rotation of the dial
         *
         * @return {void}
         */
        setDialRotation(payload: number): void {
            const newPayload = Math.round(payload / 10);
            this.dialRotation = -((newPayload - 5) * 3.94);
            this.activeDialItem = newPayload + 1;
        },

        /**
         * Sets the remaining slider background in percentages
         *
         * @return {void}
         */
        setRemaining(payload: number, drag: boolean): void {
            const newPayload = drag ? payload / 10 : Math.round(payload / 10);
            const newMin = drag ? this.min / 10 : Math.round(this.min / 10);
            const newMax = drag ? this.max / 10 : Math.round(this.max / 10);

            this.remaining = -(
                ((newPayload - newMin) / (newMax - newMin)) * 100 -
                100
            );
        },

        changeSliderValue(
            payload: number,
            animate: boolean = false,
            init: boolean = false,
        ) {
            this.dialAnimation = animate;

            setTimeout(() => {
                this.dialAnimation = false;
            }, 500);

            this.setDialRotation(payload);
            this.setRemaining(payload, false);
            this.setHandleRotation(payload, false);

            this.sliderValue = Math.round(payload / 10) * 10;

            this.$emit('setSliderValue', {
                val: Math.round(payload / 10) - 5,
                trueVal: this.sliderValue,
                slider: this.$props.sliderInstance,
                justInitialized: init,
            });
        },

        onRangeInput(e): void {
            this.setRemaining(e.target.value, true);
            this.setDialRotation(e.target.value);
            this.setHandleRotation(e.target.value, true);
        },

        onRangeChange(e): void {
            this.changeSliderValue(e.target.value);
        },
    },
};
