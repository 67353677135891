
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';
import Format from '@/functions/format';

export interface IDateObject {
    day: number;
    dayName: string;
    monthName: string;
    date: string;
    fromTime?: string;
    toTime?: string;
    isOpenHouse?: boolean;
    required?: boolean;
}

interface Data {
    option: string;
    optionText: string;
    showingDatesStart: boolean;
    showingDatesEnd: boolean;
    hasTouch: boolean;
    currentIndex: number;
    currentCount: number;
    isOverflownLeft: boolean;
    isOverflownRight: boolean;
    dates: IDateObject[];
}

export interface DatePickerStartDate {
    year: number;
    month: number;
    day: number;
}

export default {
    name: 'm-date-picker',
    props: {
        datepickerDates: {
            type: Array as PropType<string[]>,
            default: () => [],
        },

        initialDate: {
            type: String as PropType<string>,
            default: () => '',
        },

        datepickerPairs: {
            type: Array as PropType<[string, string, boolean][]>,
            default: () => [],
        },

        direction: {
            type: String as PropType<string>,
            default: () => 'horizontal',
        },

        smallTextSignup: {
            type: String as PropType<string>,
            default: () => 'Tilmelding påkrævet',
        },

        smallTextNoSignup: {
            type: String as PropType<string>,
            default: () => 'Tilmelding ikke påkrævet - vi ses på adressen',
        },

        asapText: {
            type: String as PropType<string>,
            default: () => 'Hurtigst muligt',
        },
    },

    data(): Data {
        return {
            option: '',
            optionText: '',
            showingDatesStart: true,
            showingDatesEnd: false,
            hasTouch: EventBus.hasTouch,
            currentIndex: 0,
            currentCount: 0,
            isOverflownLeft: true,
            isOverflownRight: false,
            dates: [],
        };
    },

    computed: {
        shortInitialDate(): string {
            return Format.dateShort(new Date(this.initialDate));
        },
    },

    beforeMount(): void {
        if (this.direction === 'horizontal') {
            this.datepickerDates.forEach((date: string) => {
                let d = new Date(date);
                let dayObj: IDateObject = {
                    day: d.getDate(),
                    dayName: Format.dayNameShort(d),
                    monthName: Format.monthShort(d),
                    date: d.toString(),
                    isOpenHouse: this.datepickerPairs
                        ? this.datepickerPairs
                            .map((pair: [string, string, boolean]) => {
                                const tmp = new Date(pair[0])
                                    .toString()
                                    .slice(0, 16);
                                return tmp;
                            })
                            .includes(d.toString().slice(0, 16))
                        : false,
                };
                this.dates.push(dayObj);
            });
        } else {
            this.datepickerPairs.forEach((pair: [string, string, boolean]) => {
                const from = new Date(pair[0]);
                const to = new Date(pair[1]);
                const dayNameLower = Format.dayNameLong(from);

                let dayObj: IDateObject = {
                    day: from.getDate(),
                    dayName: Format.capitalize(dayNameLower),
                    monthName: Format.monthShort(from),
                    date: Format.dateShort(from),
                    fromTime: Format.hourMinute(from),
                    toTime: Format.hourMinute(to),
                    required: pair[2],
                };

                this.dates.push(dayObj);
            });
        }
    },

    mounted(): void {
        if (this.direction === 'horizontal') {
            EventBus.$on('app.resize', this.calculateOverflow);
            (this.$refs.dates as HTMLDivElement).addEventListener(
                'scroll',
                this.calculateOverflow,
            );
            (this.$refs.dates as HTMLDivElement).dispatchEvent(
                new Event('scroll'),
            );
        }
    },

    beforeUnmount(): void {
        if (this.direction === 'horizontal') {
            EventBus.$off('app.resize');
            (this.$refs.dates as HTMLDivElement).removeEventListener(
                'scroll',
                this.calculateOverflow,
            );
        }
    },

    methods: {
        /**
         * Browse through the available showing dates
         *
         * @param {string} direction
         * @return {void}
         */
        browseShowingDates(direction: string): void {
            const trueCount = this.dates.length + 1;

            if (direction === 'next' && this.currentIndex + 4 < trueCount) {
                this.currentIndex += this.currentIndex === 0 ? 3 : 4;
                const date = (this.$refs.dateItems as any[])[this.currentIndex];
                if (date) date.scrollIntoView({ block: 'nearest', inline: 'start' });
            } else if (direction === 'previous' && this.currentIndex - 4 <= 0) {
                this.currentIndex = 0;
                const asap = this.$refs.asap as HTMLLabelElement;
                if (asap) asap.scrollIntoView({ block: 'nearest', inline: 'start' });
            } else if (direction === 'previous') {
                this.currentIndex -= 4;
                const date = (this.$refs.dateItems as any[])[this.currentIndex];
                if (date) date.scrollIntoView({ block: 'nearest', inline: 'start' });
            }

            this.showingDatesStart = this.currentIndex === 0;
            this.showingDatesEnd = this.currentIndex + 4 >= trueCount;
        },

        /**
         * Get the text from the selected option to use in next panel/screen
         *
         * @param {any} event
         * @return {void}
         */
        setOption(event: any): void {
            this.$emit('dateSelected', event.target.value);
        },

        /**
         * Calculate if list is overflowing wrapper.
         *
         * @return {void}
         */
        calculateOverflow(): void {
            if (this.direction === 'horizontal') {
                let firstDateRect = (
                    this.$refs.asap as HTMLDivElement
                ).getBoundingClientRect();
                let datePickerRect = (
                    this.$refs.datePicker as HTMLDivElement
                ).getBoundingClientRect();
                let lastDateRect = (this.$refs.dateItems as any[])[
                    this.dates.length - 1
                ].getBoundingClientRect();

                this.isOverflownLeft = Math.ceil(firstDateRect.left)
                    < Math.floor(datePickerRect.left);
                this.isOverflownRight = Math.floor(lastDateRect.right)
                    > Math.ceil(datePickerRect.right);
            }
        },
    },
};
