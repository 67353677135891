import MapBroker from '@/interfaces/mapBroker.interface';
import Broker from '@/interfaces/broker.interface';

interface RawBroker {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
    properties: {
        data: Broker;
    };
    type: 'Feature';
}

interface RawResponse {
    results: RawBroker[];
    totalCount: number;
}

export default class NeighborhoodsMap {
    results: MapBroker[] = [];

    totalCount: number = 0;

    /**
     * @constructor
     * @param {RawResponse} response
     */
    constructor(response: RawResponse) {
        this.totalCount = response.results.length ?? 0;

        this.results = response.results.map((item: RawBroker): MapBroker => {
            return {
                geometry: item.geometry,
                properties: {
                    data: item.properties.data,
                },
                type: 'Feature',
            };
        });
    }
}
