import State from './interface';

export default {
    /**
     * Check if modal should be shown.
     *
     * @param {State} state
     * @return {boolean}
     */
    show(state: State): boolean {
        return state.show;
    },

    /**
     * Get theme value.
     *
     * @param {State} state
     * @return {string}
     */
    theme(state: State): string {
        return state.theme;
    },

    /**
     * Get type value.
     *
     * @param {State} state
     * @return {string}
     */
    type(state: State): string {
        return state.type;
    },

    /**
     * Get fixed value.
     *
     * @param {State} state
     * @return {boolean}
     */
    fixed(state: State): boolean {
        return state.fixed;
    },

    /**
     * Get trackingEntrance value.
     *
     * @param {State} state
     * @return {boolean}
     */
    trackingEntrance(state: State): string | null {
        return state.trackingEntrance;
    },

    /**
     * Get trackingLabel value.
     *
     * @param {State} state
     * @return {boolean}
     */
    trackingLabel(state: State): string | null {
        return state.trackingLabel;
    },
};
