import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "o-neighborhoodPageLocal" }
const _hoisted_2 = { key: "graph" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "o-neighborhoodPageLocal__graph" }
const _hoisted_5 = { class: "o-neighborhoodPageLocal__actions" }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = {
  key: 1,
  class: "a-paragraph o-neighborhoodPageLocal__receipt"
}
const _hoisted_8 = ["textContent"]
const _hoisted_9 = { key: "poll" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "o-tags" }
const _hoisted_13 = ["disabled", "onClick"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = { class: "o-neighborhoodPageLocal__actions" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_horizontal_chart = _resolveComponent("m-horizontal-chart")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        ($data.state !== 'voting')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", {
                class: "a-heading3 o-neighborhoodPageLocal__title",
                textContent: _toDisplayString($props.texts.graphHeading)
              }, null, 8 /* PROPS */, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_m_horizontal_chart, {
                  secondary: true,
                  "voted-for": $data.votedFor,
                  "neighborhood-dataset": $data.dataset
                }, null, 8 /* PROPS */, ["voted-for", "neighborhood-dataset"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                ($data.state === '')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "m-button m-button--dark",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.changeState('voting')))
                    }, [
                      _createElementVNode("span", {
                        class: "m-button__text",
                        textContent: _toDisplayString($props.texts.buttonText)
                      }, null, 8 /* PROPS */, _hoisted_6)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_a_icon, {
                        class: "o-neighborhoodPageLocal__icon",
                        name: "check"
                      }),
                      _createElementVNode("span", {
                        textContent: _toDisplayString($props.texts.pollReceiptText)
                      }, null, 8 /* PROPS */, _hoisted_8)
                    ]))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("h2", {
                class: "a-heading3 o-neighborhoodPageLocal__pollTitle",
                textContent: _toDisplayString($props.texts.pollHeading)
              }, null, 8 /* PROPS */, _hoisted_10),
              _createElementVNode("p", {
                class: "a-paragraph o-neighborhoodPageLocal__pollDescription",
                textContent: _toDisplayString($props.texts.pollDescription)
              }, null, 8 /* PROPS */, _hoisted_11),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataset, (item, index) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: `poll-${index}`,
                    type: "button",
                    disabled: $options.isDisabled(item.id),
                    class: _normalizeClass(["m-tag m-tag--black o-neighborhoodPageLocal__button", { 'isVoted': $data.votedFor.includes(item.id) }]),
                    onClick: ($event: any) => ($options.onToggle(item.id))
                  }, [
                    _createElementVNode("span", {
                      class: "m-tag__text",
                      textContent: _toDisplayString(item.name)
                    }, null, 8 /* PROPS */, _hoisted_14)
                  ], 10 /* CLASS, PROPS */, _hoisted_13))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("button", {
                  disabled: !$data.votedFor.length,
                  class: "m-button m-button--dark",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.submit()))
                }, [
                  _createElementVNode("span", {
                    class: "m-button__text",
                    textContent: _toDisplayString($props.texts.pollButtonText)
                  }, null, 8 /* PROPS */, _hoisted_17)
                ], 8 /* PROPS */, _hoisted_16)
              ])
            ]))
      ], undefined, true),
      _: 1 /* STABLE */
    })
  ]))
}