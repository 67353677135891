import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["for", "textContent"]
const _hoisted_2 = ["id", "name", "placeholder", "type", "value"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-textarea", [
            $options.modifierClass,
            {
                hasError: $props.error,
                hasLabel: $options.hasLabel,
                isDisabled: $options.isDisabled,
                isEmpty: !$props.value,
                isFocused: $data.isFocused,
            },
        ]])
  }, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "a-label m-textarea__label",
          for: $data.uuid,
          textContent: _toDisplayString($props.label)
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("textarea", {
      id: $data.uuid,
      ref: "textarea",
      class: "a-textarea m-textarea__field",
      name: $props.name,
      placeholder: $props.placeholder,
      type: $props.type,
      value: $props.value,
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onFocus && $options.onFocus(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($props.error)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              class: _normalizeClass(["a-error m-textarea__error", { 'a-error--bright': $props.useBrightError }]),
              textContent: _toDisplayString($props.error)
            }, null, 10 /* CLASS, PROPS */, _hoisted_3))
          : _createCommentVNode("v-if", true)
      ], undefined, true),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}