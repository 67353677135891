
import Vue, { PropType } from 'vue';
import VueSlider from 'vue-slider-component';
import Dawa from '@/interfaces/dawa.interface';
import mSelect from '@/components/molecules/form/Select.vue';
import { FILTER_DEFAULTS } from '@/constants/search.const';

export interface Data {
    form: {
        address: string;
        size: string;
        municipality: string;
        id: string;
        price: number;
    };
    errors: {
        address?: string;
        size?: string;
        propertyType?: string;
        price?: string;
    };
    addressFound: boolean;
    zipcode?: number;
    isFocused: boolean;
    maxLabel: string;
    minLabel: string;
    sliderInitialized: boolean;
    priceLabel: string;
}

export interface Texts {
    heroHeadline: string;
    heroText: string;
    formHeadline: string;
    formText: string;
    minLabel: string;
    maxLabel: string;
    buttonText: string;
}

export interface FormSettings {
    addressPlaceholder: string;
    sizePlaceholder: string;
    propertyPlaceholder: string;
    pricePlaceholderText: string;
}

export default {
    components: {
        mSelect,
    },

    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                heroHeadline: '',
                heroText: '',
                formHeadline: '',
                formText: '',
                minLabel: '',
                maxLabel: '',
                buttonText: '',
            }),
        },
        images: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        formAction: {
            type: String as PropType<string>,
            default: () => '',
        },
        formSettings: {
            type: Object as PropType<FormSettings>,
            default: () => ({
                addressPlaceholder: '',
                sizePlaceholder: '',
                propertyPlaceholder: '',
                pricePlaceholderText: '',
                errors: {
                    adress: '',
                    size: '',
                    propertyType: '',
                    price: '',
                },
            }),
        },
        municipalities: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        sliderMax: {
            type: Number as PropType<number>,
            default: () => FILTER_DEFAULTS.priceMax,
        },
        sliderMin: {
            type: Number as PropType<number>,
            default: () => FILTER_DEFAULTS.priceMin,
        },
        value: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
    },

    data(): Data {
        return {
            form: {
                address: '',
                size: '',
                municipality: '',
                id: '',
                price: 0,
            },
            errors: {},
            addressFound: false,
            isFocused: false,
            maxLabel: '',
            minLabel: '',
            sliderInitialized: false,
            priceLabel: '0 kr.',
        };
    },

    computed: {
        recieve_address(): any {
            return this.form.id;
        },

        recieve_price(): any {
            return this.form.price.toString();
        },

        propertyTypeOptions(): any {
            return [
                {
                    label: this.formSettings.propertyPlaceholder,
                    value: '',
                },
                ...this.municipalities,
            ];
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    methods: {
        /**
         * Scroll down to the form area
         *
         * @return {void}
         */
        onClick(): void {
            const intro = document.querySelector('.o-findBuyer__form__intro');
            intro?.scrollIntoView();
        },

        checkForm(e: Event) {
            this.errors = {};

            if (this.form.id === '') {
                this.errors.address = this.$props.formSettings.errors.address;
            }
            if (!/^\d+$/.test(this.form.size)) {
                this.errors.size = this.$props.formSettings.errors.size;
            }
            if (this.form.municipality === '') {
                this.errors.propertyType =
                    this.$props.formSettings.errors.propertyType;
            }

            if (!this.sliderInitialized) {
                this.errors.price = this.$props.formSettings.errors.price;
            }

            if (Object.keys(this.errors).length > 0) {
                e.preventDefault();
            }
        },

        setPriceLabel(payload: number) {
            this.priceLabel =
                payload < FILTER_DEFAULTS.priceMax
                    ? `${payload.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} kr.`
                    : '+20  mio. kr.';
        },

        onChange(payload: number): void {
            this.sliderInitialized = true;
            this.form.price = payload;
            this.setPriceLabel(payload);
        },

        onDragging(payload: number): void {
            this.setPriceLabel(payload);
        },

        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            // this.errors.address = '';
            if (this.addressFound) {
                this.form.id = value.data.id;
                this.form.address = value.tekst;
                this.zipcode = parseInt(value.data.postnr);
            }
        },

        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },
    },

    mounted() {
        Vue.nextTick(() => {
            this.form.address = '';
            this.form.size = '';
            this.form.municipality = '';
            this.form.id = '';
            this.form.price = 0;

            this.errors.address = '';
            this.errors.size = '';
            this.errors.propertyType = '';
            this.errors.price = '';

            this.addressFound = false;
        });
    },
};
