
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';
import Format from '@/functions/format';
import Employee from '@/interfaces/employee.interface';

interface Data {
    isDesktop: boolean;
}

export default {
    props: {
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                address: '',
                brokerId: '',
                brokerName: '',
                city: '',
                description: '',
                employeeId: '',
                email: '',
                imageUrl: '',
                linkedIn: '',
                name: '',
                phone: '',
                type: '',
                title: '',
                videoUrl: '',
                zipCode: '',
            }),
        },

        panel: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            isDesktop: EventBus.isDesktop,
        };
    },

    computed: {
        formattedUrl(): string {
            return this.employee?.imageUrl &&
                this.employee?.imageUrl.includes('deviceid')
                ? Format.formatMindworkingUrl(
                      this.employee?.imageUrl ?? '',
                      '500',
                      '500',
                      'crop',
                  )
                : this.employee?.imageUrl ?? '';
        },
    },
};
