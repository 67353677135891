import MapCluster from '@/interfaces/mapCluster.interface';
import MapNeighborhood from '@/interfaces/mapNeighborhood.interface';

interface RawCluster {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
    properties: {
        count: number;
    };
    type: 'Feature';
}

interface RawNeighborhood {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
    properties: {
        facts: string[];
        favourites: string;
        id: string;
        multiPolygon?: number[][];
        name: string;
        polygon?: number[][];
        propertiesForSale: number;
        url: string;
    };
    type: 'Feature';
}

interface RawResponse {
    level: 'clusters' | 'markers';
    results: (RawCluster | RawNeighborhood)[];
    totalCount: number;
}

export default class NeighborhoodsMap {
    level: 'clusters' | 'markers' = 'markers';

    results: (MapCluster | MapNeighborhood)[] = [];

    totalCount: number = 0;

    /**
     * @constructor
     * @param {RawResponse} response
     */
    constructor(response: RawResponse) {
        this.level = response.level;

        this.totalCount = response.totalCount;

        this.results = response.results.map(
            (
                item: RawCluster | RawNeighborhood,
            ): MapCluster | MapNeighborhood => {
                if (response.level === 'clusters') {
                    return <MapCluster>{
                        geometry: item.geometry,
                        properties: {
                            count: (item as RawCluster).properties.count,
                            type: 'cluster',
                        },
                        type: 'Feature',
                    };
                }

                let neighborhood = <RawNeighborhood>item;
                return <MapNeighborhood>{
                    geometry: item.geometry,
                    properties: {
                        data: {
                            facts: neighborhood.properties.facts,
                            favourites: neighborhood.properties.favourites,
                            id: neighborhood.properties.id,
                            multiPolygon:
                                neighborhood.properties.multiPolygon ?? [],
                            name: neighborhood.properties.name,
                            polygon: neighborhood.properties.polygon ?? [],
                            propertiesForSale: `${neighborhood.properties.propertiesForSale} ${neighborhood.properties.propertiesForSale > 1 ? 'boliger' : 'bolig'} til salg`,
                            url: neighborhood.properties.url,
                        },
                        label: `${neighborhood.properties.name} (${neighborhood.properties.propertiesForSale})`,
                        type: 'marker',
                    },
                    type: 'Feature',
                };
            },
        );
    }
}
