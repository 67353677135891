import MapFavorite from '@/interfaces/mapFavorite.interface';

interface RawResponse {
    results: any[];
    totalCount: number;
}

export default class NeighborhoodsMap {
    level: 'markers' = 'markers';

    results: MapFavorite[] = [];

    totalCount: number = 0;

    /**
     * @constructor
     */
    constructor(response: RawResponse) {
        this.results = response.results;

        this.totalCount = response.totalCount;
    }
}
