import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "a-section" }
const _hoisted_2 = { class: "u-container" }
const _hoisted_3 = { class: "u-row" }
const _hoisted_4 = { class: "u-col-12 md:u-col-10 md:u-offset-1" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "o-tags o-tags--centered" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["href", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["o-overlayPromoBox o-overlayPromoBox--notForSale", [$options.getModifierClass($props.promoBox.backgroundColour)]])
          }, [
            ($props.promoBox.headline)
              ? (_openBlock(), _createElementBlock("h2", {
                  key: 0,
                  class: "a-heading2 o-overlayPromoBox__headline",
                  textContent: _toDisplayString($props.promoBox.headline)
                }, null, 8 /* PROPS */, _hoisted_5))
              : _createCommentVNode("v-if", true),
            ($props.promoBox.text)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: "o-overlayPromoBox__text",
                  textContent: _toDisplayString($props.promoBox.text)
                }, null, 8 /* PROPS */, _hoisted_6))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "m-tag o-overlayPromoBox__tag isDisabled",
                textContent: _toDisplayString($props.promoBox.postalCode)
              }, null, 8 /* PROPS */, _hoisted_8),
              _createElementVNode("div", {
                class: "m-tag o-overlayPromoBox__tag isDisabled",
                textContent: _toDisplayString(
                                    `${$props.promoBox.areaMin} - ${$props.promoBox.areaMax} m2`
                                )
              }, null, 8 /* PROPS */, _hoisted_9),
              _createElementVNode("div", {
                class: "m-tag o-overlayPromoBox__tag isDisabled",
                textContent: _toDisplayString($props.promoBox.propertyTypeText)
              }, null, 8 /* PROPS */, _hoisted_10),
              _createElementVNode("div", {
                class: "m-tag o-overlayPromoBox__tag isDisabled",
                textContent: _toDisplayString(
                                    $options.getPricePill(
                                        $props.promoBox.priceMin,
                                        $props.promoBox.priceMax,
                                    )
                                )
              }, null, 8 /* PROPS */, _hoisted_11)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["o-buttons o-overlayPromoBox__buttons", {
                                'o-overlayPromoBox__buttons--break':
                                    $props.promoBox.useNewLine == 'True',
                            }])
            }, [
              _createElementVNode("a", {
                href: `${$props.promoBox.pageUrl}`,
                class: "m-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClick(true, $props.promoBox.buttonText)))
              }, [
                _createElementVNode("span", {
                  class: "m-button__text",
                  textContent: _toDisplayString($props.promoBox.buttonText)
                }, null, 8 /* PROPS */, _hoisted_13)
              ], 8 /* PROPS */, _hoisted_12),
              ($props.promoBox.linkText)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: `${$props.promoBox.pageUrl}`,
                    class: "a-label a-anchor a-anchor--secondary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.onClick(false, $props.promoBox.linkText))),
                    textContent: _toDisplayString($props.promoBox.linkText)
                  }, null, 8 /* PROPS */, _hoisted_14))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ], 2 /* CLASS */)
        ])
      ])
    ])
  ]))
}