
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import IOverlayPromoBox from '@/interfaces/overlayPromoBox.interface';
import tracking from '@/functions/tracking';

export default {
    props: {
        promoBox: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [
                {
                    contentType: '',
                    backgroundColour: '',
                    headline: '',
                    text: '',
                    buttonText: '',
                    linkText: '',
                    postalCode: '',
                    areaMin: 0,
                    areaMax: 0,
                    priceMin: 0,
                    priceMax: 0,
                    propertyTypeId: 0,
                    propertyTypeText: '',
                    pageUrl: '',
                    trackingFormVariation: '',
                    ctaButtons: [
                        {
                            ctaType: '',
                            lineBreak: false,
                            link: {
                                url: '',
                                target: '',
                                name: '',
                            },
                            gaAction: '',
                            gaCategory: '',
                            gaLabel: '',
                            gaIndgangsmetode: '',
                            icon: {
                                name: '',
                            },
                            noMinHeight: false,
                        },
                    ],
                },
            ],
        },
    },

    methods: {
        ...mapActions({
            reset: 'buyerDirectory/reset',
            setTags: 'buyerDirectory/setTags',
            setPrice: 'buyerDirectory/setPrice',
            setSize: 'buyerDirectory/setSize',
            setZipcode: 'buyerDirectory/setZipcode',
        }),

        isBuyersDirectory(type: string) {
            return type === 'promoBoxBuyersDirectory';
        },

        buttonTarget(target: string): string {
            if (target === '_blank') {
                return '_blank';
            }
            return '';
        },

        buttonTheme(type: string): string {
            if (type === 'Sekundær knap') {
                return 'outlined';
            }
            return '';
        },

        getPricePill(min: number, max: number): string {
            return `${this.numberFormatter(min)} - ${this.numberFormatter(max)}`;
        },

        getModifierClass(bgColor: string) {
            return bgColor ? `o-overlayPromoBox--${bgColor}` : '';
        },

        numberFormatter(value: number): string {
            return new Intl.NumberFormat('da-DK', {
                style: 'currency',
                currency: 'DKK',
                minimumFractionDigits: 0,
            })
                .format(value)
                .toString();
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onClick(type: string, item: any): void {
            this.reset();

            let trackingLabel = item.linkText;

            if (type === 'setCriteria') {
                this.setZipcode(item.postalCode.toString());
                this.setPrice([item.priceMin, item.priceMax]);
                this.setSize([item.areaMin, item.areaMax]);
                this.setTags({
                    key: item.propertyTypeId,
                    value: item.propertyTypeText.toLowerCase(),
                });

                trackingLabel = item.buttonText;
            }

            tracking.track(
                'trackBoligpraesentation',
                'Boligpraesentation',
                'Koeberkartotek promoboks klik',
                trackingLabel,
                { formularvariant: item?.trackingFormVariation },
            );
        },
    },
};
