import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "o-availableBuyers" }
const _hoisted_2 = { class: "o-availableBuyers__wrapper u-container" }
const _hoisted_3 = { class: "o-availableBuyers__images" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "o-availableBuyers__content u-col-12 lg:u-col-8 lg:u-offset-2" }
const _hoisted_6 = { class: "u-row" }
const _hoisted_7 = { class: "u-col-5" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { class: "o-availableBuyers__image-below" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (n, i) => {
          return _createElementVNode("img", {
            key: 'image-' + i,
            class: "o-availableBuyers__image",
            src: `${$props.data.images[i]}?width=310&height=310`
          }, null, 8 /* PROPS */, _hoisted_4)
        }), 64 /* STABLE_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "o-availableBuyers__buyersCount",
              textContent: _toDisplayString($props.data.buyersCount)
            }, null, 8 /* PROPS */, _hoisted_8),
            _createElementVNode("div", {
              class: "a-heading2 o-availableBuyers__title",
              textContent: _toDisplayString($props.data.headline)
            }, null, 8 /* PROPS */, _hoisted_9),
            _createElementVNode("div", {
              class: "a-base o-availableBuyers__text",
              textContent: _toDisplayString($props.data.text)
            }, null, 8 /* PROPS */, _hoisted_10),
            _createElementVNode("button", {
              class: "m-button m-button--outlined o-availableBuyers__ctaButton",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openContactBrokerCurtain()))
            }, [
              _createElementVNode("span", {
                class: "m-button__text",
                textContent: _toDisplayString($props.data.ctaText)
              }, null, 8 /* PROPS */, _hoisted_11)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("img", {
                src: `${$props.data.images[7]}?width=310&height=310`,
                class: "o-availableBuyers__image--last"
              }, null, 8 /* PROPS */, _hoisted_13),
              _createElementVNode("img", {
                src: `${$props.data.images[3]}?width=310&height=310`,
                class: "o-availableBuyers__image--last"
              }, null, 8 /* PROPS */, _hoisted_14)
            ])
          ])
        ])
      ])
    ])
  ]))
}