
import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Api from '@/functions/api';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';
import Broker from '@/interfaces/broker.interface';
import FormSettings from '@/interfaces/formSettings.interface';

type Consent = {
    purposeText: string;
    id: string;
    heading: string;
    leadType: {
        displayName: string;
    };
};

type BrokerContactType = {
    headline: string;
    text: string;
    ctaText: string;
    ctaAltText: string;
    receiptHeadline: string;
    receiptText: string;
    awsAddressGuid: string;
    fullAddress: string;
    consent: Consent;
    brokerEmployee: Broker;
    formSettings: FormSettings;
    messageExtension: string;
};

type FormErrors = {
    name?: string;
    mail?: string;
    phone?: string;
    message?: string;
};

interface Data {
    isSignupSuccessful: boolean;
    errors: FormErrors;
    isLoading: boolean;
    form: {
        name: string;
        email: string;
        phone: string;
        message?: string;
    };
    brokerId: string;
    isFormInitiated: boolean;
    formSuccessWraperHeight: number;
}

export default {
    props: {
        formData: {
            type: Object as PropType<BrokerContactType>,
            default: () => ({
                headline: '',
                text: '',
                ctaText: '',
                ctaAltText: '',
                receiptHeadline: '',
                receiptText: '',
                awsAddressGuid: '',
                fullAddress: '',
                messageExtension: '',
                consent: {
                    purposeText: '',
                    id: '',
                    heading: '',
                    leadType: {
                        displayName: '',
                    },
                },
                brokerEmployee: {
                    address: '',
                    brokerId: '',
                    brokerName: '',
                    city: '',
                    email: '',
                    employeeId: '',
                    imageUrl: '',
                    linkedIn: '',
                    name: '',
                    phone: '',
                    title: '',
                    videoUrl: '',
                    type: '',
                    url: '',
                    zipCode: '',
                },
                formSettings: {
                    msgPlaceholder: '',
                    msgErrorMsg: '',
                    namePlaceholder: '',
                    nameErrorMsg: '',
                    addressPlaceholder: '',
                    addressErrorMsg: '',
                    mailPlaceholder: '',
                    mailErrorMsg: '',
                    phonePlaceholder: '',
                    phoneErrorMsg: '',
                    offerPlaceholder: '',
                    offerErrorMsg: '',
                    linkText: '',
                    link: '',
                    submitButtonText: '',
                    brokerErrorMsg: '',
                    heading: '',
                    bodytext: '',
                    commentPlaceholder: '',
                    commentErrorMsg: '',
                    datePlaceholder: '',
                    dateErrorMsg: '',
                    municipalityPlaceholder: '',
                    municipalityErrorMsg: '',
                    desiredAddressPlaceholder: '',
                    consentErrorMsg: '',
                },
            }),
        },
    },

    data(): Data {
        return {
            isSignupSuccessful: false,
            form: {
                name: '',
                email: '',
                phone: '',
                message: '',
            },
            isLoading: false,
            errors: {},
            brokerId: '',
            isFormInitiated: false,
            formSuccessWraperHeight: 0,
        };
    },

    computed: {
        ...mapGetters({
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
                if (!this.isFormInitiated) {
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering initieret',
                            label: 'Salgsvurdering',
                            formularIndgang:
                                'Boligskoen vurdering formular modul',
                            formularStepnavn: 'Brugerinformation',
                            maeglerId: this.brokerId ?? '',
                        },
                    });
                    this.isFormInitiated = true;
                }
            },
        },
    },

    mounted() {
        this.brokerId = window.homeEstimateBaseData.brokerId;
        this.calculateFormSuccessWrapperHeight();

        window.addEventListener(
            'resize',
            this.calculateFormSuccessWrapperHeight,
        );
    },
    unmounted() {
        window.removeEventListener(
            'resize',
            this.calculateFormSuccessWrapperHeight,
        );
    },

    methods: {
        calculateFormSuccessWrapperHeight() {
            this.formSuccessWraperHeight = (
                this.$refs.brokerRef as HTMLElement
            ).clientHeight;
        },

        onSubmitForm(): void {
            this.isLoading = true;
            const type = LeadTypes.SalesValuationHomeEstimate.toString();
            const excludeValidation = ['message'];
            const message = this.form.message
                ? `${this.form.message}\r\n\r\n${this.formData.messageExtension}`
                : this.formData.messageExtension;

            Api.postLead(
                {
                    consentIdGlobal: this.formData.consent.id,
                    address: this.formData.fullAddress,
                    email: this.form.email.trim() ?? '',
                    firstName: Format.firstName(this.form.name) ?? '',
                    lastName: Format.lastName(this.form.name) ?? '',
                    message,
                    phoneNumber: this.form.phone ?? '',
                    responsibleShopNo:
                        this.formData.brokerEmployee?.brokerId ?? '',
                    responsibleEmployeeIdGlobal:
                        this.formData.brokerEmployee?.employeeId ?? '',
                    type,
                    utmCampaign: this.utmCampaign,
                    utmContent: this.utmContent,
                    utmMedium: this.utmMedium,
                    utmSource: this.utmSource,
                },
                excludeValidation,
            )
                .then((): void => {
                    this.isSignupSuccessful = true;

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Salgsvurdering gennemfoert',
                            label: 'Salgsvurdering',
                            formularIndgang:
                                'Boligskoen vurdering formular modul',
                            formularStepnavn: 'Kvittering',
                            maeglerId: this.brokerId ?? '',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },
    },
};
