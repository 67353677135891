import actions from './broker/actions';
import getters from './broker/getters';
import mutations from './broker/mutations';
import state from './broker/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
