import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: "navigation",
  class: "o-panel"
}
const _hoisted_2 = { class: "o-panel__broker" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "o-panel__brokerInfo" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "o-panel__location" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = {
  class: "o-panel__nav",
  role: "group",
  "aria-label": "Vælg den ønskede handling"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["textContent"]
const _hoisted_14 = {
  key: "broker",
  class: "o-panel"
}
const _hoisted_15 = ["textContent"]
const _hoisted_16 = {
  key: "broker1",
  class: "o-subPanel"
}
const _hoisted_17 = ["textContent"]
const _hoisted_18 = { class: "o-fields" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["textContent"]
const _hoisted_21 = ["textContent"]
const _hoisted_22 = {
  key: "broker2",
  class: "o-subPanel o-subPanel--spacedChildrenLarge"
}
const _hoisted_23 = ["textContent"]
const _hoisted_24 = {
  key: "material",
  class: "o-panel"
}
const _hoisted_25 = ["textContent"]
const _hoisted_26 = {
  key: "material1",
  class: "o-subPanel"
}
const _hoisted_27 = ["textContent"]
const _hoisted_28 = { class: "o-fields" }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "o-subPanel__actions" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = ["textContent"]
const _hoisted_33 = ["textContent"]
const _hoisted_34 = ["textContent"]
const _hoisted_35 = {
  key: "material2",
  class: "o-subPanel o-subPanel--spacedChildrenLarge"
}
const _hoisted_36 = ["textContent"]
const _hoisted_37 = { class: "o-subPanel__downloads" }
const _hoisted_38 = ["href", "onClick"]
const _hoisted_39 = ["textContent"]
const _hoisted_40 = { class: "o-subPanel__actions" }
const _hoisted_41 = ["textContent"]
const _hoisted_42 = {
  key: "showing",
  class: "o-panel"
}
const _hoisted_43 = ["textContent"]
const _hoisted_44 = {
  key: "showing1",
  class: "o-subPanel"
}
const _hoisted_45 = ["textContent"]
const _hoisted_46 = { class: "o-subPanel__rangeSlider" }
const _hoisted_47 = ["textContent"]
const _hoisted_48 = ["disabled"]
const _hoisted_49 = ["textContent"]
const _hoisted_50 = {
  key: 0,
  class: "o-subPanel__openHouse"
}
const _hoisted_51 = { class: "o-subPanel__openHouseHeadline" }
const _hoisted_52 = ["textContent"]
const _hoisted_53 = ["innerHTML"]
const _hoisted_54 = ["textContent"]
const _hoisted_55 = ["textContent"]
const _hoisted_56 = {
  key: "showing2",
  class: "o-subPanel"
}
const _hoisted_57 = ["textContent"]
const _hoisted_58 = { class: "o-fields" }
const _hoisted_59 = ["innerHTML"]
const _hoisted_60 = { class: "o-buttons o-subPanel__actions" }
const _hoisted_61 = ["textContent"]
const _hoisted_62 = ["textContent"]
const _hoisted_63 = {
  key: "showing3",
  class: "o-subPanel o-subPanel--spacedChildrenLarge"
}
const _hoisted_64 = ["textContent"]
const _hoisted_65 = {
  key: "openHouse",
  class: "o-panel"
}
const _hoisted_66 = ["textContent"]
const _hoisted_67 = {
  key: "openHouse1",
  class: "o-subPanel"
}
const _hoisted_68 = ["textContent"]
const _hoisted_69 = ["textContent"]
const _hoisted_70 = ["hidden", "disabled"]
const _hoisted_71 = ["textContent"]
const _hoisted_72 = {
  key: "openHouse2",
  class: "o-subPanel"
}
const _hoisted_73 = ["innerHTML"]
const _hoisted_74 = { class: "o-fields" }
const _hoisted_75 = ["innerHTML"]
const _hoisted_76 = { class: "o-buttons o-subPanel__actions" }
const _hoisted_77 = ["textContent"]
const _hoisted_78 = ["textContent"]
const _hoisted_79 = {
  key: "openHouse3",
  class: "o-subPanel o-subPanel--spacedChildrenLarge"
}
const _hoisted_80 = ["textContent"]
const _hoisted_81 = {
  key: "offer",
  class: "o-panel"
}
const _hoisted_82 = ["textContent"]
const _hoisted_83 = {
  key: "offer1",
  class: "o-subPanel"
}
const _hoisted_84 = ["textContent"]
const _hoisted_85 = { class: "o-fields" }
const _hoisted_86 = ["innerHTML"]
const _hoisted_87 = ["textContent"]
const _hoisted_88 = ["textContent"]
const _hoisted_89 = {
  key: "offer2",
  class: "o-subPanel o-subPanel--spacedChildrenLarge"
}
const _hoisted_90 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_property_card = _resolveComponent("o-property-card")!
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_o_employee_card = _resolveComponent("o-employee-card")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_o_overlay_promo_box = _resolveComponent("o-overlay-promo-box")!
  const _component_m_checkbox = _resolveComponent("m-checkbox")!
  const _component_m_date_picker = _resolveComponent("m-date-picker")!
  const _component_m_range_slider = _resolveComponent("m-range-slider")!
  const _component_portal = _resolveComponent("portal")!

  return ($options.isVisible)
    ? (_openBlock(), _createBlock(_component_portal, {
        key: 0,
        to: "modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: `o-panel--${$data.currentDirection}`
          }, {
            default: _withCtx(() => [
              (!_ctx.currentPanel)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("img", {
                        src: $options.formattedEmployeeImageUrl,
                        class: "o-panel__brokerImage",
                        alt: "Billede af ejendomsmægleren"
                      }, null, 8 /* PROPS */, _hoisted_3),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h3", {
                          class: "a-heading3 o-panel__name",
                          textContent: _toDisplayString($props.employee.name)
                        }, null, 8 /* PROPS */, _hoisted_5),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", {
                            class: "a-label",
                            textContent: _toDisplayString(
                                    `${$props.employee.title}${
                                        $props.employee.title && $props.employee.brokerName
                                            ? ', '
                                            : ''
                                    }${$props.employee.brokerName}`
                                )
                          }, null, 8 /* PROPS */, _hoisted_7)
                        ])
                      ])
                    ]),
                    _createVNode(_component_o_property_card, {
                      class: "o-propertyOverlay__propertyCard",
                      property: $props.property
                    }, null, 8 /* PROPS */, ["property"]),
                    _createCommentVNode(" TODO: This navigation should probably have same style, classes and HTML as .o-navigation (main menu) "),
                    _createElementVNode("div", _hoisted_8, [
                      (!$props.property.isUnderSale)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "o-panel__navItem a-heading3",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.nextPanel('showing')))
                          }, [
                            _createElementVNode("span", {
                              textContent: _toDisplayString($props.menu.showing)
                            }, null, 8 /* PROPS */, _hoisted_9),
                            _createVNode(_component_a_icon, {
                              name: "chevron_right",
                              class: "o-panel__icon"
                            })
                          ]))
                        : _createCommentVNode("v-if", true),
                      (
                            $props.property.documents.length > 0 &&
                            !$props.property.isUnderSale
                        )
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            type: "button",
                            class: "o-panel__navItem a-heading3",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.nextPanel('material')))
                          }, [
                            _createElementVNode("span", {
                              textContent: _toDisplayString($props.menu.material)
                            }, null, 8 /* PROPS */, _hoisted_10),
                            _createVNode(_component_a_icon, {
                              name: "chevron_right",
                              class: "o-panel__icon"
                            })
                          ]))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("button", {
                        type: "button",
                        class: "o-panel__navItem a-heading3",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.nextPanel('broker')))
                      }, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString($props.menu.contactBroker)
                        }, null, 8 /* PROPS */, _hoisted_11),
                        _createVNode(_component_a_icon, {
                          name: "chevron_right",
                          class: "o-panel__icon"
                        })
                      ]),
                      ($options.openHouseDates.length > 0)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 2,
                            type: "button",
                            class: "o-panel__navItem a-heading3",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.nextPanel('openHouse')))
                          }, [
                            _createElementVNode("span", {
                              textContent: _toDisplayString(
                                $options.anyOpenHouseSignup
                                    ? $props.menu.openHouse
                                    : $props.menu.openHouseWithoutSignup
                            )
                            }, null, 8 /* PROPS */, _hoisted_12),
                            _createVNode(_component_a_icon, {
                              name: "chevron_right",
                              class: "o-panel__icon"
                            })
                          ]))
                        : _createCommentVNode("v-if", true),
                      ($props.offerAllowed)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 3,
                            type: "button",
                            class: "o-panel__navItem a-heading3",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.nextPanel('offer')))
                          }, [
                            _createElementVNode("span", {
                              textContent: _toDisplayString($props.menu.makeAnOffer)
                            }, null, 8 /* PROPS */, _hoisted_13),
                            _createVNode(_component_a_icon, {
                              name: "chevron_right",
                              class: "o-panel__icon"
                            })
                          ]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]))
                : (_ctx.currentPanel === 'broker')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("h2", {
                        class: "a-heading2 o-panel__title o-panel__heading",
                        textContent: _toDisplayString($props.texts.broker.title)
                      }, null, 8 /* PROPS */, _hoisted_15),
                      _createVNode(_component_o_employee_card, {
                        panel: true,
                        employee: $props.employee,
                        class: "o-panel__brokerCard"
                      }, null, 8 /* PROPS */, ["employee"]),
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.currentSubPanel === 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createElementVNode("p", {
                                  class: "a-paragraph o-subPanel__description",
                                  textContent: _toDisplayString($props.texts.broker.description)
                                }, null, 8 /* PROPS */, _hoisted_17),
                                _createElementVNode("div", _hoisted_18, [
                                  _createVNode(_component_m_textarea, {
                                    modelValue: $data.form.message,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.form.message) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.message,
                                    label: `${$props.texts.form.message}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.name,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.form.name) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.name,
                                    label: `${$props.texts.form.name}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.mail,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.form.mail) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.mail,
                                    label: `${$props.texts.form.mail}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.phone,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.form.phone) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.phone,
                                    label: `${$props.texts.form.phone}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  ($props.consents && $props.consents.contactConsent)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "a-small a-small--allowBreak o-fields__field",
                                        innerHTML: $props.consents.contactConsent.purposeText
                                      }, null, 8 /* PROPS */, _hoisted_19))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                }, [
                                  (!_ctx.fixedPanel)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        type: "button",
                                        class: "m-button m-button--transparent",
                                        onClick: _cache[9] || (_cache[9] = ($event: any) => ($options.previousPanel('')))
                                      }, [
                                        _createVNode(_component_a_icon, {
                                          class: "m-button__icon",
                                          name: "arrow_back"
                                        }),
                                        _createElementVNode("span", {
                                          class: "m-button__text",
                                          textContent: _toDisplayString($props.texts.backButton)
                                        }, null, 8 /* PROPS */, _hoisted_20)
                                      ]))
                                    : _createCommentVNode("v-if", true),
                                  _createElementVNode("button", {
                                    type: "submit",
                                    class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                    onClick: _cache[10] || (_cache[10] = ($event: any) => ($options.onSubmit('broker')))
                                  }, [
                                    _createElementVNode("span", {
                                      class: "m-button__text",
                                      textContent: _toDisplayString($props.texts.broker.submitButton)
                                    }, null, 8 /* PROPS */, _hoisted_21)
                                  ], 2 /* CLASS */)
                                ], 2 /* CLASS */)
                              ]))
                            : (_ctx.currentSubPanel === 2)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                  _createElementVNode("p", {
                                    class: "a-paragraph o-subPanel_description",
                                    textContent: _toDisplayString(
                                $options.formattedSuccessMessage(
                                    $props.texts.broker.successMessage,
                                )
                            )
                                  }, null, 8 /* PROPS */, _hoisted_23),
                                  _createVNode(_component_o_overlay_promo_box, {
                                    "promo-box": _ctx.$props.promoBoxesContactBroker
                                  }, null, 8 /* PROPS */, ["promo-box"])
                                ]))
                              : _createCommentVNode("v-if", true)
                        ], undefined, true),
                        _: 1 /* STABLE */
                      })
                    ]))
                  : (_ctx.currentPanel === 'material')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                        _createElementVNode("h2", {
                          class: "a-heading2 o-panel__title",
                          textContent: _toDisplayString($props.texts.material.title)
                        }, null, 8 /* PROPS */, _hoisted_25),
                        _createVNode(_component_o_property_card, {
                          class: "o-propertyOverlay__propertyCard",
                          property: $props.property
                        }, null, 8 /* PROPS */, ["property"]),
                        _createVNode(_Transition, {
                          name: "fade",
                          mode: "out-in"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.currentSubPanel === 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                  _createElementVNode("p", {
                                    class: "a-paragraph o-subPanel__description",
                                    textContent: _toDisplayString($props.texts.material.description)
                                  }, null, 8 /* PROPS */, _hoisted_27),
                                  _createElementVNode("div", _hoisted_28, [
                                    _createVNode(_component_m_input, {
                                      modelValue: $data.form.name,
                                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.form.name) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.name,
                                      label: `${$props.texts.form.name}*`
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                    _createVNode(_component_m_input, {
                                      modelValue: $data.form.phone,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($data.form.phone) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.phone,
                                      label: `${$props.texts.form.phone}*`
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                    _createVNode(_component_m_input, {
                                      modelValue: $data.form.mail,
                                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($data.form.mail) = $event)),
                                      class: "o-fields__field",
                                      error: $data.errors.mail,
                                      label: `${$props.texts.form.mail}*`
                                    }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                    ($props.consents && $props.consents.materialConsent)
                                      ? (_openBlock(), _createElementBlock("div", {
                                          key: 0,
                                          class: "a-small a-small--allowBreak o-fields__field",
                                          innerHTML: $props.consents.materialConsent.purposeText
                                        }, null, 8 /* PROPS */, _hoisted_29))
                                      : _createCommentVNode("v-if", true),
                                    ($props.consents && $props.consents.materialConsent)
                                      ? (_openBlock(), _createBlock(_component_m_checkbox, {
                                          key: 1,
                                          modelValue: $data.form.marketing,
                                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($data.form.marketing) = $event)),
                                          class: "o-fields__field",
                                          error: $data.errors.marketing,
                                          label: $props.consents.materialConsent.heading
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]))
                                      : _createCommentVNode("v-if", true)
                                  ]),
                                  _createElementVNode("div", _hoisted_30, [
                                    _createElementVNode("button", {
                                      type: "submit",
                                      class: _normalizeClass(["m-button m-button--fullWidth", { isLoading: $data.isLoading }]),
                                      disabled: !$data.form.marketing,
                                      onClick: _cache[15] || (_cache[15] = ($event: any) => ($options.onSubmit('material')))
                                    }, [
                                      _createElementVNode("span", {
                                        class: "m-button__text",
                                        textContent: _toDisplayString($props.texts.material.submitButton)
                                      }, null, 8 /* PROPS */, _hoisted_32)
                                    ], 10 /* CLASS, PROPS */, _hoisted_31)
                                  ]),
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                  }, [
                                    (!_ctx.fixedPanel)
                                      ? (_openBlock(), _createElementBlock("button", {
                                          key: 0,
                                          type: "button",
                                          class: "m-button m-button--transparent",
                                          onClick: _cache[16] || (_cache[16] = ($event: any) => ($options.previousPanel('')))
                                        }, [
                                          _createVNode(_component_a_icon, {
                                            class: "m-button__icon",
                                            name: "arrow_back"
                                          }),
                                          _createElementVNode("span", {
                                            class: "m-button__text",
                                            textContent: _toDisplayString($props.texts.backButton)
                                          }, null, 8 /* PROPS */, _hoisted_33)
                                        ]))
                                      : _createCommentVNode("v-if", true),
                                    _createElementVNode("button", {
                                      type: "button",
                                      class: "m-button m-button--transparent m-button--underlined",
                                      onClick: _cache[17] || (_cache[17] = ($event: any) => ($options.changeCurrentSubPanel(2, 'material')))
                                    }, [
                                      _createElementVNode("span", {
                                        class: "m-button__text",
                                        textContent: _toDisplayString($props.texts.material.documentsButton)
                                      }, null, 8 /* PROPS */, _hoisted_34)
                                    ])
                                  ], 2 /* CLASS */)
                                ]))
                              : (_ctx.currentSubPanel === 2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                    ($data.form.name && $data.form.phone && $data.form.mail)
                                      ? (_openBlock(), _createElementBlock("p", {
                                          key: 0,
                                          class: "a-paragraph o-subPanel__description",
                                          textContent: _toDisplayString(
                                $options.formattedSuccessMessage(
                                    $props.texts.material.successMessage,
                                )
                            )
                                        }, null, 8 /* PROPS */, _hoisted_36))
                                      : _createCommentVNode("v-if", true),
                                    _createElementVNode("div", _hoisted_37, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.property.documents, (download, index) => {
                                        return (_openBlock(), _createElementBlock("a", {
                                          key: index,
                                          href: download.url,
                                          target: "_blank",
                                          class: "a-anchor a-anchor--secondary o-subPanel__downloadLink",
                                          onClick: ($event: any) => (
                                    $options.incrementDocumentDownloadsCount(
                                        download.visibleName,
                                    )
                                )
                                        }, [
                                          _createVNode(_component_a_icon, { name: "pdf" }),
                                          _createElementVNode("span", {
                                            textContent: _toDisplayString(download.visibleName)
                                          }, null, 8 /* PROPS */, _hoisted_39)
                                        ], 8 /* PROPS */, _hoisted_38))
                                      }), 128 /* KEYED_FRAGMENT */))
                                    ]),
                                    _createElementVNode("div", _hoisted_40, [
                                      _createElementVNode("button", {
                                        type: "button",
                                        class: "m-button m-button--transparent",
                                        onClick: _cache[18] || (_cache[18] = ($event: any) => ($options.changeCurrentSubPanel(1, 'material')))
                                      }, [
                                        _createVNode(_component_a_icon, {
                                          class: "m-button__icon",
                                          name: "arrow_back"
                                        }),
                                        _createElementVNode("span", {
                                          class: "m-button__text",
                                          textContent: _toDisplayString($props.texts.backButton)
                                        }, null, 8 /* PROPS */, _hoisted_41)
                                      ])
                                    ]),
                                    _createVNode(_component_o_overlay_promo_box, {
                                      "promo-box": _ctx.$props.promoBoxesMaterial
                                    }, null, 8 /* PROPS */, ["promo-box"])
                                  ]))
                                : _createCommentVNode("v-if", true)
                          ], undefined, true),
                          _: 1 /* STABLE */
                        })
                      ]))
                    : (_ctx.currentPanel === 'showing')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                          _createElementVNode("h2", {
                            class: "a-heading2 o-panel__title",
                            textContent: _toDisplayString($props.texts.showing.title)
                          }, null, 8 /* PROPS */, _hoisted_43),
                          _createVNode(_component_o_property_card, {
                            class: "o-propertyOverlay__propertyCard",
                            property: $props.property
                          }, null, 8 /* PROPS */, ["property"]),
                          _createVNode(_Transition, {
                            name: "fade",
                            mode: "out-in"
                          }, {
                            default: _withCtx(() => [
                              (_ctx.currentSubPanel === 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                    _createElementVNode("p", {
                                      class: "a-paragraph o-subPanel__description",
                                      textContent: _toDisplayString($props.texts.showing.description)
                                    }, null, 8 /* PROPS */, _hoisted_45),
                                    _createVNode(_component_m_date_picker, {
                                      "datepicker-dates": $props.datepickerDates,
                                      "datepicker-pairs": $options.openHouseDates,
                                      "initial-date": $data.form.date,
                                      direction: "horizontal",
                                      "asap-text": $options.formatAsapText,
                                      onDateSelected: $options.onDateSelected
                                    }, null, 8 /* PROPS */, ["datepicker-dates", "datepicker-pairs", "initial-date", "asap-text", "onDateSelected"]),
                                    _createElementVNode("div", _hoisted_46, [
                                      _createVNode(_component_m_range_slider, {
                                        modelValue: $data.form.timeRange,
                                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($data.form.timeRange) = $event)),
                                        interval: 25,
                                        min: $data.showingTimeRange[0],
                                        max: $data.showingTimeRange[1],
                                        type: "time"
                                      }, null, 8 /* PROPS */, ["modelValue", "min", "max"])
                                    ]),
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                    }, [
                                      (!_ctx.fixedPanel)
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            type: "button",
                                            class: "m-button m-button--transparent",
                                            onClick: _cache[20] || (_cache[20] = ($event: any) => ($options.previousPanel('')))
                                          }, [
                                            _createVNode(_component_a_icon, {
                                              class: "m-button__icon",
                                              name: "arrow_back"
                                            }),
                                            _createElementVNode("span", {
                                              class: "m-button__text",
                                              textContent: _toDisplayString($props.texts.backButton)
                                            }, null, 8 /* PROPS */, _hoisted_47)
                                          ]))
                                        : _createCommentVNode("v-if", true),
                                      _createElementVNode("button", {
                                        type: "button",
                                        class: "m-button",
                                        disabled: !$data.showingDateHasBeenSelected,
                                        onClick: _cache[21] || (_cache[21] = ($event: any) => ($options.changeCurrentSubPanel(2, 'showing')))
                                      }, [
                                        _createElementVNode("span", {
                                          class: "m-button__text",
                                          textContent: _toDisplayString($props.texts.showing.requestShowingButton)
                                        }, null, 8 /* PROPS */, _hoisted_49)
                                      ], 8 /* PROPS */, _hoisted_48)
                                    ], 2 /* CLASS */),
                                    _createVNode(_Transition, { name: "fade" }, {
                                      default: _withCtx(() => [
                                        ($options.dateHasOpenHouse)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                                              _createElementVNode("div", _hoisted_51, [
                                                _createElementVNode("span", {
                                                  class: "a-lead",
                                                  textContent: _toDisplayString($props.texts.showing.openHouseTitle)
                                                }, null, 8 /* PROPS */, _hoisted_52),
                                                _createElementVNode("span", {
                                                  class: "a-lead",
                                                  innerHTML: $options.showingSelectedOpenHouse
                                                }, null, 8 /* PROPS */, _hoisted_53)
                                              ]),
                                              _createElementVNode("span", {
                                                class: "a-small a-small--allowBreak",
                                                textContent: _toDisplayString($props.texts.showing.openHouseDescription)
                                              }, null, 8 /* PROPS */, _hoisted_54),
                                              _createElementVNode("button", {
                                                type: "button",
                                                class: "m-button",
                                                onClick: _cache[22] || (_cache[22] = ($event: any) => ($options.nextPanel('openHouse')))
                                              }, [
                                                _createElementVNode("span", {
                                                  class: "m-button__text",
                                                  textContent: _toDisplayString(
                                            $props.texts.showing.openHouseButtonText
                                        )
                                                }, null, 8 /* PROPS */, _hoisted_55)
                                              ])
                                            ]))
                                          : _createCommentVNode("v-if", true)
                                      ], undefined, true),
                                      _: 1 /* STABLE */
                                    })
                                  ]))
                                : (_ctx.currentSubPanel === 2)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                                      _createElementVNode("p", {
                                        class: "a-paragraph o-subPanel__description",
                                        textContent: _toDisplayString($options.showingDescription)
                                      }, null, 8 /* PROPS */, _hoisted_57),
                                      _createElementVNode("div", _hoisted_58, [
                                        _createVNode(_component_m_input, {
                                          modelValue: $data.form.name,
                                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($data.form.name) = $event)),
                                          class: "o-fields__field",
                                          error: $data.errors.name,
                                          label: `${$props.texts.form.name}*`
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                        _createVNode(_component_m_input, {
                                          modelValue: $data.form.mail,
                                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($data.form.mail) = $event)),
                                          class: "o-fields__field",
                                          error: $data.errors.mail,
                                          label: `${$props.texts.form.mail}*`
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                        _createVNode(_component_m_input, {
                                          modelValue: $data.form.phone,
                                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($data.form.phone) = $event)),
                                          class: "o-fields__field",
                                          error: $data.errors.phone,
                                          label: `${$props.texts.form.phone}*`
                                        }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                        ($props.consents && $props.consents.showingConsent)
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 0,
                                              class: "a-small a-small--allowBreak o-fields__field",
                                              innerHTML: $props.consents.showingConsent.purposeText
                                            }, null, 8 /* PROPS */, _hoisted_59))
                                          : _createCommentVNode("v-if", true)
                                      ]),
                                      _createElementVNode("div", _hoisted_60, [
                                        _createElementVNode("button", {
                                          type: "button",
                                          class: "m-button m-button--transparent",
                                          onClick: _cache[26] || (_cache[26] = ($event: any) => ($options.changeCurrentSubPanel(1, 'showing')))
                                        }, [
                                          _createVNode(_component_a_icon, {
                                            class: "m-button__icon",
                                            name: "arrow_back"
                                          }),
                                          _createElementVNode("span", {
                                            class: "m-button__text",
                                            textContent: _toDisplayString($props.texts.backButton)
                                          }, null, 8 /* PROPS */, _hoisted_61)
                                        ]),
                                        _createElementVNode("button", {
                                          type: "submit",
                                          class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                          onClick: _cache[27] || (_cache[27] = ($event: any) => ($options.onSubmit('showing')))
                                        }, [
                                          _createElementVNode("span", {
                                            class: "m-button__text",
                                            textContent: _toDisplayString($props.texts.showing.submitButton)
                                          }, null, 8 /* PROPS */, _hoisted_62)
                                        ], 2 /* CLASS */)
                                      ])
                                    ]))
                                  : (_ctx.currentSubPanel === 3)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                                        _createElementVNode("p", {
                                          class: "a-paragraph o-subPanel__description",
                                          textContent: _toDisplayString(
                                $options.formattedSuccessMessage(
                                    $props.texts.showing.successMessage,
                                )
                            )
                                        }, null, 8 /* PROPS */, _hoisted_64),
                                        _createVNode(_component_o_overlay_promo_box, {
                                          "promo-box": _ctx.$props.promoBoxesShowing
                                        }, null, 8 /* PROPS */, ["promo-box"])
                                      ]))
                                    : _createCommentVNode("v-if", true)
                            ], undefined, true),
                            _: 1 /* STABLE */
                          })
                        ]))
                      : (_ctx.currentPanel === 'openHouse')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                            _createElementVNode("h2", {
                              class: "a-heading2 o-panel__title",
                              textContent: _toDisplayString(
                        $options.anyOpenHouseSignup
                            ? $props.texts.openHouse.openHouseSignupHeader
                            : $props.texts.openHouse.openHouseNoSignupHeader
                    )
                            }, null, 8 /* PROPS */, _hoisted_66),
                            _createVNode(_component_o_property_card, {
                              class: "o-propertyOverlay__propertyCard",
                              property: $props.property
                            }, null, 8 /* PROPS */, ["property"]),
                            _createVNode(_Transition, {
                              name: "fade",
                              mode: "out-in"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.currentSubPanel === 1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("p", {
                                          class: "a-paragraph o-subPanel__description",
                                          textContent: _toDisplayString(
                                    $options.anyOpenHouseSignup
                                        ? $props.texts.openHouse
                                              .openHouseSignupDateBodyText
                                        : $props.texts.openHouse
                                              .openHouseNoSignupDateBodyText
                                )
                                        }, null, 8 /* PROPS */, _hoisted_68),
                                        _createVNode(_component_m_date_picker, {
                                          "datepicker-pairs": $options.openHouseDates,
                                          "small-text-signup": 
                                    $props.texts.openHouse.smallTextSignup
                                ,
                                          "small-text-no-signup": 
                                    $props.texts.openHouse.smallTextNoSignup
                                ,
                                          "initial-date": $data.form.openHouse.openHouseFrom,
                                          direction: "vertical",
                                          onDateSelected: $options.onOpenHouseDateSelected
                                        }, null, 8 /* PROPS */, ["datepicker-pairs", "small-text-signup", "small-text-no-signup", "initial-date", "onDateSelected"]),
                                        _createElementVNode("div", {
                                          class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                    'o-subPanel__actions--fixed': _ctx.fixedPanel,
                                }])
                                        }, [
                                          (!_ctx.fixedPanel)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                type: "button",
                                                class: "m-button m-button--transparent",
                                                onClick: _cache[28] || (_cache[28] = ($event: any) => ($options.previousPanel('')))
                                              }, [
                                                _createVNode(_component_a_icon, {
                                                  class: "m-button__icon",
                                                  name: "arrow_back"
                                                }),
                                                _createElementVNode("span", {
                                                  class: "m-button__text",
                                                  textContent: _toDisplayString($props.texts.backButton)
                                                }, null, 8 /* PROPS */, _hoisted_69)
                                              ]))
                                            : _createCommentVNode("v-if", true),
                                          _createElementVNode("button", {
                                            type: "button",
                                            class: "m-button",
                                            hidden: !$options.anyRequiredOpenHouse,
                                            disabled: !$data.openHouseDateSelected,
                                            onClick: _cache[29] || (_cache[29] = ($event: any) => (
                                        $options.changeCurrentSubPanel(2, 'openHouse')
                                    ))
                                          }, [
                                            _createElementVNode("span", {
                                              class: "m-button__text",
                                              textContent: _toDisplayString(
                                            $props.texts.openHouse
                                                .openHouseSignupDateCtaText
                                        )
                                            }, null, 8 /* PROPS */, _hoisted_71)
                                          ], 8 /* PROPS */, _hoisted_70)
                                        ], 2 /* CLASS */)
                                      ])
                                    ]))
                                  : (_ctx.currentSubPanel === 2)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                                        _createElementVNode("p", {
                                          class: "a-paragraph o-subPanel__description",
                                          innerHTML: $options.formatOpenHouseBody
                                        }, null, 8 /* PROPS */, _hoisted_73),
                                        _createElementVNode("div", _hoisted_74, [
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.name,
                                            "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($data.form.name) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.name,
                                            label: `${$props.texts.form.name}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.mail,
                                            "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (($data.form.mail) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.mail,
                                            label: `${$props.texts.form.mail}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.phone,
                                            "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => (($data.form.phone) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.phone,
                                            label: `${$props.texts.form.phone}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          ($props.consents && $props.consents.openHouseConsent)
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: "a-small a-small--allowBreak o-fields__field",
                                                innerHTML: $props.consents.openHouseConsent.purposeText
                                              }, null, 8 /* PROPS */, _hoisted_75))
                                            : _createCommentVNode("v-if", true)
                                        ]),
                                        _createElementVNode("div", _hoisted_76, [
                                          _createElementVNode("button", {
                                            type: "button",
                                            class: "m-button m-button--transparent",
                                            onClick: _cache[33] || (_cache[33] = ($event: any) => ($options.changeCurrentSubPanel(1, 'openHouse')))
                                          }, [
                                            _createVNode(_component_a_icon, {
                                              class: "m-button__icon",
                                              name: "arrow_back"
                                            }),
                                            _createElementVNode("span", {
                                              class: "m-button__text",
                                              textContent: _toDisplayString($props.texts.backButton)
                                            }, null, 8 /* PROPS */, _hoisted_77)
                                          ]),
                                          _createElementVNode("button", {
                                            type: "submit",
                                            class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                            onClick: _cache[34] || (_cache[34] = ($event: any) => ($options.onSubmit('openHouse')))
                                          }, [
                                            _createElementVNode("span", {
                                              class: "m-button__text",
                                              textContent: _toDisplayString(
                                        $props.texts.openHouse
                                            .openHouseSignupFormCtaText
                                    )
                                            }, null, 8 /* PROPS */, _hoisted_78)
                                          ], 2 /* CLASS */)
                                        ])
                                      ]))
                                    : (_ctx.currentSubPanel === 3)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                          _createElementVNode("p", {
                                            class: "a-paragraph o-subPanel__description",
                                            textContent: _toDisplayString(
                                $options.formattedSuccessMessage(
                                    $props.texts.openHouse
                                        .openHouseSignupReceiptBodyText,
                                )
                            )
                                          }, null, 8 /* PROPS */, _hoisted_80),
                                          _createVNode(_component_o_overlay_promo_box, {
                                            "promo-box": _ctx.$props.promoBoxesOpenHouse
                                          }, null, 8 /* PROPS */, ["promo-box"])
                                        ]))
                                      : _createCommentVNode("v-if", true)
                              ], undefined, true),
                              _: 1 /* STABLE */
                            })
                          ]))
                        : (_ctx.currentPanel === 'offer' && $props.offerAllowed)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                              _createElementVNode("h2", {
                                class: "a-heading2 o-panel__title o-panel__heading",
                                textContent: _toDisplayString($props.texts.offer.title)
                              }, null, 8 /* PROPS */, _hoisted_82),
                              _createVNode(_component_o_property_card, {
                                class: "o-propertyOverlay__propertyCard",
                                property: $props.property
                              }, null, 8 /* PROPS */, ["property"]),
                              _createVNode(_Transition, {
                                name: "fade",
                                mode: "out-in"
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.currentSubPanel === 1)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                                        _createElementVNode("p", {
                                          class: "a-paragraph o-subPanel__description",
                                          textContent: _toDisplayString($props.texts.offer.description)
                                        }, null, 8 /* PROPS */, _hoisted_84),
                                        _createElementVNode("div", _hoisted_85, [
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.offer,
                                            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (($data.form.offer) = $event)),
                                            type: "price",
                                            class: "o-fields__field",
                                            error: $data.errors.offer,
                                            label: `${$props.texts.form.offer}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.name,
                                            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => (($data.form.name) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.name,
                                            label: `${$props.texts.form.name}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.mail,
                                            "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => (($data.form.mail) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.mail,
                                            label: `${$props.texts.form.mail}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          _createVNode(_component_m_input, {
                                            modelValue: $data.form.phone,
                                            "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => (($data.form.phone) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.phone,
                                            label: `${$props.texts.form.phone}*`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          _createVNode(_component_m_textarea, {
                                            modelValue: $data.form.message,
                                            "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (($data.form.message) = $event)),
                                            class: "o-fields__field",
                                            error: $data.errors.message,
                                            label: `${$props.texts.form.message}`
                                          }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                          ($props.consents && $props.consents.offerConsent)
                                            ? (_openBlock(), _createElementBlock("div", {
                                                key: 0,
                                                class: "a-small a-small--allowBreak o-fields__field",
                                                innerHTML: $props.consents.offerConsent.purposeText
                                              }, null, 8 /* PROPS */, _hoisted_86))
                                            : _createCommentVNode("v-if", true)
                                        ]),
                                        _createElementVNode("div", {
                                          class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                        }, [
                                          (!_ctx.fixedPanel)
                                            ? (_openBlock(), _createElementBlock("button", {
                                                key: 0,
                                                type: "button",
                                                class: "m-button m-button--transparent",
                                                onClick: _cache[40] || (_cache[40] = ($event: any) => ($options.previousPanel('')))
                                              }, [
                                                _createVNode(_component_a_icon, {
                                                  class: "m-button__icon",
                                                  name: "arrow_back"
                                                }),
                                                _createElementVNode("span", {
                                                  class: "m-button__text",
                                                  textContent: _toDisplayString($props.texts.backButton)
                                                }, null, 8 /* PROPS */, _hoisted_87)
                                              ]))
                                            : _createCommentVNode("v-if", true),
                                          _createElementVNode("button", {
                                            type: "submit",
                                            class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                            onClick: _cache[41] || (_cache[41] = ($event: any) => ($options.onSubmit('offer')))
                                          }, [
                                            _createElementVNode("span", {
                                              class: "m-button__text",
                                              textContent: _toDisplayString($props.texts.offer.submitButton)
                                            }, null, 8 /* PROPS */, _hoisted_88)
                                          ], 2 /* CLASS */)
                                        ], 2 /* CLASS */)
                                      ]))
                                    : (_ctx.currentSubPanel === 2)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                                          _createElementVNode("p", {
                                            class: "a-paragraph o-subPanel__description",
                                            textContent: _toDisplayString(
                                $options.formattedSuccessMessage(
                                    $props.texts.offer.successMessage,
                                )
                            )
                                          }, null, 8 /* PROPS */, _hoisted_90),
                                          _createVNode(_component_o_overlay_promo_box, {
                                            "promo-box": _ctx.$props.promoBoxesMakeAnOffer
                                          }, null, 8 /* PROPS */, ["promo-box"])
                                        ]))
                                      : _createCommentVNode("v-if", true)
                                ], undefined, true),
                                _: 1 /* STABLE */
                              })
                            ]))
                          : _createCommentVNode("v-if", true)
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["name"])
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}