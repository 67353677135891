import State from './interface';

export default {
    /**
     * Set the current panel.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setCurrentPanel(state: State, payload: string): void {
        state.overlay.currentPanel = payload;
    },

    /**
     * Set the current sub panel.
     *
     * @param {State} state
     * @param {number} payload
     * @return {void}
     */
    setCurrentSubPanel(state: State, payload: number): void {
        state.overlay.currentSubPanel = payload;
    },

    setName(state: State, payload: string): void {
        state.agentName = payload;
    },
};
