import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';

export default {
    /**
     * Get the current panel.
     *
     * @param {State} state
     * @return {string}
     */
    currentPanel(state: State): string {
        return state.overlay.currentPanel;
    },

    /**
     * Get the current sub panel.
     *
     * @param {State} state
     * @return {number}
     */
    currentSubPanel(state: State): number {
        return state.overlay.currentSubPanel;
    },

    /**
     * Get list of filter tags.
     *
     * @param {State} state
     * @return {string[]}
     */
    currentTags(state: State): KeyValue[] {
        return state.filters.tags;
    },

    /**
     * Get list of filter tags.
     *
     * @param {State} state
     * @return {number[]}
     */
    currentPrice(state: State): number[] {
        return state.filters.price;
    },

    /**
     * Get list of filter tags.
     *
     * @param {State} state
     * @return {number[]}
     */
    currentSize(state: State): number[] {
        return state.filters.size;
    },

    /**
     * Get comment.
     *
     * @param {State} state
     * @return {number[]}
     */
    currentName(state: State): string {
        return state.filters.name;
    },

    /**
     * Get list of filter tags.
     *
     * @param {State} state
     * @return {number[]}
     */
    currentZipcode(state: State): string {
        return state.filters.zipcode;
    },

    /**
     * Get list of filter tags.
     *
     * @param {State} state
     * @return {number[]}
     */
    currentComment(state: State): string {
        return state.filters.comment;
    },
};
