import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "aria-pressed"]
const _hoisted_2 = { class: "m-tag__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["m-tag", [$options.modifierClass, { isChecked: $options.isChecked }]]),
    disabled: $options.isDisabled,
    "aria-pressed": $options.isChecked,
    type: "button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClick()))
  }, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _renderSlot(_ctx.$slots, "icon")
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}