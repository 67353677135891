
import { PropType } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import mMap from '@/components/molecules/Map.vue';
import { BOUNDS, MAX_BOUNDS } from '@/constants/search.const';
import Api from '@/functions/api';
import MapAddress from '@/interfaces/mapAddress.interface';
import MapBroker from '@/interfaces/mapBroker.interface';
import MapFavorite from '@/interfaces/mapFavorite.interface';
import BrokersMapResponse from '@/interfaces/responses/brokersMapResponse.interface';
import FavoritesMapResponse from '@/interfaces/responses/favoritesMapResponse.interface';
import POI from '@/interfaces/POI.interface';
import { FullRoute } from '@/store/pointsOfInterest/interface';
import { EventBus } from '@/functions/eventBus';

interface Data {
    bounds: number[][];
    brokers: MapBroker[];
    favorites: MapFavorite[];
    fitMapToFavorites: boolean;
    hasFetchedAllFavorites: boolean;
    hasRenderedInitialFavorites: boolean;
    numberOfFavoritesToGet: number;
    isDesktop: boolean;
    isLoading: boolean;
    maxBounds: { lat: number; lng: number }[];
    // minZoomForEnablingPOIs: number;
    pois: POI[];
    zoom: number;
}

export default {
    components: {
        mMap,
    },

    props: {
        latitude: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        longitude: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        label: {
            type: String as PropType<string>,
            default: () => '',
        },
        neighborhoodId: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        hidePois: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            bounds: BOUNDS,
            brokers: [],
            favorites: [],
            fitMapToFavorites: true,
            hasFetchedAllFavorites: false,
            hasRenderedInitialFavorites: false,
            numberOfFavoritesToGet: 5,
            isDesktop: EventBus.isDesktop,
            isLoading: true,
            maxBounds: MAX_BOUNDS,
            // minZoomForEnablingPOIs: 14, // moved to computed value
            pois: [],
            zoom: 12,
        };
    },

    beforeMount(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    computed: {
        ...mapGetters({
            currentPoi: 'pointsOfInterest/currentPoi',
            currentRoute: 'pointsOfInterest/currentRoute',
            showFavorites: 'pointsOfInterest/showFavorites',
            selectedPois: 'pointsOfInterest/selectedPois',
        }),

        address(): MapAddress {
            return {
                geometry: {
                    coordinates: [this.longitude, this.latitude],
                    type: 'Point',
                },
                properties: {
                    label: this.label,
                },
                type: 'Feature',
            };
        },

        chosenPOITypes(): string[][] | [] {
            if (
                this.currentPoi &&
                !this.selectedPois.some(
                    (selectedPoi) =>
                        selectedPoi.key === this.currentPoi?.typeKey,
                )
            ) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.setCurrentRoutes([]);
                this.setCurrentPoi(undefined);
            }
            if (!this.selectedPois.length) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.pois = [];
                this.setCurrentRoutes([]);
                return [];
            }

            return this.selectedPois.map(
                (selectedPoi: {
                    key: string;
                    value: { key: string; title: string }[];
                }) => {
                    return selectedPoi.value.map((value) => value.key);
                },
            );
        },

        minZoomForEnablingPOIs(): number {
            return this.isDesktop ? 14 : 11;
        },
    },

    watch: {
        chosenPOITypes(): void {
            this.getPOIs();
        },
        zoom: {
            handler(): void {
                this.setSelectorsEnabled(
                    this.zoom >= this.minZoomForEnablingPOIs,
                );
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions({
            setCurrentRoutes: 'pointsOfInterest/setCurrentRoutes',
            setCurrentPoi: 'pointsOfInterest/setCurrentPoi',
            setSelectorsEnabled: 'pointsOfInterest/setSelectorsEnabled',
        }),

        getBrokers(): Promise<BrokersMapResponse> {
            return Api.getBrokers({
                query: '',
            });
        },

        getData(): void {
            this.isLoading = true;
            const brokersPromise = this.getBrokers();

            if (!this.hasFetchedAllFavorites) {
                const favoritesPromise = this.getFavorites();

                Promise.all([brokersPromise, favoritesPromise]).then(
                    (values) => {
                        if (values[0]?.results) {
                            this.brokers = values[0].results;
                        }

                        if (values[1]?.results) {
                            this.favorites = values[1].results;
                            this.hasRenderedInitialFavorites = true;
                        }

                        this.isLoading = false;
                    },
                );
            } else {
                Promise.all([brokersPromise]).then((values) => {
                    if (values[0]?.results) {
                        this.brokers = values[0].results;
                    }
                    this.isLoading = false;
                });
            }
        },

        getFavorites(): Promise<FavoritesMapResponse> {
            if (
                !this.hasFetchedAllFavorites &&
                this.hasRenderedInitialFavorites
            ) {
                this.numberOfFavoritesToGet = 10000;
                this.hasFetchedAllFavorites = true;
            }

            if (this.latitude !== 0 && this.longitude !== 0) {
                return Api.getFavoritesMap({
                    lat: this.latitude,
                    lng: this.longitude,
                    numberOfFavorites: this.numberOfFavoritesToGet,
                });
            }
            return Promise.reject();
        },

        /**
         * Fetch POIs from ViaMap to render on map
         *
         * @return {void}
         */
        getPOIs(): void {
            // Needed for ProjectSales, in which case we don't want to show the POIs
            if (this.hidePois) {
                return;
            }

            if (this.zoom < this.minZoomForEnablingPOIs) {
                if (this.currentPoi) {
                    this.pois = [this.currentPoi];
                } else {
                    this.pois = [];
                }
                return;
            }
            if (this.chosenPOITypes.length) {
                let a = this.bounds.map((item) => {
                    return item[0];
                });
                let b = this.bounds.map((item) => {
                    return item[1];
                });

                let minA = Math.min(...a);
                let maxA = Math.max(...a);

                let minB = Math.min(...b);
                let maxB = Math.max(...b);

                const bBox = `${minB},${minA}+${maxB},${maxA}`;

                Api.getPOIs({
                    bBox,
                    poiTypes: this.chosenPOITypes,
                    lat: this.latitude,
                    lng: this.longitude,
                    zoom: this.zoom,
                }).then((response: any) => {
                    const poisOnly = Object.keys(response.data).map(
                        (poiType): POI[] => {
                            if (response.data[poiType].numfound < 1) {
                                return [];
                            }
                            const groupedType = this.selectedPois.find(
                                (selectedPoi: {
                                    key: string;
                                    value: { key: string; title: string }[];
                                }) => {
                                    return selectedPoi.value.find(
                                        (selectedPoiValues: {
                                            key: string;
                                            title: string;
                                        }) => selectedPoiValues.key === poiType,
                                    );
                                },
                            );
                            const poiTypeDisplayName = groupedType.value.find(
                                (values: { key: string; title: string }) =>
                                    values.key === poiType,
                            );
                            return response.data[poiType].POIs?.map(
                                (poi: POI): POI => {
                                    return {
                                        ...poi,
                                        typeKey: groupedType?.key,
                                        typeName: groupedType?.name,
                                        poiTypeTitle: poiTypeDisplayName?.title,
                                    };
                                },
                            );
                        },
                    );
                    this.pois = poisOnly.flat();
                });
            }
        },

        /**
         * Fetch POIs from ViaMap to render on map
         *
         * @return {void}
         */
        getRoutesToPOI({
            poi,
            zoomLevel,
        }: {
            poi: POI;
            zoomLevel: number;
        }): void {
            if (poi) {
                const baseQuery = {
                    lat: this.latitude,
                    lng: this.longitude,
                    poiCoordinate: poi,
                    decodepolyline: true,
                    zoomLevel: Math.floor(zoomLevel),
                };
                const carPromise = Api.getPOIroute({
                    ...baseQuery,
                    methodOfTransport: 'car',
                });
                const footPromise = Api.getPOIroute({
                    ...baseQuery,
                    methodOfTransport: 'foot',
                });
                const bikePromise = Api.getPOIroute({
                    ...baseQuery,
                    methodOfTransport: 'bicycle',
                });
                Promise.all([carPromise, footPromise, bikePromise]).then(
                    (values) => {
                        const [car, foot, bicycle] = values;

                        const routes: FullRoute[] = [];

                        function secondsHelper(seconds: number) {
                            const minutes = Math.ceil(seconds / 60);
                            return minutes;
                        }

                        routes.push({
                            routeType: 'car',
                            routedmeters: car[0].routedmeters,
                            routeTravelSeconds: car[0]
                                ? secondsHelper(car[0].travelseconds)
                                : 0,
                            routePolyline:
                                car.length && car[0].routepolyline
                                    ? car[0].routepolyline.map(
                                          (point: number[]) => {
                                              return [point[1], point[0]];
                                          },
                                      )
                                    : [],
                        });

                        routes.push({
                            routeType: 'bicycle',
                            routedmeters: bicycle[0].routedmeters,
                            routeTravelSeconds: bicycle[0]
                                ? secondsHelper(bicycle[0].travelseconds)
                                : 0,
                            routePolyline:
                                bicycle.length && bicycle[0].routepolyline
                                    ? bicycle[0].routepolyline.map(
                                          (point: number[]) => {
                                              return [point[1], point[0]];
                                          },
                                      )
                                    : [],
                        });

                        routes.push({
                            routeType: 'foot',
                            routedmeters: foot[0].routedmeters,
                            routeTravelSeconds: foot[0]
                                ? secondsHelper(foot[0].travelseconds)
                                : 0,
                            routePolyline:
                                foot.length && foot[0].routepolyline
                                    ? foot[0].routepolyline.map(
                                          (point: number[]) => {
                                              return [point[1], point[0]];
                                          },
                                      )
                                    : [],
                        });

                        this.setCurrentRoutes(routes);
                    },
                );
            }
        },

        /**
         * Handle "close" event on map.
         *
         * @return {void}
         */
        onClose(): void {
            this.setCurrentRoutes([]);
        },

        /**
         * Handle "load" event on map.
         *
         * @return {void}
         */
        onLoad(payload: any): void {
            const northEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._ne.lat,
            ];
            const northWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._ne.lat,
            ];
            const southEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._sw.lat,
            ];
            const southWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._sw.lat,
            ];

            this.bounds = [
                southWest,
                southEast,
                northEast,
                northWest,
                southWest,
            ];

            this.getData();
            // this.getPOIs();
        },

        /**
         * Handle "move" event on map.
         *
         * @param {any} event
         * @return {void}
         */
        onMove(payload: any): void {
            this.zoom = payload.zoom;

            const northEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._ne.lat,
            ];
            const northWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._ne.lat,
            ];
            const southEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._sw.lat,
            ];
            const southWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._sw.lat,
            ];

            this.bounds = [
                southWest,
                southEast,
                northEast,
                northWest,
                southWest,
            ];
            if (payload.isUserInteraction) {
                this.getData();
            }
            this.getPOIs();
        },
    },
};
