import emitter from 'tiny-emitter/instance';
import { Breakpoints } from '@/enums/breakpoints.enum';
import tracking from './tracking';

// eslint-disable-next-line import/prefer-default-export
export const EventBus = {
    hasMouse: false,
    hasTouch: false,
    isDesktop: false,
    isLandscape: false,

    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args),

    initiateListeners() {
        window.addEventListener('resize', () => {
            this.hasMouse = (matchMedia('(pointer:fine)').matches);
            this.hasTouch = ('ontouchstart' in window || 'onmsgesturechange' in window);
            this.isDesktop = (window.innerWidth >= Breakpoints.md);
            this.isLandscape = (window.innerWidth >= window.innerHeight);

            this.$emit('app.resize');
        });
        window.dispatchEvent(new Event('resize'));

        document.addEventListener('click', (event: TouchEvent | MouseEvent) => {
            this.$emit('app.click', event);

            // Add tracking for footer links
            if ((event.target as HTMLElement).dataset.trackfooter === '') {
                tracking.track('trackFooter', 'Footer', (event.target as HTMLElement)?.textContent?.trim(), window.location.href);
            } else if ((event.target as HTMLElement)?.closest('a')?.dataset?.trackfooter === '') {
                const element = (event.target as HTMLElement)?.closest('a');
                tracking.track('trackFooter', 'Footer', element?.textContent?.trim(), window.location.href);
            }

            let propertyType = (document.querySelector('[data-propertytype]') as HTMLTableElement)?.dataset.propertytype?.trim();
            if (!propertyType || propertyType === '') {
                propertyType = 'Ukendt type';
            }
            // Add tracking for Nordea links on the property page
            if ((event.target as HTMLAnchorElement).dataset.tracknordealink === '') {
                const textContent = (event.target as HTMLAnchorElement)?.textContent?.trim() ?? '';

                tracking.trackRawEvent({
                    event: 'trackBoligpraesentation',
                    eventData: {
                        category: 'Boligpraesentation',
                        action: 'Nordealinks',
                        label: textContent,
                        type: propertyType,
                    },
                });
            } else if ((event.target as HTMLAnchorElement)?.closest('a')?.dataset?.tracknordealink === '') {
                const element = (event.target as HTMLAnchorElement)?.closest('a');
                const textContent = element?.textContent?.trim() ?? '';

                tracking.trackRawEvent({
                    event: 'trackBoligpraesentation',
                    eventData: {
                        category: 'Boligpraesentation',
                        action: 'Nordealinks',
                        label: textContent,
                        type: propertyType,
                    },
                });
            }

            // Add tracking for links on the Small Campaign module
            if ((event.target as HTMLAnchorElement).dataset.trackSmallCampaign === '') {
                const el = (event.target as HTMLAnchorElement);
                const cat = el.dataset.gaCategory?.toString() ?? '';
                const action = el.dataset.gaAction?.toString() ?? '';
                const label = el.dataset.gaLabel?.toString() ?? '';
                tracking.track('trackCta', cat, action, label);
            }

            // Add tracking for USP Band on new Frontpage Hero
            if ((event.target as HTMLAnchorElement).dataset.trackUspband === '') {
                const el = (event.target as HTMLAnchorElement);
                const category = el.dataset.gaCategory?.toString() ?? '';
                const action = el.dataset.gaAction?.toString() ?? '';
                const label = el.dataset.gaLabel?.toString() ?? '';

                if (category !== '') {
                    tracking.trackRawEvent({
                        event: 'trackCta',
                        eventData: {
                            category,
                            action,
                            label,
                        },
                    });
                }
            }
        });

        document.addEventListener('keyup', (event: KeyboardEvent) => {
            if (event.code && event.code !== '') {
                this.$emit('app.keyup', event.code);
            } else if (event.key && event.key !== '') {
                this.$emit('app.keyup', event.key);
            }
        });

        document.addEventListener('focusout', (event: FocusEvent) => {
            this.$emit('app.focusout');

            if (event.target instanceof HTMLInputElement) {
                this.$emit('app.focusout.input', event);
            }
        });

        window.addEventListener('scroll', () => {
            this.$emit('app.scroll');
        });

        window.addEventListener('hashchange', () => {
            this.$emit('app.hashchange');
        });
    },
};
EventBus.initiateListeners();
