
import { PropType } from 'vue';

export interface Data {
    isLoading: boolean;
}

export default {
    components: {},

    props: {
        activeStep: {
            type: Number as PropType<number>,
            default: () => 1,
        },
        prevButtonText: {
            type: String as PropType<string>,
            default: () => '',
        },
        prevButtonAltText: {
            type: String as PropType<string>,
            default: () => '',
        },
        nextButtonText: {
            type: String as PropType<string>,
            default: () => '',
        },
        nextButtonAltText: {
            type: String as PropType<string>,
            default: () => '',
        },
        nextButtonDisabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            isLoading: false,
        };
    },

    methods: {
        flowNavigation(event: any, dir: string): void {
            if (this.$props.activeStep < 7 || dir === 'prev') {
                event.preventDefault();
                this.$emit('flowNavigation', dir);
            }

            if (this.$props.activeStep === 7 && dir === 'next') {
                this.$emit('submitForm');
                this.isLoading = true;
            }
        },
    },
};
