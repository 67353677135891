import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "o-homeEstimateParamaterExplainer" }
const _hoisted_2 = { class: "o-homeEstimateParamaterExplainer__content" }
const _hoisted_3 = { class: "o-homeEstimateParamaterExplainer__content--text" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = {
  key: 0,
  textContent: ' '
}
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "o-panel" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portal = _resolveComponent("portal")!
  const _component_o_speech_bubble = _resolveComponent("o-speech-bubble")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_o_speech_bubble, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("span", {
              textContent: _toDisplayString($props.texts.explainerText)
            }, null, 8 /* PROPS */, _hoisted_4),
            ($props.texts.infoOpenText)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "o-homeEstimateParamaterExplainer__content--infoToggler",
                  "aria-label": $props.texts.ariaLabel,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClickEstimateTeaserInfo && $options.onClickEstimateTeaserInfo(...args)))
                }, [
                  _withDirectives(_createElementVNode("br", null, null, 512 /* NEED_PATCH */), [
                    [_vShow, $props.brokerLinkTextLineBreak]
                  ]),
                  (!$props.brokerLinkTextLineBreak)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("span", {
                    textContent: _toDisplayString($props.texts.infoOpenText)
                  }, null, 8 /* PROPS */, _hoisted_7)
                ], 8 /* PROPS */, _hoisted_5))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        ($options.showEstimateTeaserInfo)
          ? (_openBlock(), _createBlock(_component_portal, {
              key: 0,
              to: "modal"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h2", {
                    class: "a-heading2 o-panel__title o-panel__heading o-homeEstimateParamaterExplainer__infoHeadline",
                    textContent: _toDisplayString($props.texts.infoHeadline)
                  }, null, 8 /* PROPS */, _hoisted_9),
                  _createElementVNode("div", {
                    class: "o-content",
                    innerHTML: $props.texts.infoDescription
                  }, null, 8 /* PROPS */, _hoisted_10)
                ])
              ], undefined, true),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ], undefined, true),
      _: 1 /* STABLE */
    }, 512 /* NEED_PATCH */), [
      [_vShow, $props.showSpeechBubble]
    ])
  ]))
}