
import { PropType } from 'vue';
import tracking from '@/functions/tracking';

export default {
    props: {
        action: {
            type: String as PropType<string>,
            required: true,
            default: () => '',
        },
        method: {
            type: String as PropType<string>,
            default: () => 'post',
        },
        formSection: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    methods: {
        onSubmit(): void {
            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Nyhedsbrev indgang',
                    label: 'Nyhedsbrev',
                    formularIndgang: this.$props.formSection ?? 'Ukendt',
                },
            });
            sessionStorage.setItem('nyhedsbrevFormularIndgang', this.$props.formSection ?? 'Ukendt');
        },
    },
};
