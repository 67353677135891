import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "m-statusIndicator" }
const _hoisted_2 = ["aria-label", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.steps, (n) => {
      return (_openBlock(), _createElementBlock("button", {
        key: n,
        class: _normalizeClass(["m-statusIndicator__item", { 'is-active': n === $data.currentIndex }]),
        "aria-label": `Step ${n}`,
        onClick: ($event: any) => ($options.onClick(n))
      }, null, 10 /* CLASS, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}