import { CancelTokenSource } from 'axios';
import State from './interface';

export default {
    /**
     * Get state of addressId.
     *
     * @param {State} state
     * @return {string | null}
     */
    addressId(state: State): string | null {
        return state.property.addressId;
    },

    /**
     * Get state of address.
     *
     * @param {State} state
     * @return {string | null}
     */
    address(state: State): string | null {
        return state.property.address;
    },

    /**
     * Get state of zipcode.
     *
     * @param {State} state
     * @return {number | null}
     */
    zipCode(state: State): number | null {
        return state.property.zipCode;
    },

    /**
     * Get state of city.
     *
     * @param {State} state
     * @return {string | null}
     */
    city(state: State): string | null {
        return state.property.city;
    },

    /**
     * Get state of brokerDescription.
     *
     * @param {State} state
     * @return {string}
     */
    brokerDescription(state: State): string {
        return state.broker.description;
    },

    /**
     * Get state of brokerImage
     *
     * @param {State} state
     * @return {string}
     */
    brokerImage(state: State): string {
        return state.broker.image;
    },

    /**
     * Get state of buyers buttonText.
     *
     * @param {State} state
     * @return {string}
     */
    buyersButtonText(state: State): string {
        return state.buyers.buttonText;
    },

    /**
     * Get state of buyers count.
     *
     * @param {State} state
     * @return {number}
     */
    buyersCount(state: State): number {
        return state.buyers.count;
    },

    /**
     * Get state of buyers description.
     *
     * @param {State} state
     * @return {string}
     */
    buyersDescription(state: State): string {
        return state.buyers.description;
    },

    /**
     * Get state of buyers heading.
     *
     * @param {State} state
     * @return {string}
     */
    buyersHeading(state: State): string {
        return state.buyers.heading;
    },

    /**
     * Get state of buyers screenReaderText.
     *
     * @param {State} state
     * @return {string}
     */
    buyersScreenReaderText(state: State): string {
        return state.buyers.screenReaderText;
    },

    /**
     * Get state of cancelToken.
     *
     * @param {State} state
     * @return {string}
     */
    cancelToken(state: State): CancelTokenSource | undefined {
        return state.cancelToken;
    },

    /**
     * Get the current panel.
     *
     * @param {State} state
     * @return {string}
     */
    currentPanel(state: State): string {
        return state.overlay.currentPanel;
    },

    /**
     * Get the current sub panel.
     *
     * @param {State} state
     * @return {number}
     */
    currentSubPanel(state: State): number {
        return state.overlay.currentSubPanel;
    },

    /**
     * Get state of page path.
     *
     * @param {State} state
     * @return {number}
     */
    pagePath(state: State): string {
        return state.pagePath;
    },

    /**
     * Get state of parameters.
     *
     * @param {State} state
     * @return {number}
     */
    parameters(state: State): { [key: string]: number } {
        return {
            amenityValue: state.parameters.amenityValue,
            energyLevel: state.parameters.energyLevel,
            exteriorCondition: state.parameters.exteriorCondition,
            interiorCondition: state.parameters.interiorCondition,
            location: state.parameters.location,
        };
    },

    /**
     * Get state of userEstimate.
     *
     * @param {State} state
     * @return {number}
     */
    userEstimate(state: State): number {
        return state.estimates.user;
    },
};
