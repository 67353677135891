import State from './interface';

export default <State>{
    broker: {
        description: '',
        image: '',
    },
    buyers: {
        buttonText: '',
        count: 0,
        description: '',
        heading: '',
        screenReaderText: '',
    },
    cancelToken: undefined,
    estimates: {
        bank: 0,
        user: 0,
    },
    overlay: {
        currentPanel: '',
        currentSubPanel: 1,
    },
    pagePath: '',
    parameters: {
        amenityValue: 5,
        energyLevel: 5,
        exteriorCondition: 5,
        interiorCondition: 5,
        location: 5,
    },
    property: {
        addressId: '',
        buildYear: 0,
        address: '',
        zipCode: 0,
        city: '',
    },
};
