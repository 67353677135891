import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "m-input__wrapper" }
const _hoisted_2 = ["for", "textContent"]
const _hoisted_3 = ["id", "name", "placeholder", "value"]
const _hoisted_4 = ["id", "name", "placeholder", "type", "value"]
const _hoisted_5 = ["aria-label", "title"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _directive_money = _resolveDirective("money")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-input", [
            $options.modifierClass,
            {
                hasError: $props.error,
                hasIcon: $options.hasIcon,
                hasLabel: $options.hasLabel,
                isDisabled: $options.isDisabled,
                isEmpty: $options.isEmpty,
                isFocused: $data.isFocused,
                'autocomplete-container': $props.type === 'dawa',
                search: $props.type === 'search',
            },
        ]]),
    onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "a-label m-input__label",
            for: $data.uuid,
            textContent: _toDisplayString($props.label)
          }, null, 8 /* PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      ($props.type === 'price')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            id: $data.uuid,
            ref: "input",
            class: "a-input m-input__field",
            name: $props.name,
            placeholder: $props.placeholder,
            type: "text",
            value: $props.value,
            onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onFocus && $options.onFocus(...args))),
            onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args))),
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onInput && $options.onInput(...args)))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3)), [
            [_directive_money, {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: ' kr.',
                    precision: 0,
                }]
          ])
        : (_openBlock(), _createElementBlock("input", {
            key: 2,
            id: $data.uuid,
            ref: "input",
            class: "a-input m-input__field",
            name: $props.name,
            placeholder: $props.placeholder,
            type: $options.getType,
            value: $props.value,
            onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onFocus && $options.onFocus(...args))),
            onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args))),
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onInput && $options.onInput(...args)))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4))
    ]),
    ($options.hasIcon)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "submit",
          class: "m-input__button",
          "aria-label": $props.buttonText,
          title: $props.buttonText
        }, [
          _createVNode(_component_a_icon, {
            name: $props.icon,
            class: "m-input__icon"
          }, null, 8 /* PROPS */, ["name"]),
          ($options.hasIconText)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "m-input__buttonText",
                textContent: _toDisplayString($props.iconText)
              }, null, 8 /* PROPS */, _hoisted_6))
            : _createCommentVNode("v-if", true)
        ], 8 /* PROPS */, _hoisted_5))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($props.error)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              class: _normalizeClass(["a-error m-input__error", { 'a-error--bright': $props.useBrightError }]),
              textContent: _toDisplayString($props.error)
            }, null, 10 /* CLASS, PROPS */, _hoisted_7))
          : _createCommentVNode("v-if", true)
      ], undefined, true),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}