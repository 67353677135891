import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-orderAssessment u-container" }
const _hoisted_2 = { class: "u-row" }
const _hoisted_3 = { class: "o-orderAssessment__wrapper u-col-12 lg:u-col-6 lg:u-offset-3" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactBrokerModalWithAddress = _resolveComponent("ContactBrokerModalWithAddress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "o-orderAssessment__headline a-heading2",
          textContent: _toDisplayString($props.data.headline)
        }, null, 8 /* PROPS */, _hoisted_4),
        _createElementVNode("div", {
          class: "a-lead o-orderAssessment__text",
          textContent: _toDisplayString($props.data.leadingParagraph)
        }, null, 8 /* PROPS */, _hoisted_5),
        _createElementVNode("button", {
          class: "m-button m-button--outlined o-orderAssessment__ctaButton",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openContactBrokerCurtain()))
        }, [
          _createElementVNode("span", {
            class: "m-button__text",
            textContent: _toDisplayString($props.data.ctaText)
          }, null, 8 /* PROPS */, _hoisted_6)
        ])
      ])
    ]),
    _createVNode(_component_ContactBrokerModalWithAddress, { "curtain-data": $props.data }, null, 8 /* PROPS */, ["curtain-data"])
  ]))
}