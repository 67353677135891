import State from './interface';

export default <State>{
    fixed: false,
    show: false,
    theme: '',
    type: '',
    trackingEntrance: null,
    trackingLabel: null,
};
