import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "o-findBuyerResult" }
const _hoisted_2 = { class: "u-row" }
const _hoisted_3 = { class: "u-col-12 lg:u-col-6 lg:u-offset-3" }
const _hoisted_4 = { class: "o-findBuyerResult__images" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "u-col-12 lg:u-col-8 lg:u-offset-2" }
const _hoisted_7 = { class: "u-row" }
const _hoisted_8 = { class: "u-col-5" }
const _hoisted_9 = { class: "o-content o-findBuyerResult__heroText" }
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["textContent"]
const _hoisted_15 = { class: "o-findBuyerResult__image-below" }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_modal_opener = _resolveComponent("m-modal-opener")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" HERO "),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (n, i) => {
            return _createElementVNode("img", {
              key: i,
              src: `${$props.images[i]}?width=310&height=310`,
              class: "o-findBuyerResult__image",
              alt: "",
              role: "presentation"
            }, null, 8 /* PROPS */, _hoisted_5)
          }), 64 /* STABLE_FRAGMENT */))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            ($props.texts.buyersCount !== '0')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "o-findBuyerResult__count",
                  textContent: _toDisplayString($props.texts.buyersCount)
                }, null, 8 /* PROPS */, _hoisted_10))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", {
              class: "a-heading2 o-findBuyerResult__title",
              textContent: _toDisplayString($props.texts.heroHeadline)
            }, null, 8 /* PROPS */, _hoisted_11),
            _createElementVNode("p", {
              textContent: _toDisplayString($props.texts.heroText)
            }, null, 8 /* PROPS */, _hoisted_12)
          ]),
          _createVNode(_component_m_modal_opener, {
            "btn-label": `${$props.texts.ctaText}`,
            modal: `broker`,
            "tracking-data": {
                            name: `trackForm`,
                            category: `Formular`,
                            action: `Find koeber kontakt initieret`,
                            label: `Kontakt maegler`,
                            formularIndgang: `CTA modul`,
                            formularSektion: `Kontakt maegler`,
                            formularStepnavn: `Brugerinformation`,
                            maeglerId: `${$props.brokerEmployee.maeglerId}`,
                        }
          }, {
            default: _withCtx((modalOpener) => [
              _createElementVNode("button", {
                type: "button",
                class: "m-button",
                onClick: modalOpener.onClick
              }, [
                _createElementVNode("span", {
                  textContent: _toDisplayString(modalOpener.btnLabel)
                }, null, 8 /* PROPS */, _hoisted_14)
              ], 8 /* PROPS */, _hoisted_13)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["btn-label", "tracking-data"]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("img", {
              src: `${$props.images[7]}?width=310&height=310`,
              class: "o-findBuyerResult__image--last",
              alt: "",
              role: "presentation"
            }, null, 8 /* PROPS */, _hoisted_16),
            _createElementVNode("img", {
              src: `${$props.images[3]}?width=310&height=310`,
              class: "o-findBuyerResult__image--last",
              alt: "",
              role: "presentation"
            }, null, 8 /* PROPS */, _hoisted_17)
          ])
        ])
      ])
    ])
  ]))
}