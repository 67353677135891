import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "o-contactBrokerModal" }
const _hoisted_2 = {
  key: "form",
  class: "o-contactBrokerModal__formContent o-panel"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = {
  key: 0,
  class: "o-contactBrokerModal__wrapper"
}
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "o-contactBrokerModal__form" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["aria-label", "disabled"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: "success",
  class: "o-contactBrokerModal__formSuccessWrapper"
}
const _hoisted_11 = { class: "o-contactBrokerModal__formSuccess" }
const _hoisted_12 = { class: "o-contactBrokerModal__icon" }
const _hoisted_13 = ["textContent"]
const _hoisted_14 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_o_employee_card = _resolveComponent("o-employee-card")!
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_portal = _resolveComponent("portal")!

  return ($options.showModal)
    ? (_openBlock(), _createBlock(_component_portal, {
        key: 0,
        to: "modal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", {
                class: "a-heading2 o-panel__title o-panel__heading o-contactBrokerModal__headline",
                textContent: _toDisplayString($props.curtainData.formHeadline)
              }, null, 8 /* PROPS */, _hoisted_3),
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (!$data.isSignupSuccessful)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", {
                          class: "a-base o-contactBrokerModal__text",
                          textContent: _toDisplayString($props.curtainData.formText)
                        }, null, 8 /* PROPS */, _hoisted_5),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_m_input, {
                            value: $data.address,
                            class: "m-input__field o-homeEstimateLandingHero__contentInput",
                            name: "address‌_search",
                            error: 
                                    $data.propertyEstimateError
                                        ? 'Det er ikke muligt at foretage boligskøn for denne boligtype'
                                        : ''
                                ,
                            label: `${$props.curtainData.formSettings.addressPlaceholder}*`,
                            theme: "",
                            type: "dawa",
                            onDawa: $options.enableSubmit,
                            onDawaReset: $options.disableSubmit,
                            onInput: $options.setAddressInfo,
                            onFocus: _cache[0] || (_cache[0] = ($event: any) => ($options.setIsFocused(true))),
                            onBlur: _cache[1] || (_cache[1] = ($event: any) => ($options.setIsFocused(false)))
                          }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
                          _createVNode(_component_m_input, {
                            modelValue: $data.form.name,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.name) = $event)),
                            class: "o-contactBrokerModal__formInput",
                            label: `${$props.curtainData.formSettings.namePlaceholder}*`,
                            type: "text",
                            name: "Name",
                            error: $data.errors.name,
                            required: ""
                          }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                          _createVNode(_component_m_input, {
                            modelValue: $data.form.email,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.email) = $event)),
                            class: "o-contactBrokerModal__formInput",
                            label: `${$props.curtainData.formSettings.mailPlaceholder}*`,
                            type: "email",
                            name: "Email",
                            error: $data.errors.mail,
                            required: ""
                          }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                          _createVNode(_component_m_input, {
                            modelValue: $data.form.phone,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.phone) = $event)),
                            class: "o-contactBrokerModal__formInput",
                            label: `${$props.curtainData.formSettings.phonePlaceholder}*`,
                            type: "text",
                            name: "Phone",
                            error: $data.errors.phone,
                            required: ""
                          }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                          _createVNode(_component_m_textarea, {
                            modelValue: $data.form.message,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.form.message) = $event)),
                            class: "o-contactBrokerModal__textareaInput",
                            label: `${$props.curtainData.formSettings.msgPlaceholder}`,
                            error: $data.errors.message,
                            name: "Message"
                          }, null, 8 /* PROPS */, ["modelValue", "label", "error"])
                        ]),
                        ($props.curtainData.consent)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "a-small a-small--allowBreak o-fields__field",
                              innerHTML: $props.curtainData.consent.purposeText
                            }, null, 8 /* PROPS */, _hoisted_7))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("button", {
                          type: "submit",
                          class: _normalizeClass(["m-button o-contactBrokerModal__formSubmitButton", { isLoading: $data.isLoading }]),
                          "aria-label": $props.curtainData.formCtaAltText,
                          disabled: !$data.addressFound || !$data.address,
                          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.checkIfPropertyHasEstimate && $options.checkIfPropertyHasEstimate(...args)))
                        }, [
                          _createElementVNode("span", {
                            textContent: _toDisplayString($props.curtainData.formCtaText)
                          }, null, 8 /* PROPS */, _hoisted_9)
                        ], 10 /* CLASS, PROPS */, _hoisted_8)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        ($data.responsibleBroker)
                          ? (_openBlock(), _createBlock(_component_o_employee_card, {
                              key: 0,
                              class: "o-contactBrokerModal__employeeCard",
                              employee: $data.responsibleBroker,
                              panel: true
                            }, null, 8 /* PROPS */, ["employee"]))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_a_icon, { name: "check" })
                          ]),
                          _createElementVNode("div", {
                            class: "a-heading3 o-contactBrokerModal__formSuccessHeadline",
                            textContent: _toDisplayString($props.curtainData.formReceiptHeadline)
                          }, null, 8 /* PROPS */, _hoisted_13),
                          _createElementVNode("div", {
                            class: "a-base o-contactBrokerModal__formSuccessText",
                            innerHTML: $options.receiptText
                          }, null, 8 /* PROPS */, _hoisted_14)
                        ])
                      ]))
                ], undefined, true),
                _: 1 /* STABLE */
              })
            ])
          ])
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}