
import { PropType } from 'vue';
import SpeechBubble from '@/interfaces/speechBubble.interface';

export default {
    props: {
        speechBubble: {
            type: Object as PropType<SpeechBubble>,
            default: () => ({
                theme: 'default',
            }),
        },
    },
};
