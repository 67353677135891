import actions from './projectSale/actions';
import getters from './projectSale/getters';
import mutations from './projectSale/mutations';
import state from './projectSale/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
