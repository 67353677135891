
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import tracking from '@/functions/tracking';
import FormSettings from '@/interfaces/formSettings.interface';
import ContactBrokerModalWithAddress from '../contactBrokerModal/ContactBrokerModalWithAddress.vue';

type OrderAssessmentData = {
    headline: string;
    leadingParagraph: string;
    ctaText: string;
    ctaAltText: string;
    formHeadline: string;
    formText: string;
    formLeadText: string;
    formCtaText: string;
    formCtaAltText: string;
    formReceiptHeadline: string;
    formReceiptText: string;
    consent: {
        purposeText: string;
        id: string;
        heading: string;
        leadType: {
            displayName: string;
        };
    };
    formSettings: FormSettings;
};

export default {
    components: {
        ContactBrokerModalWithAddress,
    },
    props: {
        data: {
            type: Object as PropType<OrderAssessmentData>,
            default: () => ({
                headline: '',
                leadingParagraph: '',
                ctaText: '',
                ctaAltText: '',
                formHeadline: '',
                formText: '',
                formLeadText: '',
                formCtaText: '',
                formCtaAltText: '',
                formReceiptHeadline: '',
                formReceiptText: '',
                consent: {
                    purposeText: '',
                    id: '',
                    heading: '',
                    leadType: {
                        displayName: '',
                    },
                },
                formSettings: {
                    msgPlaceholder: '',
                    msgErrorMsg: '',
                    namePlaceholder: '',
                    nameErrorMsg: '',
                    addressPlaceholder: '',
                    addressErrorMsg: '',
                    mailPlaceholder: '',
                    mailErrorMsg: '',
                    phonePlaceholder: '',
                    phoneErrorMsg: '',
                    offerPlaceholder: '',
                    offerErrorMsg: '',
                    linkText: '',
                    link: null,
                    submitButtonText: '',
                    brokerErrorMsg: '',
                    heading: '',
                    bodytext: '',
                    commentPlaceholder: '',
                    commentErrorMsg: '',
                    datePlaceholder: '',
                    dateErrorMsg: '',
                    municipalityPlaceholder: '',
                    municipalityErrorMsg: '',
                    desiredAddressPlaceholder: '',
                    consentErrorMsg: '',
                },
            }),
        },
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),
        openContactBrokerCurtain(): void {
            this.openModal('brokerContactWithAddress');
            this.setTrackingEntrance('Boligskoen landing page');
            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Salgsvurdering indgang',
                    label: 'Salgsvurdering',
                    formularIndgang: 'Boligskoen landing page',
                    formularStepnavn: 'Klik paa modul',
                },
            });
        },
    },
};
