import State from './interface';

export default {
    /**
     * Set type value.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setFixed(state: State, payload: boolean): void {
        state.fixed = payload;
    },

    /**
     * Set show value.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setShow(state: State, payload: boolean): void {
        state.show = payload;
    },

    /**
     * Set theme value.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setTheme(state: State, payload: string): void {
        state.theme = payload;
    },

    /**
     * Set type value.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setType(state: State, payload: string): void {
        state.type = payload;
    },

    /**
     * Set type value.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setTrackingEntrance(state: State, payload: string | null): void {
        state.trackingEntrance = payload;
    },

    /**
     * Set type value.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setTrackingLabel(state: State, payload: string | null): void {
        state.trackingLabel = payload;
    },
};
