import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "o-snapSwiper" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 1,
  ref: "snapSwiper",
  class: "o-snapSwiper__container u-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.header)
      ? (_openBlock(), _createElementBlock("h1", {
          key: 0,
          class: "o-snapSwiper__header a-heading1 u-container",
          textContent: _toDisplayString($props.header)
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($props.cardType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (product, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `swiper-card-${index.toString()}`,
              class: "o-snapSwiper__item"
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($props.cardType), { product: product }, null, 8 /* PROPS */, ["product"]))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ], 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ]))
}