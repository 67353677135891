
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';

interface ChartItem {
    name: string;
    id: number;
    votes: number;
}

interface Data {
    isDesktop: boolean;
    dataset: number[];
    neighborhoodData: ChartItem[];
}

export default {
    name: 'm-horizontal-chart',
    props: {
        primaryDataset: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        labels: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        secondary: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        suffix: {
            type: String as PropType<string>,
            default: () => '',
        },
        votedFor: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        neighborhoodDataset: {
            type: Array as PropType<ChartItem[]>,
            default: () => [],
        },
    },

    data(): Data {
        return {
            isDesktop: EventBus.isDesktop,
            dataset: this.primaryDataset,
            neighborhoodData: this.neighborhoodDataset,
        };
    },

    computed: {
        maxValue(): number {
            return Math.max(...this.dataset);
        },
        neighborhoodDatasetMaxValue(): number {
            let itemWithMaxNumber = this.neighborhoodData.reduce((max, x) => {
                return x.votes > max.votes ? x : max;
            });
            return itemWithMaxNumber.votes;
        },
    },

    mounted() {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });

        if (this.votedFor.length) {
            this.showUpVote();
        }
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    methods: {
        showUpVote(): void {
            this.votedFor.forEach((vote: number) => {
                document
                    .getElementById(`label-${vote}`)!
                    .classList.add('isActive');
            });
        },
    },
};
