import State from './interface';

export default <State>{
    activeBroker: null,
    initialLoad: true,
    isLoading: false,
    results: [],
    totalCount: 0,
    query: '',
    view: '',
};
