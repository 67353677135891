import State from './interface';

export default {
    /**
     * Return referrer
     *
     * @param {State} state
     * @return {string}
     */
    referrer(state: State): string {
        return state.document.referrer ?? '';
    },
    utmCampaign(state: State): string {
        return state.document.utmCampaign ?? '';
    },
    utmContent(state: State): string {
        return state.document.utmContent ?? '';
    },
    utmMedium(state: State): string {
        return state.document.utmMedium ?? '';
    },
    utmSource(state: State): string {
        return state.document.utmSource ?? '';
    },
    timestamp(state: State): Date | undefined {
        return state.document.timestamp ?? undefined;
    },
};
