import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "o-stickyButton__menuIcon" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 0,
  class: "o-stickyButton__image"
}
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["m-button m-button--custom o-stickyButton", { noImage: !$options.hasImage }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClick()))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_icon, { name: "more_vert" })
    ]),
    _createElementVNode("span", {
      class: "a-label o-stickyButton__text",
      textContent: _toDisplayString($props.body)
    }, null, 8 /* PROPS */, _hoisted_2),
    ($options.hasImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: $options.formattedUrl,
            alt: $props.alt,
            class: "a-image",
            loading: "lazy"
          }, null, 8 /* PROPS */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}