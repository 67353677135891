export interface Param {
    key: string;
    value: string | string[];
}

export default {
    /**
     * Get list of all parameters.
     *
     * @return {Param[]}
     */
    getAll(): Param[] {
        const searchParams = new URL(window.location.href).searchParams;
        let params: Param[] = [];

        searchParams.forEach((value: string, key: string) => {
            params.push({
                key,
                value:
                    value.includes('[') && value.includes(']')
                        ? value.replace('[', '').replace(']', '').split(',')
                        : value,
            });
        });

        return params;
    },

    /**
     * Append parameters in url.
     *
     * @param {string} key
     * @param {number[] | string[]} value
     * @return {void}
     */
    append(key: string, value: number | string): void {
        const searchParams = new URL(window.location.href).searchParams;

        if (!searchParams.toString().includes(`${key}=${value}`)) {
            if (value) {
                searchParams.append(
                    key,
                    typeof value === 'number' ? value.toString() : value,
                );
            }
        }

        window.history.replaceState(null, '', `?${searchParams.toString()}`);
    },

    /**
     * Remove parameter in url.
     *
     * @param {string} key
     * @return {void}
     */
    remove(key: string): void {
        const searchParams = new URL(window.location.href).searchParams;

        searchParams.delete(key);

        window.history.replaceState(null, '', `?${searchParams.toString()}`);
    },

    /**
     * Remove all dynamic query params.
     *
     * @return {void}
     */
    removeAll(keys: string[]): void {
        const searchParams = new URL(window.location.href).searchParams;

        let params: string[] = [];
        searchParams.forEach((value: string, key: string) => {
            if (!keys.includes(key)) {
                params.push(key);
            }
        });

        params.forEach((value: string) => {
            searchParams.delete(value);
        });

        window.history.replaceState(null, '', `?${searchParams.toString()}`);
    },

    /**
     * Update parameter in url.
     *
     * @param {string} key
     * @param {number | number[] | string} value
     * @return {void}
     */
    update(key: string, value: number | number[] | string): void {
        const searchParams = new URL(window.location.href).searchParams;

        if (typeof value === 'object') {
            let arrayValue = '[';
            value.forEach((item: any, index: number) => {
                arrayValue += item;

                if (index + 1 < value.length) {
                    arrayValue += ',';
                }
            });
            arrayValue += ']';

            searchParams.set(key, arrayValue);
        } else {
            if (value) {
                searchParams.set(
                    key,
                    typeof value === 'number' ? value.toString() : value,
                );
            } else {
                searchParams.delete(key);
            }
        }

        window.history.replaceState(null, '', `?${searchParams.toString()}`);
    },
};
