import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "o-modal__box" }
const _hoisted_2 = { class: "o-modal__buttons" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "o-modal__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_portal_target = _resolveComponent("portal-target")!

  return (_openBlock(), _createBlock(_Transition, { name: "o-modal" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["o-modal", _ctx.theme])
          }, [
            _createElementVNode("div", {
              class: "o-modal__background",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  type: "button",
                  class: "o-modal__close",
                  "aria-label": $props.closeLabel,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, [
                  _createVNode(_component_a_icon, { name: "close" })
                ], 8 /* PROPS */, _hoisted_3)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_portal_target, {
                  name: "modal",
                  multiple: ""
                })
              ])
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], undefined, true),
    _: 1 /* STABLE */
  }))
}