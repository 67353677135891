import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: "sendMessage",
  class: "o-panel"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: "sendMessage1",
  class: "o-subPanel"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { class: "o-fields" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = {
  key: "successMessage",
  class: "o-panel"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { class: "a-confirmationNotice" }
const _hoisted_11 = { class: "a-confirmationNotice__checkmark" }
const _hoisted_12 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_oEmployeeCard = _resolveComponent("oEmployeeCard")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_portal = _resolveComponent("portal")!

  return ($options.isVisible)
    ? (_openBlock(), _createBlock(_component_portal, {
        key: 0,
        to: "modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: `o-panel--${$data.currentDirection}`
          }, {
            default: _withCtx(() => [
              (!_ctx.currentPanel)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("h2", {
                      class: "a-heading3 o-panel__title",
                      textContent: _toDisplayString($props.texts.sendMessageHeadingText)
                    }, null, 8 /* PROPS */, _hoisted_2),
                    _createVNode(_component_oEmployeeCard, {
                      panel: true,
                      employee: $props.employee,
                      class: "o-panel__brokerCard"
                    }, null, 8 /* PROPS */, ["employee"]),
                    (_ctx.currentSubPanel === 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("p", {
                            class: "a-paragraph o-subPanel__description",
                            textContent: _toDisplayString($props.texts.sendMessageDescriptionText)
                          }, null, 8 /* PROPS */, _hoisted_4),
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_m_textarea, {
                              modelValue: $data.form.message,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.message) = $event)),
                              class: "o-fields__field",
                              error: $data.errors.message,
                              label: `${$props.texts.form.message}*`
                            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                            _createVNode(_component_m_input, {
                              modelValue: $data.form.name,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.name) = $event)),
                              class: "o-fields__field",
                              error: $data.errors.name,
                              label: `${$props.texts.form.name}*`
                            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                            _createVNode(_component_m_input, {
                              modelValue: $data.form.mail,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.mail) = $event)),
                              class: "o-fields__field",
                              error: $data.errors.mail,
                              label: `${$props.texts.form.mail}*`
                            }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                            _createVNode(_component_m_input, {
                              modelValue: $data.form.phone,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.phone) = $event)),
                              class: "o-fields__field",
                              error: $data.errors.phone,
                              label: `${$props.texts.form.phone}*`
                            }, null, 8 /* PROPS */, ["modelValue", "error", "label"])
                          ]),
                          ($props.consents && $props.consents.contactConsent)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "a-small a-small--allowBreak o-fields__field",
                                innerHTML: $props.consents.contactConsent.purposeText
                              }, null, 8 /* PROPS */, _hoisted_6))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("div", {
                            class: _normalizeClass(["o-buttons o-subPanel__actions", { 'o-subPanel__actions--fixed': _ctx.fixedPanel }])
                          }, [
                            _createElementVNode("button", {
                              type: "submit",
                              class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                              onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.onSubmit('sendMessage')))
                            }, [
                              _createElementVNode("span", {
                                class: "m-button__text",
                                textContent: _toDisplayString($props.texts.sendMessageSubmitButtonText)
                              }, null, 8 /* PROPS */, _hoisted_7)
                            ], 2 /* CLASS */)
                          ], 2 /* CLASS */)
                        ]))
                      : _createCommentVNode("v-if", true)
                  ]))
                : (_ctx.currentPanel === 'successMessage')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("h2", {
                        class: "a-heading3 o-panel__title",
                        textContent: _toDisplayString($props.texts.formReceiptSuccessHeadingText)
                      }, null, 8 /* PROPS */, _hoisted_9),
                      _createVNode(_component_oEmployeeCard, {
                        panel: true,
                        employee: $props.employee,
                        class: "o-panel__brokerCard"
                      }, null, 8 /* PROPS */, ["employee"]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_a_icon, { name: "check" })
                        ]),
                        _createElementVNode("span", {
                          class: "a-label",
                          textContent: _toDisplayString(
                            $options.formattedSuccessMessage(
                                $props.texts.formReceiptSuccessMessageText,
                            )
                        )
                        }, null, 8 /* PROPS */, _hoisted_12)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["name"])
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}