
import { PropType } from 'vue';
import { VMoney } from 'v-money';
import * as dawaAutocomplete2 from 'dawa-autocomplete2';
import { v4 as uuidv4 } from 'uuid';

interface Data {
    isFocused: boolean;
    uuid: string;
}

export default {
    name: 'm-input',
    directives: {
        money: VMoney,
    },

    props: {
        buttonText: {
            type: String as PropType<string>,
            default: () => '',
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        error: {
            type: String as PropType<string>,
            default: () => '',
        },
        icon: {
            type: String as PropType<string>,
            default: () => undefined,
        },
        iconText: {
            type: String as PropType<string>,
            default: () => undefined,
        },
        label: {
            type: String as PropType<string>,
            default: () => '',
        },
        name: {
            type: String as PropType<string>,
            default: () => '',
        },
        placeholder: {
            type: String as PropType<string>,
            default: () => '',
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        type: {
            type: String as PropType<string>,
            default: () => 'text',
        },
        value: {
            type: String as PropType<string>,
            required: true,
            default: () => '',
        },
        searchUrl: {
            type: String as PropType<string>,
            default: () => '',
        },
        useBrightError: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            isFocused: false,
            uuid: '',
        };
    },

    computed: {
        /**
         * Get type of input field.
         *
         * @return {string}
         */
        getType(): string {
            if (
                this.type === 'price' ||
                this.type === 'search' ||
                this.type === 'zip'
            ) {
                return 'text';
            }

            return this.type;
        },

        /**
         * Check if the component has an icon.
         *
         * @return {boolean}
         */
        hasIcon(): boolean {
            return this.icon !== undefined;
        },

        /**
         * Check if the component has an icon text.
         *
         * @return {boolean}
         */
        hasIconText(): boolean {
            return this.icon !== undefined;
        },

        /**
         * Check if the component has a label.
         *
         * @return {boolean}
         */
        hasLabel(): boolean {
            return this.label !== '';
        },

        /**
         * Check if the component is disabled.
         *
         * @return {boolean}
         */
        isDisabled(): boolean {
            return this.disabled !== false;
        },

        /**
         * Check if the component's value is empty.
         *
         * @return {boolean}
         */
        isEmpty(): boolean {
            return !this.value;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-input--${this.theme}` : '';
        },
    },

    mounted(): void {
        this.uuid = `guid-${uuidv4()}`;
        if (this.type === 'dawa') {
            this.dawa();
            // Dawa automatically adds autocomplete="off", but this is not respected by Chromium based browsers.
            // So for these browsers to accept this, we change it to something the browser doesn't recognise
            // User Story 42622: Slå browserens autosuggest fra i alle formular adressefelter
            let dawaElement = this.$refs.input as HTMLInputElement;
            dawaElement?.setAttribute('autocomplete', 'dawa-input-field');
        }
    },

    methods: {
        onKeyNavigation(e: any) {
            const dawaAutocompleteContainer =
                e.target?.nextElementSibling?.firstChild;
            if (!dawaAutocompleteContainer) return;
            setTimeout(() => {
                const selectedElement =
                    dawaAutocompleteContainer.querySelector('.dawa-selected');
                const scrollTo =
                    selectedElement.offsetTop - selectedElement.offsetHeight;
                dawaAutocompleteContainer.scrollTo({
                    top: scrollTo,
                    behavior: 'smooth',
                });
            }, 100);
        },
        /**
         * Handle "blur" event.
         *
         * @return {void}
         */
        onBlur(): void {
            let inputElement = this.$refs.input as HTMLInputElement;
            inputElement.blur();
            this.isFocused = false;
            this.$emit('blur');
            if (this.type === 'dawa') {
                inputElement.removeEventListener(
                    'keydown',
                    this.onKeyNavigation,
                );
            }
        },

        /**
         * Handle "focus" event.
         *
         * @return {void}
         */
        onFocus(): void {
            this.isFocused = true;
            this.$emit('focus');
            if (this.type === 'dawa') {
                (this.$refs.input as HTMLDivElement).addEventListener(
                    'keydown',
                    this.onKeyNavigation,
                );
            }
        },

        /**
         * Handle "input" event.
         *
         * @return {void}
         */
        onInput(): void {
            this.$emit('input', (this.$refs.input as HTMLInputElement).value);
            if (this.type === 'dawa') this.$emit('dawa-reset');
        },

        /**
         * Handle DAWA autocomplete event.
         *
         * @return {void}
         */
        dawa(): void {
            const ele = this.$refs.input as HTMLInputElement;

            if (ele) {
                dawaAutocomplete2.dawaAutocomplete(ele, {
                    select: (selected: any) => {
                        this.$emit('dawa', selected);
                        this.$emit('input', selected);
                    },
                });
            }
        },
    },
};
