import State from './interface';

export default {
    /**
     * Return all visited.
     *
     * @param {State} state
     * @return {number[]}
     */
    visited(state: State): number[] {
        return state.visited;
    },
};
