
import { PropType } from 'vue';
import { v4 as uuidv4 } from 'uuid';

interface Data {
    isFocused: boolean;
    uuid: string;
}

export default {
    name: 'm-textarea',
    props: {
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        error: {
            type: String as PropType<string>,
            default: () => '',
        },
        label: {
            type: String as PropType<string>,
            default: () => '',
        },
        name: {
            type: String as PropType<string>,
            default: () => '',
        },
        placeholder: {
            type: String as PropType<string>,
            default: () => '',
        },
        sendText: {
            type: String as PropType<string>,
            default: () => '',
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        type: {
            type: String as PropType<string>,
            default: () => 'text',
        },
        value: {
            type: String as PropType<string>,
            required: true,
            default: () => '',
        },
        useBrightError: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            isFocused: false,
            uuid: '',
        };
    },

    computed: {
        /**
         * Check if component has a label.
         *
         * @return {boolean}
         */
        hasLabel(): boolean {
            return this.label !== '';
        },

        /**
         * Check if component is disabled.
         *
         * @return {boolean}
         */
        isDisabled(): boolean {
            return this.disabled !== false;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-textarea--${this.theme}` : '';
        },
    },

    mounted(): void {
        this.uuid = `guid-${uuidv4()}`;
    },

    methods: {
        /**
         * Handle blur event.
         *
         * @return {void}
         */
        onBlur(): void {
            this.isFocused = false;

            this.$emit('blur');
        },

        /**
         * Handle change event.
         *
         * @return {void}
         */
        onChange(): void {
            this.$emit(
                'input',
                (this.$refs.textarea as HTMLTextAreaElement).value,
            );
        },

        /**
         * Handle focus event.
         *
         * @return {void}
         */
        onFocus(): void {
            this.isFocused = true;

            this.$emit('focus');
        },
    },
};
