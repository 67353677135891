import { FILTER_DEFAULTS } from '@/constants/search.const';
import State from './interface';

export default <State>{
    overlay: {
        currentPanel: '',
        currentSubPanel: 1,
    },
    filters: {
        tags: [],
        price: [FILTER_DEFAULTS.priceMin, FILTER_DEFAULTS.priceMax],
        size: [FILTER_DEFAULTS.houseAreaMin, FILTER_DEFAULTS.houseAreaMax],
        zipcode: '',
        comment: '',
        name: '',
    },
};
