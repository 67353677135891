/* eslint-disable */
export const drawStyles = [
    {
        filter: ['all', ['==', '$type', 'LineString']],
        id: 'draw-line',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#3fa9f5',
            'line-width': 3,
        },
        type: 'line',
    },
    {
        filter: ['all', ['==', '$type', 'Polygon']],
        id: 'draw-polygon-line',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#3fa9f5',
            'line-width': 3,
        },
        type: 'line',
    },
    {
        filter: ['all', ['==', '$type', 'Polygon'], ['==', 'active', 'true']],
        id: 'draw-polygon-fill-active',
        paint: {
            'fill-color': '#3fa9f5',
            'fill-opacity': 0.2,
        },
        type: 'fill',
    },
    {
        filter: ['all', ['==', '$type', 'Polygon'], ['==', 'active', 'false']],
        id: 'draw-polygon-fill-inactive',
        paint: {
            'fill-color': '#3fa9f5',
            'fill-opacity': 0.05,
        },
        type: 'fill',
    },
];

export const mapLayers = {
    unClusteredFavorites: {
        filter: ['!', ['has', 'point_count']],
        id: 'unClusteredFavorites',
        paint: {
            'circle-color': 'transparent',
            'circle-radius': 0,
            'circle-stroke-width': 0,
            'circle-stroke-color': 'transparent',
        },
        source: 'favorites',
        type: 'circle',
    },
    clustersCircleFavorites: {
        filter: ['has', 'point_count'],
        id: 'clustersCircleFavorites',
        paint: {
            'circle-color': '#F5968B',
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                15,
                25,
                20,
                50,
                25,
            ],
            'circle-stroke-color': '#FDD7D1',
            'circle-stroke-width': ['step', ['get', 'point_count'], 10, 10, 12],
        },
        source: 'favorites',
        type: 'circle',
    },
    clustersCountFavorites: {
        filter: ['has', 'point_count'],
        id: 'clustersCountFavorites',
        layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-font': ['Klavika Medium'],
            'text-size': ['step', ['get', 'point_count'], 11, 25, 12],
            'text-line-height': 1,
            'text-transform': 'uppercase',
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': '#fff',
        },
        source: 'favorites',
        type: 'symbol',
    },
    clustersCircle: {
        filter: ['all', ['==', 'type', 'cluster']],
        id: 'clustersCircle',
        paint: {
            'circle-color': '#024849',
            'circle-radius': [
                'step',
                ['get', 'count'],
                16,
                100,
                24,
                1000,
                32,
                10000,
                40,
            ],
            'circle-stroke-color': '#006664',
            'circle-stroke-width': [
                'step',
                ['get', 'count'],
                8,
                100,
                8,
                1000,
                8,
                10000,
                8,
            ],
        },
        source: 'clusters',
        type: 'circle',
    },
    clustersCount: {
        filter: ['all', ['==', 'type', 'cluster'], ['has', 'count']],
        id: 'clustersCount',
        layout: {
            'text-field': '{count}',
            'text-font': ['Klavika Medium'],
            'text-size': 12,
            'text-transform': 'uppercase',
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': '#fff',
        },
        source: 'clusters',
        type: 'symbol',
    },
    dots: {
        filter: ['all', ['==', 'type', 'dot']],
        id: 'dots',
        paint: {
            'circle-color': '#024849',
            'circle-radius': 3,
        },
        source: 'dots',
        type: 'circle',
    },
    polygons: {
        filter: ['==', '$type', 'Polygon'],
        id: 'polygons',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#000',
            'line-width': 1,
        },
        source: 'polygons',
        type: 'line',
    },
    polygonFill: {
        id: 'polygonFill',
        layout: {
            visibility: 'none',
        },
        paint: {
            'fill-color': 'rgba(63, 169, 245, 0.05)',
        },
        source: 'polygon',
        type: 'fill',
    },
    polygonLine: {
        id: 'polygonLine',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
            visibility: 'none',
        },
        paint: {
            'line-color': '#3FA9F5',
            'line-width': 3,
        },
        source: 'polygon',
        type: 'line',
    },
    route: {
        id: 'routeLine',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#3FA9F9',
            'line-width': 4,
        },
        source: 'route',
        type: 'line',
    },
    routeStart: {
        id: 'routeStart',
        paint: {
            'circle-color': '#3fa9f933', //hexcolor with transparency 33 is equal to 20% - https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
            'circle-radius': 14,
        },
        source: 'routeStart',
        type: 'circle',
    },
    routeStartInner: {
        id: 'routeStartInner',
        paint: {
            'circle-color': '#3fa9f9',
            'circle-radius': 8,
        },
        source: 'routeStart',
        type: 'circle',
    },
    routeStartDot: {
        id: 'routeStartDot',
        paint: {
            'circle-color': '#ffffff',
            'circle-radius': 3,
        },
        source: 'routeStart',
        type: 'circle',
    },
};
