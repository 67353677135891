
import { PropType } from 'vue';
import Api from '../../../functions/api';
import {
    BOUNDS,
    LATITUDE,
    LONGITUDE,
    MAX_BOUNDS,
} from '../../../constants/search.const';
import mMap from '../../molecules/Map.vue';
import { EventBus } from '@/functions/eventBus';
import FavoritesMapResponse from '../../../interfaces/responses/favoritesMapResponse.interface';
import MapAddress from '../../../interfaces/mapAddress.interface';
import MapFavorite from '../../../interfaces/mapFavorite.interface';
import MapProperty from '../../../interfaces/mapProperty.interface';
import MapCluster from '../../../interfaces/mapCluster.interface';
import MapDot from '../../../interfaces/mapDot.interface';
import PropertiesMapResponse from '../../../interfaces/responses/propertiesMapResponse.interface';

interface Data {
    bounds: number[][];
    favorites: MapFavorite[];
    headline?: string;
    isInteractive: boolean;
    isLoading: boolean;
    latitude: number;
    longitude: number;
    maxBounds: { lat: number; lng: number }[];
    properties: (MapCluster | MapDot | MapProperty)[];
    zoom: number;
    addZoom: boolean;
}

interface Coordinates {
    lat: number;
    lon: number;
}

export interface Texts {
    openHouseTeaser: string;
    openHouseTeaserSignup: string;
}

export default {
    components: {
        mMap,
    },
    props: {
        addressCoordinates: {
            type: Object as PropType<Coordinates>,
            default() {
                return { lat: 0, lon: 0 };
            },
        },
        fullAddress: {
            type: String,
            default() {
                return '';
            },
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                openHouseTeaser: '',
                openHouseTeaserSignup: '',
            }),
        },
    },
    data(): Data {
        return {
            bounds: BOUNDS,
            favorites: [],
            isInteractive: false,
            isLoading: true,
            latitude: LATITUDE,
            longitude: LONGITUDE,
            maxBounds: MAX_BOUNDS,
            properties: [],
            zoom: EventBus.isDesktop ? 14 : 13,
            addZoom: false,
        };
    },
    computed: {
        propertyAddress(): MapAddress {
            return {
                geometry: {
                    coordinates: [
                        this.addressCoordinates.lon,
                        this.addressCoordinates.lat,
                    ],
                    type: 'Point',
                },
                properties: {
                    label: this.fullAddress,
                },
                type: 'Feature',
            };
        },
        propertyCoordinates(): number[] {
            return [this.addressCoordinates.lon, this.addressCoordinates.lat];
        },
    },
    watch: {
        bounds(): void {
            this.getData();
        },
    },
    mounted() {
        this.observeMapIntersection();
    },
    methods: {
        observeMapIntersection(): void {
            let intersectionObserverElement = this.$refs.map as any;
            const isMidViewport = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        this.doAddZoom();
                        isMidViewport.unobserve(intersectionObserverElement);
                    }
                },
                {
                    rootMargin: '0px 0px -40% 0px',
                },
            );
            isMidViewport.observe(intersectionObserverElement);
        },
        doAddZoom() {
            this.addZoom = true;
        },
        getData(): void {
            this.isLoading = true;
            const propertiesPromise = this.getProperties();
            const favoritesPromise = this.getFavorites();
            Promise.all([propertiesPromise, favoritesPromise]).then(() => {
                this.isLoading = false;
            });
        },
        getFavorites(): void {
            Api.getFavoritesMap({
                lat: this.latitude,
                lng: this.longitude,
                distance: 10,
            }).then((response: FavoritesMapResponse) => {
                this.favorites = response.results;
            });
        },
        getProperties(): void {
            Api.getPropertiesMap({
                bounds: this.bounds,
                zoom: this.zoom,
            }).then((response: PropertiesMapResponse) => {
                this.properties = response.results;
            });
        },
        onLoad(): void {
            this.getData();
        },
        /**
         * Handle "move" event on map.
         *
         * @param {any} event
         * @return {void}
         */
        onMove(payload: any): void {
            this.latitude = payload.latitude;
            this.longitude = payload.longitude;
            this.zoom = payload.zoom;

            const northEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._ne.lat,
            ];
            const northWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._ne.lat,
            ];
            const southEast: [number, number] = [
                payload.bounds._ne.lng,
                payload.bounds._sw.lat,
            ];
            const southWest: [number, number] = [
                payload.bounds._sw.lng,
                payload.bounds._sw.lat,
            ];

            this.bounds = [
                southWest,
                southEast,
                northEast,
                northWest,
                southWest,
            ];
        },
    },
};
