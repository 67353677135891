
import { PropType } from 'vue';
import KeyValue from '@/interfaces/keyValue.interface';

interface Data {
    selectedItem: string;
}

export default {
    props: {
        items: {
            type: Array as PropType<KeyValue[]>,
            required: true,
            default: () => [],
        },
    },

    data(): Data {
        return {
            selectedItem: this.items[0].key,
        };
    },

    methods: {
        /**
         * Handle click event.
         *
         * @return {void}
         */
        onClick(e: any): void {
            this.$emit('change', e.target.value);
        },
    },
};
