import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-propertyMap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_map = _resolveComponent("m-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_m_map, {
      class: "o-propertyMap__map",
      address: $options.address,
      brokers: $data.brokers,
      "do-favorite-clusters": true,
      center: [$props.longitude, $props.latitude],
      favorites: $data.favorites,
      "fit-favorites": $data.fitMapToFavorites,
      "is-loading": $data.isLoading,
      "max-bounds": $data.maxBounds,
      pois: $data.pois,
      zoom: $data.zoom,
      "show-favorites": _ctx.showFavorites,
      route: _ctx.currentRoute,
      "map-type": "propertymap",
      onLoad: $options.onLoad,
      onMove: $options.onMove,
      onClose: $options.onClose,
      onPoiClicked: $options.getRoutesToPOI
    }, null, 8 /* PROPS */, ["address", "brokers", "center", "favorites", "fit-favorites", "is-loading", "max-bounds", "pois", "zoom", "show-favorites", "route", "onLoad", "onMove", "onClose", "onPoiClicked"])
  ]))
}