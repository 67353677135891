import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "o-homeEstimateFlow__actions" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["aria-label", "disabled"]
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.prevButtonText)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "m-button m-button--fadeIn m-button--link",
          "aria-label": $props.prevButtonAltText,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.flowNavigation($event, 'prev')))
        }, [
          _createElementVNode("span", {
            class: "m-button__text",
            textContent: _toDisplayString($props.prevButtonText)
          }, null, 8 /* PROPS */, _hoisted_3)
        ], 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("button", {
      type: "submit",
      class: _normalizeClass(["m-button m-button--fadeIn", { isLoading: $data.isLoading }]),
      "aria-label": $props.nextButtonAltText,
      disabled: $props.nextButtonDisabled,
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.flowNavigation($event, 'next')))
    }, [
      _createElementVNode("span", {
        class: "m-button__text",
        textContent: _toDisplayString($props.nextButtonText)
      }, null, 8 /* PROPS */, _hoisted_5)
    ], 10 /* CLASS, PROPS */, _hoisted_4)
  ]))
}