import State from './interface';

export default {
    /**
     * Get the current panel.
     *
     * @param {State} state
     * @return {string}
     */
    currentPanel(state: State): string {
        return state.overlay.currentPanel;
    },

    /**
     * Get the current sub panel.
     *
     * @param {State} state
     * @return {number}
     */
    currentSubPanel(state: State): number {
        return state.overlay.currentSubPanel;
    },

    /**
     * Get comment.
     *
     * @param {State} state
     * @return {number[]}
     */
    agentName(state: State): string {
        return state.agentName;
    },
};
