
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import oEmployeeShopCard from '@/components/organisms/broker/EmployeeShopCard.vue';
import Employee from '@/interfaces/employee.interface';

interface IData {
    isTeamListExpandable: boolean;
}

interface IClickedEmployeeData {
    id: string;
    panel: string;
    track: boolean;
}

export default {
    components: {
        oEmployeeShopCard,
    },
    props: {
        employees: {
            type: Array as PropType<Employee[]>,
            default: () => [],
        },
        ctaText: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): IData {
        return {
            isTeamListExpandable: false,
        };
    },

    computed: {
        lessThanFour(): boolean {
            return this.employees.length < 4;
        },
    },

    mounted(): void {
        const teamListWrapperElement = this.$refs
            .teamExpandWrapper as HTMLDivElement;
        const teamListElement = this.$refs.teamList as HTMLDivElement;
        if (teamListElement && teamListWrapperElement) {
            if (this.employees.length > 8) {
                if (
                    teamListElement.getBoundingClientRect().height >
                    teamListWrapperElement.getBoundingClientRect().height
                ) {
                    this.isTeamListExpandable = true;
                }
                teamListWrapperElement.classList.add(
                    this.isTeamListExpandable
                        ? 'isExpandable'
                        : 'isNotExpandable',
                );
            } else {
                teamListWrapperElement.classList.add('isNotExpandable');
            }
        }
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'employee/setCurrentPanel',
            setTrackingLabel: 'modal/setTrackingLabel',
            setFixed: 'modal/setFixed',
        }),

        onClickChild(payload: IClickedEmployeeData): void {
            const buttonToFind = document.querySelector(
                `[data-cta][data-employeeid='${payload.id}']`,
            ) as HTMLButtonElement;
            this.setTrackingLabel('Koeberraadgivning');
            this.setCurrentPanel('form');
            this.setFixed(true);
            buttonToFind.click();
        },

        expand(): void {
            const div = this.$refs.teamExpandWrapper as HTMLDivElement;
            div.classList.add('expanded');
        },
    },
};
