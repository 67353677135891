
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';
import HorizontalChart from './HorizontalChart.vue';

interface Data {
    isDesktop: boolean;
}

export default {
    name: 'm-bar-chart',
    components: {
        HorizontalChart,
    },

    props: {
        primaryDataset: {
            type: Array as PropType<number[]>, // KeyValue[]
            default: () => [],
        },
        labels: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        suffix: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            isDesktop: EventBus.isDesktop,
        };
    },

    computed: {
        maxValue(): number {
            return Math.max(...this.primaryDataset);
        },
    },

    mounted() {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },
};
