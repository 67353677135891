import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = {
  key: 0,
  class: "o-propertyPreview__multiple"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "o-propertyPreview__multipleArrows" }
const _hoisted_5 = { class: "o-propertyPreview__top" }
const _hoisted_6 = { class: "a-small o-propertyPreview__teaser" }
const _hoisted_7 = {
  key: 0,
  class: "o-propertyPreview__tag o-propertyPreview__tag--liebhaver",
  textContent: 'Liebhaver'
}
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { class: "o-propertyPreview__slider" }
const _hoisted_11 = {
  key: 0,
  class: "o-propertyPreview__indicators"
}
const _hoisted_12 = {
  key: 1,
  ref: "slides",
  class: "o-propertyPreview__slides"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 2,
  class: "o-propertyPreview__slides"
}
const _hoisted_15 = { class: "o-propertyPreview__slide" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 3,
  class: "a-small o-propertyPreview__externalBroker o-propertyPreview__tag",
  textContent: 'Anden mægler'
}
const _hoisted_18 = {
  key: 4,
  class: "o-propertyPreview__controls"
}
const _hoisted_19 = {
  key: 0,
  class: "o-propertyPreview__gradient"
}
const _hoisted_20 = ["textContent"]
const _hoisted_21 = { class: "o-propertyPreview__bottom" }
const _hoisted_22 = { class: "o-propertyPreview__text" }
const _hoisted_23 = ["textContent"]
const _hoisted_24 = {
  key: 0,
  class: "a-label o-propertyPreview__label"
}
const _hoisted_25 = {
  key: 2,
  class: "a-label o-propertyPreview__label"
}
const _hoisted_26 = ["id"]
const _hoisted_27 = {
  key: 0,
  class: "o-propertyPreview__link"
}
const _hoisted_28 = ["textContent"]
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "o-propertyPreview__addressElement" }
const _hoisted_31 = ["textContent"]
const _hoisted_32 = ["textContent"]
const _hoisted_33 = ["href"]
const _hoisted_34 = ["textContent"]
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "o-propertyPreview__addressElement" }
const _hoisted_37 = ["textContent"]
const _hoisted_38 = ["textContent"]
const _hoisted_39 = {
  key: 0,
  class: "a-label o-propertyPreview__label o-propertyPreview__label--underSale"
}
const _hoisted_40 = {
  key: 1,
  class: "o-propertyPreview__facts"
}
const _hoisted_41 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_a_energy_label = _resolveComponent("a-energy-label")!
  const _directive_touch = _resolveDirective("touch")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$slots.default)
      ? _renderSlot(_ctx.$slots, "default", {
          key: 0,
          onClick: $options.onClick
        })
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["o-propertyPreview", [
                $props.addedClasses,
                {
                    hasOpenHouse: $props.property.openHouse,
                    isSold: $props.property.isSold,
                },
            ]]),
          "aria-labelledby": $data.uuid
        }, [
          ($props.total > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", {
                  class: "a-label o-propertyPreview__multipleText",
                  textContent: _toDisplayString(`${$props.index + 1} af ${$props.total} boliger`)
                }, null, 8 /* PROPS */, _hoisted_3),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    class: "o-propertyPreview__multipleArrow",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClickPrev()))
                  }, [
                    _createVNode(_component_a_icon, { name: "arrow_back" })
                  ]),
                  _createElementVNode("button", {
                    class: "o-propertyPreview__multipleArrow",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.onClickNext()))
                  }, [
                    _createVNode(_component_a_icon, { name: "arrow_forward" })
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              ($props.property.luxurious)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                : _createCommentVNode("v-if", true),
              ($props.property.spotText && !$props.property.isSold)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "o-propertyPreview__tag",
                    textContent: _toDisplayString($props.property.spotText)
                  }, null, 8 /* PROPS */, _hoisted_8))
                : _createCommentVNode("v-if", true),
              ($props.property.isSold && $options.soldDate !== '')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "o-propertyPreview__tag",
                    textContent: _toDisplayString($options.soldDate)
                  }, null, 8 /* PROPS */, _hoisted_9))
                : _createCommentVNode("v-if", true)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
              ($props.property.images.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.property.images, (image, imageIndex) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: imageIndex,
                        class: _normalizeClass([{ isActive: $data.currentSlide === imageIndex }, "o-propertyPreview__indicator"])
                      }, null, 2 /* CLASS */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
              ($props.property.images.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_TransitionGroup, {
                      name: `o-propertyPreview__slide--${$data.currentDirection}`,
                      tag: "div"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.property.images, (image, imageIndex) => {
                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                            ($data.currentSlide === imageIndex)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: imageIndex,
                                  class: "o-propertyPreview__slide",
                                  draggable: "true"
                                }, [
                                  _createElementVNode("img", {
                                    src: $options.formattedImageUrl(image),
                                    onerror: "this.onerror=null;this.src='/assets/images/fallback/propertyFallbackImage.svg'",
                                    class: "o-propertyPreview__image"
                                  }, null, 8 /* PROPS */, _hoisted_13)
                                ]))
                              : _createCommentVNode("v-if", true)
                          ], 64 /* STABLE_FRAGMENT */))
                        }), 256 /* UNKEYED_FRAGMENT */))
                      ], undefined, true),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["name"])
                  ], 512 /* NEED_PATCH */))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("img", {
                        src: $props.property.fallbackImageUrl,
                        onerror: "this.onerror=null;this.src='/assets/images/fallback/propertyFallbackImage.svg'",
                        alt: "",
                        class: "o-propertyPreview__image"
                      }, null, 8 /* PROPS */, _hoisted_16)
                    ])
                  ])),
              ($options.isExternal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17))
                : _createCommentVNode("v-if", true),
              ($props.property.images.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("button", {
                      class: "o-propertyPreview__control",
                      "aria-label": "Forrige billede i galleri",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.previousSlide && $options.previousSlide(...args)))
                    }, [
                      _createVNode(_component_a_icon, {
                        name: "arrow_back",
                        "aria-hidden": "true",
                        class: "o-propertyPreview__controlIcon"
                      })
                    ]),
                    _createElementVNode("button", {
                      class: "o-propertyPreview__control",
                      "aria-label": "Næste billede i galleri",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.nextSlide && $options.nextSlide(...args)))
                    }, [
                      _createVNode(_component_a_icon, {
                        name: "arrow_forward",
                        "aria-hidden": "true",
                        class: "o-propertyPreview__controlIcon"
                      })
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ])), [
              [
                _directive_touch,
                $options.nextSlide,
                "swipe",
                { left: true }
              ],
              [
                _directive_touch,
                $options.previousSlide,
                "swipe",
                { right: true }
              ]
            ]),
            ($props.property.images.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19))
              : _createCommentVNode("v-if", true),
            ($props.property.openHouse)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "a-label o-propertyPreview__openHouse",
                  textContent: _toDisplayString($options.openHouseFormatted)
                }, null, 8 /* PROPS */, _hoisted_20))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", {
                class: "a-label o-propertyPreview__type",
                textContent: _toDisplayString($props.property.type)
              }, null, 8 /* PROPS */, _hoisted_23),
              ($props.property.isSold)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, " Solgt "))
                : ($props.property.isNew)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["a-label o-propertyPreview__label", {
                            'o-propertyPreview__label--luxurious':
                                $props.property.luxurious,
                        }])
                    }, " Nyhed! ", 2 /* CLASS */))
                  : ($props.property.hasNewPrice)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, " Ny pris! "))
                    : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("h3", {
              id: $data.uuid,
              class: _normalizeClass(["a-lead o-propertyPreview__address", { isMultiline: $data.isMultiline }])
            }, [
              ($props.property.isSold)
                ? (_openBlock(), _createElementBlock("span", _hoisted_27, [
                    _createElementVNode("span", {
                      ref: "street",
                      class: "o-propertyPreview__addressElement",
                      textContent: _toDisplayString($props.property.address)
                    }, null, 8 /* PROPS */, _hoisted_28),
                    ($data.isMultiline)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, ", "))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("span", _hoisted_30, [
                      _createElementVNode("span", {
                        textContent: _toDisplayString($props.property.zipCode)
                      }, null, 8 /* PROPS */, _hoisted_31),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(` ${$props.property.city}`)
                      }, null, 8 /* PROPS */, _hoisted_32)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: $props.property.url,
                    target: "_blank",
                    class: "o-propertyPreview__link",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.onClick($props.property.propertyId, 'propertysearch')))
                  }, [
                    _createElementVNode("span", {
                      ref: "street",
                      class: "o-propertyPreview__addressElement",
                      textContent: _toDisplayString($props.property.address)
                    }, null, 8 /* PROPS */, _hoisted_34),
                    ($data.isMultiline)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_35, ", "))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("span", _hoisted_36, [
                      _createElementVNode("span", {
                        textContent: _toDisplayString($props.property.zipCode)
                      }, null, 8 /* PROPS */, _hoisted_37),
                      _createElementVNode("span", {
                        textContent: _toDisplayString(` ${$props.property.city}`)
                      }, null, 8 /* PROPS */, _hoisted_38)
                    ])
                  ], 8 /* PROPS */, _hoisted_33))
            ], 10 /* CLASS, PROPS */, _hoisted_26),
            ($props.property.isUnderSale)
              ? (_openBlock(), _createElementBlock("div", _hoisted_39, " Købsaftale underskrevet! "))
              : ($props.property.factsDesktop && !$props.property.isUnderSale)
                ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.property.factsDesktop, (fact, factIndex) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (fact.name === 'EnergyLabel')
                          ? (_openBlock(), _createBlock(_component_a_energy_label, {
                              key: `label-${factIndex}`,
                              name: fact.value,
                              class: "o-propertyPreview__fact"
                            }, null, 8 /* PROPS */, ["name"]))
                          : (_openBlock(), _createElementBlock("span", {
                              key: `span-${factIndex}`,
                              class: "a-label o-propertyPreview__fact",
                              innerHTML: fact.value
                            }, null, 8 /* PROPS */, _hoisted_41))
                      ], 64 /* STABLE_FRAGMENT */))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
            _createCommentVNode(" OUT OF SCOPE UNTIL LAUNCH "),
            _createCommentVNode(" <div class=\"o-propertyPreview__icon\">\n                    <button class=\"o-propertyPreview__favorite\">\n                        <a-icon name=\"star_outline\" />\n                    </button>\n                </div> ")
          ])
        ], 10 /* CLASS, PROPS */, _hoisted_1))
  ]))
}