
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';

interface Data {
    backgroundColors: string[];
    scale: number;
}

export default {
    name: 'm-doughnut-chart',
    props: {
        primaryDataset: {
            type: Array as PropType<string[]>, // KeyValue[]
            default: () => [],
        },
        labels: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        suffix: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            backgroundColors: ['#fff', '#006664', '#3FA9F5'],
            scale: Math.ceil(window.devicePixelRatio),
        };
    },

    mounted() {
        this.createChart();
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
    },

    methods: {
        createChart(): void {
            const canvas = this.$refs.doughnut as HTMLCanvasElement;
            const ctx = canvas?.getContext('2d');

            if (ctx) {
                ctx.scale(this.scale, this.scale);

                const size = 226;
                canvas.width = this.scale * size;
                canvas.height = this.scale * size;

                canvas.style.width = `${size}px`;
                canvas.style.height = `${size}px`;

                const sum = this.primaryDataset
                    .map((datum) => parseInt(datum))
                    .reduce((a, b) => a + b);
                const percentages = this.primaryDataset.map(
                    (datum: string) => (parseInt(datum) * 100) / sum,
                );

                percentages.forEach((datum: number, i: number) => {
                    const progress =
                        i === 0
                            ? 0
                            : percentages.slice(0, i).reduce((a, b) => a + b);
                    const sAngle =
                        (progress / 100) * (Math.PI * 2) - Math.PI * 0.5;
                    const eAngle =
                        ((progress + datum) / 100) * (Math.PI * 2) -
                        Math.PI * 0.5;

                    ctx.beginPath();
                    ctx.arc(
                        ctx.canvas.width / 2,
                        ctx.canvas.height / 2,
                        ctx.canvas.height * 0.4,
                        sAngle,
                        eAngle,
                        false,
                    );
                    ctx.strokeStyle =
                        this.backgroundColors[i % this.backgroundColors.length];
                    ctx.lineWidth = this.scale * 16;
                    ctx.lineCap = 'round';
                    ctx.stroke();
                });
            }
        },
    },
};
