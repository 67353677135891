import { ActionContext } from 'vuex';

export default {
    /**
     * Add article to list of visited.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    addVisited({ commit }: ActionContext<any, any>, value: number): void {
        commit('addVisited', value);
    },
};
