
import { PropType } from 'vue';
import mSelect from '@/components/molecules/form/Select.vue';
import mInput from '@/components/molecules/form/Input.vue';
import mCheckbox from '@/components/molecules/form/Checkbox.vue';
import Api from '@/functions/api';
import tracking from '@/functions/tracking';

export interface Data {
    form: {
        name: string;
        mail: string;
        municipality: string;
        consent: boolean;
    };
    errors: {
        name?: string;
        mail?: string;
        municipality?: string;
        consent?: string;
    };
    step: number;
    isLoading: boolean;
}

export interface Texts {
    headline: string;
    helpText: string;
    buttonText: string;
    confirmationHeadline: string;
    confirmationText: string;
    consentText: string;
    checkboxLabel: string;
    backgroundColour: string;
}

export interface FormPlaceholders {
    namePlaceholder: string;
    mailPlaceholder: string;
    municipalityPlaceholder: string;
}

export default {
    components: {
        mSelect,
        mInput,
        mCheckbox,
    },

    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                headline: '',
                helpText: '',
                buttonText: '',
                confirmationHeadline: '',
                confirmationText: '',
                consentText: '',
                checkboxLabel: '',
            }),
        },
        placeholders: {
            type: Object as PropType<FormPlaceholders>,
            default: () => ({
                namePlaceholder: '',
                mailPlaceholder: '',
                municipalityPlaceholder: '',
            }),
        },
        municipalities: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        email: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    data(): Data {
        return {
            form: {
                name: '',
                mail: this.email,
                municipality: '',
                consent: false,
            },
            errors: {},
            step: 1,
            isLoading: false,
        };
    },

    computed: {
        /**
         * Gets first name from name
         *
         * @return {string}
         */
        firstName(): string {
            return this.form.name.substr(0, this.form.name.indexOf(' '));
        },

        /**
         * Gets last name from name
         *
         * @return {string}
         */
        lastName(): string {
            return this.form.name.substr(
                this.form.name.indexOf(' ') + 1,
                this.form.name.length - 1,
            );
        },

        municipalityOptions(): any {
            const options = this.municipalities.map(
                (municipality: string): any => ({
                    label: municipality,
                    value: municipality,
                }),
            );

            return [
                {
                    label: `${this.placeholders.municipalityPlaceholder}*`,
                    value: '',
                },
                ...options,
            ];
        },
        checkboxLabel(): string {
            return this.texts.checkboxLabel.replace(/\n/g, '<br>');
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    methods: {
        /**
         * Increment form step
         *
         * @return {void}
         */
        incrementStep(): void {
            if (this.step === 1) {
                this.step += 1;
            }
        },

        onSubmit(): void {
            this.isLoading = true;

            Api.newsletterSignup({
                email: this.form.mail.trim(),
                name: this.form.name,
                municipality: this.form.municipality,
                consent: this.form.consent,
            })
                .then(() => {
                    this.incrementStep();

                    let nyhedsbrevFormularIndgang = sessionStorage.getItem(
                        'nyhedsbrevFormularIndgang',
                    );

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Nyhedsbrev gennemfoert',
                            label: 'Nyhedsbrev',
                            formularIndgang:
                                nyhedsbrevFormularIndgang ?? 'Ukendt',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
