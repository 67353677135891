
import { PropType } from 'vue';
import Format from '@/functions/format';

interface Data {
    currentRange: number | number[] | null;
    maxLabel: string;
    minLabel: string;
}

export default {
    compatConfig: {
        MODE: 3,
        COMPONENT_V_MODEL: false,
    },
    emits: ['update:modelValue'],
    props: {
        append: {
            type: String as PropType<string>,
            default: () => '',
        },
        ariaLabel: {
            type: String as PropType<string>,
            default: () => '',
        },
        complexInterval: {
            type: Array as PropType<number[]>,
            default: () => null,
        },
        interval: {
            type: Number as PropType<number>,
            default: () => 1,
        },
        infinityIndicator: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        max: {
            type: Number as PropType<number>,
            default: () => 100,
        },
        min: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        prepend: {
            type: String as PropType<string>,
            default: () => '',
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        type: {
            type: String as PropType<string>,
            default: () => '',
        },
        modelValue: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        value: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        valueMap: {
            type: Object as PropType<{ [key: number]: string }>,
            default: () => ({}),
        },
    },
    data(): Data {
        return {
            currentRange: null,
            maxLabel: '',
            minLabel: '',
        };
    },

    computed: {
        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-rangeSlider--${this.theme}` : '';
        },
    },

    mounted() {
        let initialRange = [];

        if (this.value && this.value.length) {
            initialRange = this.value.length
                ? this.value
                : [this.min, this.max];
        } else if (this.modelValue && this.modelValue.length) {
            initialRange = this.modelValue.length
                ? this.modelValue
                : [this.min, this.max];
        }

        this.currentRange = initialRange;
        this.setLabels(initialRange);
        this.setAriaLabels(initialRange);
    },

    watch: {
        currentRange: {
            handler() {
                
                this.setLabels(this.currentRange);
            },
        },
        value: {
            handler() {
                
                this.currentRange = this.value;
            },
        },
    },

    methods: {
        /**
         * Handle change event.
         *
         * @param {number[]} payload
         * @return {void}
         */
        onChange(payload: number[]): void {
            // console.log('onChange', payload);
            // this.$emit('input', payload);
            this.$emit('update:modelValue', payload);
        },

        /**
         * Handle dragging event.
         *
         * @param {number[]} payload
         * @return {void}
         */
        onDragging(payload: number[]): void {
            this.setLabels(payload);
            this.setAriaLabels(payload);
        },

        /**
         * Set min/max labels.
         *
         * @param {number[]} range
         * @return {void}
         */
        setLabels(range: number[]): void {
            if (!range.length) {
                range = [this.min, this.max];
            }

            this.maxLabel = this.formatLabel(
                range[1],
                this.max === range[1] ? 'append' : undefined,
            );
            this.minLabel = this.formatLabel(
                range[0],
                this.min === range[0] ? 'prepend' : undefined,
            );
        },

        /**
         * Set aria labels on slider dots for screen reader.
         *
         * @param {number[]} range
         * @return {void}
         */
        setAriaLabels(range?: number[]): void {
            const dots = [].slice
                .call(document.getElementsByClassName('vue-slider-dot'))
                .filter(
                    (ele: HTMLElement) =>
                        ele.getAttribute('aria-label') === this.ariaLabel,
                );

            dots.forEach((dot: HTMLElement, index: number) => {
                let key = range
                    ? range[index]
                    : dot.getAttribute('aria-valuenow');
                if (key) {
                    if (typeof key === 'string') key = parseInt(key);
                    if (!(Object.keys(this.valueMap).length === 0)) {
                        dot.setAttribute('aria-valuetext', this.valueMap[key]);
                    } else {
                        dot.setAttribute('aria-valuetext', key + this.append);
                    }
                }
            });
        },

        /**
         * Format label.
         *
         * @param {number} value
         * @param {string | undefined} affix
         * @return {string}
         */
        formatLabel(value: number, affix: string | undefined): string {
            const prependValue =
                affix === 'prepend' && this.prepend ? this.prepend : '';
            const appendValue =
                affix === 'append' && this.infinityIndicator ? '+' : '';

            if (this.type === 'time') {
                let hourValue = value
                    .toString()
                    .substring(0, value.toString().length - 2);
                let minValue = `${parseInt(value.toString().substring(value.toString().length - 2)) * 0.6}`;

                if (minValue.toString().length < 2) {
                    minValue = `0${minValue}`;
                }

                return `${prependValue} ${hourValue}:${minValue}${appendValue} ${this.append}`;
            }

            if (this.type === 'valueMapRange') {
                return '';
            }

            if (this.append === 'mio. kr.') {
                return `${prependValue} ${Format.millions(value)}${appendValue} ${this.append}`;
            }

            if (this.append === 'm2') {
                return `${prependValue} ${Format.amount(value)}${appendValue} m<sup>2</sup>`;
            }

            if (this.append) {
                return `${prependValue} ${Format.amount(value)}${appendValue} ${this.append}`;
            }

            return `${prependValue} ${value}`;
        },
    },
};
