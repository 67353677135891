
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Dawa from '@/interfaces/dawa.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import oAccordion from '@/components/organisms/Accordion.vue';
import oEmployeeCard from '@/components/organisms/broker/EmployeeCard.vue';
import mSelect from '@/components/molecules/form/Select.vue';
import { EventBus } from '@/functions/eventBus';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Employee from '@/interfaces/employee.interface';
import tracking from '@/functions/tracking';
import EmployeesByZipcodeResponse from '@/interfaces/responses/employeesByZipcodeResponse.interface';
import LeadResponse from '@/interfaces/responses/LeadResponse.interface';

export interface Data {
    form: {
        address: string;
        name: string;
        mail: string;
        phone?: string;
        date: string;
        comment?: string;
    };
    errors: {
        address?: string;
        name?: string;
        mail?: string;
        phone?: string;
        date?: string;
    };
    addressFound: boolean;
    zipcode?: number;
    assignedEmployee: Employee | undefined;
    isLoading: boolean;
    step: number;
    isDesktop: boolean;
    relatedBroker: boolean;
}

export interface Texts {
    stepOneHelpText: string;
    buttonText: string;
    backgroundColour: string;
    nextButtonText: string;
    stepTwoHelpText: string;
    backButtonText: string;
    stepThreeHeadline: string;
    confirmationText: string;
    ariaLabel: string;
}

export interface FormSettings {
    commentPlaceholder: string;
    datePlaceholder: string;
    addressPlaceholder: string;
    namePlaceholder: string;
    mailPlaceholder: string;
    phonePlaceholder: string;
}

export interface PopupSubject {
    label: string;
    description: string;
}

export default {
    components: {
        oAccordion,
        mSelect,
        oEmployeeCard,
    },

    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                stepOneHelpText: '',
                buttonText: '',
                backgroundColour: '',
                stepTwoHelpText: '',
                backButtonText: '',
                stepThreeHeadline: '',
                confirmationText: '',
                ariaLabel: '',
            }),
        },
        formSettings: {
            type: Object as PropType<FormSettings>,
            default: () => ({
                commentPlaceholder: '',
                datePlaceholder: '',
                addressPlaceholder: '',
                namePlaceholder: '',
                mailPlaceholder: '',
                phonePlaceholder: '',
            }),
        },
        popupSubjects: {
            type: Array as PropType<PopupSubject[]>,
            default: () => [],
        },
        dates: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                brokerId: '',
            }),
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
        alternateLeadType: {
            type: String as PropType<string>,
            default: () => '',
        },
        leadSource: {
            type: String as PropType<string>,
            default: () => '',
        },
        hasValuationWeekend: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            form: {
                address: '',
                name: '',
                mail: '',
                date: '',
            },
            assignedEmployee: undefined,
            errors: {},
            isLoading: false,
            addressFound: false,
            step: 1,
            isDesktop: EventBus.isDesktop,
            relatedBroker: false,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        /**
         * Check if sales valuation modal is to be shown.
         *
         * @return {boolean}
         */
        showSalesValuationInfo(): boolean {
            return this.show && this.type === 'salesValuation';
        },

        /**
         * Check if step two is to be shown
         *
         * @return {boolean}
         */
        showValuationWeekend(): boolean {
            return !!this.$slots.second && this.hasValuationWeekend;
        },

        /**
         * Create array of dates for select.
         *
         * @return {array}
         */
        dateOptions(): any {
            const dateOptionList = this.dates.map((date: string): any => ({
                label: date,
                value: date,
            }));

            return [
                {
                    label: `${this.formSettings.datePlaceholder}*`,
                    value: '',
                },
                ...dateOptionList,
            ];
        },

        /**
         * Gets first name from name
         *
         * @return {string}
         */
        firstName(): string {
            return this.form.name.substr(0, this.form.name.indexOf(' '));
        },

        /**
         * Gets last name from name
         *
         * @return {string}
         */
        lastName(): string {
            return this.form.name.substr(
                this.form.name.indexOf(' ') + 1,
                this.form.name.length - 1,
            );
        },

        /**
         * Format confirmation headline
         *
         * @return {string}
         */
        formattedConfirmation(): string {
            return this.texts.stepThreeHeadline.replace(
                '#adresse#',
                this.form.address,
            );
        },

        showPopupSubjectsInfoIcon(): boolean {
            return this.popupSubjects.length > 0;
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    beforeMount() {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });

        // If user is coming from https://www.dingeo.dk/ we should prefill the dawa input field with the address from the URL
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('address')) {
            const dinGeoAddress = urlParams.get('address') as string;
            this.form.address = dinGeoAddress;
        }
    },

    mounted() {
        if (this.employee && this.employee.brokerId) {
            this.relatedBroker = true;
        }
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        /**
         * Open modal
         *
         * @return {void}
         */
        openPopup(): void {
            this.openModal('salesValuation');
        },

        /**
         * On click for submit buttons
         *
         * @return {void}
         */
        onClick(): void {
            if (
                (this.step === 1 && !this.showValuationWeekend) ||
                this.step === 2
            ) {
                this.onSubmit();
            } else if (this.step === 1) {
                this.validateLead();
            }
        },

        /**
         * Increment form step
         *
         * @return {void}
         */
        incrementStep(): void {
            if (this.step === 1 && !this.showValuationWeekend) {
                this.step += 2;
            } else {
                this.step += 1;
            }
        },

        /**
         * Increment form step
         *
         * @return {void}
         */
        decrementStep(): void {
            this.step -= 1;
        },

        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },

        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },

        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            // this.errors.address = '';
            if (this.addressFound) {
                this.form.address = value.tekst;
                this.zipcode = parseInt(value.data.postnr);
            }
        },

        focusForm(): void {
            if (!this.isDesktop) {
                (this.$refs.salesValuation as HTMLDivElement).scrollIntoView();
            }
        },

        validateLead(): void {
            this.isLoading = true;

            Api.validateLead({
                consentIdGlobal: this.consent?.id ?? '',
                address: this.form.address,
                comment: 'validation',
                email: this.form.mail.trim(),
                firstName: this.firstName,
                lastName: this.lastName,
                phoneNumber: this.form.phone ?? '',
                responsibleShopNo: this.relatedBroker
                    ? this.employee?.brokerId
                    : '001',
                type: this.form.date
                    ? LeadTypes.SalesValuationShopWeekend.toString()
                    : LeadTypes.SalesValuationShop.toString(),
                httpReferral: this.referrer,
                alternateLeadSource: this.leadSource,
                utmCampaign: this.utmCampaign,
                utmContent: this.utmContent,
                utmMedium: this.utmMedium,
                utmSource: this.utmSource,
            })
                .then(() => {
                    this.incrementStep();
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Vurderingsweekend stepskift',
                            label: 'Vurderingsweekend',
                            formularStepnavn: 'Vaelg data',
                            maeglerId: this.relatedBroker
                                ? this.employee?.brokerId
                                : '001',
                        },
                    });
                })
                .catch((error: any) => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                    this.focusForm();
                });
        },

        addLead(): Promise<LeadResponse> {
            let type = '';
            if (this.showValuationWeekend) {
                if (this.relatedBroker) {
                    type = LeadTypes.SalesValuationShopWeekend.toString();
                } else {
                    type = LeadTypes.SalesValuationWeekend.toString();
                }
            } else {
                if (this.relatedBroker) {
                    type = LeadTypes.SalesValuationShop.toString();
                } else {
                    type = LeadTypes.SalesValuation.toString();
                }
            }

            if (this.alternateLeadType !== '') {
                type = LeadTypes.SalesValuationDinGeo.toString();
            }

            return Api.postLead({
                consentIdGlobal: this.consent?.id ?? '',
                address: this.form.address,
                comment: `${this.form.date ? `Valgt dato: ${this.form.date}` : ''}${this.form.comment && this.form.date ? '\n' : ''}${this.form.comment ? `Kommentar: ${this.form.comment}` : ''}`,
                email: this.form.mail.trim(),
                firstName: this.firstName,
                lastName: this.lastName,
                phoneNumber: this.form.phone ?? '',
                responsibleShopNo: this.employee?.brokerId ?? '',
                type,
                httpReferral: this.referrer,
                utmCampaign: this.utmCampaign,
                utmContent: this.utmContent,
                utmMedium: this.utmMedium,
                utmSource: this.utmSource,
                alternateLeadSource: this.leadSource,
            });
        },

        getEmployee(): Promise<EmployeesByZipcodeResponse> {
            if (this.employee && this.employee.brokerId !== '') {
                return Promise.resolve({ data: this.employee });
            }

            if (this.zipcode) {
                return Api.getEmployeeByZipcode({
                    zipcode: this.zipcode,
                });
            }
            return Promise.resolve({ data: undefined });
        },

        onSubmit(): void {
            this.isLoading = true;

            const leadPromise = this.addLead();
            const employeePromise = this.getEmployee();

            Promise.all([leadPromise, employeePromise])
                .then((values) => {
                    if (this.showValuationWeekend) {
                        tracking.trackRawEvent({
                            event: 'trackForm',
                            eventData: {
                                category: 'Formular',
                                action: 'Vurderingsweekend gennemfoert',
                                label: 'Vurderingsweekend',
                                formularStepnavn: 'Kvittering',
                                maeglerId: this.relatedBroker
                                    ? this.employee?.brokerId
                                    : '001',
                            },
                        });
                    } else {
                        tracking.trackRawEvent({
                            event: 'trackForm',
                            eventData: {
                                category: 'Formular',
                                action: 'Salgsvurdering gennemfoert',
                                label: 'Salgsvurdering',
                                formularStepnavn: 'Kvittering',
                                maeglerId: this.relatedBroker
                                    ? this.employee?.brokerId
                                    : '001',
                            },
                        });
                    }

                    if (values[1]?.data) {
                        this.assignedEmployee = values[1].data;
                    }
                    this.incrementStep();
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                    this.focusForm();
                });
        },
    },
};
