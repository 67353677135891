import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';

export default {
    /**
     * Set value of referrer.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setReferrer(state: State, payload: KeyValue): void {
        // console.log('payload: ', payload);
        // Vue.set(state.document, payload.key, payload.value);

        state.document[payload.key] = payload.value;
    },

    /**
     * Set utm source
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setUtmSource(state: State, payload: string): void {
        state.document.utmSource = payload;
    },

    /**
     * Set utm medium
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setUtmMedium(state: State, payload: string): void {
        state.document.utmMedium = payload;
    },

    /**
     * Set utm campaign
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setUtmCampaign(state: State, payload: string): void {
        state.document.utmCampaign = payload;
    },

    /**
     * Set utm content
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setUtmContent(state: State, payload: string): void {
        state.document.utmContent = payload;
    },

    /**
     * Set timestamp
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setTimeStamp(state: State, payload: Date | undefined): void {
        state.document.timestamp = payload;
    },
};
