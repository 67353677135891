
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import tracking from '@/functions/tracking';
import Broker from '@/interfaces/broker.interface';

interface Data {
    brokerId: string;
}

export default {
    props: {
        brokerIntroductionText: {
            type: String as PropType<string>,
            default: () => '',
        },
        ctaText: {
            type: String as PropType<string>,
            default: () => '',
        },
        ctaAltText: {
            type: String as PropType<string>,
            default: () => '',
        },
        broker: {
            type: Object as PropType<Broker>,
            default: () => ({
                imageUrl: '',
            }),
        },
    },
    data(): Data {
        return {
            brokerId: '',
        };
    },
    mounted() {
        this.brokerId = window.homeEstimateBaseData.brokerId;
    },
    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),

        openContactBrokerCurtain(): void {
            this.openModal('brokerContact');
            this.setTrackingEntrance('Boligskoen resultat hero');
            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Salgsvurdering indgang',
                    label: 'Salgsvurdering',
                    formularIndgang: 'Boligskoen resultat hero',
                    formularStepnavn: 'Klik paa modul',
                    maeglerId: this.brokerId ?? '',
                },
            });
        },
    },
};
