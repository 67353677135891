
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'm-info-modal',
    props: {
        headline: {
            type: String as PropType<string>,
            default: () => '',
        },
        content: {
            type: String as PropType<string>,
            default: () => '',
        },
        modalName: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),

        showModal(): boolean {
            return this.show && this.type === this.modalName;
        },
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        /**
         * Open modal
         *
         * @return {void}
         */
        openPopup(): void {
            this.openModal(this.modalName);
        },
    },
};
