import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = {
  key: 2,
  class: "o-tags o-tags--centered"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = {
  key: 3,
  class: "o-buttons o-buttons--centered o-overlayPromoBox__buttons"
}
const _hoisted_9 = ["href", "onClick"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["href", "onClick", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_button = _resolveComponent("m-button")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.promoBox, (item, i) => {
    return (_openBlock(), _createElementBlock("div", {
      key: i,
      class: _normalizeClass(["o-overlayPromoBox", [$options.getModifierClass(item.backgroundColour)]])
    }, [
      (item.headline)
        ? (_openBlock(), _createElementBlock("h3", {
            key: 0,
            class: "a-heading3",
            textContent: _toDisplayString(item.headline)
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      (item.text)
        ? (_openBlock(), _createElementBlock("p", {
            key: 1,
            class: _normalizeClass(
                $options.isBuyersDirectory(item.contentType)
                    ? 'a-small a-small--allowBreak'
                    : 'o-overlayPromoBox__default-text'
            ),
            textContent: _toDisplayString(item.text)
          }, null, 10 /* CLASS, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" BUYERS DIRECTORY PROMOBOX "),
      ($options.isBuyersDirectory(item.contentType))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "m-tag o-overlayPromoBox__tag isDisabled",
              textContent: _toDisplayString(`${item.areaMin} - ${item.areaMax} m2`)
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("div", {
              class: "m-tag o-overlayPromoBox__tag isDisabled",
              textContent: _toDisplayString(item.postalCode)
            }, null, 8 /* PROPS */, _hoisted_5),
            _createElementVNode("div", {
              class: "m-tag o-overlayPromoBox__tag isDisabled",
              textContent: _toDisplayString(item.propertyTypeText)
            }, null, 8 /* PROPS */, _hoisted_6),
            _createElementVNode("div", {
              class: "m-tag o-overlayPromoBox__tag isDisabled",
              textContent: _toDisplayString($options.getPricePill(item.priceMin, item.priceMax))
            }, null, 8 /* PROPS */, _hoisted_7)
          ]))
        : _createCommentVNode("v-if", true),
      ($options.isBuyersDirectory(item.contentType))
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
            _createElementVNode("a", {
              href: `${item.pageUrl}#koberkartotek`,
              class: "m-button",
              onClick: ($event: any) => ($options.onClick('setCriteria', item))
            }, [
              _createElementVNode("span", {
                class: "m-button__text",
                textContent: _toDisplayString(item.buttonText)
              }, null, 8 /* PROPS */, _hoisted_10)
            ], 8 /* PROPS */, _hoisted_9)
          ]))
        : _createCommentVNode("v-if", true),
      ($options.isBuyersDirectory(item.contentType) && item.linkText)
        ? (_openBlock(), _createElementBlock("a", {
            key: 4,
            href: `${item.pageUrl}#koberkartotek`,
            class: "a-label a-anchor a-anchor--secondary",
            onClick: ($event: any) => ($options.onClick('ownCriteria', item)),
            textContent: _toDisplayString(item.linkText)
          }, null, 8 /* PROPS */, _hoisted_11))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" IF RECEIPT PROMOBOX m-button--link m-button--underlined "),
      (item.contentType === 'promoBoxFormReceipt')
        ? (_openBlock(), _createElementBlock("div", {
            key: 5,
            class: _normalizeClass(["o-buttons o-buttons--centered o-overlayPromoBox__buttons", 
                item.ctaButtons[1] != null &&
                item.ctaButtons[1].lineBreak &&
                'o-buttons--stacked'
            ])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.ctaButtons, (btn, j) => {
              return (_openBlock(), _createBlock(_component_m_button, {
                key: j,
                class: _normalizeClass(
                    btn.ctaType == 'Tekst link'
                        ? 'm-button--link m-button--underlined'
                        : ''
                ),
                icon: `${btn.icon == null ? '' : btn.icon.name}`,
                url: `${btn.link.url}`,
                target: $options.buttonTarget(btn.link.target),
                theme: $options.buttonTheme(btn.ctaType),
                "ga-category": btn.gaCategory,
                "ga-action": btn.gaAction,
                "ga-label": btn.gaLabel,
                "ga-indgangsmetode": btn.gaIndgangsmetode,
                "button-text": btn.link.name
              }, null, 8 /* PROPS */, ["class", "icon", "url", "target", "theme", "ga-category", "ga-action", "ga-label", "ga-indgangsmetode", "button-text"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */))
  }), 128 /* KEYED_FRAGMENT */))
}