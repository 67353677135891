import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "m-select__wrapper" }
const _hoisted_2 = ["for", "textContent"]
const _hoisted_3 = ["id", "value", "name"]
const _hoisted_4 = ["value", "textContent"]
const _hoisted_5 = ["aria-expanded"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["onFocus", "onClick"]
const _hoisted_8 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-select", [
            $options.modifierClass,
            {
                hasError: $props.error,
                hasIcon: $options.hasIcon,
                isDisabled: $options.isDisabled,
                isEmpty: $options.isEmpty,
                isFocused: $data.isFocused,
            },
        ]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_icon, {
        class: "m-select__iconPrepend",
        name: $props.icon
      }, null, 8 /* PROPS */, ["name"]),
      ($props.options[0].value === '')
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "visuallyhidden",
            for: $data.uuid,
            textContent: _toDisplayString($props.options[0].label)
          }, null, 8 /* PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      _createElementVNode("select", {
        id: $data.uuid,
        ref: "select",
        class: "a-select m-select__field",
        value: $props.value,
        name: $props.name,
        onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onFocus && $options.onFocus(...args))),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, optionIndex) => {
          return (_openBlock(), _createElementBlock("option", {
            key: optionIndex,
            value: option.value,
            textContent: _toDisplayString(option.label)
          }, null, 8 /* PROPS */, _hoisted_4))
        }), 128 /* KEYED_FRAGMENT */))
      ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_3),
      _createVNode(_component_a_icon, {
        class: "m-select__iconAppend",
        name: "arrow_drop_down"
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["m-select__customWrapper", { isOpen: $data.open }]),
        onBlur: _cache[7] || (_cache[7] = ($event: any) => ($data.open = false))
      }, [
        _createElementVNode("button", {
          ref: "trigger",
          type: "button",
          class: "m-select__customTrigger",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($options.onTriggerClick && $options.onTriggerClick(...args)), ["stop"])),
          onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.onTriggerKeydown && $options.onTriggerKeydown(...args))),
          "aria-expanded": $data.open ? 'true' : 'false'
        }, [
          _createVNode(_component_a_icon, {
            class: "m-select__customTriggerIcon",
            name: $props.icon
          }, null, 8 /* PROPS */, ["name"]),
          _createElementVNode("span", {
            class: "m-select__customTriggerText",
            textContent: _toDisplayString($options.selected)
          }, null, 8 /* PROPS */, _hoisted_6),
          _createVNode(_component_a_icon, {
            class: "m-select__customTriggerIcon",
            name: "arrow_drop_down"
          })
        ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_5),
        _createElementVNode("div", {
          class: _normalizeClass(["m-select__customOptions", { isOpen: $data.open }]),
          onMousemove: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.onOptionsMousemove && $options.onOptionsMousemove(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, optionIndex) => {
            return (_openBlock(), _createElementBlock("button", {
              ref_for: true,
              ref: "option",
              key: optionIndex,
              type: "button",
              class: _normalizeClass(["m-select__customOption", { isSelected: $options.selected == option.label }]),
              onKeydown: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onOptionKeydown && $options.onOptionKeydown(...args))),
              onFocus: ($event: any) => ($data.selectedIndex = optionIndex),
              onClick: ($event: any) => ($options.onOptionClick(option.value))
            }, _toDisplayString(option.label), 43 /* TEXT, CLASS, PROPS, NEED_HYDRATION */, _hoisted_7))
          }), 128 /* KEYED_FRAGMENT */))
        ], 34 /* CLASS, NEED_HYDRATION */)
      ], 34 /* CLASS, NEED_HYDRATION */)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($props.error)
          ? (_openBlock(), _createElementBlock("small", {
              key: 0,
              class: _normalizeClass(["a-error m-select__error", { 'a-error--bright': $props.useBrightError }]),
              textContent: _toDisplayString($props.error)
            }, null, 10 /* CLASS, PROPS */, _hoisted_8))
          : _createCommentVNode("v-if", true)
      ], undefined, true),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}