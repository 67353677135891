import State from './interface';

export default {
    /**
     * Add visited article to list.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    addVisited(state: State, payload: number): void {
        state.visited = [...state.visited, payload];
    },
};
