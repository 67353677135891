import { CancelTokenSource } from 'axios';
import Filter from '@/interfaces/filter.interface';
import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';

export default {
    /**
     * Add a filter to the list.
     *
     * @param {State} state
     * @param {Filter} payload
     * @return {void}
     */
    addFilter(state: State, payload: Filter): void {
        state.filters.push(payload);
    },

    /**
     * Remove a filter from the list.
     *
     * @param {State} state
     * @param {Filter} payload
     * @return {void}
     */
    removeFilter(state: State, payload: Filter): void {
        state.filters = state.filters.filter((filter: Filter): boolean => {
            return (
                (payload.key !== undefined && filter.key !== payload.key) ||
                (payload.value !== undefined && filter.value !== payload.value)
            );
        });
    },

    /**
     * Set value.
     *
     * @param {State} state
     * @param {KeyValue} payload
     * @return {void}
     */
    setValue(state: State, payload: KeyValue): void {
        // Vue.set(state, payload.key, payload.value);

        state[payload.key] = payload.value;
    },

    /**
     * Update a filter in the list.
     *
     * @param {State} state
     * @param {Filter} payload
     * @return {void}
     */
    updateFilter(state: State, payload: Filter): void {
        state.filters = state.filters.map((filter: Filter): Filter => {
            if (filter.key === payload.key) {
                return payload;
            }

            return filter;
        });
    },

    /**
     * Clear filters in the list.
     *
     * @param {State} state
     * @param {Filter} payload
     * @return {void}
     */
    clearFilters(state: State): void {
        state.filters = [];
    },

    /**
     * Set value of cancelToken.
     *
     * @param {State} state
     * @param {CancelTokenSource} value
     * @return {void}
     */
    setPropertiesListCancelToken(
        state: State,
        value?: CancelTokenSource,
    ): void {
        state.propertiesListCancelToken = value;
    },

    /**
     * Set value of cancelToken.
     *
     * @param {State} state
     * @param {CancelTokenSource} value
     * @return {void}
     */
    setPropertiesMapCancelToken(state: State, value?: CancelTokenSource): void {
        state.propertiesMapCancelToken = value;
    },

    setListIsCanceled(state: State, value: boolean): void {
        state.listIsCanceled = value;
    },

    setIsDrawingArea(state: State, value: boolean): void {
        state.isDrawingArea = value;
    },
};
