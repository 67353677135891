import { ActionContext } from 'vuex';

export default {
    /**
     * Close modal.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    close({ commit }: ActionContext<any, any>): void {
        document.body.style.overflow = '';

        commit('setFixed', false);
        commit('setShow', false);
        commit('setType', '');
        commit('setTrackingEntrance', null);
        commit('setTrackingLabel', null);

        if (window.location.hash) {
            let hash = window.location.hash.toLowerCase();
            if (hash === '#koberkartotek' || hash === '#bestilboligskoen') {
                let uri = window.location.toString();
                let cleanUri = uri.substring(0, uri.indexOf('#'));
                window.history.replaceState({}, document.title, cleanUri);
            }
        }
    },

    /**
     * Open modal.
     *
     * @param {ActionContext} context
     * @param {string} type
     * @return {void}
     */
    open({ commit }: ActionContext<any, any>, type: string): void {
        document.body.style.overflow = 'hidden';

        commit('setShow', true);
        commit('setType', type);

        if (type === 'navigation') {
            commit('setTheme', 'o-modal--primary');
        } else if (type === 'homeEstimate') {
            commit('setTheme', 'o-modal--fullWidth');
        } else if (type.includes('popup')) {
            commit('setTheme', 'o-modal--popup');
        } else {
            commit('setTheme', '');
        }
    },

    /**
     * Set fixed panel.
     *
     * @param {any} state
     * @param {boolean} value
     * @return {void}
     */
    setFixed({ commit }: ActionContext<any, any>, value: boolean): void {
        commit('setFixed', value);
    },

    /**
     * Set trackingEntrance.
     *
     * @param {any} state
     * @param {string} value
     * @return {void}
     */
    setTrackingEntrance(
        { commit }: ActionContext<any, any>,
        value: string | null,
    ): void {
        commit('setTrackingEntrance', value);
    },

    /**
     * Set trackingLabel.
     *
     * @param {any} state
     * @param {string} value
     * @return {void}
     */
    setTrackingLabel(
        { commit }: ActionContext<any, any>,
        value: string | null,
    ): void {
        commit('setTrackingLabel', value);
    },
};
