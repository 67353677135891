
import { mapActions, mapGetters } from 'vuex';
import mTag from '@/components/molecules/form/Tag.vue';
import PoiCategory from '@/interfaces/POICategory.interface';
import PoiCategories from '@/constants/poiCategories.const';
import tracking from '@/functions/tracking';

interface Data {
    poiCategories: PoiCategory[];
}

export default {
    components: {
        mTag,
    },

    data(): Data {
        return {
            poiCategories: PoiCategories,
        };
    },

    computed: {
        ...mapGetters({
            showFavorites: 'pointsOfInterest/showFavorites',
            selectedPois: 'pointsOfInterest/selectedPois',
            selectorsEnabled: 'pointsOfInterest/selectorsEnabled',
        }),
    },

    methods: {
        ...mapActions({
            setShowFavorites: 'pointsOfInterest/setShowFavorites',
            setSelectedPois: 'pointsOfInterest/setSelectedPois',
        }),

        updateFavoritesState() {
            this.setShowFavorites(!this.showFavorites);
            tracking.trackRawEvent({
                event: 'trackBoligpraesentation',
                eventData: {
                    category: 'Boligpraesentation',
                    action: `Kort - ${this.showFavorites ? 'Aktiver' : 'Deaktiver'} pille`,
                    label: 'Lokale favoritsteder',
                },
            });
        },

        updateSelectedPois(payload: PoiCategory): void {
            this.setSelectedPois(payload);
            const newCategoryState = this.selectedPois.some(
                (x: PoiCategory) => x.key === payload.key,
            );
            tracking.trackRawEvent({
                event: 'trackBoligpraesentation',
                eventData: {
                    category: 'Boligpraesentation',
                    action: `Kort - ${newCategoryState ? 'Aktiver' : 'Deaktiver'} pille`,
                    label: payload.name,
                },
            });
        },
    },
};
