import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-neighborhoodMap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_map = _resolveComponent("m-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_m_map, {
      class: "o-neighborhoodMap__map",
      brokers: $data.brokers,
      center: [$data.longitude, $data.latitude],
      "do-favorite-clusters": true,
      favorites: $data.favorites,
      "fit-neighborhoods": !!$props.municipalityId || !!$props.neighborhoodId,
      "municipality-polygon": $props.polygon,
      "is-loading": $data.isLoading,
      "max-bounds": $data.maxBounds,
      neighborhoods: $data.neighborhoods,
      properties: $data.properties,
      "map-type": 'neighborhoodmap',
      zoom: $data.zoom,
      texts: $props.texts,
      onLoad: $options.onLoad,
      onMove: $options.onMove
    }, null, 8 /* PROPS */, ["brokers", "center", "favorites", "fit-neighborhoods", "municipality-polygon", "is-loading", "max-bounds", "neighborhoods", "properties", "zoom", "texts", "onLoad", "onMove"])
  ]))
}