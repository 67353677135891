
export default {
    name: 'a-icon',
    props: {
        name: {
            type: String,
            required: true,
            default: () => '',
        },
    },
};
