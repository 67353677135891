import { CancelTokenSource } from 'axios';
import Coordinates from '@/interfaces/coordinates.interface';
import Filter from '@/interfaces/filter.interface';
import Property from '@/interfaces/property.interface';
import State from './interface';
import { ResponseTypes } from '@/models/propertiesList.model';

export default {
    /**
     * Get state of activeProperty.
     *
     * @param {State} state
     * @return {string | null}
     */
    activeProperty(state: State): string | null {
        return state.activeProperty;
    },

    /**
     * Get state of area.
     *
     * @param {State} state
     * @return {number[][]}
     */
    area(state: State): number[][] {
        return state.area;
    },

    /**
     * Get state of bounds.
     *
     * @param {State} state
     * @return {number[][]}
     */
    bounds(state: State): number[][] {
        return state.bounds;
    },

    /**
     * Get list of filters.
     *
     * @param {State} state
     * @return {Filter[]}
     */
    filters(state: State): Filter[] {
        return state.filters;
    },

    /**
     * Get state of map fitProperties.
     *
     * @param {State} state
     * @return {boolean}
     */
    fitProperties(state: State): boolean {
        return state.fitProperties;
    },

    /**
     * Get state of latitude.
     *
     * @param {State} state
     * @return {number}
     */
    latitude(state: State): number {
        return state.latitude;
    },

    /**
     * Get loading state of list results.
     *
     * @param {State} state
     * @return {boolean}
     */
    listIsLoading(state: State): boolean {
        return state.initialLoad || state.listIsLoading;
    },

    /**
     * Get state of list results.
     *
     * @param {State} state
     * @return {ResponseTypes[]}
     */
    listResults(state: State): Array<ResponseTypes> {
        return state.listResults;
    },

    /**
     * Get state of map longitude.
     *
     * @param {State} state
     * @return {number}
     */
    longitude(state: State): number {
        return state.longitude;
    },

    /**
     * Get state of mapHasInteraction.
     *
     * @param {State} state
     * @return {boolean}
     */
    mapHasInteraction(state: State): boolean {
        return state.mapHasInteraction;
    },

    /**
     * Get loading state of map results.
     *
     * @param {State} state
     * @return {boolean}
     */
    mapIsLoading(state: State): boolean {
        return state.initialLoad || state.mapIsLoading;
    },

    /**
     * Get state of map results.
     *
     * @param {State} state
     * @return {Property[]}
     */
    mapResults(state: State): Property[] {
        return state.mapResults;
    },

    /**
     * Get state of order.
     *
     * @param {State} state
     * @return {string}
     */
    order(state: State): string {
        return state.order;
    },

    /**
     * Get state of query.
     *
     * @param {State} state
     * @return {string}
     */
    query(state: State): string {
        return state.query;
    },

    /**
     * Get state of redirect.
     *
     * @param {State} state
     * @return {boolean}
     */
    redirect(state: State): boolean {
        return state.redirect;
    },

    /**
     * Get state of totalCount.
     *
     * @param {State} state
     * @return {number}
     */
    totalCount(state: State): number {
        return state.totalCount;
    },

    /**
     * Get state of users location.
     *
     * @param {State} state
     * @return {Coordinates | null}
     */
    userLocation(state: State): Coordinates | null {
        return state.userLocation;
    },

    /**
     * Get state of view.
     *
     * @param {State} state
     * @return {string}
     */
    view(state: State): string {
        return state.view;
    },

    /**
     * Get state of zoom.
     *
     * @param {State} state
     * @return {number}
     */
    zoom(state: State): number {
        return state.zoom;
    },

    /**
     * Get state of cancelToken.
     *
     * @param {State} state
     * @return {string}
     */
    propertiesListCancelToken(state: State): CancelTokenSource | undefined {
        return state.propertiesListCancelToken;
    },

    /**
     * Get state of cancelToken.
     *
     * @param {State} state
     * @return {string}
     */
    propertiesMapCancelToken(state: State): CancelTokenSource | undefined {
        return state.propertiesMapCancelToken;
    },

    listIsCanceled(state: State): boolean {
        return state.listIsCanceled;
    },

    isDrawingArea(state: State): boolean {
        return state.isDrawingArea;
    },
};
