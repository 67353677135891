
export default {
    name: 'm-skeleton-loader',
    props: {
        name: {
            type: String,
            required: false,
            default: () => '',
        },
    },

    computed: {
        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.name ? `skeleton-loader--${this.name}` : '';
        },
    },
};
