import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "m-productCard" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.product.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $props.product.icon.relativeUrl,
          width: "100",
          height: "100",
          alt: $props.product.icon.altText
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    ($props.product.headline)
      ? (_openBlock(), _createElementBlock("h3", {
          key: 1,
          class: "a-heading3",
          textContent: _toDisplayString($props.product.headline)
        }, null, 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true),
    ($props.product.text)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          class: "a-paragraph",
          textContent: _toDisplayString($props.product.text)
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ]))
}