
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import tracking from '@/functions/tracking';
import CurrentUser from '@/interfaces/currentUser.interface';
import Employee from '@/interfaces/employee.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Format from '@/functions/format';
import oEmployeeCard from '@/components/organisms/broker/EmployeeCard.vue';

export interface Texts {
    sendMessageMenuName?: string;
    sendMessageHeadingText?: string;
    sendMessageDescriptionText?: string;
    sendMessageSubmitButtonText?: string;
    formReceiptSuccessHeadingText?: string;
    formReceiptSuccessMessageText?: string;
    form?: {
        mail: string;
        message: string;
        name: string;
        offer: string;
        phone: string;
        address: string;
    };
}

interface Consents {
    contactConsent: LeadConsent;
    salesValuationConsent: LeadConsent;
    buyerAdviceConsent: LeadConsent;
}

interface Data {
    currentDirection: string;
    errors: {
        mail?: string;
        message?: string;
        name?: string;
        phone?: string;
    };
    form: {
        mail: string;
        message: string;
        name: string;
        phone: string;
        address: string;
    };
    isLoading: boolean;
    successMessage: string;
    addressFound: boolean;
    error: boolean;
    isFocused: boolean;
}

interface EventData {
    [key: string]: any;
}

export default {
    components: {
        oEmployeeCard,
    },
    props: {
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                employeeId: '',
                brokerId: '',
                imageUrl: '',
            }),
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                sendMessageMenuName: '',
                formReceiptSuccessMessageText: '',
                sendMessageSubmitButtonText: '',
                formReceiptSuccessHeadingText: '',
                sendMessageDescriptionText: '',
                sendMessageHeadingText: '',
                form: {
                    mail: '',
                    message: '',
                    name: '',
                    offer: '',
                    phone: '',
                    address: '',
                },
            }),
        },
        consents: {
            type: Object as PropType<Consents>,
            default: () => ({
                contactConsent: {
                    id: '',
                    purposeText: '',
                },
            }),
        },
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                message: '',
                email: '',
                fullName: '',
            }),
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            errors: {},
            form: {
                mail: this.user?.email ?? '',
                message: this.user?.message ?? '',
                name: this.user?.fullName ?? '',
                phone: '',
                address: '',
            },
            isLoading: false,
            successMessage: '',
            addressFound: false,
            isFocused: false,
            error: false,
        };
    },

    computed: {
        ...mapGetters({
            currentPanel: 'broker/currentPanel',
            currentSubPanel: 'broker/currentSubPanel',
            fixedPanel: 'modal/fixed',
            modalShow: 'modal/show',
            modalType: 'modal/type',
            trackingEntrance: 'modal/trackingEntrance',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow && this.modalType === 'broker';
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'broker/setCurrentPanel',
            setCurrentSubPanel: 'broker/setCurrentSubPanel',
        }),

        formattedSuccessMessage(successMessage: string): string {
            return (
                successMessage
                    .replace('#navn#', this.form.name)
                    .replace('#mail#', this.form.mail)
                    .replace('#telefon#', this.form.phone) ?? ''
            );
        },

        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },

        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },

        getTrackingText(value: string): string {
            let returnValue = '';
            switch (value) {
                case 'sendMessage':
                    returnValue = 'Skriv til os';
                    break;
                default:
                    returnValue = '';
                    break;
            }
            return returnValue;
        },

        /**
         * Go to the next panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        nextPanel(value: string, track: boolean): void {
            this.currentDirection = 'next';
            this.setCurrentPanel(value);
            if (track) {
                const trackingEventData: EventData = {
                    event: 'trackForm',
                    eventData: {
                        category: 'Formular',
                        action: `${this.getTrackingText(value)} initieret`,
                        label: `${this.getTrackingText(value)}`,
                        formularIndgang: this.trackingEntrance ?? '',
                        formularStepnavn: 'Brugerinformation',
                        maeglerId: this.employee.brokerId ?? '',
                    },
                };

                tracking.trackRawEvent(trackingEventData);
            }
        },

        /**
         * Update value in form.
         *
         * @param {string} key
         * @param {string} value
         * @return {void}
         */
        onChange(key: string, value: string): void {
            // Vue.set(this.form, key, value);

            this.form[key] = value;
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(value: string): void {
            this.isLoading = true;

            let excludeValidation: string[] = [];
            let type = '';
            let id = '';
            let trackingAction = '';
            let trackingLabel = '';
            switch (value) {
                case 'sendMessage':
                    type = LeadTypes.FindBuyer.toString();
                    id = this.consents?.contactConsent?.id ?? '';
                    trackingAction = 'Find koeber kontakt gennemfoert';
                    trackingLabel = 'Kontakt maegler';
                    break;
                default:
                    break;
            }

            Api.postLead(
                {
                    consentIdGlobal: id,
                    address: this.form.address ?? '',
                    email: this.form.mail.trim() ?? '',
                    firstName: Format.firstName(this.form.name) ?? '',
                    lastName: Format.lastName(this.form.name) ?? '',
                    message: this.form.message ?? '',
                    phoneNumber: this.form.phone ?? '',
                    responsibleEmployeeIdGlobal:
                        this.employee?.employeeId ?? '',
                    responsibleShopNo: this.employee?.brokerId ?? '',
                    type,
                    httpReferral: this.referrer,
                    utmCampaign: this.utmCampaign,
                    utmContent: this.utmContent,
                    utmMedium: this.utmMedium,
                    utmSource: this.utmSource,
                },
                excludeValidation,
            )
                .then((): void => {
                    this.nextPanel('successMessage', false);

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: trackingAction,
                            label: trackingLabel,
                            formularIndgang: 'CTA modul',
                            formularStepnavn: 'Kvittering',
                            maeglerId: this.employee.brokerId ?? '',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },

        /**
         * Go to the previous panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        previousPanel(value: string): void {
            this.currentDirection = 'prev';
            this.setCurrentPanel(value);
        },
    },
};
