import { ActionContext } from 'vuex';
import { debounce } from 'lodash';

import { QueryNames } from '@/enums/querynames.enum';
import { DEBOUNCE } from '@/constants/search.const';

import Api from '@/functions/api';
import Query from '@/functions/query';
import BrokersMapResponse from '@/interfaces/responses/brokersMapResponse.interface';

export default {
    /**
     * Fetch list of properties for list view.
     *
     * @param {ActionContext} context
     * @param {boolean} ignorePolygon
     * @return {void}
     */
    fetchResults: debounce(({ commit, state }: any) => {
        if (state.isLoading) {
            return;
        }

        commit('setValue', { key: 'isLoading', value: true });
        commit('setValue', { key: 'results', value: [] });
        commit('setValue', { key: 'totalCount', value: 0 });

        let encodedQuery = encodeURIComponent(state.query);

        Api.getBrokers({
            query: encodedQuery,
        })
            .then((response: BrokersMapResponse) => {
                commit('setValue', { key: 'results', value: response.results });
                commit('setValue', {
                    key: 'totalCount',
                    value: response.totalCount,
                });
            })
            .finally(() => {
                commit('setValue', { key: 'initialLoad', value: false });
                commit('setValue', { key: 'isLoading', value: false });
            });
    }, DEBOUNCE),

    /**
     * Set value of activeBroker.
     *
     * @param {ActionContext} context
     * @param {string | null} value
     * @return {void}
     */
    setActiveBroker(
        { commit }: ActionContext<any, any>,
        value: string | null,
    ): void {
        commit('setValue', { key: 'activeBroker', value });
    },

    /**
     * Set value of query.
     *
     * @param {ActionContext} context
     * @param {String} value
     * @return {Void}
     */
    setQuery(
        { commit, dispatch }: ActionContext<any, any>,
        value: string,
    ): void {
        commit('setValue', { key: 'query', value });
        dispatch('fetchResults');

        Query.update(QueryNames.query, value);
    },

    /**
     * Set value of view.
     *
     * @param {ActionContext} context
     * @param {string} value
     * @return {void}
     */
    setView({ commit }: ActionContext<any, any>, value: string): void {
        commit('setValue', { key: 'view', value });
    },

    /**
     * Toggle the current view.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    toggleView({ dispatch, state }: ActionContext<any, any>): void {
        if (state.view === 'list') {
            dispatch('setView', 'map');
        } else {
            dispatch('setView', 'list');
        }
    },
};
