import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src", "srcset", "width", "height", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_skeleton_loader = _resolveComponent("m-skeleton-loader")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($data.isLoading)
      ? (_openBlock(), _createBlock(_component_m_skeleton_loader, {
          key: 0,
          name: "gallerySkeletonLoader"
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("img", {
          key: `thumbnail-${$props.index}`,
          class: "o-propertyGallery__galleryImageListImage",
          src: `${$props.url}&${$props.dimensions[0]}`,
          srcset: `${$props.url}&${$props.dimensions[0]} 208w, ${$props.url}&${$props.dimensions[1]} 416w`,
          width: `${$props.width}`,
          height: `${$props.height}`,
          alt: $props.alt,
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onLoad && $options.onLoad(...args)))
        }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
      ], undefined, true),
      _: 1 /* STABLE */
    })
  ]))
}