
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        closeLabel: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    mounted(): void {
        document.addEventListener('keydown', (e) => {
            if (e.code === 'Escape') {
                this.close();
            }
        });
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            theme: 'modal/theme',
        }),
    },

    methods: {
        ...mapActions({
            close: 'modal/close',
        }),
    },
};
