import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-brokerSalesValuation" }
const _hoisted_2 = {
  key: 0,
  class: "o-brokerSalesValuation__stepWrapper"
}
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "o-brokerSalesValuation__inputWrapper" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "o-brokerSalesValuation__navigation" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: 1,
  class: "o-brokerSalesValuation__stepWrapper"
}
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "o-brokerSalesValuation__confirmationCheckmark" }
const _hoisted_14 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_input = _resolveComponent("m-input")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.step === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            class: "a-label a-label--white a-label--centered o-brokerSalesValuation__trumpet",
            textContent: _toDisplayString($props.texts.trumpet)
          }, null, 8 /* PROPS */, _hoisted_3),
          _createElementVNode("h2", {
            class: "a-heading1 a-heading1--white o-brokerSaleEstimate__headline o-brokerSalesValuation__headline",
            textContent: _toDisplayString($props.texts.headline)
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("p", {
            class: "a-paragraph a-paragraph--white o-brokerSalesValuation__body",
            textContent: _toDisplayString($props.texts.body)
          }, null, 8 /* PROPS */, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_m_input, {
              value: $data.form.address,
              class: "m-input__field o-brokerSalesValuation__input o-brokerSalesValuation__input--dawa",
              name: "address‌_search",
              "use-bright-error": true,
              error: $data.errors.address,
              label: `${$props.formSettings.addressPlaceholder}*`,
              type: "dawa",
              onDawa: $options.enableSubmit,
              onDawaReset: $options.disableSubmit,
              onInput: $options.setAddressInfo,
              onFocus: _cache[0] || (_cache[0] = ($event: any) => ($options.setIsFocused(true))),
              onBlur: _cache[1] || (_cache[1] = ($event: any) => ($options.setIsFocused(false)))
            }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.name,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.name) = $event)),
              class: "m-input__field o-brokerSalesValuation__input",
              name: "name",
              "use-bright-error": true,
              error: $data.errors.name,
              label: `${$props.formSettings.namePlaceholder}*`,
              onInput: $options.checkFormInteraction
            }, null, 8 /* PROPS */, ["modelValue", "error", "label", "onInput"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.phone,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.phone) = $event)),
              class: "m-input__field o-brokerSalesValuation__input",
              name: "phone",
              "use-bright-error": true,
              error: $data.errors.phone,
              label: `${$props.formSettings.phonePlaceholder}*`,
              onInput: $options.checkFormInteraction
            }, null, 8 /* PROPS */, ["modelValue", "error", "label", "onInput"]),
            _createVNode(_component_m_input, {
              modelValue: $data.form.mail,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.mail) = $event)),
              class: "m-input__field o-brokerSalesValuation__input",
              name: "mail",
              "use-bright-error": true,
              type: "email",
              error: $data.errors.mail,
              label: `${$props.formSettings.mailPlaceholder}*`,
              onInput: $options.checkFormInteraction
            }, null, 8 /* PROPS */, ["modelValue", "error", "label", "onInput"])
          ]),
          _createElementVNode("small", {
            class: "a-small a-small--white a-small--allowBreak o-brokerSalesValuation__consent",
            innerHTML: $props.consent.purposeText
          }, null, 8 /* PROPS */, _hoisted_7),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: _normalizeClass(["m-button o-brokerSalesValuation__inputButton", { isLoading: $data.isLoading }]),
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
            }, [
              _createElementVNode("span", {
                class: "m-button__text",
                textContent: _toDisplayString($props.texts.buttonText)
              }, null, 8 /* PROPS */, _hoisted_9)
            ], 2 /* CLASS */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    ($data.step === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("span", {
            class: "a-label a-label--white a-label--centered o-brokerSalesValuation__trumpet",
            textContent: _toDisplayString($props.texts.receiptTrumpet)
          }, null, 8 /* PROPS */, _hoisted_11),
          _createElementVNode("h2", {
            class: "a-heading1 a-heading1--white o-brokerSaleEstimate__headline o-brokerSalesValuation__headline",
            textContent: _toDisplayString($options.formattedConfirmation)
          }, null, 8 /* PROPS */, _hoisted_12),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_a_icon, { name: "check" })
          ]),
          _createElementVNode("p", {
            class: "a-label a-label--white o-brokerSalesValuation__body",
            textContent: _toDisplayString($props.texts.receiptBody)
          }, null, 8 /* PROPS */, _hoisted_14)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}