
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import mSelect from '@/components/molecules/form/Select.vue';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Format from '@/functions/format';
import LeadConsent from '@/interfaces/leadConsent.interface';
import Employee from '@/interfaces/employee.interface';
import CurrentUser from '@/interfaces/currentUser.interface';
import tracking from '@/functions/tracking';

export interface Texts {
    message: string;
    name: string;
    mail: string;
    phone: string;
    linkText: string;
    linkUrl: string;
    submitButtonText: string;
    heading: string;
    bodyText: string;
    neighbourhoodName: string;
    municipalityName: string;
    headingReceipt: string;
    bodyReceipt: string;
}

export interface BrokerContact {
    heading: string;
    text: string;
    broker: {
        description: string;
        buttonText: string;
        screenReaderText: string;
    };
    hasOneBroker: string;
    popupHeading: string;
    popupDescription: string;
    dropdownDefaultText: string;
}

interface Data {
    currentDirection: string;
    currentSubPanel: number;
    errors: {
        mail?: string;
        message?: string;
        name?: string;
        phone?: string;
        responsibleShopNo?: string;
    };
    form: {
        mail: string;
        message: string;
        name: string;
        phone: string;
        selectedEmployee: string;
    };
    isLoading: boolean;
    successMessage: string;
}

export default {
    components: {
        mSelect,
    },

    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                message: '',
                name: '',
                mail: '',
                phone: '',
                linkText: '',
                linkUrl: '',
                submitButtonText: '',
                neighbourhoodName: '',
                municipalityName: '',
                headingReceipt: '',
                bodyReceipt: '',
            }),
        },
        employees: {
            type: Array as PropType<Employee[]>,
            default: () => [],
        },
        brokerContact: {
            type: Object as PropType<BrokerContact>,
            default: () => ({
                heading: '',
                text: '',
                broker: {
                    description: '',
                    buttonText: '',
                    screenReaderText: '',
                },
                hasOneBroker: '',
                popupHeading: '',
                popupDescription: '',
                dropdownDefaultText: '',
            }),
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                email: '',
                fullName: '',
            }),
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            currentSubPanel: 1,
            errors: {},
            form: {
                mail: this.user?.email ?? '',
                message: '',
                name: this.user?.fullName ?? '',
                phone: '',
                selectedEmployee: '',
            },
            isLoading: false,
            successMessage: '',
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        showModal(): boolean {
            return this.show && this.type === 'neighborhoodBrokerContact';
        },

        /**
         * Create array of broker stores for select.
         *
         * @return {array}
         */
        brokerStores(): any {
            const brokers = this.employees.map(
                (employee: Employee, employeeIndex: number): any => ({
                    label: employee.brokerName,
                    value: employeeIndex.toString(),
                }),
            );

            return [
                {
                    label: `${this.brokerContact.dropdownDefaultText}*`,
                    value: '',
                },
                ...brokers,
            ];
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    mounted(): void {
        if (this.employees.length === 1) {
            this.form.selectedEmployee = '0';
        }
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        /**
         * Go to the next panel/screen.
         *
         * @return {void}
         */
        open(): void {
            this.openModal('neighborhoodBrokerContact');

            tracking.track(
                'trackForm',
                'Formular',
                'Kontakt maegler initieret',
                'Kontakt maegler',
                {
                    formularIndgang: 'Nabolag',
                    formularStepnavn: 'Brugerinformation',
                    maeglerId:
                        this.employees[parseInt(this.form.selectedEmployee)]
                            ?.brokerId ?? '',
                },
            );
        },

        /**
         * Update value in form.
         *
         * @param {string} key
         * @param {string} value
         * @return {void}
         */
        onChange(key: string, value: string): void {
            // Vue.set(this.form, key, value);

            this.form[key] = value;
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(): void {
            this.isLoading = true;

            Api.postLead({
                consentIdGlobal: this.consent?.id ?? '',
                email: this.form.mail.trim() ?? '',
                firstName: Format.firstName(this.form.name) ?? '',
                lastName: Format.lastName(this.form.name) ?? '',
                message: this.form.message ?? '',
                phoneNumber: this.form.phone ?? '',
                responsibleShopNo:
                    this.employees[parseInt(this.form.selectedEmployee)]
                        ?.brokerId ?? '',
                responsibleEmployeeIdGlobal:
                    this.employees[parseInt(this.form.selectedEmployee)]
                        ?.employeeId ?? '',
                type: LeadTypes.ContactSpecific.toString(),
                comment: `Kontakt vedrørende nabolag: ${this.texts.neighbourhoodName}. Kommune: ${this.texts.municipalityName}.`,
                httpReferral: this.referrer,
                utmCampaign: this.utmCampaign,
                utmContent: this.utmContent,
                utmMedium: this.utmMedium,
                utmSource: this.utmSource,
            })
                .then(() => {
                    this.successMessage = this.texts.bodyReceipt
                        .replace('#navn#', this.form.name)
                        .replace('#mail#', this.form.mail.trim())
                        .replace('#telefon#', this.form.phone);
                    this.currentDirection = 'next';
                    this.currentSubPanel = 2;

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Kontakt maegler gennemfoert',
                            label: 'Kontakt maegler',
                            formularIndgang: 'Nabolag',
                            formularStepnavn: 'Kvittering',
                            maeglerId:
                                this.employees[
                                    parseInt(this.form.selectedEmployee)
                                ]?.brokerId ?? '',
                        },
                    });
                })
                .catch((error: any) => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
