
import { PropType } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { EventBus } from '../../../functions/eventBus';

interface Data {
    isFocused: boolean;
    selectedIndex: number;
    open: boolean;
    uuid: string;
}

export default {
    name: 'm-select',
    props: {
        default: {
            type: String as PropType<string>,
            required: false,
            default: () => null,
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        error: {
            type: String as PropType<string>,
            default: () => '',
        },
        icon: {
            type: String as PropType<string>,
            default: () => '',
        },
        options: {
            type: Array as PropType<{ label: string; value: string }[]>,
            required: true,
            default: () => [],
        },
        name: {
            type: String as PropType<string>,
            default: () => '',
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        value: {
            type: String as PropType<string | null>,
            default: () => '',
        },
        useBrightError: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
    },

    data(): Data {
        return {
            isFocused: false,
            selectedIndex: 0,
            open: false,
            uuid: '',
        };
    },

    computed: {
        /**
         * Check if component has a icon.
         *
         * @return {boolean}
         */
        hasIcon(): boolean {
            return this.icon !== '';
        },

        /**
         * Check if component is disabled.
         *
         * @return {boolean}
         */
        isDisabled(): boolean {
            return this.disabled !== false;
        },

        /**
         * Check if component is empty.
         *
         * @return {boolean}
         */
        isEmpty(): boolean {
            return !this.value;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-select--${this.theme}` : '';
        },

        /**
         * Check which option is selected.
         *
         * @return {string}
         */
        selected(): string {
            if (this.options.length) {
                if (this.value) {
                    return (
                        (
                            this.options as { label: string; value: string }[]
                        ).find((option) => option.value === this.value)
                            ?.label ?? ''
                    );
                }

                return this.options[0].label;
            }

            return '';
        },
    },

    mounted() {
        this.uuid = `guid-${uuidv4()}`;
        if (this.options.length > 0) {
            (this.$refs.select as HTMLSelectElement).options[0].selected = true;
        }
    },

    methods: {
        /**
         * Close custom select.
         *
         * @return {void}
         */
        close(): void {
            this.open = false;
            (this.$refs.trigger as HTMLButtonElement).focus();
        },

        /**
         * Focus selected item.
         *
         * @return {void}
         */
        focusItem(): void {
            setTimeout(() => {
                (this.$refs.option as HTMLButtonElement[])[
                    this.selectedIndex
                ].focus();
            }, 10);
        },

        /**
         * Move selected item down.
         *
         * @return {void}
         */
        moveDown(): void {
            if (this.selectedIndex === this.options.length - 1) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex += 1;
            }
            (this.$refs.option as HTMLButtonElement[])[
                this.selectedIndex
            ].focus();
        },

        /**
         * Move selected item up.
         *
         * @return {void}
         */
        moveUp(): void {
            if (this.selectedIndex === 0) {
                this.selectedIndex = this.options.length - 1;
            } else {
                this.selectedIndex -= 1;
            }
            (this.$refs.option as HTMLButtonElement[])[
                this.selectedIndex
            ].focus();
        },

        /**
         * Handle blur event.
         *
         * @return {void}
         */
        onBlur(): void {
            this.isFocused = false;

            this.$emit('blur');
        },

        /**
         * Handle change event.
         *
         * @return {void}
         */
        onChange(): void {
            this.$emit('input', (this.$refs.select as HTMLSelectElement).value);
        },

        /**
         * Handle focus event.
         *
         * @return {void}
         */
        onFocus(): void {
            this.isFocused = true;

            this.$emit('focus');
        },

        /**
         * Handle "click" event on option.
         *
         * @param {string} value
         * @return {void}
         */
        onOptionClick(value: string): void {
            this.$emit('input', value);
            (this.$refs.trigger as HTMLButtonElement).focus();
            this.open = false;
        },

        /**
         * Handle options mousemove event.
         *
         * @return {void}
         */
        onOptionsMousemove(): void {
            (this.$refs.option as HTMLButtonElement[])[
                this.selectedIndex
            ].blur();
        },

        /**
         * Handle trigger click event.
         *
         * @return {void}
         */
        onTriggerClick(): void {
            this.open = !this.open;

            if (this.open) {
                EventBus.$on('app.click', () => {
                    this.open = false;
                });
            } else {
                EventBus.$off('app.click');
            }
        },

        /**
         * Handle trigger keydown event.
         *
         * @return {void}
         */
        onTriggerKeydown(event: KeyboardEvent): void {
            switch (event.key) {
                case 'Enter':
                    this.focusItem();
                    break;
                case 'Escape':
                    this.close();
                    break;
                default:
                    break;
            }
        },

        /**
         * Handle option keydown event.
         *
         * @return {void}
         */
        onOptionKeydown(event: KeyboardEvent): void {
            switch (event.key) {
                case 'ArrowUp':
                    this.moveUp();
                    break;
                case 'ArrowDown':
                    this.moveDown();
                    break;
                case 'Escape':
                    this.close();
                    break;
                default:
                    break;
            }
        },
    },
};
