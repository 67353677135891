import actions from './property/actions';
import getters from './property/getters';
import mutations from './property/mutations';
import state from './property/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
