
import { PropType } from 'vue';

interface Data {
    currentIndex: number | null;
}

export default {
    props: {
        items: {
            type: Array as PropType<any[]>,
            default: () => ([]),
        },
    },

    data(): Data {
        return {
            currentIndex: null,
        };
    },

    methods: {
        onClickToggle(index: number): void {
            this.currentIndex = (this.currentIndex === index) ? null : index;
        },
    },
};
