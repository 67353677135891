
import { PropType } from 'vue';

export interface Texts {
    heroHeadline: string;
    heroText: string;
    buyersCount: string;
    ctaText: string;
}

export interface brokerEmployee {
    address: string;
    brokerName: string;
    maeglerId: string;
}

export default {
    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                heroHeadline: '',
                heroText: '',
                buyersCount: '',
                ctaText: '',
            }),
        },
        images: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        brokerEmployee: {
            type: Object as PropType<brokerEmployee>,
            default: () => ({
                address: '',
                brokerName: '',
                maeglerId: '',
            }),
        },
    },
};
