import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "o-moduleStickyAnchors",
  ref: "stickyAnchors"
}
const _hoisted_2 = { class: "o-moduleStickyAnchors__buttons o-buttons" }
const _hoisted_3 = ["href", "aria-label", "onClick"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.anchors, (anchor, anchorIndex) => {
        return (_openBlock(), _createElementBlock("a", {
          key: anchorIndex,
          href: `#${anchor.link}`,
          "aria-label": anchor.text,
          class: "m-button m-button--transparent o-moduleStickyAnchors__button",
          onClick: ($event: any) => ($options.trackAnchor(anchorIndex))
        }, [
          _createElementVNode("span", {
            class: "m-button__text",
            textContent: _toDisplayString(anchor.text)
          }, null, 8 /* PROPS */, _hoisted_4),
          _createVNode(_component_a_icon, {
            class: "m-button__icon",
            name: anchor.icon
          }, null, 8 /* PROPS */, ["name"])
        ], 8 /* PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 512 /* NEED_PATCH */))
}