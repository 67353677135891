import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "m-horizontalChart"
}
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["id"]
const _hoisted_5 = {
  key: 1,
  class: "m-horizontalChart"
}
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($data.neighborhoodData && $data.neighborhoodData.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.neighborhoodData, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `horizontalChart-${index.toString()}-${item.id.toString()}`,
            class: "m-horizontalChart__barWrapper"
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(["a-label m-horizontalChart__chartText", { 'a-label--white': !$props.secondary }]),
              textContent: _toDisplayString(item.name)
            }, null, 10 /* CLASS, PROPS */, _hoisted_2),
            _createElementVNode("p", {
              class: _normalizeClass(["a-label m-horizontalChart__chartValue", { 'a-label--white': !$props.secondary }]),
              textContent: _toDisplayString(item.votes + $props.suffix)
            }, null, 10 /* CLASS, PROPS */, _hoisted_3),
            _createElementVNode("div", {
              class: _normalizeClass(["m-horizontalChart__barBackground", {
                    'm-horizontalChart__barBackground--secondary': $props.secondary,
                }])
            }, [
              (item.votes > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["m-horizontalChart__bar", { 'm-horizontalChart__bar--secondary': $props.secondary }]),
                    style: _normalizeStyle({
                        width:
                            ((item.votes * 100) / $options.neighborhoodDatasetMaxValue) *
                                0.95 +
                            '%',
                    })
                  }, [
                    _createElementVNode("div", {
                      id: `label-${item.id.toString()}`,
                      class: "m-horizontalChart__label a-small--white",
                      textContent: '+1'
                    }, null, 8 /* PROPS */, _hoisted_4)
                  ], 6 /* CLASS, STYLE */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dataset, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `horizontalChart-${i.toString()}-${$props.labels[i]}`,
            class: "m-horizontalChart__barWrapper"
          }, [
            _createElementVNode("p", {
              class: _normalizeClass(["a-label m-horizontalChart__chartText", { 'a-label--white': !$props.secondary }]),
              textContent: _toDisplayString($props.labels[i])
            }, null, 10 /* CLASS, PROPS */, _hoisted_6),
            _createElementVNode("p", {
              class: _normalizeClass(["a-label m-horizontalChart__chartValue", { 'a-label--white': !$props.secondary }]),
              textContent: _toDisplayString(item + $props.suffix)
            }, null, 10 /* CLASS, PROPS */, _hoisted_7),
            _createElementVNode("div", {
              class: _normalizeClass(["m-horizontalChart__barBackground", {
                    'm-horizontalChart__barBackground--secondary': $props.secondary,
                }])
            }, [
              (item > 0)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["m-horizontalChart__bar", { 'm-horizontalChart__bar--secondary': $props.secondary }]),
                    style: _normalizeStyle({ width: ((item * 100) / $options.maxValue) * 0.95 + '%' })
                  }, [
                    _createElementVNode("div", {
                      id: `label-${item.toString()}`,
                      class: "m-horizontalChart__label a-small--white",
                      textContent: '+1'
                    }, null, 8 /* PROPS */, _hoisted_8)
                  ], 6 /* CLASS, STYLE */))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
}