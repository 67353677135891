import Broker from '@/interfaces/broker.interface';
import State from '@/store/brokerSearch/interface';

export default {
    /**
     * Get state of activeBroker.
     *
     * @param {State} state
     * @return {string | null}
     */
    activeBroker(state: State): string | null {
        return state.activeBroker;
    },

    /**
     * Get loading state of list results.
     *
     * @param {State} state
     * @return {boolean}
     */
    isLoading(state: State): boolean {
        return state.initialLoad || state.isLoading;
    },

    /**
     * Get state of query.
     *
     * @param {State} state
     * @return {string}
     */
    query(state: State): string {
        return state.query;
    },

    /**
     * Get state of results.
     *
     * @param {State} state
     * @return {Broker[]}
     */
    results(state: State): Broker[] {
        return state.results;
    },

    /**
     * Get state of totalCount.
     *
     * @param {State} state
     * @return {number}
     */
    totalCount(state: State): number {
        return state.totalCount;
    },

    /**
     * Get state of view.
     *
     * @param {State} state
     * @return {string}
     */
    view(state: State): string {
        return state.view;
    },
};
