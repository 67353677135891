import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "m-doughnutChart" }
const _hoisted_2 = { class: "m-doughnutChart__wrapper" }
const _hoisted_3 = {
  ref: "doughnut",
  class: "m-doughnutChart__doughnut"
}
const _hoisted_4 = { class: "m-doughnutChart__legendWrapper" }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("canvas", _hoisted_3, null, 512 /* NEED_PATCH */),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.primaryDataset, (elem, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `doughnutChart-${i.toString()}-${$props.labels[i]}`,
            class: "m-doughnutChart__legend"
          }, [
            _createElementVNode("div", {
              class: "m-doughnutChart__legendColor",
              style: _normalizeStyle({ backgroundColor: $data.backgroundColors[i] })
            }, null, 4 /* STYLE */),
            _createElementVNode("p", {
              class: "a-label a-label--white m-doughnutChart__legendText",
              textContent: _toDisplayString(`${elem}${$props.suffix} ${$props.labels[i] ?? ''}`)
            }, null, 8 /* PROPS */, _hoisted_5)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}