import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["m-checkbox", [
                $options.modifierClass,
                { isChecked: $options.isChecked, isDisabled: $options.isDisabled },
            ]]),
      disabled: $options.isDisabled || null,
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onClick()))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["m-checkbox__field", [$options.modifierClass, { hasError: $props.error }]])
      }, [
        _createVNode(_component_a_icon, {
          name: "check",
          class: "m-checkbox__icon"
        })
      ], 2 /* CLASS */),
      ($props.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: _normalizeClass(["a-label m-checkbox__label", [$options.fontModifierClass]]),
            innerHTML: $props.label
          }, null, 10 /* CLASS, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true)
    ], 10 /* CLASS, PROPS */, _hoisted_1),
    ($props.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("small", {
            class: "a-error m-input__error",
            textContent: _toDisplayString($props.error)
          }, null, 8 /* PROPS */, _hoisted_4)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}