type LngLatLike = {
    lng: number;
    lat: number;
};
export const DEBOUNCE = 500;
export const USER_ESTIMATE_DEBOUNCE = 1000;

export const BOUNDS = [
    [-90, -90],
    [90, -90],
    [90, 90],
    [-90, 90],
    [-90, -90],
];

export const LATITUDE: number = 56.1798691;

export const LONGITUDE: number = 10.4697395;

export const MAX_BOUNDS: [LngLatLike, LngLatLike] = [
    { lat: 53.3, lng: 6 },
    { lat: 59.0, lng: 17.25 },
];

export const ZOOM: number = 6;

// These are all default values from "Køberkartotek" in Mindworking
export const FILTER_DEFAULTS = {
    priceMin: 0,
    priceMax: 20_000_000,

    distanceCoastMin: 0,
    distanceCoastMax: 5_000,

    distanceSupermarketMin: 0,
    distanceSupermarketMax: 5_000,

    distancePublicTransportationMin: 0,
    distancePublicTransportationMax: 5_000,

    distanceSchoolMin: 0,
    distanceSchoolMax: 5_000,

    distanceDaycareMin: 0,
    distanceDaycareMax: 5_000,

    houseAreaMin: 0,
    houseAreaMax: 300,

    roomCountMin: 0,
    roomCountMax: 8,

    floorNumberMin: 0,
    floorNumberMax: 50,

    plotAreaMin: 0,
    plotAreaMax: 10_000,

    floorCountMin: 0,
    floorCountMax: 4,

    yearBuiltMin: 1900,
    yearBuiltMax: new Date().getFullYear(),
} as const;

export const SEARCH_ENERGY_LABELS: Record<number, string> = {
    100: 'A',
    200: 'B',
    300: 'C',
    400: 'D',
    500: 'E',
    600: 'F',
    700: 'G',
} as const;

export const FILTER_NAMES: Record<string, string> = {
    PropertyType: 'PropertyType',
    EnergyLabel: 'EnergyLabel',
    BuiltYear: 'BuiltYear',
    PropertyForm: 'PropertyForm',
    DistanceToWater: 'DistanceToWater',
    DistanceToSchool: 'DistanceToSchool',
    DistanceToShopping: 'DistanceToShopping',
    DistanceToDaycare: 'DistanceToDaycare',
    DistanceToPublicTransport: 'DistanceToPublicTransport',
    Price: 'Price',
    Propertysize: 'Propertysize',
    Groundsize: 'Groundsize',
    Rooms: 'Rooms',
    Plan: 'Plan',
    Floor: 'Floor',
    Facilities: 'Facilities',
} as const;
