import { ActionContext } from 'vuex';
import PoiCategory from '@/interfaces/POICategory.interface';
import POI from '@/interfaces/POI.interface';
import State, { Route, TransportationMethod } from './interface';

export default {
    setCurrentPoi(
        { commit }: ActionContext<any, State>,
        payload: POI | undefined,
    ): void {
        commit('setCurrentPoi', payload);
    },
    setCurrentRoutes(
        { commit }: ActionContext<any, State>,
        payload: Route[],
    ): void {
        commit('setCurrentRoutes', payload);
    },
    setSelectorsEnabled(
        { commit }: ActionContext<any, State>,
        payload: boolean,
    ): void {
        commit('setSelectorsEnabled', payload);
    },
    setShowFavorites(
        { commit }: ActionContext<any, State>,
        payload: boolean,
    ): void {
        commit('setShowFavorites', payload);
    },
    setSelectedPois(
        { commit }: ActionContext<any, State>,
        payload: PoiCategory,
    ): void {
        commit('setSelectedPois', payload);
    },

    setSelectedTransportationMethod(
        { commit }: ActionContext<any, State>,
        payload: TransportationMethod,
    ): void {
        commit('setSelectedTransportationMethod', payload);
    },
};
