
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/functions/eventBus';
import Dawa from '@/interfaces/dawa.interface';
import Api from '@/functions/api';
import tracking from '@/functions/tracking';

export interface Texts {
    helpText: string;
    imageUrl: string;
    imageAltText: string;
    buttonText: string;
    buttonUrl: string;
    placeholder: string;
    ariaLabel: string;
    errorMessage: string;
}

interface Data {
    addressFound: boolean;
    address: string;
    dawa: Dawa | undefined;
    error: boolean;
    isLoading: boolean;
    isFocused: boolean;
}

export default {
    props: {
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                helpText: '',
                imageUrl: '',
                imageAltText: '',
                buttonText: '',
                buttonUrl: '',
                placeholder: '',
                ariaLabel: '',
                errorMessage: '',
            }),
        },
    },

    data(): Data {
        return {
            isFocused: false,
            isLoading: false,
            addressFound: false,
            address: '',
            dawa: undefined,
            error: false,
        };
    },

    computed: {
        ...mapGetters({
            show: 'modal/show',
            type: 'modal/type',
        }),

        /**
         * Check if estimate teaser modal is to be shown.
         *
         * @return {boolean}
         */
        showEstimateTeaserInfo(): boolean {
            return this.show && this.type === 'estimateTeaser';
        },
    },

    mounted() {
        EventBus.$on('app.keyup', (keycode: string) => {
            if (keycode === 'Enter' && this.addressFound && this.isFocused) {
                this.onSubmit();
            }
        });
    },

    unmounted() {
        EventBus.$off('app.keyup');
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
        }),

        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },

        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },

        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            this.error = false;
            if (this.addressFound) {
                this.dawa = value;
                this.address = value.tekst;
            }
        },

        /**
         * Open modal
         *
         * @return {void}
         */
        onClickEstimateTeaserInfo(): void {
            this.openModal('estimateTeaser');
        },

        /**
         * Handle submit event.
         *
         * @return {void}
         */
        onSubmit(): void {
            if (this.dawa) {
                const id = this.dawa.data.id;
                const url = `${this.texts.buttonUrl}?id=${id}`;

                this.isLoading = true;

                Api.hasPropertyEstimate({
                    addressId: id,
                })
                    .then((response: any) => {
                        if (response.exists === true) {
                            tracking.trackRawEvent({
                                event: 'trackForm',
                                eventData: {
                                    category: 'Formular',
                                    action: 'Boligskoen indgang',
                                    label: 'Boligskoen',
                                },
                            });
                            window.location.href = url;
                        } else {
                            this.error = true;
                            this.isLoading = false;
                        }
                    })
                    .catch(() => {
                        this.error = true;
                        this.isLoading = false;
                    });
            }
        },
    },
};
