import PoiCategory from '@/interfaces/POICategory.interface';
import POI from '@/interfaces/POI.interface';
import State, { FullRoute, TransportationMethod } from './interface';

export default {
    setCurrentPoi(state: State, payload: POI | undefined): void {
        state.currentPoi = payload;
    },
    setCurrentRoutes(state: State, payload: FullRoute[]): void {
        state.currentRoutes = payload.map((route) => {
            return {
                ...route,
                routePolyline: state.currentPoi
                    ? [
                          ...route.routePolyline,
                          [
                              state.currentPoi.poilatlng[1],
                              state.currentPoi.poilatlng[0],
                          ],
                      ]
                    : route.routePolyline,
            };
        });
    },

    setShowFavorites(state: State, payload: boolean): void {
        state.showFavorites = payload;
    },

    setSelectedPois(state: State, payload: PoiCategory): void {
        if (state.selectedPois.some((e) => e.key === payload.key)) {
            state.selectedPois = state.selectedPois.filter(
                (item) => item.key !== payload.key,
            );
        } else {
            state.selectedPois = [...state.selectedPois, payload];
        }
    },

    setSelectedTransportationMethod(
        state: State,
        payload: TransportationMethod,
    ): void {
        state.selectedTransportationMethod = payload;
    },
    setSelectorsEnabled(state: State, payload: boolean): void {
        state.selectorsEnabled = payload;
    },
};
