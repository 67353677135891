interface RawResponse {
    brokerContact: {
        broker: {
            description: string;
        };
    };
    buyersDirectory: {
        buttonText: string;
        buyerCount: string;
        description: string;
        heading: string;
        screenReaderText: string;
    };
}

export default class EstimateRequest {
    broker = {
        description: '',
    };

    buyers = {
        buttonText: '',
        count: '',
        description: '',
        heading: '',
        screenReaderText: '',
    };

    /**
     * @constructor
     * @param {RawResponse} response
     */
    constructor(response: RawResponse) {
        this.broker.description = response.brokerContact.broker.description;
        this.buyers.buttonText = response.buyersDirectory.buttonText;
        this.buyers.count = response.buyersDirectory.buyerCount;
        this.buyers.description = response.buyersDirectory.description;
        this.buyers.heading = response.buyersDirectory.heading;
        this.buyers.screenReaderText = response.buyersDirectory.screenReaderText;
    }
}
