
import { PropType } from 'vue';
import { EventBus } from '@/functions/eventBus';

interface Data {
    isDesktop: boolean;
    showMore: boolean;
    characters: number;
}

export default {
    props: {
        characterLimit: {
            type: Number as PropType<number>,
            default: () => 400,
        },
    },

    data(): Data {
        return {
            isDesktop: EventBus.isDesktop,
            showMore: false,
            characters: this.$props.characterLimit,
        };
    },

    beforeMount(): void {
        this.isDesktop = EventBus.isDesktop;
    },

    mounted(): void {
        const wrapper = (this.$refs.wrapper as HTMLElement);
        const button = (this.$refs.trigger as HTMLButtonElement);

        if (wrapper && button) {
            if (wrapper.innerText.length >= this.characters) {
                wrapper.classList.add('isTruncated');
                button.style.display = 'block';
            }
        }
    },

    methods: {
        onClick(): void {
            const wrapper = (this.$refs.wrapper as HTMLDivElement);
            const button = (this.$refs.trigger as HTMLButtonElement);

            if (wrapper && button) {
                this.showMore = !this.showMore;
                button.classList.toggle('isRevealed');
                wrapper.classList.toggle('isRevealed');
                setTimeout(() => {
                    wrapper.classList.toggle('isTruncated');
                }, 350);
            }
        },
    },
};
