
import { EventBus } from '@/functions/eventBus';

interface Data {
    isNotOverflown: boolean;
    isOverflownLeft: boolean;
    isOverflownRight: boolean;
    wrapperElement: HTMLElement | null;
    contentElement: HTMLElement | null;
}

export default {
    name: 'm-table',
    data(): Data {
        return {
            isNotOverflown: false,
            isOverflownLeft: false,
            isOverflownRight: false,
            wrapperElement: null,
            contentElement: null,
        };
    },

    mounted(): void {
        this.wrapperElement = this.$el.querySelector(
            '[data-wrapper]',
        ) as HTMLDivElement;
        this.contentElement = this.$el.querySelector(
            '[data-content]',
        ) as HTMLDivElement;

        EventBus.$on('app.resize', this.calculateOverflow);

        this.wrapperElement?.addEventListener('scroll', this.calculateOverflow);
        this.wrapperElement?.dispatchEvent(new Event('scroll'));
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
        this.wrapperElement?.removeEventListener(
            'scroll',
            this.calculateOverflow,
        );
    },

    methods: {
        /**
         * Calculate if table is overflowing wrapper.
         *
         * @return {void}
         */
        calculateOverflow(): void {
            let wrapperRectBounds = this.wrapperElement?.getBoundingClientRect();
            let contentRectBounds = this.contentElement?.getBoundingClientRect();

            this.isOverflownLeft = Math.ceil(contentRectBounds.left)
                < Math.floor(wrapperRectBounds.left);
            this.isOverflownRight = Math.floor(contentRectBounds.right)
                > Math.ceil(wrapperRectBounds.right);
            this.isNotOverflown = !this.isOverflownLeft && !this.isOverflownRight;
        },
    },
};
