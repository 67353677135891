import { renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _renderSlot(_ctx.$slots, "default", {
    isMounted: $data.isMounted,
    activeItem: $data.activeItem,
    onMenuItemClick: $options.onMenuItemClick,
    onToggle: $options.onToggle,
    onMobileUserAccountClick: $options.onMobileUserAccountClick,
    showUserAccountMobileMenu: $data.showUserAccountMobileMenu
  })
}