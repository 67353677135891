import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-hiddenText" }
const _hoisted_2 = {
  ref: "wrapper",
  class: "o-hiddenText__wrapper"
}
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", { ref: "content" })
    ], 512 /* NEED_PATCH */),
    _createElementVNode("button", {
      ref: "trigger",
      type: "button",
      class: "a-anchor a-anchor--secondary o-hiddenText__trigger",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args))),
      textContent: _toDisplayString($data.showMore ? 'Se mindre' : 'Læs mere')
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}