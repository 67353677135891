import { CancelTokenSource } from 'axios';
import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';

const factors: { [key: string]: any } = {
    location: [
        {
            maxYear: 1979,
            values: [
                -0.22, -0.15, -0.11, -0.07, -0.04, 0, 0.04, 0.07, 0.11, 0.15,
                0.22,
            ],
        },
        {
            maxYear: 1995,
            values: [
                -0.22, -0.15, -0.11, -0.07, -0.04, 0, 0.04, 0.07, 0.11, 0.15,
                0.22,
            ],
        },
        {
            maxYear: 3000,
            values: [
                -0.22, -0.15, -0.11, -0.07, -0.04, 0, 0.04, 0.07, 0.11, 0.15,
                0.22,
            ],
        },
    ],
    exteriorCondition: [
        {
            maxYear: 1979,
            values: [
                -0.2, -0.15, -0.11, -0.07, -0.04, 0, 0.04, 0.07, 0.11, 0.15,
                0.2,
            ],
        },
        {
            maxYear: 1995,
            values: [
                -0.15, -0.1125, -0.0825, -0.525, -0.03, 0, 0.03, 0.0525, 0.082,
                0.1125, 0.15,
            ],
        },
        {
            maxYear: 3000,
            values: [
                -0.1, -0.075, -0.055, -0.035, -0.02, 0, 0.02, 0.035, 0.055,
                0.075, 0.1,
            ],
        },
    ],
    interiorCondition: [
        {
            maxYear: 1979,
            values: [
                -0.1, -0.08, -0.06, -0.04, -0.02, 0, 0.02, 0.04, 0.06, 0.08,
                0.1,
            ],
        },
        {
            maxYear: 1995,
            values: [
                -0.1, -0.08, -0.06, -0.04, -0.02, 0, 0.02, 0.04, 0.06, 0.08,
                0.1,
            ],
        },
        {
            maxYear: 3000,
            values: [
                -0.1, -0.08, -0.06, -0.04, -0.02, 0, 0.02, 0.04, 0.06, 0.08,
                0.1,
            ],
        },
    ],
    energyLevel: [
        {
            maxYear: 1979,
            values: [
                -0.05, -0.04, -0.03, -0.02, -0.01, 0, 0.01, 0.02, 0.03, 0.04,
                0.05,
            ],
        },
        {
            maxYear: 1995,
            values: [
                -0.05, -0.04, -0.03, -0.02, -0.01, 0, 0.01, 0.02, 0.03, 0.04,
                0.05,
            ],
        },
        {
            maxYear: 3000,
            values: [
                -0.05, -0.04, -0.03, -0.02, -0.01, 0, 0.01, 0.02, 0.03, 0.04,
                0.05,
            ],
        },
    ],
    amenityValue: [
        {
            maxYear: 1979,
            values: [
                -0.05, -0.04, -0.03, -0.02, -0.01, 0, 0.01, 0.02, 0.03, 0.04,
                0.05,
            ],
        },
        {
            maxYear: 1995,
            values: [
                -0.05, -0.04, -0.03, -0.02, -0.01, 0, 0.01, 0.02, 0.03, 0.04,
                0.05,
            ],
        },
        {
            maxYear: 3000,
            values: [
                -0.05, -0.04, -0.03, -0.02, -0.01, 0, 0.01, 0.02, 0.03, 0.04,
                0.05,
            ],
        },
    ],
};

export default {
    /**
     * Set value of bank estimate.
     *
     * @param {State} state
     * @param {number} value
     * @return {void}
     */
    setBankEstimate(state: State, value: number): void {
        // Vue.set(state.estimates, 'bank', value);

        state.estimates.bank = value;
    },

    /**
     * Set value of broker description.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setBrokerDescription(state: State, value: string): void {
        // Vue.set(state.broker, 'description', value);

        state.broker.description = value;
    },

    /**
     * Set value of broker image.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setBrokerImage(state: State, value: string): void {
        // Vue.set(state.broker, 'image', value);

        state.broker.image = value;
    },

    /**
     * Set value of property buildYear.
     *
     * @param {State} state
     * @param {number} value
     * @return {void}
     */
    setBuildYear(state: State, value: number): void {
        // Vue.set(state.property, 'buildYear', value);

        state.property.buildYear = value;
    },

    /**
     * Set value of property address.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setAddress(state: State, value: string): void {
        // Vue.set(state.property, 'address', value);

        state.property.address = value;
    },

    /**
     * Set value of property zipCode.
     *
     * @param {State} state
     * @param {number} value
     * @return {void}
     */
    setZipCode(state: State, value: number): void {
        // Vue.set(state.property, 'zipCode', value);

        state.property.zipCode = value;
    },

    /**
     * Set value of property city.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setCity(state: State, value: string): void {
        // Vue.set(state.property, 'city', value);

        state.property.city = value;
    },

    /**
     * Set value of buyers buttonText.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setBuyersButtonText(state: State, value: string): void {
        // Vue.set(state.buyers, 'buttonText', value);

        state.buyers.buttonText = value;
    },

    /**
     * Set value of buyers count.
     *
     * @param {State} state
     * @param {number} value
     * @return {void}
     */
    setBuyersCount(state: State, value: number): void {
        // Vue.set(state.buyers, 'count', value);

        state.buyers.count = value;
    },

    /**
     * Set value of buyers description.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setBuyersDescription(state: State, value: string): void {
        // Vue.set(state.buyers, 'description', value);

        state.buyers.description = value;
    },

    /**
     * Set value of buyers heading.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setBuyersHeading(state: State, value: string): void {
        // Vue.set(state.buyers, 'heading', value);

        state.buyers.heading = value;
    },

    /**
     * Set value of buyers screenReaderText.
     *
     * @param {State} state
     * @param {string} value
     * @return {void}
     */
    setBuyersScreenReaderText(state: State, value: string): void {
        // Vue.set(state.buyers, 'screenReaderText', value);

        state.buyers.screenReaderText = value;
    },

    /**
     * Set value of cancelToken.
     *
     * @param {State} state
     * @param {CancelTokenSource} value
     * @return {void}
     */
    setCancelToken(state: State, value?: CancelTokenSource): void {
        state.cancelToken = value;
    },

    /**
     * Set the current panel.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setCurrentPanel(state: State, payload: string): void {
        state.overlay.currentPanel = payload;
    },

    /**
     * Set the current sub panel.
     *
     * @param {State} state
     * @param {number} payload
     * @return {void}
     */
    setCurrentSubPanel(state: State, payload: number): void {
        state.overlay.currentSubPanel = payload;
    },

    /**
     * Set value on page path state.
     *
     * @param {State} state
     * @param {KeyValue} payload
     * @return {void}
     */
    setPagePath(state: State, payload: string): void {
        state.pagePath = payload;
    },

    /**
     * Set value on parameters state.
     *
     * @param {State} state
     * @param {KeyValue} payload
     * @return {void}
     */
    setParametersValue(state: State, payload: KeyValue): void {
        // Vue.set(state.parameters, payload.key, payload.value);

        state.parameters[payload.key] = payload.value;
    },

    /**
     * Set value on property state.
     *
     * @param {State} state
     * @param {KeyValue} payload
     * @return {void}
     */
    setPropertyValue(state: State, payload: KeyValue): void {
        // Vue.set(state.property, payload.key, payload.value);

        state.property[payload.key] = payload.value;
    },

    /**
     * Calculate and set the userEstimate.
     *
     * @param {State} state
     * @return {void}
     */
    setUserEstimate(state: State): void {
        let value = state.estimates.bank;
        value
            *= 1
            + parseFloat(
                factors.amenityValue.find(
                    (item: any) => state.property.buildYear <= item.maxYear,
                ).values[state.parameters.amenityValue],
            );
        value
            *= 1
            + parseFloat(
                factors.energyLevel.find(
                    (item: any) => state.property.buildYear <= item.maxYear,
                ).values[state.parameters.energyLevel],
            );
        value
            *= 1
            + parseFloat(
                factors.exteriorCondition.find(
                    (item: any) => state.property.buildYear <= item.maxYear,
                ).values[state.parameters.exteriorCondition],
            );
        value
            *= 1
            + parseFloat(
                factors.interiorCondition.find(
                    (item: any) => state.property.buildYear <= item.maxYear,
                ).values[state.parameters.interiorCondition],
            );
        value
            *= 1
            + parseFloat(
                factors.location.find(
                    (item: any) => state.property.buildYear <= item.maxYear,
                ).values[state.parameters.location],
            );

        // Vue.set(state.estimates, 'user', (Math.round(value / 1000) * 1000));

        state.estimates.user = Math.round(value / 1000) * 1000;
    },
};
