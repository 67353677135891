
import Vue, { PropType, isProxy, toRaw } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import KeyValue from '@/interfaces/keyValue.interface';
import tracking from '@/functions/tracking';
import mTag from '@/components/molecules/form/Tag.vue';
import mRangeSlider from '@/components/molecules/form/RangeSlider.vue';
import BuyerDirectoryLead from '@/interfaces/buyerDirectoryLead.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import { EventBus } from '@/functions/eventBus';
import Format from '@/functions/format';
import Api from '@/functions/api';
import { OriginalFilter } from '../../../models/searchFilters.model';
import format from '../../../functions/format';
import { FILTER_DEFAULTS } from '@/constants/search.const';

export interface ITexts {
    stepOne: {
        headline: string;
        helpText: string;
        buttonText: string;
    };
    stepTwo: {
        headline: string;
        isLoggedInBodyText: string;
        isNotLoggedInBodyText: string;
        saveButtonText: string;
        signupLoginButtonLink: string;
        signupLoginButtonText: string;
    };
    stepFour: {
        headline: string;
        bodyText: string;
        profileLink: string;
        profileLinkText: string;
    };
    backButton: string;
}

export interface IFormData {
    zipCodeLabel: string;
    commentLabel: string;
    nameLabel: string;
    namePlaceholder: string;
}

interface IData {
    currentDirection: string;
    currentGroup: string | null;
    isLoading: boolean;
    isDesktop: boolean;
    form: {
        tags: { [key: string]: string };
        price: number[];
        size: number[];
        zipcode: string;
        comment: string;
        name: string;
    };
    errors: {
        stepOne?: string;
        name?: string;
        zipCode?: string;
    };
    priceComplexInterval: number[];
    houseAreaMin: number;
    houseAreaMax: number;
}

export default {
    components: {
        mTag,
        mRangeSlider,
    },

    props: {
        isLoggedIn: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        redirected: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        texts: {
            type: Object as PropType<ITexts>,
            default: () => ({
                stepOne: {
                    headline: '',
                    helpText: '',
                    buttonText: '',
                },
                stepTwo: {
                    headline: '',
                    isLoggedInBodyText: '',
                    isNotLoggedInBodyText: '',
                    saveButtonText: '',
                    signupLoginButtonText: '',
                    signupLoginButtonLink: '',
                },
                stepFour: {
                    headline: '',
                    bodyText: '',
                    profileLink: '',
                    profileLinkText: '',
                },
                backButton: '',
            }),
        },
        priceFilter: {
            type: Object as PropType<OriginalFilter>,
            default: () => ({
                multiplierComplex: [],
                min: FILTER_DEFAULTS.priceMin,
                max: FILTER_DEFAULTS.priceMax,
            }),
        },
        consent: {
            type: Object as PropType<LeadConsent>,
            default: () => ({
                id: '',
                purposeText: '',
            }),
        },
        tagOptions: {
            type: Object as PropType<{ [key: string]: string }>,
            default: () => ({}),
        },
        formData: {
            type: Object as PropType<IFormData>,
            default: () => ({
                zipCodeLabel: '',
                commentLabel: '',
                nameLabel: '',
                namePlaceholder: '',
            }),
        },
    },

    data(): IData {
        return {
            currentDirection: '',
            currentGroup: null,
            isLoading: false,
            isDesktop: EventBus.isDesktop,
            form: {
                tags: {},
                price: [],
                size: [],
                zipcode: '',
                comment: '',
                name: '',
            },
            priceComplexInterval: [],
            errors: {},
            houseAreaMin: FILTER_DEFAULTS.houseAreaMin,
            houseAreaMax: FILTER_DEFAULTS.houseAreaMax,
        };
    },

    computed: {
        ...mapGetters({
            currentPrice: 'buyerDirectory/currentPrice',
            currentSize: 'buyerDirectory/currentSize',
            currentName: 'buyerDirectory/currentName',
            currentTags: 'buyerDirectory/currentTags',
            currentZipcode: 'buyerDirectory/currentZipcode',
            currentComment: 'buyerDirectory/currentComment',
            currentPanel: 'buyerDirectory/currentPanel',
            modalShow: 'modal/show',
            modalType: 'modal/type',
        }),

        show(): boolean {
            return this.modalShow && this.modalType === 'buyerDirectory';
        },

        currentFilters(): KeyValue[] {
            let filters: KeyValue[] = [];

            if (this.currentTags) {
                filters = [...filters, ...this.currentTags];
            }
            if (this.currentPrice) {
                const fromPrice = (
                    parseInt(this.currentPrice[0]) / 1000000
                ).toString();
                const fromPriceFormatted = fromPrice.slice(
                    0,
                    fromPrice.length === 1
                        ? 1
                        : fromPrice.indexOf('.') === -1
                          ? fromPrice.length
                          : fromPrice.indexOf('.') + 2,
                ); // eslint-disable-line
                const toPrice = (
                    parseInt(this.currentPrice[1]) / 1000000
                ).toString();
                const toPriceFormatted = toPrice.slice(
                    0,
                    toPrice.indexOf('.') === -1
                        ? toPrice.length
                        : toPrice.indexOf('.') + 2,
                );
                filters = [
                    ...filters,
                    {
                        key: 'price',
                        value: `${fromPriceFormatted}-${toPriceFormatted} mio. kr.`,
                    },
                ];
            }
            if (this.currentSize) {
                filters = [
                    ...filters,
                    {
                        key: 'size',
                        value: `${parseInt(this.currentSize[0])}-${parseInt(this.currentSize[1])} m2`,
                    },
                ];
            }
            if (this.currentZipcode) {
                filters = [
                    ...filters,
                    { key: 'zipcode', value: this.currentZipcode },
                ];
            }

            return filters;
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    mounted(): void {
        EventBus.$on('app.resize', () => {
            this.isDesktop = EventBus.isDesktop;
        });

        if (this.currentPanel === 'buyerDirectoryNameSuccess') {
            this.setCurrentPanel('');
            this.reset();
        }

        if (this.currentName === '' && !this.redirected) {
            this.setName(this.formData.namePlaceholder);
        }

        if (isProxy(this.currentSize)) {
            const rawData = toRaw(this.currentSize);
            this.form.size = rawData;
        } else {
            this.form.size = [this.currentSize[0], this.currentSize[1]];
        }

        if (isProxy(this.currentPrice)) {
            const rawData = toRaw(this.currentPrice);
            this.form.price = rawData;
        } else {
            this.form.price = [this.currentPrice[0], this.currentPrice[1]];
        }

        this.form.name = this.currentName;
        this.form.tags = this.currentTags;
        this.form.zipcode = this.currentZipcode;
        this.form.comment = this.currentComment;
        this.form.name = this.currentName;

        if (this.redirected) {
            this.onSubmit('buyerDirectoryNameSuccess');
        }

        if (this.priceFilter && this.priceFilter.multiplierComplex) {
            const interval = format.complexInterval(
                this.priceFilter.multiplierComplex,
                this.priceFilter?.min ?? FILTER_DEFAULTS.priceMin,
                this.priceFilter?.max ?? FILTER_DEFAULTS.priceMax,
            );

            if (interval !== undefined) {
                this.priceComplexInterval = interval;
            }
        }
    },

    methods: {
        ...mapActions({
            reset: 'buyerDirectory/reset',
            setTags: 'buyerDirectory/setTags',
            setPrice: 'buyerDirectory/setPrice',
            setSize: 'buyerDirectory/setSize',
            setZipcode: 'buyerDirectory/setZipcode',
            setComment: 'buyerDirectory/setComment',
            setName: 'buyerDirectory/setName',
            removeFilter: 'buyerDirectory/removeFilter',
            setCurrentPanel: 'buyerDirectory/setCurrentPanel',
            setCurrentSubPanel: 'buyerDirectory/setCurrentSubPanel',
        }),

        capitalize(word: string): string {
            return Format.capitalize(word);
        },

        updateTags(payload: KeyValue): void {
            this.setTags(payload);
            this.form.tags = this.currentTags; // Do I even need this???
        },

        /**
         * Update a input filter.
         *
         * @param {Filter} filter
         * @param {string} value
         * @return {void}
         */
        updateInput(name: string, value: string): void {
            if (name === 'zipcode') {
                this.setZipcode(value);
            }

            if (name === 'comment') {
                this.setComment(value);
            }

            if (name === 'name') {
                this.setName(value);
            }
        },

        /**
         * Update a range filter.
         *
         * @param {Filter} filter
         * @param {[number, number]} value
         * @return {void}
         */
        updateRange(name: string, value: [number, number]): void {
            if (name === 'price') {
                this.setPrice([value[0], value[1]]);
            }

            if (name === 'size') {
                this.setSize([value[0], value[1]]);
            }
        },

        /**
         * Go to the next panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        nextPanel(value: string): void {
            this.validate(value);
        },

        /**
         * Go to the previous panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        previousPanel(value: string): void {
            this.currentDirection = 'prev';
            this.setCurrentPanel(value);
        },

        generateLeadRequest(): BuyerDirectoryLead {
            let tagValues = this.currentTags.map((t: KeyValue) => t.value);

            const buyerDirectoryLead: BuyerDirectoryLead = {
                propertyTypes: tagValues,
                priceFrom: this.form.price[0],
                priceTo: this.form.price[1],
                houseAreaFrom: this.form.size[0],
                houseAreaTo: this.form.size[1],
                specialWishes: this.form.comment,
                zipCodes: this.form.zipcode,
                name: this.form.name,
            };

            return buyerDirectoryLead;
        },

        validate(value: string): void {
            this.isLoading = true;

            const buyerDirectoryLead = this.generateLeadRequest();

            Api.validateBuyerDirectoryLead(buyerDirectoryLead)
                .then(() => {
                    this.currentDirection = 'next';
                    this.setCurrentPanel(value);
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Koeberkartotek indgang',
                            label: 'Koeberkartotek',
                            formularStepnavn: 'Brugerinformation',
                        },
                    });
                })
                .catch((error: any) => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        onSubmit(value: string): void {
            this.isLoading = true;

            const buyerDirectoryLead = this.generateLeadRequest();

            Api.postBuyerDirectoryLead(buyerDirectoryLead)
                .then(() => {
                    this.currentDirection = 'next';
                    this.setCurrentPanel(value);
                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: 'Koeberkartotek gennemfoert',
                            label: 'Koeberkartotek',
                            formularStepnavn: 'Kvittering',
                        },
                    });
                })
                .catch((error: any) => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
