
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import tracking from '@/functions/tracking';

export default {
    name: 'm-buyer-directory-agent-button',
    props: {
        postalCode: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        areaMin: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        areaMax: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        priceMin: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        priceMax: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        propertyTypeId: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        propertyTypeText: {
            type: String as PropType<string>,
            default: () => '',
        },
        pageUrl: {
            type: String as PropType<string>,
            default: () => '/koeberkartotek/',
        },
        loading: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    computed: {
        /**
         * Check if the component is loading.
         *
         * @return {boolean}
         */
        isLoading(): boolean {
            return this.loading !== false;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-button--${this.theme}` : '';
        },

        modifiedUrl(): string {
            return `${this.pageUrl}#koberkartotek`;
        },
    },

    methods: {
        ...mapActions({
            reset: 'buyerDirectory/reset',
            setTags: 'buyerDirectory/setTags',
            setPrice: 'buyerDirectory/setPrice',
            setSize: 'buyerDirectory/setSize',
            setZipcode: 'buyerDirectory/setZipcode',
        }),

        /**
         * Handle click event on button.
         *
         * @return {void}
         */
        onClick(): void {
            this.reset();

            this.setZipcode(this.postalCode.toString());
            this.setPrice([this.priceMin, this.priceMax]);
            this.setSize([this.areaMin, this.areaMax]);
            this.setTags({
                key: this.propertyTypeId,
                value: this.propertyTypeText.toLowerCase(),
            });

            const trackingLabel = (this.$refs.button as HTMLAnchorElement)?.textContent?.trim()
                ?? '';

            let propertyType = (
                document.querySelector(
                    '[data-propertytype]',
                ) as HTMLTableElement
            )?.dataset.propertytype?.trim();
            if (!propertyType || propertyType === '') {
                propertyType = 'Ukendt type';
            }

            tracking.trackRawEvent({
                event: 'trackBoligpraesentation',
                eventData: {
                    category: 'Boligpraesentation',
                    action: 'Koeberkartotek agent klik',
                    label: trackingLabel,
                    type: propertyType,
                },
            });
        },
    },
};
