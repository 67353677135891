import { ActionContext } from 'vuex';
import { debounce } from 'lodash';
import { USER_ESTIMATE_DEBOUNCE } from '@/constants/search.const';
import Api from '@/functions/api';
import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';

export default {
    /**
     * Set state of addressId.
     *
     * @param {ActionContext} context
     * @param {string | null} payload
     * @return {void}
     */
    setAddressId(
        { commit }: ActionContext<any, State>,
        payload: string | null,
    ): void {
        commit('setPropertyValue', { key: 'addressId', value: payload });
    },

    /**
     * Calculate the user estimate using set parameters.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    calculateUserEstimate: debounce(
        ({ commit, state }: ActionContext<any, State>) => {
            commit('setUserEstimate');
            commit('setCancelToken', Api.getCancelToken(state.cancelToken));

            Api.getEstimateRequest({
                addressId: state.property.addressId,
                cancelToken: state.cancelToken,
                price: state.estimates.user,
                pagePath: state.pagePath,
            }).then((response: any) => {
                commit('setCancelToken', null);
                commit(
                    'setBrokerDescription',
                    response.broker.description ?? '',
                );
                commit('setBuyersButtonText', response.buyers.buttonText ?? '');
                commit('setBuyersCount', response.buyers.count ?? '');
                commit(
                    'setBuyersDescription',
                    response.buyers.description ?? '',
                );
                commit('setBuyersHeading', response.buyers.heading ?? '');
                commit(
                    'setBuyersScreenReaderText',
                    response.buyers.screenReaderText ?? '',
                );
            });
        },
        USER_ESTIMATE_DEBOUNCE,
    ),

    /**
     * Close the estimate modal.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    close({ commit }: ActionContext<any, State>): void {
        commit('close');
    },

    /**
     * Open the estimate modal.
     *
     * @param {ActionContext} context
     * @param {string} panel
     * @return {void}
     */
    open({ commit, dispatch }: ActionContext<any, State>, panel: string): void {
        commit('setCurrentPanel', panel);
        commit('setCurrentSubPanel', 1);
        dispatch('modal/open', 'estimate', { root: true });
    },

    /**
     * Set state of bankEstimate.
     *
     * @param {ActionContext} context
     * @param {number} payload
     * @return {void}
     */
    setBankEstimate(
        { commit }: ActionContext<any, State>,
        payload: number,
    ): void {
        commit('setBankEstimate', payload);
    },

    /**
     * Set state of buildYear.
     *
     * @param {ActionContext} context
     * @param {number} payload
     * @return {void}
     */
    setBuildYear({ commit }: ActionContext<any, State>, payload: number): void {
        commit('setBuildYear', payload);
    },

    /**
     * Set state of address.
     *
     * @param {ActionContext} context
     * @param {string} payload
     * @return {void}
     */
    setAddress({ commit }: ActionContext<any, State>, payload: string): void {
        commit('setAddress', payload);
    },

    /**
     * Set state of address.
     *
     * @param {ActionContext} context
     * @param {number} payload
     * @return {void}
     */
    setZipCode({ commit }: ActionContext<any, State>, payload: number): void {
        commit('setZipCode', payload);
    },

    /**
     * Set state of city.
     *
     * @param {ActionContext} context
     * @param {string} payload
     * @return {void}
     */
    setCity({ commit }: ActionContext<any, State>, payload: string): void {
        commit('setCity', payload);
    },

    /**
     * Change the current panel.
     *
     * @param {ActionContext} context
     * @param {string} value
     * @return {void}
     */
    setCurrentPanel(
        { commit }: ActionContext<any, State>,
        value: string,
    ): void {
        commit('setCurrentPanel', value);
    },

    /**
     * Change the current sub panel.
     *
     * @param {ActionContext} context
     * @param {number} panel
     * @return {void}
     */
    setCurrentSubPanel(
        { commit }: ActionContext<any, State>,
        value: number,
    ): void {
        commit('setCurrentSubPanel', value);
    },

    /**
     * Set state of page path.
     *
     * @param {ActionContext} context
     * @param {KeyValue} payload
     * @return {void}
     */
    setPagePath({ commit }: ActionContext<any, State>, payload: string): void {
        commit('setPagePath', payload);
    },

    /**
     * Set state of a parameter.
     *
     * @param {ActionContext} context
     * @param {KeyValue} payload
     * @return {void}
     */
    setParameter(
        { commit }: ActionContext<any, State>,
        payload: KeyValue,
    ): void {
        commit('setParametersValue', {
            key: payload.key,
            value: payload.value,
        });
    },

    /**
     * Set state of broker image.
     *
     * @param {ActionContext} context
     * @param {string} payload
     * @return {void}
     */
    setBrokerImage(
        { commit }: ActionContext<any, State>,
        payload: string,
    ): void {
        commit('setBrokerImage', payload);
    },
};
