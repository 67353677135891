
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import tracking from '@/functions/tracking';

type KeyNumbersData = {
    headline: string;
    leadingParagraph: string;
    ctaText: string;
    ctaAltText: string;
    sourceText: string;
    keyNumbersSmallHeadline: string;
    keyNumbersHeadline: string;
    keyNumbersLeadingParagraph: string;
    keyNumbersPropertiesForSaleTitle: string;
    keyNumbersPropertiesForSaleText: string;
    keyNumbersLayTimeTitle: string;
    keyNumbersLayTimeText: string;
    keyNumbersSquareMeterPriceTitle: string;
    keyNumbersSquareMeterPriceText: string;
};

interface Data {
    brokerId: string;
}

export default {
    props: {
        data: {
            type: Object as PropType<KeyNumbersData>,
            default: () => ({
                headline: '',
                leadingParagraph: '',
                ctaText: '',
                ctaAltText: '',
                sourceText: '',
                keyNumbersSmallHeadline: '',
                keyNumbersHeadline: '',
                keyNumbersLeadingParagraph: '',
                keyNumbersPropertiesForSaleTitle: '',
                keyNumbersPropertiesForSaleText: '',
                keyNumbersLayTimeTitle: '',
                keyNumbersLayTimeText: '',
                keyNumbersSquareMeterPriceTitle: '',
                keyNumbersSquareMeterPriceText: '',
            }),
        },
    },
    data(): Data {
        return {
            brokerId: '',
        };
    },
    mounted() {
        this.brokerId = window.homeEstimateBaseData.brokerId;
    },
    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),

        openContactBrokerCurtain() {
            this.openModal('brokerContact');
            this.setTrackingEntrance('Boligskoen noegletal modul');

            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Salgsvurdering indgang',
                    label: 'Salgsvurdering',
                    formularIndgang: 'Boligskoen noegletal modul',
                    formularStepnavn: 'Klik paa modul',
                    maeglerId: this.brokerId ?? '',
                },
            });
        },
    },
};
