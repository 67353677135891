import actions from './search/actions';
import getters from './search/getters';
import mutations from './search/mutations';
import state from './search/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
