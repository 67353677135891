import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_ctx.$slots.default)
    ? _renderSlot(_ctx.$slots, "default", {
        key: 0,
        onClick: $options.onClick
      })
    : ($props.buttonText)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          ref: "button",
          class: _normalizeClass(["m-button", [
            $options.modifierClass,
            {
                hasIcon: $options.hasIcon,
                isDisabled: $options.isDisabled,
                isLoading: $options.isLoading,
            },
        ]]),
          href: $props.url,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
        }, [
          ($options.hasIcon && $props.iconPosition === 'left')
            ? (_openBlock(), _createBlock(_component_a_icon, {
                key: 0,
                class: "m-button__icon",
                name: $props.icon
              }, null, 8 /* PROPS */, ["name"]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("span", {
            class: "m-button__text",
            textContent: _toDisplayString($props.buttonText)
          }, null, 8 /* PROPS */, _hoisted_2),
          ($options.hasIcon && $props.iconPosition !== 'left')
            ? (_openBlock(), _createBlock(_component_a_icon, {
                key: 1,
                class: "m-button__icon",
                name: $props.icon
              }, null, 8 /* PROPS */, ["name"]))
            : _createCommentVNode("v-if", true)
        ], 10 /* CLASS, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true)
}