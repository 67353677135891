
import { PropType } from 'vue';
import Broker from '@/interfaces/broker.interface';
import oHomeEstimateResponsibleBroker from './HomeEstimateResponsibleBroker.vue';
import tracking from '@/functions/tracking';

interface HomeEstimateResultHero {
    headline: string;
    fullAddress: string;
    brokerIntroductionText: string;
    ctaText: string;
    ctaAltText: string;
    estimateFrom: number;
    estimateTo: number;
    estimateFromLabel: string;
    estimateToLabel: string;
    responsibleBroker: Broker;
}

export default {
    components: {
        oHomeEstimateResponsibleBroker,
    },

    props: {
        data: {
            type: Object as PropType<HomeEstimateResultHero>,
            default: () => ({
                headline: '',
                fullAddress: '',
                brokerIntroductionText: '',
                ctaText: '',
                ctaAltText: '',
                estimateFrom: '',
                estimateTo: '',
                estimateFromLabel: '',
                estimateToLabel: '',
                responsibleBroker: {
                    address: '',
                    brokerId: '',
                    brokerName: '',
                    city: '',
                    email: '',
                    employeeId: '',
                    imageUrl: '',
                    linkedIn: '',
                    name: '',
                    phone: '',
                    title: '',
                    videoUrl: '',
                    type: '',
                    url: '',
                    zipCode: '',
                },
            }),
        },
    },

    mounted() {
        const { homeEstimateBaseData } = window;
        if (homeEstimateBaseData) {
            tracking.trackRawEvent({
                event: 'trackBoligskoenResultater',
                eventData: {
                    category: 'Boligskoen',
                    action: 'Boligskoen valgte svar',
                    udvendig_stand: homeEstimateBaseData.query.exteriorConditionValue,
                    indvendig_stand: homeEstimateBaseData.query.interiorConditionValue,
                    beliggenhed: homeEstimateBaseData.query.locationValue,
                    energi_niveau: homeEstimateBaseData.query.energyLevelValue,
                    herlighedsværdi: homeEstimateBaseData.query.amenityValue,
                    boligmarked_situation: homeEstimateBaseData.segment,
                    bank_estimat: homeEstimateBaseData.bankEstimate,
                },
            });
        }
    },
};
