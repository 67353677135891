import State, { Widget } from './interface';

export default {
    /**
     * Add widget to list.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    addWidget(state: State, payload: Widget): void {
        const exists = state.widgets.find((item) => item.name === payload.name);

        if (exists === undefined) {
            state.widgets = [...state.widgets, payload];
        }
    },

    /**
     * Remove widget from list.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    deleteWidget(state: State, payload: Widget): void {
        state.widgets = [
            ...state.widgets.filter((item) => item.name !== payload.name),
        ];
    },

    /**
     * Set current widget.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setCurrent(state: State, payload: string): void {
        state.current = payload;
    },
};
