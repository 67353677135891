import actions from './estimate/actions';
import getters from './estimate/getters';
import mutations from './estimate/mutations';
import state from './estimate/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
