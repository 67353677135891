import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "teamExpandWrapper",
  class: "u-row o-brokerTeam__hideBrokers"
}
const _hoisted_2 = { class: "u-col-12 md:u-offset-1 md:u-col-10" }
const _hoisted_3 = {
  key: 0,
  class: "o-brokerTeam__expander o-brokerTeam__expander--yellow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_employee_shop_card = _resolveComponent("o-employee-shop-card")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        ref: "teamList",
        class: _normalizeClass(["o-brokerTeam__brokerList o-brokerTeam__brokerList--noHeadline", {
                    'o-brokerTeam__brokerList--lessThanFour': $options.lessThanFour,
                }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.employees, (employee, index) => {
          return (_openBlock(), _createBlock(_component_o_employee_shop_card, {
            key: index,
            employee: employee,
            "cta-text": $props.ctaText,
            "show-blue-buttons": false,
            onClicked: $options.onClickChild
          }, null, 8 /* PROPS */, ["employee", "cta-text", "onClicked"]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 2 /* CLASS */)
    ]),
    ($data.isTeamListExpandable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            ref: "teamExpandButton",
            type: "button",
            class: "o-brokerTeam__expanderButton",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.expand && $options.expand(...args)))
          }, [
            _createVNode(_component_a_icon, { name: "expand_more" })
          ], 512 /* NEED_PATCH */)
        ]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}