import actions from './homeEstimate/actions';
import getters from './homeEstimate/getters';
import mutations from './homeEstimate/mutations';
import state from './homeEstimate/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
