
import Vue, { PropType } from 'vue';
import Api from '@/functions/api';
import Format from '@/functions/format';
import tracking from '@/functions/tracking';
import { EventBus } from '@/functions/eventBus';
import { Breakpoints } from '@/enums/breakpoints.enum';
import GalleryItem from '@/interfaces/galleryItem.interface';
import GalleryItemsResponse from '@/interfaces/responses/galleryItemsResponse.interface';
import mSkeletonLoader from '@/components/molecules/SkeletonLoader.vue';
import mImageGalleryThumbnail from '@/components/molecules/ImageGalleryThumbnail.vue';

interface Texts {
    screenReaderPreviousImage: string;
    screenReaderNextImage: string;
    screenReaderClose: string;
    screenReaderStartGallery: string;
    openFullScreen: string;
    closeFullScreen: string;
}

interface Data {
    currentIndex: number;
    type: string;
    isVisible: boolean;
    galleryItems: GalleryItem[];
    isDesktop: boolean;
    hasTouch: boolean;
    initialLoad: boolean;
    isLoading: boolean;
    galleryTimeout: number | null;
    isFullscreen: boolean;
    hasInteractedWithGallery: boolean;
}

export default {
    components: {
        mSkeletonLoader,
        mImageGalleryThumbnail,
    },
    props: {
        projectId: {
            type: String as PropType<string>,
            default: () => '',
        },
        caseNo: {
            type: String as PropType<string>,
            default: () => '',
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                screenReaderPreviousImage: '',
                screenReaderNextImage: '',
                screenReaderClose: '',
                screenReaderStartGallery: '',
                openFullScreen: '',
                closeFullScreen: '',
            }),
        },
    },

    data(): Data {
        return {
            currentIndex: 0,
            type: 'Image',
            isVisible: false,
            initialLoad: true,
            galleryItems: [],
            isDesktop: EventBus.isDesktop,
            hasTouch: EventBus.hasTouch,
            isLoading: false,
            galleryTimeout: null,
            isFullscreen: false,
            hasInteractedWithGallery: false,
        };
    },

    computed: {
        /**
         * Get the appropriate dimensions for the gallery thumbnails.
         *
         * @return {string[]}
         */
        thumbnailDimensions(): string[] {
            return ['width=208', 'width=416'];
        },
    },

    mounted(): void {
        document.addEventListener('keydown', (e) => {
            if (!this.isVisible) return;
            if (
                e.code === 'Space' ||
                e.code === 'PageDown' ||
                e.code === 'PageUp' ||
                e.code === 'ArrowDown' ||
                e.code === 'ArrowUp' ||
                e.code === 'Home' ||
                e.code === 'End'
            ) {
                const myDialog = this.$refs.galleryDialog as HTMLDialogElement;
                myDialog.focus();
                return;
            }
        });

        EventBus.$on('app.keyup', (keycode: string) => {
            if (!this.isVisible) return;
            switch (keycode) {
                case 'Escape':
                    this.close();
                    break;
                default:
                    break;
            }
        });
    },

    beforeUnmount(): void {
        EventBus.$off('app.keyup', this.close);
    },

    methods: {
        trackGallery(): void {
            if (!this.isVisible) return;

            if (!this.hasInteractedWithGallery) {
                tracking.track(
                    'trackBoligpraesentation',
                    'Boligpraesentation',
                    'Galleri skift',
                    this.projectId,
                );
                this.hasInteractedWithGallery = true;
                this.offScroll();
            }
        },

        onScroll(): void {
            this.trackGallery();
        },

        offScroll(): void {
            const d = this.$refs.galleryDialog as HTMLDialogElement;
            d?.removeEventListener('scroll', this.onScroll);
        },

        /**
         * Toggle the image gallery to and from fullscreen mode
         *
         * @return {void}
         */
        toggleFullScreen(): void {
            const myDialog = this.$refs.galleryDialog as HTMLDialogElement;

            const images = [
                ...document.querySelectorAll(
                    '.o-propertyGallery__galleryImages img',
                ),
            ] as HTMLImageElement[];

            const currentActiveImage = images[this.currentIndex];

            this.isFullscreen = !this.isFullscreen;

            tracking.track(
                'trackBoligpraesentation',
                'Boligpraesentation',
                `Galleri fuldskaerm ${this.isFullscreen ? 'til' : 'fra'}`,
                this.projectId,
            );

            Vue.nextTick(() => {
                myDialog.scrollTo({
                    top: currentActiveImage.parentElement.offsetTop,
                    left: 0,
                });

                currentActiveImage.classList.add('visibleEffect');
            });
        },

        /**
         * Close the gallery.
         *
         * @return {void}
         */
        close(): void {
            document.body.style.overflow = '';
            this.currentIndex = 0;
            this.isVisible = false;
            this.isLoading = false;
            this.isFullscreen = false;
            this.performanceMeasured = false;
            this.hasInteractedWithGallery = false;

            Vue.nextTick(() => {
                const d = this.$refs.galleryDialog as HTMLDialogElement;
                d?.close();
                this.offScroll();
            });
        },

        scrollTo(): void {
            const myDialog = this.$refs.galleryDialog as HTMLDialogElement;

            const img = document.querySelector(
                `.o-propertyGallery__galleryImages img[data-index="${this.currentIndex}"]`,
            );
            const x = window.getComputedStyle(myDialog, ':after').height;

            myDialog.scrollTo({
                top: img.parentElement.offsetTop - Math.round(parseFloat(x)),
                left: 0,
            });

            this.trackGallery();

            (document.activeElement as HTMLElement)?.blur();
            myDialog.focus();
        },

        /**
         * Increment index.
         *
         * @return {void}
         */
        next(): void {
            if (this.currentIndex === this.galleryItems.length - 1) return;

            if (this.currentIndex < this.galleryItems.length - 1) {
                this.currentIndex += 1;
            } else {
                this.currentIndex = 0;
            }

            this.scrollTo();
        },

        /**
         * Handle "click" event on thumbnails.
         *
         * @param {MouseEvent} event
         * @param {number} index
         * @return {void}
         */
        onClick(index: number): void {
            if (this.currentIndex === index) return;
            this.currentIndex = index;

            this.scrollTo();
        },

        /**
         * Handle "load" event on selected image.
         *
         * @param event
         * @return {void}
         */
        onLoad(): void {
            this.isLoading = false;

            if (this.galleryTimeout) {
                window.clearTimeout(this.galleryTimeout);
                this.galleryTimeout = null;
            }
        },

        /**
         * Decrease index.
         *
         * @return {void}
         */
        prev(): void {
            if (this.currentIndex === 0) return;

            if (this.currentIndex > 0) {
                this.currentIndex -= 1;
            } else {
                this.currentIndex = this.galleryItems.length - 1;
            }

            this.scrollTo();
        },

        resetThumbs(): void {
            const allThumbs = document.querySelectorAll(
                '.o-propertyGallery__galleryThumbnailImages button',
            );
            allThumbs.forEach((t) => t.classList.remove('outline'));
        },

        /**
         * Handle when image is intersecting the viewport.
         *
         * @param {entries} IntersectionObserverEntry[]
         * @return {void}
         */
        callbackFunction(entries: IntersectionObserverEntry[]): void {
            // array of observing elements
            entries.forEach((entry: IntersectionObserverEntry) => {
                entry.target.classList.remove('visibleEffect');
                // The logic to apply for each entry

                const rect = entry.boundingClientRect;
                const isPortraitImage = rect.height > rect.width;

                if (
                    (entry.isIntersecting && entry.intersectionRatio >= 0.45) ||
                    (entry.intersectionRatio > 0.2 && isPortraitImage)
                ) {
                    entry.target.classList.add('visibleEffect');

                    const visibleElements = [
                        ...document.querySelectorAll('img.visibleEffect'),
                    ] as HTMLImageElement[];

                    const firstItem = visibleElements[0] as HTMLImageElement;

                    const first = parseInt(firstItem.dataset.index);
                    this.currentIndex = first;
                    let activeThumb = document.querySelector(
                        `button[data-index="${first}"]`,
                    ) as HTMLButtonElement;

                    this.resetThumbs();

                    activeThumb?.classList.add('outline');

                    document
                        .querySelector(
                            '.o-propertyGallery__galleryThumbnailImages',
                        )
                        .scrollTo({
                            top: activeThumb.offsetTop,
                            left: 0,
                        });

                    activeThumb.focus();
                }
            });
        },

        /**
         * Open the gallery on the provided index.
         *
         * @param {number} index
         * @return {void}
         */
        show(index: number): void {
            this.currentIndex = index;

            document.body.style.overflow = 'hidden';
            this.isVisible = true;

            Vue.nextTick(() => {
                const d = this.$refs.galleryDialog as HTMLDialogElement;
                d?.showModal();
                d?.addEventListener('scroll', this.onScroll);
            });

            if (this.initialLoad) {
                tracking.track(
                    'trackBoligpraesentation',
                    'Boligpraesentation',
                    'Galleri start',
                    this.projectId,
                );
                this.initialLoad = false;
            }

            Api.getGalleryMediaByCaseNo({
                caseNo: this.caseNo,
            })
                .then((response: GalleryItemsResponse) => {
                    this.galleryItems = response.results.filter(
                        (x) => x.type === 'Billede',
                    );
                })
                .then(() => {
                    var targets = [
                        ...document.querySelectorAll(
                            '.o-propertyGallery__galleryImages img',
                        ),
                    ] as HTMLImageElement[];

                    var options = {
                        //root: document.querySelector(".dialog"),
                        rootMargin: '0px 0px 0px 0px',
                        threshold: [
                            0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
                        ],
                    };

                    var observer = new IntersectionObserver(
                        this.callbackFunction,
                        options,
                    );

                    targets.forEach((target, index) => {
                        observer.observe(target);
                    });

                    Vue.nextTick(() => {
                        this.scrollCurrentIntoView();
                    });
                })
                .catch(() => {
                    this.isVisible = false;
                });
        },

        /**
         * Scrolls the current element of the list into view.
         *
         * @return {void}
         */
        scrollCurrentIntoView(): void {
            const myDialog = this.$refs.galleryDialog as HTMLDialogElement;

            const img = document.querySelector(
                `.o-propertyGallery__galleryImages img[data-index="${this.currentIndex}"]`,
            );
            const extraOffset = window.getComputedStyle(
                myDialog,
                ':after',
            ).height;

            myDialog.scrollTo({
                top:
                    img.parentElement.offsetTop -
                    Math.round(parseFloat(extraOffset)),
                left: 0,
            });
        },
    },
};
