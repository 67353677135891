import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "o-accordion" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, itemIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: itemIndex,
        class: _normalizeClass(["o-accordion__item", { 'isOpen': itemIndex === $data.currentIndex }])
      }, [
        _createElementVNode("div", {
          class: "o-accordion__itemHeader",
          onClick: ($event: any) => ($options.onClickToggle(itemIndex))
        }, [
          _createElementVNode("h3", {
            class: "a-heading3 o-accordion__itemTitle",
            textContent: _toDisplayString(item.label)
          }, null, 8 /* PROPS */, _hoisted_3),
          _createVNode(_component_a_icon, {
            class: "o-accordion__itemIcon",
            name: "expand_more"
          })
        ], 8 /* PROPS */, _hoisted_2),
        _createElementVNode("div", {
          class: "o-content o-accordion__itemContent",
          innerHTML: item.description
        }, null, 8 /* PROPS */, _hoisted_4)
      ], 2 /* CLASS */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}