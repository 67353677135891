import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-rangeSlider__values" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider = _resolveComponent("vue-slider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-rangeSlider", $options.modifierClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "a-label m-rangeSlider__value",
        innerHTML: $data.minLabel
      }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("div", {
        class: "a-label m-rangeSlider__value",
        innerHTML: $data.maxLabel
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createVNode(_component_vue_slider, {
      class: "m-rangeSlider__slider",
      absorb: true,
      contained: true,
      data: $props.complexInterval,
      "dot-attrs": { 'aria-label': $props.ariaLabel },
      "dot-size": 32,
      height: 2,
      "hide-label": true,
      "infinity-indicator": $props.infinityIndicator,
      interval: $props.interval,
      lazy: true,
      max: $props.max,
      min: $props.min,
      tooltip: "none",
      "use-keyboard": true,
      modelValue: $data.currentRange,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentRange) = $event)),
      onChange: $options.onChange,
      onDragging: $options.onDragging
    }, null, 8 /* PROPS */, ["data", "dot-attrs", "infinity-indicator", "interval", "max", "min", "modelValue", "onChange", "onDragging"])
  ], 2 /* CLASS */))
}