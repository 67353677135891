import PoiCategory from '@/interfaces/POICategory.interface';
import POI from '@/interfaces/POI.interface';
import State, { FullRoute, TransportationMethod } from './interface';

export default {
    /**
     * G...
     *
     * @param {State} state
     * @return {object}
     */
    currentPoi(state: State): POI | undefined {
        return state.currentPoi;
    },
    /**
     * Get array of current Routes. (returns an array with car, bicycle and foot)
     *
     * @param {State} state
     * @return {Route[]}
     */
    currentRoutes(state: State): FullRoute[] {
        return state.currentRoutes;
    },

    currentRoute(state: State): FullRoute {
        return state.currentRoutes.filter(
            (x) => x.routeType === state.selectedTransportationMethod,
        )[0];
    },
    /**
     * Show or hide the favorites
     *
     * @param {State} state
     * @return {boolean}
     */
    showFavorites(state: State): boolean {
        return state.showFavorites;
    },
    /**
     * Return if selectors are enabled
     *
     * @param {State} state
     * @return {string[]}
     */
    selectorsEnabled(state: State): boolean {
        return state.selectorsEnabled;
    },
    /**
     * Get list of filter tags.
     *
     * @param {State} state
     * @return {string[]}
     */
    selectedPois(state: State): PoiCategory[] {
        return state.selectedPois;
    },

    selectedTransportationMethod(state: State): TransportationMethod {
        return state.selectedTransportationMethod;
    },
};
