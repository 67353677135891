
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import tracking from '@/functions/tracking';

type AvailableBuyersData = {
    buyersCount: number;
    headline: string;
    text: string;
    ctaText: string;
    ctaAltText: string;
    images: string[];
};

interface Data {
    brokerId: string;
}

export default {
    props: {
        data: {
            type: Object as PropType<AvailableBuyersData>,
            default: () => ({
                buyersCount: 0,
                headline: '',
                text: '',
                ctaText: '',
                ctaAltText: '',
                images: [],
            }),
        },
    },

    data(): Data {
        return {
            brokerId: '',
        };
    },

    mounted() {
        this.brokerId = window.homeEstimateBaseData.brokerId;
    },

    methods: {
        ...mapActions({
            openModal: 'modal/open',
            setTrackingEntrance: 'modal/setTrackingEntrance',
        }),
        openContactBrokerCurtain() {
            this.openModal('brokerContact');
            this.setTrackingEntrance('Boligskoen find koeber modul');
            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: 'Salgsvurdering indgang',
                    label: 'Salgsvurdering',
                    formularIndgang: 'Boligskoen find koeber modul',
                    formularStepnavn: 'Klik paa modul',
                    maeglerId: this.brokerId ?? '',
                },
            });
        },
    },
};
