import actions from './widget/actions';
import getters from './widget/getters';
import mutations from './widget/mutations';
import state from './widget/state';

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
};
