import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "m-button__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    ref: "button",
    class: _normalizeClass(["m-button", [$options.modifierClass]]),
    href: $options.modifiedUrl,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
  }, [
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("v-if", true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}