
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Api from '../../../functions/api';

export default {
    props: {
        pageId: {
            type: Number as PropType<number>,
            default: () => 0,
        },
    },

    computed: {
        ...mapGetters({
            visited: 'article/visited',
        }),
    },

    mounted(): void {
        this.updateArticleViewCount();
    },

    methods: {
        ...mapActions({
            addVisited: 'article/addVisited',
        }),

        /**
         * Update the view count for the article, if not in sessionStorage
         *
         * @return {void}
         */
        updateArticleViewCount(): void {
            if (this.visited.includes(this.pageId)) {
                return;
            }

            Api.incrementArticleViewCount({
                pageId: this.pageId,
            }).then(() => {
                this.addVisited(this.pageId);
            });
        },

        /**
         * Scroll document to content, includes breadcrumb, header and hero
         *
         * @return {void}
         */
        scrollToContent(): void {
            const heroTop = (this.$root.$refs.hero as HTMLDivElement).scrollTop;
            const heroHeight = (this.$root.$refs.hero as HTMLDivElement).scrollHeight;
            window.scrollTo({
                top: heroTop + heroHeight + 162,
                left: 0,
                behavior: 'smooth',
            });
        },
    },
    template: `
    <div>
        <slot :scrollToContent="scrollToContent" />
    </div>`,
};
