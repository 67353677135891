import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "m-multiToggle" }
const _hoisted_2 = { class: "m-multiToggle__fields" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
        return (_openBlock(), _createElementBlock("label", { key: index }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedItem) = $event)),
            type: "radio",
            value: item.key,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
          }, null, 8 /* PROPS */, _hoisted_3), [
            [_vModelRadio, $data.selectedItem]
          ]),
          _createElementVNode("span", {
            textContent: _toDisplayString(item.value)
          }, null, 8 /* PROPS */, _hoisted_4)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}