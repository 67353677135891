import State from './interface';

export default <State>{
    currentPoi: undefined,
    showFavorites: true,
    selectedPois: [],
    selectorsEnabled: true,
    currentRoutes: [],
    selectedTransportationMethod: 'foot',
};
