import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "o-employeeCard__wrapper" }
const _hoisted_2 = { class: "o-employeeCard__portrait" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "o-employeeCard__content" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "o-employeeCard__info" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 3,
  class: "o-employeeCard__infoPhone"
}
const _hoisted_11 = ["href", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["o-employeeCard", { 'o-employeeCard--panel': $props.panel }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            loading: "lazy",
            src: $options.formattedUrl,
            class: "o-employeeCard__image",
            alt: "Billede af mægleren",
            width: "500",
            height: "500"
          }, null, 8 /* PROPS */, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "a-lead o-employeeCard__name",
            textContent: _toDisplayString($props.employee.name)
          }, null, 8 /* PROPS */, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            ($data.isDesktop && !$props.panel)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "a-label",
                  textContent: _toDisplayString($props.employee.title)
                }, null, 8 /* PROPS */, _hoisted_7))
              : _createCommentVNode("v-if", true),
            ($data.isDesktop && !$props.panel)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "a-label",
                  innerHTML: 
                                `${$props.employee.brokerName}, ${$props.employee.address}, ${$props.employee.zipCode} ${$props.employee.city}, <a href='mailto:${$props.employee.email}' class='a-anchor email'>${$props.employee.email}</a>`
                            
                }, null, 8 /* PROPS */, _hoisted_8))
              : _createCommentVNode("v-if", true),
            (!$data.isDesktop || $props.panel)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 2,
                  class: "a-label",
                  innerHTML: 
                                `${$props.employee.title}, ${$props.employee.brokerName}, ${
                                    $props.employee.address
                                }, ${$props.employee.zipCode} ${$props.employee.city}${
                                    $props.employee.email
                                        ? `, <a href='mailto:${$props.employee.email}' class='a-anchor email'>${$props.employee.email}</a>`
                                        : ``
                                }`
                            
                }, null, 8 /* PROPS */, _hoisted_9))
              : _createCommentVNode("v-if", true),
            ($props.employee.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("a", {
                    href: `tel:${$props.employee.phone}`,
                    class: _normalizeClass(
                                    $data.isDesktop
                                        ? 'a-anchor'
                                        : 'm-button m-button--outlined'
                                ),
                    textContent: _toDisplayString($props.employee.phone)
                  }, null, 10 /* CLASS, PROPS */, _hoisted_11)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ])
  ], 2 /* CLASS */))
}