import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["src", "srcset", "width", "height"]
const _hoisted_4 = {
  key: 0,
  class: "o-uspGrid",
  "data-mobile-scroll-grid": "",
  "data-mobile-scroll-grid-shadow": ""
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "container",
      class: _normalizeClass(["o-sectionHeroWithUsp", {'no-headline': !$props.headline, 'has-icon': ($props.icon && $props.image), 'has-image': $props.image, 'has-gap': (!$props.image && !$props.icon && $props.headline && $props.uspItems.length > 0)}])
    }, [
      ($props.icon && $props.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            ref: "icon",
            src: $props.icon,
            class: "o-sectionHeroWithUsp__icon",
            width: "220",
            height: "220",
            alt: ""
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      ($props.headline)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 1,
            class: "a-heading1 o-sectionHeroWithUsp__headline",
            textContent: _toDisplayString($props.headline)
          }, null, 8 /* PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      ($props.image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            decoding: "async",
            loading: "lazy",
            src: `${$props.image}?mode=crop&${$props.imageCenter}&width=350`,
            srcset: `${$props.image}?mode=crop&${$props.imageCenter}&width=350 350w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=700 700w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=1050 1035w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=1408 1408w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=1600 1600w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=1800 1800w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=2000 2000w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=2200 2200w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=2400 2400w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=2600 2600w,
                ${$props.image}?mode=crop&${$props.imageCenter}&width=2816 2816w`,
            style: _normalizeStyle(`--focalPoint: ${$props.focalPoint}`),
            sizes: "(min-width: 1440px) 1408px, calc(100vw - 8rem)",
            width: $props.imageWidth,
            height: $props.imageHeight,
            alt: "",
            class: "o-sectionHeroWithUsp__mediaImage"
          }, null, 12 /* STYLE, PROPS */, _hoisted_3))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    ($props.uspItems.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.uspItems, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "o-uspGrid__item",
              "data-mobile-scroll-grid-item": ""
            }, [
              (item.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_a_icon, {
                      name: item.icon.name
                    }, null, 8 /* PROPS */, ["name"])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", {
                class: "a-lead",
                textContent: _toDisplayString(item.headline)
              }, null, 8 /* PROPS */, _hoisted_6),
              _createElementVNode("div", {
                class: "a-paragraph",
                innerHTML: item.text
              }, null, 8 /* PROPS */, _hoisted_7)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}