import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-keyNumbers" }
const _hoisted_2 = { class: "o-keyNumbers__wrapper" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "o-keyNumbers__numbersWrapper" }
const _hoisted_7 = { class: "o-keyNumbers__numbers" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { class: "o-keyNumbers__numbers" }
const _hoisted_11 = ["textContent"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "o-keyNumbers__numbers" }
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "a-heading1 o-keyNumbers__headline",
        textContent: _toDisplayString($props.data.headline)
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", {
        class: "a-lead o-keyNumbers__text u-col-12 lg:u-col-8",
        textContent: _toDisplayString($props.data.leadingParagraph)
      }, null, 8 /* PROPS */, _hoisted_4),
      _createElementVNode("button", {
        class: "m-button o-keyNumbers__ctaButton",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openContactBrokerCurtain())),
        textContent: _toDisplayString($props.data.ctaText)
      }, null, 8 /* PROPS */, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "a-heading1 o-keyNumbers__numbersTitle",
            textContent: _toDisplayString($props.data.keyNumbersPropertiesForSaleTitle)
          }, null, 8 /* PROPS */, _hoisted_8),
          _createElementVNode("div", {
            class: "a-lead o-keyNumbers__numbersText",
            textContent: _toDisplayString($props.data.keyNumbersPropertiesForSaleText)
          }, null, 8 /* PROPS */, _hoisted_9)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "a-heading1 o-keyNumbers__numbersTitle",
            textContent: _toDisplayString($props.data.keyNumbersSquareMeterPriceTitle)
          }, null, 8 /* PROPS */, _hoisted_11),
          _createElementVNode("div", {
            class: "a-lead o-keyNumbers__numbersText",
            textContent: _toDisplayString($props.data.keyNumbersSquareMeterPriceText)
          }, null, 8 /* PROPS */, _hoisted_12)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", {
            class: "a-heading1 o-keyNumbers__numbersTitle",
            textContent: _toDisplayString($props.data.keyNumbersLayTimeTitle)
          }, null, 8 /* PROPS */, _hoisted_14),
          _createElementVNode("div", {
            class: "a-lead o-keyNumbers__numbersText",
            textContent: _toDisplayString($props.data.keyNumbersLayTimeText)
          }, null, 8 /* PROPS */, _hoisted_15)
        ])
      ]),
      _createElementVNode("div", {
        class: "a-base o-keyNumbers__note",
        innerHTML: $props.data.sourceText
      }, null, 8 /* PROPS */, _hoisted_16)
    ])
  ]))
}