
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import tracking from '@/functions/tracking';
import Broker from '@/interfaces/broker.interface';
import CurrentUser from '@/interfaces/currentUser.interface';
import LeadError from '@/interfaces/leads/leadError.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Format from '@/functions/format';
import oBrokerMapCard from '@/components/organisms/broker/BrokerMapCard.vue';
import oOverlayPromoBox from '@/components/molecules/OverlayPromoBox.vue';
import Dawa from '@/interfaces/dawa.interface';
import IOverlayPromoBox from '@/interfaces/overlayPromoBox.interface';

export interface Texts {
    panelHeadline?: string;
    backButton?: string;
    getCallMenuName?: string;
    getCallHeadingText?: string;
    getCallDescriptionText?: string;
    getCallSubmitButtonText?: string;
    sendMessageMenuName?: string;
    sendMessageHeadingText?: string;
    sendMessageDescriptionText?: string;
    sendMessageSubmitButtonText?: string;
    orderEvaluationMenuName?: string;
    orderEvaluationHeadingText?: string;
    orderEvaluationDescriptionText?: string;
    orderEvaluationSubmitButtonText?: string;
    buyerAdviceMenuName?: string;
    buyerAdviceHeadingText?: string;
    buyerAdviceDescriptionText?: string;
    buyerAdviceSubmitButtonText?: string;
    formReceiptSuccessHeadingText?: string;
    formReceiptSuccessMessageText?: string;
    form?: {
        mail: string;
        message: string;
        name: string;
        offer: string;
        phone: string;
        address: string;
    };
}

interface Consents {
    contactConsent: LeadConsent;
    salesValuationConsent: LeadConsent;
    buyerAdviceConsent: LeadConsent;
}

interface Data {
    currentDirection: string;
    errors: LeadError;
    form: {
        mail: string;
        message: string;
        name: string;
        phone: string;
        address: string;
    };
    isLoading: boolean;
    successMessage: string;
    addressFound: boolean;
    error: boolean;
    isFocused: boolean;
    activePromoBoxes: string;
}

interface EventData {
    [key: string]: any;
}

export default {
    components: {
        oBrokerMapCard,
        oOverlayPromoBox,
    },

    props: {
        broker: {
            type: Object as PropType<Broker>,
            default: () => ({
                brokerId: '',
            }),
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                getCallMenuName: '',
                sendMessageMenuName: '',
                orderEvaluationMenuName: '',
                buyerAdviceMenuName: '',
            }),
        },
        consents: {
            type: Object as PropType<Consents>,
            default: () => ({
                contactConsent: {
                    id: '',
                    purposeText: '',
                },
                salesValuationConsent: {
                    id: '',
                    purposeText: '',
                },
                buyerAdviceConsent: {
                    id: '',
                    purposeText: '',
                },
            }),
        },
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                email: '',
                fullName: '',
            }),
        },
        promoBoxesGetPhoneCall: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesSendMessage: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesOrderEvaluation: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesBuyerAdvice: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            errors: {},
            form: {
                mail: this.user?.email ?? '',
                message: '',
                name: this.user?.fullName ?? '',
                phone: '',
                address: '',
            },
            isLoading: false,
            successMessage: '',
            addressFound: false,
            isFocused: false,
            error: false,
            activePromoBoxes: '',
        };
    },

    computed: {
        ...mapGetters({
            currentPanel: 'broker/currentPanel',
            currentSubPanel: 'broker/currentSubPanel',
            fixedPanel: 'modal/fixed',
            modalShow: 'modal/show',
            modalType: 'modal/type',
            trackingEntrance: 'modal/trackingEntrance',
            modalTrackingLabel: 'modal/trackingLabel',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow && this.modalType === 'broker';
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'broker/setCurrentPanel',
            setCurrentSubPanel: 'broker/setCurrentSubPanel',
        }),

        /**
         * Set whether input field is focused
         *
         * @return {void}
         */
        setIsFocused(value: boolean): void {
            this.isFocused = value;
        },

        /**
         * Enable submit button.
         *
         * @return {void}
         */
        enableSubmit(): void {
            this.addressFound = true;
        },

        /**
         * Disable submit button.
         *
         * @return {void}
         */
        disableSubmit(): void {
            this.addressFound = false;
        },

        /**
         * Set address information if address is found
         *
         * @return {void}
         */
        setAddressInfo(value: Dawa): void {
            // this.errors.address = '';
            if (this.addressFound) {
                this.form.address = value.tekst;
            }
        },

        getTrackingText(value: string): string {
            let returnValue = '';
            switch (value) {
                case 'getPhoneCall':
                    returnValue = 'Bliv ringet op';
                    break;
                case 'sendMessage':
                    returnValue = 'Skriv til os';
                    break;
                case 'orderEvaluation':
                    returnValue = 'Salgsvurdering';
                    break;
                case 'buyerAdvice':
                    returnValue = 'Koeberraadgivning';
                    break;
                default:
                    returnValue = '';
                    break;
            }
            return returnValue;
        },

        /**
         * Go to the next panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        nextPanel(value: string, track: boolean): void {
            this.currentDirection = 'next';
            this.setCurrentPanel(value);
            if (track) {
                const trackingEventData: EventData = {
                    event: 'trackForm',
                    eventData: {
                        category: 'Formular',
                        action: `${this.getTrackingText(value)} initieret`,
                        label: `${this.getTrackingText(value)}`,
                        formularIndgang: this.trackingEntrance ?? '',
                        formularStepnavn: 'Brugerinformation',
                        maeglerId: this.broker.brokerId ?? '',
                    },
                };

                tracking.trackRawEvent(trackingEventData);
            }
        },

        /**
         * Update value in form.
         *
         * @param {string} key
         * @param {string} value
         * @return {void}
         */
        onChange(key: string, value: string): void {
            // Vue.set(this.form, key, value);

            this.form[key] = value;
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(value: string): void {
            this.isLoading = true;

            let excludeValidation: string[] = [];
            let type = '';
            let id = '';
            let trackingAction = '';
            let trackingLabel = '';
            switch (value) {
                case 'callMe':
                    type = LeadTypes.Contact.toString();
                    id = this.consents?.contactConsent?.id ?? '';
                    trackingAction = 'Bliv ringet op gennemfoert';
                    trackingLabel = 'Bliv ringet op';
                    excludeValidation = ['message', 'email'];
                    break;
                case 'sendMessage':
                    type = LeadTypes.Contact.toString();
                    id = this.consents?.contactConsent?.id ?? '';
                    trackingAction = 'Skriv til os gennemfoert';
                    trackingLabel = 'Skriv til os';
                    excludeValidation =
                        this.form?.mail.trim() === '' ? ['email'] : [];
                    break;
                case 'orderEvaluation':
                    type = LeadTypes.SalesValuationShop.toString();
                    id = this.consents?.salesValuationConsent?.id ?? '';
                    trackingAction = 'Salgsvurdering gennemfoert';
                    trackingLabel = 'Salgsvurdering';
                    excludeValidation =
                        this.form?.mail.trim() === '' ? ['email'] : [];
                    break;
                case 'buyerAdvice':
                    type = LeadTypes.BuyersAdvice.toString();
                    id = this.consents?.buyerAdviceConsent?.id ?? '';
                    trackingAction = 'Koeberraadgivning gennemfoert';
                    trackingLabel = 'Koeberraadgivning';
                    excludeValidation = ['address'];
                    break;
                default:
                    break;
            }

            Api.postLead(
                {
                    consentIdGlobal: id,
                    address: this.form.address ?? '',
                    email: this.form.mail.trim() ?? '',
                    firstName: Format.firstName(this.form.name) ?? '',
                    lastName: Format.lastName(this.form.name) ?? '',
                    message: this.form.message ?? '',
                    phoneNumber: this.form.phone ?? '',
                    responsibleShopNo: this.broker?.brokerId ?? '',
                    type,
                    httpReferral: this.referrer,
                    utmCampaign: this.utmCampaign,
                    utmContent: this.utmContent,
                    utmMedium: this.utmMedium,
                    utmSource: this.utmSource,
                },
                excludeValidation,
            )
                .then((): void => {
                    this.activePromoBoxes = this.currentPanel;

                    this.nextPanel('successMessage', false);

                    tracking.trackRawEvent({
                        event: 'trackForm',
                        eventData: {
                            category: 'Formular',
                            action: trackingAction,
                            label: this.modalTrackingLabel ?? trackingLabel,
                            formularIndgang: this.trackingEntrance ?? '',
                            formularStepnavn: 'Kvittering',
                            maeglerId: this.broker.brokerId ?? '',
                        },
                    });
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },

        /**
         * Go to the previous panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        previousPanel(value: string): void {
            this.currentDirection = 'prev';
            this.setCurrentPanel(value);
        },
    },
};
