import { ActionContext } from 'vuex';
import State from './interface';

export default {
    /**
     * Add referrer
     *
     * @param {ActionContext} context
     * @return {void}
     */
    setReferrer({ commit }: ActionContext<any, State>, payload: string): void {
        // console.log('setReferrer value: ', payload);
        commit('setReferrer', { key: 'referrer', value: payload });
    },

    setUtmSource({ commit }: any, value: string): void {
        commit('setUtmSource', value);
    },

    setUtmMedium({ commit }: any, value: string): void {
        commit('setUtmMedium', value);
    },

    setUtmCampaign({ commit }: any, value: string): void {
        commit('setUtmCampaign', value);
    },

    setUtmContent({ commit }: any, value: string): void {
        commit('setUtmContent', value);
    },

    setTimeStamp({ commit }: any, value: Date | undefined): void {
        commit('setTimeStamp', value);
    },
};
