
import { EventBus } from '@/functions/eventBus';

interface Data {
    isNotOverflown: boolean;
    isOverflownLeft: boolean;
    isOverflownRight: boolean;
}

export default {
    data(): Data {
        return {
            isNotOverflown: false,
            isOverflownLeft: false,
            isOverflownRight: false,
        };
    },
    mounted(): void {
        EventBus.$on('app.resize', this.calculateOverflow);
        const breadcrumbWrapper = this.$root.$refs
            .breadcrumbWrapper as HTMLDivElement;
        const listItems = (this.$root.$refs.breadcrumbList as HTMLOListElement)
            .childNodes;

        breadcrumbWrapper.addEventListener('scroll', this.calculateOverflow);

        let lastItem = null;
        if (listItems) {
            listItems.forEach((item) => {
                if (item.nodeType === Node.ELEMENT_NODE) {
                    lastItem = item as HTMLLIElement;
                }
            });

            if (breadcrumbWrapper && lastItem) {
                // (lastItem as HTMLLIElement).scrollIntoView({ block: 'start', inline: 'end' });

                breadcrumbWrapper.scrollTo({
                    top: 0,
                    left: 2000,
                    behavior: 'auto',
                });
            }
        }
    },

    beforeUnmount(): void {
        EventBus.$off('app.resize');
        (
            this.$root.$refs.breadcrumbWrapper as HTMLDivElement
        ).removeEventListener('scroll', this.calculateOverflow);
    },

    methods: {
        /**
         * Calculate if list is overflowing wrapper.
         *
         * @return {void}
         */
        calculateOverflow(): void {
            let elementRect = (
                this.$root.$refs.breadcrumbList as HTMLDivElement
            ).getBoundingClientRect();
            let wrapperRect = (
                this.$root.$refs.breadcrumbWrapper as HTMLDivElement
            ).getBoundingClientRect();

            this.isOverflownLeft =
                Math.ceil(elementRect.left) < Math.floor(wrapperRect.left);
            this.isOverflownRight =
                Math.floor(elementRect.right) > Math.ceil(wrapperRect.right);
            this.isNotOverflown =
                !this.isOverflownLeft && !this.isOverflownRight;
        },
    },
};
