import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href", "aria-label"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "m-ctaWidget__content" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 1,
  class: "m-ctaWidget__iconWrapper"
}
const _hoisted_7 = ["textContent"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "m-ctaWidget__iconWrapper m-ctaWidget__iconWrapper--static"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return ($data.isShown)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["m-ctaWidget", [$options.modifierClass]])
      }, [
        _createElementVNode("button", {
          type: "button",
          class: "m-ctaWidget__dismiss",
          "aria-label": "Luk",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.dismiss && $options.dismiss(...args)))
        }, [
          _createVNode(_component_a_icon, { name: "close" })
        ]),
        _createElementVNode("a", {
          href: $options.stickerUrl,
          class: "m-ctaWidget__link",
          "aria-label": $options.stickerScreenReaderText,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.track && $options.track(...args)))
        }, [
          ($options.showIcon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "m-ctaWidget__illustration",
                src: $options.iconUrl,
                width: "112",
                height: "112",
                alt: ""
              }, null, 8 /* PROPS */, _hoisted_2))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "m-ctaWidget__contentHeadline",
              innerHTML: $options.headlineText
            }, null, 8 /* PROPS */, _hoisted_4),
            _createElementVNode("div", {
              class: "m-ctaWidget__contentDescription",
              innerHTML: $options.descriptionText
            }, null, 8 /* PROPS */, _hoisted_5)
          ]),
          (_ctx.userEstimate > 0 && $props.texts.isHomeEstimateSticker)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", {
                  class: "m-ctaWidget__iconWrapper__text",
                  textContent: _toDisplayString($options.stickerButtonText)
                }, null, 8 /* PROPS */, _hoisted_7),
                (_ctx.brokerImage !== '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "m-ctaWidget__link__illustration",
                      src: _ctx.brokerImage,
                      width: "50",
                      height: "50",
                      alt: ""
                    }, null, 8 /* PROPS */, _hoisted_8))
                  : _createCommentVNode("v-if", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_a_icon, { name: "arrow_forward" })
              ]))
        ], 8 /* PROPS */, _hoisted_1)
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}