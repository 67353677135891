import { ActionContext } from 'vuex';
import { Widget } from './interface';

export default {
    /**
     * Add widget to list.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    dismissWidget({ commit }: ActionContext<any, any>, value: Widget): void {
        commit('addWidget', value);
    },

    /**
     * Delete widget from the list.
     *
     * @param {ActionContext} context
     * @return {void}
     */
    deleteWidget({ commit }: ActionContext<any, any>, value: Widget): void {
        commit('deleteWidget', value);
    },

    /**
     * Return widget by id if exists.
     *
     * @param {State} state
     * @return {string | undefined}
     */
    setCurrent({ commit, state }: any, value: Widget): void {
        commit(
            'setCurrent',
            state.widgets.find((w: string) => w === value.name),
        );
    },
};
