import State from './interface';

export default <State>{
    document: {
        referrer: '',
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        utmContent: '',
        timestamp: undefined,
    },
};
