import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "a-section o-homeEstimateLandingHero__wrapper" }
const _hoisted_2 = { class: "o-homeEstimateLandingHero" }
const _hoisted_3 = { class: "o-homeEstimateLandingHero__inputSection" }
const _hoisted_4 = { class: "o-homeEstimateLandingHero__inputSectionTexts" }
const _hoisted_5 = { class: "a-heading1 o-homeEstimateLandingHero__contentHeadline" }
const _hoisted_6 = { class: "o-homeEstimateLandingHero__contentDescription" }
const _hoisted_7 = { class: "a-lead" }
const _hoisted_8 = { class: "o-homeEstimateLandingHero__contentDescriptionIcon" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { class: "o-homeEstimateLandingHero__inputWrapper" }
const _hoisted_11 = { class: "o-homeEstimateLandingHero__disclaimer" }
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "o-homeEstimateLandingHero__buttonWrapper" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["textContent"]
const _hoisted_16 = { class: "o-homeEstimateLandingHero__imageWrapper" }
const _hoisted_17 = ["srcset", "src", "alt"]
const _hoisted_18 = ["loop", "autoplay", "muted", "controls", "src", "poster"]
const _hoisted_19 = ["loop", "autoplay", "muted", "controls", "src", "poster"]
const _hoisted_20 = { class: "o-panel" }
const _hoisted_21 = { class: "a-heading2 o-panel__title o-panel__heading o-homeEstimateLandingHero__infoHeadline" }
const _hoisted_22 = { class: "o-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_media = _resolveComponent("m-media")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, [
            _renderSlot(_ctx.$slots, "headline")
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _renderSlot(_ctx.$slots, "description")
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "o-homeEstimateLandingHero__info",
                "aria-label": $props.content.ariaLabel,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClickEstimateTeaserInfo && $options.onClickEstimateTeaserInfo(...args)))
              }, [
                _createVNode(_component_a_icon, { name: "info" })
              ], 8 /* PROPS */, _hoisted_9)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_m_input, {
            value: $data.address,
            class: "m-input__field o-homeEstimateLandingHero__contentInput",
            name: "address‌_search",
            error: $data.error ? $props.content.errorMessage : '',
            label: $props.content.placeholder,
            theme: "",
            type: "dawa",
            onDawa: $options.enableSubmit,
            onDawaReset: $options.disableSubmit,
            onInput: $options.setAddressInfo,
            onFocus: _cache[1] || (_cache[1] = ($event: any) => ($options.setIsFocused(true))),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => ($options.setIsFocused(false)))
          }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", {
              class: "a-small o-homeEstimateLandingHero__disclaimerText",
              textContent: _toDisplayString($props.content.helpText)
            }, null, 8 /* PROPS */, _hoisted_12)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass(["m-button o-homeEstimateLandingHero__contentInputButton", { isLoading: $data.isLoading }]),
            disabled: !$data.addressFound || !$data.address,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
          }, [
            _createElementVNode("span", {
              class: "m-button__text",
              textContent: _toDisplayString($props.content.buttonText)
            }, null, 8 /* PROPS */, _hoisted_15)
          ], 10 /* CLASS, PROPS */, _hoisted_14)
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("img", {
          class: "o-homeEstimateLandingHero__image",
          srcset: $options.currentImageSrcset,
          src: $options.currentImageUrl,
          alt: $options.currentImageAltText,
          sizes: "100vw",
          height: "900",
          width: "1600"
        }, null, 8 /* PROPS */, _hoisted_17),
        (
                        !$data.isDesktop &&
                            $props.content.mobileVideo &&
                            $props.content.mobileVideo.videoType === 'youtube' &&
                            $props.content.mobileVideo.videoId
                    )
          ? (_openBlock(), _createBlock(_component_m_media, {
              key: 0,
              class: "o-homeEstimateLandingHero__video",
              "youtube-id": $props.content.mobileVideo.videoId,
              "video-title": ""
            }, null, 8 /* PROPS */, ["youtube-id"]))
          : _createCommentVNode("v-if", true),
        (
                        $data.isDesktop &&
                            $props.content.desktopVideo &&
                            $props.content.desktopVideo.videoType === 'youtube' &&
                            $props.content.desktopVideo.videoId
                    )
          ? (_openBlock(), _createBlock(_component_m_media, {
              key: 1,
              class: "o-homeEstimateLandingHero__video",
              "youtube-id": $props.content.desktopVideo.videoId,
              "video-title": ""
            }, null, 8 /* PROPS */, ["youtube-id"]))
          : _createCommentVNode("v-if", true),
        (
                        !$data.isDesktop &&
                            $props.content.mobileVideo &&
                            $props.content.mobileVideo.videoType === 'cms' &&
                            $props.content.mobileVideo.videoUrl
                    )
          ? (_openBlock(), _createElementBlock("video", {
              key: 2,
              class: "o-homeEstimateLandingHero__video",
              loop: $props.content.enableVideoLoop,
              autoplay: $props.content.disableVideoControls,
              muted: $props.content.disableVideoControls,
              controls: !$props.content.disableVideoControls,
              src: $props.content.mobileVideo.videoUrl,
              poster: $options.currentImageUrl,
              disablePictureInPicture: "",
              controlsList: "nodownload",
              playsinline: ""
            }, null, 8 /* PROPS */, _hoisted_18))
          : _createCommentVNode("v-if", true),
        (
                        $data.isDesktop &&
                            $props.content.desktopVideo &&
                            $props.content.desktopVideo.videoType === 'cms' &&
                            $props.content.desktopVideo.videoUrl
                    )
          ? (_openBlock(), _createElementBlock("video", {
              key: 3,
              class: "o-homeEstimateLandingHero__video",
              loop: $props.content.enableVideoLoop,
              autoplay: $props.content.disableVideoControls,
              muted: $props.content.disableVideoControls,
              controls: !$props.content.disableVideoControls,
              src: $props.content.desktopVideo.videoUrl,
              poster: $options.currentImageUrl,
              disablePictureInPicture: "",
              controlsList: "nodownload",
              playsinline: ""
            }, null, 8 /* PROPS */, _hoisted_19))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    ($options.showEstimateTeaserInfo)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 0,
          to: "modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("h2", _hoisted_21, [
                _renderSlot(_ctx.$slots, "info-headline")
              ]),
              _createElementVNode("div", _hoisted_22, [
                _renderSlot(_ctx.$slots, "info-description")
              ])
            ])
          ], undefined, true),
          _: 3 /* FORWARDED */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}