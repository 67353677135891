import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "o-homeEstimateOrderEstimate",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openContactBrokerCurtain()))
  }, [
    _createElementVNode("img", {
      class: "o-homeEstimateOrderEstimate__image",
      src: $props.image
    }, null, 8 /* PROPS */, _hoisted_1),
    _createElementVNode("p", {
      class: "o-homeEstimateOrderEstimate__text",
      textContent: _toDisplayString($props.texts.brokerCardText)
    }, null, 8 /* PROPS */, _hoisted_2),
    _createVNode(_component_a_icon, {
      class: "o-homeEstimateOrderEstimate__icon",
      name: "arrow_forward"
    })
  ]))
}