
import tracking from '@/functions/tracking';

interface Data {
    isOpen: boolean;
}

export default {
    data(): Data {
        return {
            isOpen: false,
        };
    },

    methods: {
        /**
         * Toggles the open state.
         *
         * @return {void}
         */
        toggle(e: any): void {
            this.isOpen = !this.isOpen;

            if (
                window?.location?.href.includes('ejendomsmaegler') &&
                this.isOpen
            ) {
                console.log(e.target);
                const heading = e.target.querySelector(
                    '.o-moduleAccordion__title',
                ) as HTMLButtonElement;
                const headingText = heading?.textContent.trim() ?? '';
                tracking.track(
                    'trackButiksside',
                    'Butiksside',
                    'Accordion aabnet',
                    headingText,
                );
            }
        },
    },
};
