import State from './interface';
import Dawa from '../../interfaces/dawa.interface';

export default {
    /**
     * Set show value.
     *
     * @param {State} state
     * @param {boolean} payload
     * @return {void}
     */
    setShow(state: State, payload: boolean): void {
        state.show = payload;
    },

    /**
     * Set type value.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setType(state: State, payload: string): void {
        state.type = payload;
    },
    /**
     * Set dawa to state.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setDawa(state: State, payload: Dawa): void {
        state.dawa = payload;
    },
};
