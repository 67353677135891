import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-homeEstimateResponsibleBroker" }
const _hoisted_2 = { class: "o-homeEstimateResponsibleBroker__wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "o-homeEstimateResponsibleBroker__body" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.broker.imageUrl,
        class: "o-homeEstimateResponsibleBroker__image"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "a-base o-homeEstimateResponsibleBroker__text",
          textContent: _toDisplayString($props.brokerIntroductionText)
        }, null, 8 /* PROPS */, _hoisted_5),
        _createElementVNode("button", {
          class: "m-button o-homeEstimateResponsibleBroker__ctaButton",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openContactBrokerCurtain()))
        }, [
          _createElementVNode("span", {
            class: "m-button__text",
            textContent: _toDisplayString($props.ctaText)
          }, null, 8 /* PROPS */, _hoisted_6)
        ])
      ])
    ])
  ]))
}