import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["action", "method"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    action: $props.action,
    method: $props.method,
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}