
import { PropType } from 'vue';

export default {
    name: 'm-checkbox',
    props: {
        disabled: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        label: {
            type: String as PropType<string>,
            default: () => '',
        },
        theme: {
            type: String as PropType<string>,
            default: () => '',
        },
        value: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        useSecondaryFont: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        error: {
            type: String as PropType<string>,
            default: () => '',
        },
    },

    computed: {
        /**
         * Check if checkbox is checked.
         *
         * @return {boolean}
         */
        isChecked(): boolean {
            return this.value !== false;
        },

        /**
         * Check if checkbox is disabled.
         *
         * @return {boolean}
         */
        isDisabled(): boolean {
            return this.disabled !== false;
        },

        /**
         * Define modifier class.
         *
         * @return {string}
         */
        modifierClass(): string {
            return this.theme ? `m-checkbox--${this.theme}` : '';
        },

        /**
         * Define font modifier class.
         *
         * @return {string}
         */
        fontModifierClass(): string {
            return this.useSecondaryFont ? 'a-label--secondaryFont' : '';
        },
    },

    methods: {
        /**
         * Handle click event.
         *
         * @return {void}
         */
        onClick(): void {
            this.$emit('input', !this.value);
        },
    },
};
