import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-infoModal" }
const _hoisted_2 = { class: "o-panel" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "o-subPanel" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "m-infoModal__button",
      "aria-label": "Se mere",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.openPopup && $options.openPopup(...args)))
    }, [
      _createVNode(_component_a_icon, { name: "info" })
    ]),
    ($options.showModal)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 0,
          to: "modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", {
                class: "a-heading2 o-panel__heading",
                innerHTML: $props.headline
              }, null, 8 /* PROPS */, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "a-label o-subPanel--spacedChildrenSmall",
                  innerHTML: $props.content
                }, null, 8 /* PROPS */, _hoisted_5)
              ])
            ])
          ], undefined, true),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}