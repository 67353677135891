import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: "navigation",
  class: "o-panel"
}
const _hoisted_2 = { class: "o-panel__broker" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "o-panel__brokerInfo" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { class: "o-panel__location" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = {
  class: "o-panel__nav",
  role: "group",
  "aria-label": "Vælg den ønskede handling"
}
const _hoisted_9 = ["textContent"]
const _hoisted_10 = {
  key: "broker",
  class: "o-panel"
}
const _hoisted_11 = ["textContent"]
const _hoisted_12 = {
  key: "broker1",
  class: "o-subPanel"
}
const _hoisted_13 = ["textContent"]
const _hoisted_14 = { class: "o-fields" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["textContent"]
const _hoisted_17 = ["textContent"]
const _hoisted_18 = {
  key: "successMessage",
  class: "o-panel"
}
const _hoisted_19 = ["textContent"]
const _hoisted_20 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_o_employee_card = _resolveComponent("o-employee-card")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_portal = _resolveComponent("portal")!

  return ($options.isVisible)
    ? (_openBlock(), _createBlock(_component_portal, {
        key: 0,
        to: "modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            name: `o-panel--${$data.currentDirection}`
          }, {
            default: _withCtx(() => [
              (!_ctx.currentPanel)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("img", {
                        src: $options.formattedEmployeeImageUrl,
                        class: "o-panel__brokerImage",
                        alt: "Billede af ejendomsmægleren"
                      }, null, 8 /* PROPS */, _hoisted_3),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h3", {
                          class: "a-heading3 o-panel__name",
                          textContent: _toDisplayString($props.employee.name)
                        }, null, 8 /* PROPS */, _hoisted_5),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", {
                            class: "a-label",
                            textContent: _toDisplayString(
                                    `${$props.employee.title}${$props.employee.title && $props.employee.brokerName ? ', ' : ''}${$props.employee.brokerName}`
                                )
                          }, null, 8 /* PROPS */, _hoisted_7)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "o-panel__navItem a-heading3",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.nextPanel('broker', true)))
                      }, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString($props.menu.contactBroker)
                        }, null, 8 /* PROPS */, _hoisted_9),
                        _createVNode(_component_a_icon, {
                          name: "chevron_right",
                          class: "o-panel__icon"
                        })
                      ])
                    ])
                  ]))
                : (_ctx.currentPanel === 'broker')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("h2", {
                        class: "a-heading2 o-panel__title o-panel__heading",
                        textContent: _toDisplayString($props.texts.broker.heading)
                      }, null, 8 /* PROPS */, _hoisted_11),
                      _createVNode(_component_o_employee_card, {
                        panel: true,
                        employee: $props.employee,
                        class: "o-panel__brokerCard"
                      }, null, 8 /* PROPS */, ["employee"]),
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.currentSubPanel === 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createElementVNode("p", {
                                  class: "a-paragraph o-subPanel__description",
                                  textContent: _toDisplayString($props.texts.broker.description)
                                }, null, 8 /* PROPS */, _hoisted_13),
                                _createElementVNode("div", _hoisted_14, [
                                  _createVNode(_component_m_textarea, {
                                    modelValue: $data.form.message,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.message) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.message,
                                    label: `${$props.texts.form.message}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.name,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.name) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.name,
                                    label: `${$props.texts.form.name}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.mail,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.mail) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.mail,
                                    label: `${$props.texts.form.mail}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  _createVNode(_component_m_input, {
                                    modelValue: $data.form.phone,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.form.phone) = $event)),
                                    class: "o-fields__field",
                                    error: $data.errors.phone,
                                    label: `${$props.texts.form.phone}*`
                                  }, null, 8 /* PROPS */, ["modelValue", "error", "label"]),
                                  ($props.consents && $props.consents.contactConsent)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "a-small a-small--allowBreak o-fields__field",
                                        innerHTML: $props.consents.contactConsent.purposeText
                                      }, null, 8 /* PROPS */, _hoisted_15))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _createElementVNode("div", {
                                  class: _normalizeClass(["o-buttons o-subPanel__actions", {
                                'o-subPanel__actions--fixed': _ctx.fixedPanel,
                            }])
                                }, [
                                  (!_ctx.fixedPanel)
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        type: "button",
                                        class: "m-button m-button--transparent",
                                        onClick: _cache[5] || (_cache[5] = ($event: any) => ($options.previousPanel('')))
                                      }, [
                                        _createVNode(_component_a_icon, {
                                          class: "m-button__icon",
                                          name: "arrow_back"
                                        }),
                                        _createElementVNode("span", {
                                          class: "m-button__text",
                                          textContent: _toDisplayString($props.texts.backButton)
                                        }, null, 8 /* PROPS */, _hoisted_16)
                                      ]))
                                    : _createCommentVNode("v-if", true),
                                  _createElementVNode("button", {
                                    type: "submit",
                                    class: _normalizeClass(["m-button", { isLoading: $data.isLoading }]),
                                    onClick: _cache[6] || (_cache[6] = ($event: any) => ($options.onSubmit('broker')))
                                  }, [
                                    _createElementVNode("span", {
                                      class: "m-button__text",
                                      textContent: _toDisplayString($props.texts.broker.submitButton)
                                    }, null, 8 /* PROPS */, _hoisted_17)
                                  ], 2 /* CLASS */)
                                ], 2 /* CLASS */)
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], undefined, true),
                        _: 1 /* STABLE */
                      })
                    ]))
                  : (_ctx.currentPanel === 'successMessage')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("h2", {
                          class: "a-heading2 o-panel__title o-panel__heading",
                          textContent: _toDisplayString($props.texts.broker.successHeading)
                        }, null, 8 /* PROPS */, _hoisted_19),
                        _createVNode(_component_o_employee_card, {
                          panel: true,
                          employee: $props.employee,
                          class: "o-panel__brokerCard"
                        }, null, 8 /* PROPS */, ["employee"]),
                        _createElementVNode("p", {
                          class: "a-paragraph o-subPanel_description",
                          textContent: _toDisplayString(
                        $options.formattedSuccessMessage($props.texts.broker.successMessage)
                    )
                        }, null, 8 /* PROPS */, _hoisted_20)
                      ]))
                    : _createCommentVNode("v-if", true)
            ], undefined, true),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["name"])
        ], undefined, true),
        _: 1 /* STABLE */
      }))
    : _createCommentVNode("v-if", true)
}