
interface Data {
    showComponent: boolean;
    showGrid: boolean;
}

export default {
    name: 'a-grid-overlay',
    data(): Data {
        return {
            showComponent: false,
            showGrid: false,
        };
    },

    mounted(): void {
        if (process.env.NODE_ENV === 'production') {
            this.showComponent = false;
            return;
        }

        this.showComponent = true;
    },

    methods: {
        toggle(): void {
            this.showGrid = !this.showGrid;
        },
    },
};
