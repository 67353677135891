
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import oEmployeeCard from '@/components/organisms/broker/EmployeeCard.vue';
// import oPropertyCard from '@/components/organisms/property/PropertyCard.vue';
// import Property from '@/interfaces/property.interface';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Format from '@/functions/format';
import LeadConsent from '@/interfaces/leadConsent.interface';
import Employee from '@/interfaces/employee.interface';
import tracking from '@/functions/tracking';
import CurrentUser from '@/interfaces/currentUser.interface';

export interface Texts {
    backButton?: string;
    form?: {
        compliance: string;
        mail: string;
        marketing: string;
        message: string;
        name: string;
        offer: string;
        phone: string;
    };
    broker?: {
        heading: string;
        description: string;
        submitButton: string;
        successHeading: string;
        successMessage: string;
    };
    brokerStore?: {
        storeImageUrl: string;
        storeName: string;
        storePhone: string;
        storeAddress: string;
        storeZipCode: string;
        storeCity: string;
        hasPicture: boolean;
    };
}

interface Consents {
    contactConsent?: LeadConsent;
}

interface Data {
    currentDirection: string;
    optionText: string;
    errors: {
        date?: string;
        mail?: string;
        marketing?: string;
        message?: string;
        name?: string;
        phone?: string;
    };
    form: {
        date: string;
        mail: string;
        marketing: boolean;
        message: string;
        name: string;
        phone: string;
    };
    isLoading: boolean;
    successMessage: string;
}

export interface IMenu {
    contactBroker?: string;
}

export default {
    components: {
        oEmployeeCard,
        // oPropertyCard,
    },

    props: {
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                address: '',
                brokerId: '',
                brokerName: '',
                city: '',
                description: '',
                employeeId: '',
                email: '',
                imageUrl: '',
                linkedIn: '',
                name: '',
                phone: '',
                title: '',
                type: '',
                videoUrl: '',
                zipCode: '',
            }),
        },
        consents: {
            type: Object as PropType<Consents>,
            default: () => ({
                contactConsent: {
                    id: '',
                    purposeText: '',
                },
            }),
        },
        menu: {
            type: Object as PropType<IMenu>,
            default: () => ({
                contactBroker: '',
            }),
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                backButton: '',
                broker: {
                    heading: '',
                    description: '',
                    submitButton: '',
                    successHeading: '',
                    successMessage: '',
                },
                form: {
                    compliance: '',
                    mail: '',
                    marketing: '',
                    message: '',
                    name: '',
                    offer: '',
                    phone: '',
                },
            }),
        },
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                email: '',
                fullName: '',
            }),
        },
    },

    data(): Data {
        return {
            currentDirection: '',
            errors: {},
            optionText: '',
            form: {
                date: '',
                mail: this.user?.email ?? '',
                marketing: false,
                message: '',
                name: this.user?.fullName ?? '',
                phone: '',
            },
            isLoading: false,
            successMessage: '',
        };
    },

    computed: {
        ...mapGetters({
            currentPanel: 'projectSale/currentPanel',
            currentSubPanel: 'projectSale/currentSubPanel',
            fixedPanel: 'modal/fixed',
            modalShow: 'modal/show',
            modalType: 'modal/type',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow && this.modalType === 'projectSale';
        },

        formattedEmployeeImageUrl(): string {
            return this.employee.imageUrl &&
                this.employee.imageUrl.includes('deviceid')
                ? Format.formatMindworkingUrl(
                      this.employee.imageUrl ?? '',
                      '96',
                      '',
                  )
                : this.employee.imageUrl ?? '';
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },
    },

    mounted() {
        // console.log('mounted')
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'projectSale/setCurrentPanel',
            setCurrentSubPanel: 'projectSale/setCurrentSubPanel',
        }),

        formattedSuccessMessage(successMessage: string): string {
            return (
                successMessage
                    .replace('#navn#', this.form.name)
                    .replace('#mail#', this.form.mail.trim())
                    .replace('#telefon#', this.form.phone) ?? ''
            );
        },

        /**
         * Update value in form.
         *
         * @param {string} key
         * @param {string} value
         * @return {void}
         */
        onChange(key: string, value: string): void {
            // Vue.set(this.form, key, value);

            this.form[key] = value;
        },

        getSubmitButtonText(value: string): string {
            let returnValue = '';
            switch (value) {
                case 'broker':
                    returnValue = this.texts.broker?.submitButton ?? '';
                    break;
                default:
                    returnValue = '';
                    break;
            }
            return returnValue;
        },

        getTrackingText(value: string): string {
            let returnValue = '';
            switch (value) {
                case 'broker':
                    returnValue = 'Kontakt maegler';
                    break;
                default:
                    returnValue = '';
                    break;
            }
            return returnValue;
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(value: string): void {
            this.isLoading = true;

            let excludeValidation: string[] = [];
            let type = '';
            let id = '';
            switch (value) {
                case 'broker':
                    type = LeadTypes.Contact.toString();
                    id = this.consents?.contactConsent?.id ?? '';
                    excludeValidation =
                        this.form?.mail.trim() === '' ? ['email'] : [];
                    break;
                default:
                    break;
            }

            Api.postLead(
                {
                    consentIdGlobal: id,
                    address: '',
                    email: this.form.mail.trim() ?? '',
                    firstName: Format.firstName(this.form.name) ?? '',
                    lastName: Format.lastName(this.form.name) ?? '',
                    message: this.form.message ?? '',
                    phoneNumber: this.form.phone ?? '',
                    responsibleShopNo: this.employee?.brokerId ?? '',
                    type,
                    httpReferral: this.referrer,
                    utmCampaign: this.utmCampaign,
                    utmContent: this.utmContent,
                    utmMedium: this.utmMedium,
                    utmSource: this.utmSource,
                },
                excludeValidation,
            )
                .then((): void => {
                    // this.setCurrentSubPanel(2);
                    this.nextPanel('successMessage', false);
                    tracking.track(
                        'trackForm',
                        'Formular',
                        'Kontakt maegler gennemfoert',
                        'Kontakt maegler',
                    );
                })
                .catch((error: any): void => {
                    this.errors = error.errors ?? [];
                })
                .finally((): void => {
                    this.isLoading = false;
                });
        },

        /**
         * Go to the next panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        nextPanel(value: string, track: boolean): void {
            this.currentDirection = 'next';
            this.setCurrentPanel(value);
            if (track) {
                tracking.track(
                    'trackForm',
                    'Formular',
                    `${this.getTrackingText(value)} initieret`,
                    `${this.getTrackingText(value)}`,
                );
            }
        },

        /**
         * Go to the previous panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        previousPanel(value: string): void {
            this.errors = {};
            this.currentDirection = 'prev';
            this.setCurrentPanel(value);
        },
    },
};
