import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "o-tags o-pointOfInterestSelectors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_m_tag = _resolveComponent("m-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_m_tag, {
      key: 'favorite',
      theme: "light",
      checked: _ctx.showFavorites,
      onInput: _cache[0] || (_cache[0] = ($event: any) => ($options.updateFavoritesState()))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_a_icon, {
          name: "favorites_tag_inctive",
          class: "m-tag__icon m-tag__icon--hug-border"
        }),
        _createVNode(_component_a_icon, {
          name: "favorites_tag",
          class: "m-tag__icon m-tag__icon--hug-border"
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(" Lokale favoritsteder ")
      ], undefined, true),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["checked"])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.poiCategories, (category, categoryIndex) => {
      return (_openBlock(), _createBlock(_component_m_tag, {
        key: `${category.key}-${categoryIndex}`,
        theme: "light",
        checked: _ctx.selectedPois.some((x) => x.key === category.key),
        disabled: !_ctx.selectorsEnabled,
        onInput: ($event: any) => (
                $options.updateSelectedPois({
                    key: category.key,
                    name: category.name,
                    value: category.value,
                })
            )
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(category.name), 1 /* TEXT */)
        ], undefined, true),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["checked", "disabled", "onInput"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}