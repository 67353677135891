
import { PropType } from 'vue';

interface Data {
    currentIndex: number;
}

export default {
    name: 'm-status-indicator',
    props: {
        activeStep: {
            type: Number as PropType<number>,
            default: () => 1,
        },
        steps: {
            type: Number as PropType<number>,
            default: () => 1,
        },
    },

    data(): Data {
        return {
            currentIndex: this.$props.activeStep,
        };
    },

    watch: {
        activeStep: {
            deep: true,
            handler() {
                this.currentIndex = this.$props.activeStep;
            },
        },
    },

    methods: {
        onClick(index: number): void {
            this.currentIndex = index;
            this.$emit('changeActiveStep', index);
        },
    },
};
