
import { PropType } from 'vue';
import Api from '../../../functions/api';
import mHorizontalChart from '../../molecules/chart/HorizontalChart.vue';

export interface Texts {
    buttonText: string;
    graphHeading: string;
    pollDescription: string;
    pollHeading: string;
    pollButtonText: string;
    pollReceiptText: string;
}

interface FeatureVote {
    name: string;
    id: number;
    votes: number;
}

interface Data {
    dataset: FeatureVote[];
    state: string;
    votedFor: number[];
}

export default {
    components: {
        mHorizontalChart,
    },

    props: {
        initialDataset: {
            type: Array as PropType<FeatureVote[]>,
            default: () => ([]),
        },
        neighborhoodId: {
            type: Number as PropType<number>,
            default: () => 0,
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                buttonText: '',
                graphHeading: '',
                pollDescription: '',
                pollHeading: '',
                pollButtonText: '',
                pollReceiptText: '',
            }),
        },
    },

    data(): Data {
        return {
            dataset: this.initialDataset,
            state: '',
            votedFor: [],
        };
    },

    mounted(): void {
        if (this.dataset && this.dataset.every(x => x.votes === 0)) {
            this.state = 'voting';
        }
    },

    methods: {
        /**
         * Is the label (button) disabled?
         *
         * @param {number} idx
         * @return {boolean}
         */
        isDisabled(value: number): boolean {
            return !this.votedFor.includes(value) && this.votedFor.length === 5;
        },
        /**
         * Change the current state.
         *
         * @param {string} state
         * @return {void}
         */
        changeState(state: string): void {
            this.state = state;
        },

        /**
         * Handle toggling of buttons.
         *
         * @param {string} value
         * @return {void}
         */
        onToggle(value: number): void {
            if (!this.votedFor.includes(value)) {
                this.votedFor.push(value);
            } else {
                let indexToRemove = this.votedFor.findIndex((element) => element === value);
                this.votedFor.splice(indexToRemove, 1);
            }
        },

        /**
         * Handle submission of votes.
         *
         * @return {void}
         */
        submit(): void {
            if (this.neighborhoodId) {
                Api.postNeighborhoodLocalVotes({
                    votes: this.votedFor,
                    neighborhoodId: this.neighborhoodId,
                }).then(() => {
                    this.votedFor.forEach((id: number) => {
                        const item = this.dataset.find(x => x.id === id);
                        if (item) {
                            item.votes += 1;
                        }
                    });

                    this.dataset.sort((a: FeatureVote, b: FeatureVote) => {
                        return (a.votes > b.votes) ? -1 : 1;
                    });

                    this.state = 'voted';
                });
            }
        },
    },
};
