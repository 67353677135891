import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "o-homeEstimateResultsMap u-container" }
const _hoisted_2 = { class: "o-homeEstimateResultsMap__mapContainer" }
const _hoisted_3 = {
  ref: "map",
  class: "o-homeEstimateResultsMap__mapContainer--inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_map = _resolveComponent("m-map")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_m_map, {
          class: "o-neighborhoodMap__map",
          address: $options.propertyAddress,
          "add-zoom": $data.addZoom,
          center: $options.propertyCoordinates,
          favorites: $data.favorites,
          "is-loading": $data.isLoading,
          "map-type": 'propertymap',
          "max-bounds": $data.maxBounds,
          properties: $data.properties,
          zoom: $data.zoom,
          texts: $props.texts,
          onLoad: $options.onLoad,
          onMove: $options.onMove
        }, null, 8 /* PROPS */, ["address", "add-zoom", "center", "favorites", "is-loading", "max-bounds", "properties", "zoom", "texts", "onLoad", "onMove"])
      ], 512 /* NEED_PATCH */)
    ])
  ]))
}