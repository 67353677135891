
import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import {
    BOUNDS,
    LATITUDE,
    LONGITUDE,
    MAX_BOUNDS,
} from '@/constants/search.const';
import Broker from '@/interfaces/broker.interface';
import mMap from '@/components//molecules/Map.vue';
import oEmployeeCard from '@/components/organisms/broker/EmployeeCard.vue';

interface Data {
    bounds: number[][];
    headline?: string;
    isInteractive: boolean;
    isLoading: boolean;
    latitude: number;
    longitude: number;
    maxBounds: { lat: number; lng: number }[];
    zoom: number;
}

type Coordinates = {
    lat: number;
    lon: number;
};

export default {
    components: {
        oEmployeeCard,
        mMap,
    },

    props: {
        headline: {
            type: String,
            default() {
                return '';
            },
        },
        text: {
            type: String,
            default() {
                return '';
            },
        },
        responsibleBroker: {
            type: Object as PropType<Broker>,
            default() {
                return {};
            },
        },
        addressCoordinates: {
            type: Object as PropType<Coordinates>,
            default() {
                return { lon: LONGITUDE, lat: LATITUDE };
            },
        },
        fullAddress: {
            type: String,
            default() {
                return '';
            },
        },
    },

    data(): Data {
        return {
            bounds: BOUNDS,
            isInteractive: false,
            isLoading: true,
            latitude: LATITUDE,
            longitude: LONGITUDE,
            maxBounds: MAX_BOUNDS,
            zoom: 14,
        };
    },
    computed: {
        ...mapGetters({
            coordinates: 'homeEstimate/propertyCoordinates',
            areaName: 'homeEstimate/areaName',
            address: 'homeEstimate/address',
        }),
        headlineWithArea() {
            return this.headline.replace('#bynavn#', this.areaName);
        },
        propertyAddress() {
            if (this.address) return this.address;
            return {
                geometry: {
                    coordinates: [
                        (this.addressCoordinates as Coordinates).lon,
                        (this.addressCoordinates as Coordinates).lat,
                    ],
                    type: 'Point',
                },
                properties: {
                    label: this.fullAddress,
                },
                type: 'Feature',
            };
        },
        propertyCoordinates() {
            if (this.coordinates) return this.coordinates;
            return [
                (this.addressCoordinates as Coordinates).lon,
                (this.addressCoordinates as Coordinates).lat,
            ];
        },
    },
};
