
import { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import mCheckbox from '@/components/molecules/form/Checkbox.vue';
import mDatePicker from '@/components/molecules/form/DatePicker.vue';
import mRangeSlider from '@/components/molecules/form/RangeSlider.vue';
import oEmployeeCard from '@/components/organisms/broker/EmployeeCard.vue';
import oPropertyCard from '@/components/organisms/property/PropertyCard.vue';
import oOverlayPromoBox from '@/components/molecules/OverlayPromoBox.vue';
import Property from '@/interfaces/property.interface';
import Api from '@/functions/api';
import { LeadTypes } from '@/enums/leadtypes.enum';
import Format from '@/functions/format';
import OpenHouse from '@/interfaces/openHouse.interface';
import LeadConsent from '@/interfaces/leadConsent.interface';
import Employee from '@/interfaces/employee.interface';
import tracking from '@/functions/tracking';
import CurrentUser from '@/interfaces/currentUser.interface';
import IOverlayPromoBox from '@/interfaces/overlayPromoBox.interface';

export interface Texts {
    backButton?: string;
    form?: {
        compliance: string;
        mail: string;
        marketing: string;
        message: string;
        name: string;
        offer: string;
        phone: string;
    };
    broker?: {
        description: string;
        submitButton: string;
        successMessage: string;
        title: string;
    };
    brokerStore?: {
        storeImageUrl: string;
        storeName: string;
        storePhone: string;
        storeAddress: string;
        storeZipCode: string;
        storeCity: string;
        hasPicture: boolean;
    };
    material?: {
        title: string;
        description: string;
        documentsButton: string;
        submitButton: string;
        successMessage: string;
        downloadsTitle: string;
    };
    offer?: {
        title: string;
        description: string;
        submitButton: string;
        successMessage: string;
    };
    openHouse?: {
        openHouseSignupHeader: string;
        openHouseNoSignupHeader: string;
        openHouseSignupDateBodyText: string;
        openHouseNoSignupDateBodyText: string;
        openHouseSignupFormBodyText: string;
        openHouseSignupDateCtaText: string;
        openHouseSignupFormCtaText: string;
        openHouseSignupReceiptBodyText: string;
        smallTextNoSignup: string;
        smallTextSignup: string;
    };
    showing?: {
        asapText: string;
        title: string;
        description: string;
        openHouseTitle: string;
        openHouseDescription: string;
        openHouseButtonText: string;
        showingStartTime: string;
        showingEndTime: string;
        requestShowingButton: string;
        description2: string;
        step2BodyTextFast: string;
        submitButton: string;
        successMessage: string;
    };
}

interface Consents {
    contactConsent?: LeadConsent;
    materialConsent?: LeadConsent;
    showingConsent?: LeadConsent;
    openHouseConsent?: LeadConsent;
    offerConsent?: LeadConsent;
}

interface Data {
    asap: boolean;
    currentDirection: string;
    optionText: string;
    errors: {
        date?: string;
        mail?: string;
        marketing?: string;
        message?: string;
        name?: string;
        offer?: string;
        openHouse?: string;
        phone?: string;
        timeRange?: string;
    };
    form: {
        date: string;
        mail: string;
        marketing: boolean;
        message: string;
        name: string;
        offer: string;
        openHouse: OpenHouse;
        phone: string;
        timeRange: number[];
    };
    isLoading: boolean;
    successMessage: string;
    showingDateHasBeenSelected: boolean;
    openHouseDateSelected: boolean;
    showingTimeRange: number[];
}

export interface DatePickerStartDate {
    year: number;
    month: number;
    day: number;
}

export interface IMenu {
    showing?: string;
    openHouse?: string;
    openHouseWithoutSignup?: string;
    makeAnOffer?: string;
    material?: string;
    contactBroker?: string;
}

export default {
    components: {
        mCheckbox,
        mDatePicker,
        mRangeSlider,
        oEmployeeCard,
        oPropertyCard,
        oOverlayPromoBox,
    },

    props: {
        employee: {
            type: Object as PropType<Employee>,
            default: () => ({
                address: '',
                brokerId: '',
                brokerName: '',
                city: '',
                description: '',
                employeeId: '',
                email: '',
                imageUrl: '',
                linkedIn: '',
                name: '',
                phone: '',
                title: '',
                type: '',
                videoUrl: '',
                zipCode: '',
            }),
        },
        consents: {
            type: Object as PropType<Consents>,
            default: () => ({
                contactConsent: {
                    id: '',
                    purposeText: '',
                },
                materialConsent: {
                    id: '',
                    heading: '',
                    purposeText: '',
                },
                showingConsent: {
                    id: '',
                    purposeText: '',
                },
                openHouseConsent: {
                    id: '',
                    purposeText: '',
                },
                offerConsent: {
                    id: '',
                    purposeText: '',
                },
            }),
        },
        datepickerDates: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        offerAllowed: {
            type: Boolean as PropType<boolean>,
            default: () => false,
        },
        menu: {
            type: Object as PropType<IMenu>,
            default: () => ({
                showing: '',
                openHouse: '',
                openHouseWithoutSignup: '',
                makeAnOffer: '',
                material: '',
                contactBroker: '',
            }),
        },
        openHouses: {
            type: Array as PropType<OpenHouse[]>,
            default: () => [],
        },
        property: {
            type: Object as PropType<Property>,
            default: () => ({
                address: '',
                city: '',
                documents: [],
                factsDesktop: [],
                factsMobile: [],
                propertyId: '',
                propertySize: 0,
                type: '',
                url: '',
                zipCode: 0,
                isUnderSale: false,
            }),
        },
        texts: {
            type: Object as PropType<Texts>,
            default: () => ({
                backButton: '',
                form: {
                    compliance: '',
                    mail: '',
                    marketing: '',
                    message: '',
                    name: '',
                    offer: '',
                    phone: '',
                },
                broker: {
                    description: '',
                    submitButton: '',
                    successMessage: '',
                    title: '',
                },
                brokerStore: {
                    storeImageUrl: '',
                    storeName: '',
                    storePhone: '',
                    storeAddress: '',
                    storeZipCode: '',
                    storeCity: '',
                    hasPicture: false,
                },
                material: {
                    successMessage: '',
                    submitButton: '',
                    title: '',
                    description: '',
                    documentsButton: '',
                    downloadsTitle: '',
                },
                offer: {
                    description: '',
                    submitButton: '',
                    successMessage: '',
                    title: '',
                },
                openHouse: {
                    openHouseSignupFormBodyText: '',
                    openHouseSignupFormCtaText: '',
                    smallTextSignup: '',
                    smallTextNoSignup: '',
                    openHouseSignupHeader: '',
                    openHouseNoSignupHeader: '',
                    openHouseSignupDateBodyText: '',
                    openHouseNoSignupDateBodyText: '',
                    openHouseSignupDateCtaText: '',
                    openHouseSignupReceiptBodyText: '',
                },
                showing: {
                    asapText: '',
                    title: '',
                    description: '',
                    description2: '',
                    openHouseTitle: '',
                    openHouseDescription: '',
                    openHouseButtonText: '',
                    showingStartTime: '',
                    showingEndTime: '',
                    requestShowingButton: '',
                    submitButton: '',
                    successMessage: '',
                    step2BodyTextFast: '',
                },
            }),
        },
        promoBoxesShowing: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesOpenHouse: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesMakeAnOffer: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesMaterial: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        promoBoxesContactBroker: {
            type: Array as PropType<IOverlayPromoBox[]>,
            default: () => [],
        },
        user: {
            type: Object as PropType<CurrentUser>,
            default: () => ({
                email: '',
                fullName: '',
            }),
        },
    },

    data(): Data {
        return {
            asap: false,
            currentDirection: '',
            errors: {},
            optionText: '',
            form: {
                date: '',
                mail: this.user?.email ?? '',
                marketing: false,
                message: '',
                name: this.user?.fullName ?? '',
                offer: '',
                openHouse: this.openHouses[0],
                phone: '',
                timeRange: [
                    this.texts?.showing?.showingStartTime
                        ? parseInt(
                              this.texts.showing.showingStartTime
                                  .replace(':', '')
                                  .replace('.', '')
                                  .replace('AM', '')
                                  .replace('PM', ''),
                          ) ?? 800
                        : 800,
                    this.texts?.showing?.showingEndTime
                        ? parseInt(
                              this.texts.showing.showingEndTime
                                  .replace(':', '')
                                  .replace('.', '')
                                  .replace('AM', '')
                                  .replace('PM', ''),
                          ) ?? 2000
                        : 2000,
                ],
            },
            isLoading: false,
            successMessage: '',
            showingDateHasBeenSelected: false,
            openHouseDateSelected: false,
            showingTimeRange: [
                this.texts?.showing?.showingStartTime
                    ? parseInt(
                          this.texts.showing.showingStartTime
                              .replace(':', '')
                              .replace('.', '')
                              .replace('AM', '')
                              .replace('PM', ''),
                      ) ?? 800
                    : 800,
                this.texts?.showing?.showingEndTime
                    ? parseInt(
                          this.texts.showing.showingEndTime
                              .replace(':', '')
                              .replace('.', '')
                              .replace('AM', '')
                              .replace('PM', ''),
                      ) ?? 2000
                    : 2000,
            ],
        };
    },

    computed: {
        ...mapGetters({
            currentPanel: 'property/currentPanel',
            currentSubPanel: 'property/currentSubPanel',
            fixedPanel: 'modal/fixed',
            modalShow: 'modal/show',
            modalType: 'modal/type',
            trackingEntrance: 'modal/trackingEntrance',
            referrer: 'document/referrer',
            utmSource: 'document/utmSource',
            utmMedium: 'document/utmMedium',
            utmCampaign: 'document/utmCampaign',
            utmContent: 'document/utmContent',
        }),

        formatAsapText(): string {
            return this.texts?.showing?.asapText
                ? this.texts?.showing?.asapText
                : 'Hurtigst muligt';
        },

        /**
         * Check if the modal should be visible.
         *
         * @return {boolean}
         */
        isVisible(): boolean {
            return this.modalShow && this.modalType === 'property';
        },

        anyRequiredOpenHouse(): boolean {
            return this.openHouses.some((o: OpenHouse) => o.signupRequired);
        },

        showingSelectedOpenHouse(): string {
            const openHouse = this.openHouses.find(
                (o: OpenHouse) =>
                    new Date(o.openHouseFrom).toString().slice(0, 10) ===
                    this.form.date.slice(0, 10),
            );

            if (openHouse) {
                const fromDateHour =
                    openHouse.openHouseFromCET?.slice(11, 16) ?? '';
                const toDateHour =
                    openHouse.openHouseToCET?.slice(11, 16) ?? '';

                return `${this.optionText} kl. ${fromDateHour}&mdash;${toDateHour}`;
            }

            return '';
        },

        openHouseDates(): [string, string, boolean][] {
            let openHouseDates: [string, string, boolean][] = [];
            this.openHouses.forEach((openHouse: OpenHouse) => {
                const fromDate = new Date(openHouse.openHouseFromCET);
                const newFromDate = new Date(
                    fromDate.setHours(fromDate.getHours()),
                ).toString();
                const toDate = new Date(openHouse.openHouseToCET);
                const newToDate = new Date(
                    toDate.setHours(toDate.getHours()),
                ).toString();

                openHouseDates.push([
                    newFromDate,
                    newToDate,
                    openHouse.signupRequired,
                ]);
            });
            return openHouseDates;
        },

        dateHasOpenHouse(): boolean {
            if (this.form.date) {
                const date = new Date(this.form.date);
                const shortDate = date.toISOString().substring(0, 10);

                return this.openHouses.some(
                    (o: OpenHouse) =>
                        o.openHouseFromCET.substring(0, 10) === shortDate,
                );
            }

            return false;
        },

        anyOpenHouseSignup(): boolean {
            return this.openHouses.some((o) => o.signupRequired);
        },

        formatOpenHouseBody(): string {
            const from = new Date(this.form.openHouse.openHouseFromCET);
            const to = new Date(this.form.openHouse.openHouseToCET);
            const dayName = Format.dayNameLong(from);
            const date = Format.dateShort(from);
            const monthName = Format.monthLong(from);
            const fromTime = Format.hourMinute(from);
            const toTime = Format.hourMinute(to);
            let formattedDate = `${dayName} d. ${date} ${monthName} kl. ${fromTime} &mdash; ${toTime}`;
            return (
                this.texts.openHouse?.openHouseSignupFormBodyText?.replace(
                    '#valgtedato#',
                    formattedDate ?? '',
                ) ?? ''
            );
        },

        showingDescription(): string {
            let description = this.texts.showing
                ? this.texts.showing.description2
                : '#date#';

            if (this.asap) {
                description = this.texts.showing?.step2BodyTextFast
                    ? this.texts.showing?.step2BodyTextFast
                    : 'Du har ønsket en fremvisning Hurtigst muligt - hvordan kan vi kontakte dig?';
            } else {
                description =
                    description
                        ?.replace('#dato#', this.optionText)
                        ?.replace(
                            '#tidspunkt#',
                            `${Format.prettifyHourMinute(
                                this.form.timeRange[0],
                            )} og ${Format.prettifyHourMinute(
                                this.form.timeRange[1],
                            )}`,
                        ) ?? '';
            }

            return description;
        },

        formattedEmployeeImageUrl(): string {
            return this.employee.imageUrl &&
                this.employee.imageUrl.includes('deviceid')
                ? Format.formatMindworkingUrl(
                      this.employee.imageUrl ?? '',
                      '96',
                      '',
                  )
                : this.employee.imageUrl ?? '';
        },
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.errors = {};
            },
        },

        'form.date': {
            deep: true,
            handler() {
                if (!this.asap) {
                    const d = new Date(this.form.date);
                    const selectedDay = d.getDate();
                    const selectedDayName = Format.dayNameLong(d);
                    const selectedMonthName = Format.monthLong(d);

                    this.optionText =
                        `${selectedDayName} d. ${selectedDay} ${selectedMonthName}`.trim();
                } else {
                    this.optionText = 'snarest muligt';
                }
            },
        },
    },

    mounted() {
        if (this.openHouses && this.openHouses.length >= 1) {
            if (this.openHouses[0].signupRequired) {
                this.openHouseDateSelected = true;
            }
        }
    },

    methods: {
        ...mapActions({
            setCurrentPanel: 'property/setCurrentPanel',
            setCurrentSubPanel: 'property/setCurrentSubPanel',
        }),

        formattedSuccessMessage(successMessage: string): string {
            return (
                successMessage
                    .replace('#navn#', this.form.name)
                    .replace('#mail#', this.form.mail)
                    .replace('#telefon#', this.form.phone) ?? ''
            );
        },

        incrementDocumentDownloadsCount(downloadName: string): void {
            if (this.property.propertyId) {
                tracking.track(
                    'trackDownload',
                    'Dokument download',
                    downloadName,
                    window.location.href,
                );
            }
        },

        /**
         * Update value in form.
         *
         * @param {string} key
         * @param {string} value
         * @return {void}
         */
        onChange(key: string, value: string): void {
            this.form[key] = value;
        },

        onOpenHouseDateSelected(payload: string): void {
            this.form.openHouse = this.openHouses[parseInt(payload)];
            this.openHouseDateSelected = true;
        },

        onDateSelected(payload: string): void {
            if (payload === 'asap') {
                this.asap = true;
            } else {
                this.asap = false;
                this.form.date = payload;
            }
            this.showingDateHasBeenSelected = true;
        },

        getSubmitButtonText(value: string): string {
            let returnValue = '';
            switch (value) {
                case 'broker':
                    returnValue = this.texts.broker?.submitButton ?? '';
                    break;
                case 'material':
                    returnValue = this.texts.material?.submitButton ?? '';
                    break;
                case 'offer':
                    returnValue = this.texts.offer?.submitButton ?? '';
                    break;
                case 'openHouse':
                    returnValue =
                        this.texts.openHouse?.openHouseSignupFormCtaText ?? '';
                    break;
                case 'showing':
                    returnValue = this.texts.showing?.submitButton ?? '';
                    break;
                default:
                    returnValue = '';
                    break;
            }
            return returnValue;
        },

        getTrackingText(value: string): string {
            let returnValue = '';
            switch (value) {
                case 'broker':
                    returnValue = 'Kontakt maegler';
                    break;
                case 'material':
                    returnValue = 'Hent salgsmateriale';
                    break;
                case 'offer':
                    returnValue = 'Giv et bud';
                    break;
                case 'openHouse':
                    returnValue = 'Aabent hus';
                    break;
                case 'showing':
                    returnValue = 'Bestil fremvisning';
                    break;
                default:
                    returnValue = '';
                    break;
            }
            return returnValue;
        },

        changeCurrentSubPanel(value: number, formSection: string): void {
            // Go back
            if (value === 1) {
                this.setCurrentSubPanel(value);
                return;
            }

            if (formSection === 'showing') {
                tracking.trackRawEvent({
                    event: 'trackForm',
                    eventData: {
                        category: 'Formular',
                        action: `${this.getTrackingText(
                            formSection,
                        )} stepskift`,
                        label: `${this.getTrackingText(formSection)}`,
                        formularIndgang: this.trackingEntrance ?? '',
                        formularStepnavn: 'Brugerinformation',
                        maeglerId: this.employee.brokerId ?? '',
                    },
                });
            }

            if (formSection === 'openHouse') {
                tracking.trackRawEvent({
                    event: 'trackForm',
                    eventData: {
                        category: 'Formular',
                        action: `${this.getTrackingText(
                            formSection,
                        )} stepskift`,
                        label: `${this.getTrackingText(formSection)}`,
                        formularIndgang: this.trackingEntrance ?? '',
                        formularStepnavn: 'Brugerinformation',
                        maeglerId: this.employee.brokerId ?? '',
                    },
                });
            }

            this.setCurrentSubPanel(value);
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onSubmit(value: string): void {
            this.isLoading = true;

            let type = '';
            let id = '';
            let comment = '';
            switch (value) {
                case 'broker':
                    type = LeadTypes.ContactEmployeeSpecific.toString();
                    id = this.consents?.contactConsent?.id ?? '';
                    break;
                case 'material':
                    id = this.consents?.materialConsent?.id ?? '';
                    type = LeadTypes.SalesMaterialWithContact.toString();
                    break;
                case 'offer':
                    id = this.consents?.offerConsent?.id ?? '';
                    type = LeadTypes.PurchaseOffer.toString();
                    break;
                case 'openHouse':
                    id = this.consents?.openHouseConsent?.id ?? '';
                    type = LeadTypes.OpenHouse.toString();
                    break;
                case 'showing':
                    type = LeadTypes.Presentation.toString();
                    id = this.consents?.showingConsent?.id ?? '';
                    comment = `Kunden ønsker en fremvisning ${
                        this.asap ? 'snarest muligt' : this.optionText
                    } mellem ${this.numberToMinutesConverter(
                        this.form.timeRange[0].toString(),
                    )}-${this.numberToMinutesConverter(
                        this.form.timeRange[1].toString(),
                    )}`;
                    break;
                default:
                    break;
            }

            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);

            Api.postLead({
                consentIdGlobal: id,
                email: this.form.mail.trim() ?? '',
                firstName: Format.firstName(this.form.name) ?? '',
                lastName: Format.lastName(this.form.name) ?? '',
                message: this.form.message ?? '',
                phoneNumber: this.form.phone ?? '',
                responsibleEmployeeIdGlobal: this.employee.employeeId ?? '',
                caseNo: this.property.propertyId,
                shopNo: this.employee.brokerId ?? '',
                presentationDate:
                    (value === 'showing' && !this.asap
                        ? new Date(this.form.date)
                        : tomorrow
                    )
                        ?.toJSON()
                        ?.slice(0, 10) ?? '',
                comment: value === 'showing' ? comment : '',
                openHouseId:
                    value === 'openHouse'
                        ? this.form.openHouse.appointmentId
                        : '',
                openHouseStartTime: (value === 'openHouse'
                    ? new Date(this.form.openHouse.openHouseFromCET)
                    : new Date()
                ).toJSON(),
                offerAmount:
                    value === 'offer'
                        ? parseInt(this.form.offer.replace(/\D/g, ''))
                        : 0,
                type,
                httpReferral: this.referrer,
                utmCampaign: this.utmCampaign,
                utmContent: this.utmContent,
                utmMedium: this.utmMedium,
                utmSource: this.utmSource,
            })
                .then(() => {
                    let track = false;

                    switch (value) {
                        case 'broker':
                            this.setCurrentSubPanel(2);
                            track = true;
                            break;
                        case 'openHouse':
                            this.setCurrentSubPanel(3);
                            track = true;
                            break;
                        case 'offer':
                            this.setCurrentSubPanel(2);
                            track = true;
                            break;
                        case 'showing':
                            this.setCurrentSubPanel(3);
                            track = true;
                            break;
                        case 'material':
                            this.setCurrentSubPanel(2);
                            track = true;
                            break;
                        default:
                            track = false;
                            break;
                    }

                    if (track) {
                        tracking.trackRawEvent({
                            event: 'trackForm',
                            eventData: {
                                category: 'Formular',
                                action: `${this.getTrackingText(
                                    value,
                                )} gennemfoert`,
                                label: `${this.getTrackingText(value)}`,
                                formularIndgang: this.trackingEntrance ?? '',
                                formularStepnavn: 'Kvittering',
                                maeglerId: this.employee.brokerId ?? '',
                            },
                        });
                    }
                })
                .catch((error: any) => {
                    this.errors = error.errors ?? [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        /**
         * Go to the next panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        nextPanel(value: string): void {
            this.currentDirection = 'next';
            this.setCurrentPanel(value);

            let returnValue = '';
            switch (value) {
                case 'broker':
                    returnValue = 'Brugerinformation';
                    break;
                case 'material':
                    returnValue = 'Brugerinformation';
                    break;
                case 'offer':
                    returnValue = 'Brugerinformation';
                    break;
                case 'openHouse':
                    returnValue = 'Vaelg dato';
                    break;
                case 'showing':
                    returnValue = 'Vaelg dato';
                    break;
                default:
                    returnValue = '';
                    break;
            }

            tracking.trackRawEvent({
                event: 'trackForm',
                eventData: {
                    category: 'Formular',
                    action: `${this.getTrackingText(value)} initieret`,
                    label: `${this.getTrackingText(value)}`,
                    formularIndgang: this.trackingEntrance ?? '',
                    formularStepnavn: returnValue,
                    maeglerId: this.employee.brokerId ?? '',
                },
            });
        },

        /**
         * Convert number to hours and minutes. ie. 2.25 will return "02:15" or 3.75 will return "03:45"
         *
         * @param {string} value
         * @return {string}
         */
        numberToMinutesConverter(value: string): string {
            let hourValue = value
                .toString()
                .substring(0, value.toString().length - 2);
            let minValue = `${
                parseInt(
                    value.toString().substring(value.toString().length - 2),
                ) * 0.6
            }`;

            if (minValue.toString().length < 2) {
                minValue = `0${minValue}`;
            }

            return `${hourValue}:${minValue}`;
        },

        /**
         * Go to the previous panel/screen.
         *
         * @param {string} value
         * @return {void}
         */
        previousPanel(value: string): void {
            this.errors = {};
            this.currentDirection = 'prev';
            this.setCurrentPanel(value);
        },
    },
};
