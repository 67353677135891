
import { PropType } from 'vue';
import mRadio from '@/components/molecules/form/Radio.vue';

type Segment = {
    text: string;
    hash: string;
};

type SegmentationStepData = {
    segmentHeadline: string;
    segmentCtaText: string;
    segmentCtaAltText: string;
    segmentSelectedText: string;
    segments: Segment[];
};

interface Data {
    selectedSegment: string;
    selectedSegmentId: string;
}

export default {
    components: {
        mRadio,
    },
    props: {
        segmentsStep: {
            type: Object as PropType<SegmentationStepData>,
            default: () => ({
                segmentHeadline: '',
                segmentCtaText: '',
                segmentCtaAltText: '',
                segmentSelectedText: '',
                segments: [
                    {
                        text: '',
                        hash: '',
                    },
                    {
                        text: '',
                        hash: '',
                    },
                    {
                        text: '',
                        hash: '',
                    },
                ],
            }),
        },
    },
    data(): Data {
        return {
            selectedSegment: '',
            selectedSegmentId: '',
        };
    },
    mounted(): void {
        const segmentationData = JSON.parse(
            sessionStorage.getItem('houseEstimateSegmentationData') as string,
        );

        if (segmentationData) {
            const text: string = segmentationData.text;
            const hash: string = segmentationData.hash;

            this.selectedSegment = segmentationData.text;
            this.$emit('selectSegment', { text, hash });
        }
    },
    methods: {
        handleSegmentStepClick(text: string, hash: string): void {
            sessionStorage.setItem(
                'houseEstimateSegmentationData',
                JSON.stringify({
                    text,
                    hash,
                }),
            );

            this.$emit('selectSegment', { text, hash });
        },
    },
};
