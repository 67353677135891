import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "o-homeEstimateBrokerIntro u-row" }
const _hoisted_2 = { class: "o-homeEstimateBrokerIntro__content u-col-12 md:u-col-8 lg:u-col-8 md:u-offset-2 lg:u-offset-2" }
const _hoisted_3 = { class: "o-homeEstimateBrokerIntro__content-mapContainer" }
const _hoisted_4 = { class: "o-homeEstimateBrokerIntro__content-mapContainer--inner" }
const _hoisted_5 = {
  key: 0,
  class: "o-homeEstimateBrokerIntro__content-broker"
}
const _hoisted_6 = { class: "o-homeEstimateBrokerIntro__content-broker--text" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "o-content" }
const _hoisted_9 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_map = _resolveComponent("m-map")!
  const _component_o_employee_card = _resolveComponent("o-employee-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_m_map, {
            class: "o-neighborhoodMap__map",
            address: $options.propertyAddress,
            center: $options.propertyCoordinates,
            "is-loading": !$options.propertyCoordinates,
            "max-bounds": $data.maxBounds,
            zoom: $data.zoom,
            interactive: $data.isInteractive
          }, null, 8 /* PROPS */, ["address", "center", "is-loading", "max-bounds", "zoom", "interactive"])
        ])
      ]),
      ($props.responsibleBroker.hasOwnProperty('brokerId'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_o_employee_card, {
              employee: $props.responsibleBroker,
              panel: true
            }, null, 8 /* PROPS */, ["employee"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", {
                class: "a-heading3",
                textContent: _toDisplayString($options.headlineWithArea)
              }, null, 8 /* PROPS */, _hoisted_7),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", {
                  textContent: _toDisplayString($props.text)
                }, null, 8 /* PROPS */, _hoisted_9)
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}