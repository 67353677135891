
import { PropType } from 'vue';
import { mapActions } from 'vuex';
import IPropertyBuyersDirectoryNotForSalePromoBox from '@/interfaces/PropertyBuyersDirectoryNotForSalePromoBox';
import tracking from '@/functions/tracking';

export default {
    props: {
        promoBox: {
            type: Object as PropType<IPropertyBuyersDirectoryNotForSalePromoBox>,
            default: () => ({
                backgroundColour: '',
                headline: '',
                text: '',
                buttonText: '',
                useNewLine: '',
                linkText: '',
                postalCode: '',
                areaMin: 0,
                areaMax: 0,
                priceMin: 0,
                priceMax: 0,
                propertyTypeId: 0,
                propertyTypeText: '',
                pageUrl: '',
            }),
        },
    },

    methods: {
        ...mapActions({
            reset: 'buyerDirectory/reset',
            setTags: 'buyerDirectory/setTags',
            setPrice: 'buyerDirectory/setPrice',
            setSize: 'buyerDirectory/setSize',
            setZipcode: 'buyerDirectory/setZipcode',
        }),

        getPricePill(min: number, max: number): string {
            return `${this.numberFormatter(min)} - ${this.numberFormatter(
                max,
            )}`;
        },

        getModifierClass(bgColor: string) {
            return bgColor ? `o-overlayPromoBox--${bgColor}` : '';
        },

        numberFormatter(value: number): string {
            return new Intl.NumberFormat('da-DK', {
                style: 'currency',
                currency: 'DKK',
                minimumFractionDigits: 0,
            })
                .format(value)
                .toString();
        },

        /**
         * Submit a form.
         *
         * @param {string} value
         * @return {void}
         */
        onClick(setCriteria: boolean, label: string): void {
            this.reset();

            if (setCriteria) {
                this.setZipcode(this.$props.promoBox.postalCode.toString());
                this.setPrice([
                    this.$props.promoBox.priceMin,
                    this.$props.promoBox.priceMax,
                ]);
                this.setSize([
                    this.$props.promoBox.areaMin,
                    this.$props.promoBox.areaMax,
                ]);
                this.setTags({
                    key: this.$props.promoBox.propertyTypeId,
                    value: this.$props.promoBox.propertyTypeText.toLowerCase(),
                });
            }

            tracking.track(
                'trackBoligpraesentation',
                'Bolig ikke tilgaengelig',
                'Koeberkartotek promoboks klik',
                label,
            );
        },
    },
};
