export const SceneStep2 = {
    v: '5.9.1',
    fr: 60,
    ip: 0,
    op: 240,
    w: 5760,
    h: 600,
    nm: 'Scene_01_Easy_quarter_Desktop',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            nm: 'swing',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector 313',
                    parent: 4,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [99, 102, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [27, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [54, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 313',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector 312',
                    parent: 4,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [121.5, 49.5, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [4.5, 49.5, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [9, 0],
                                                [0, 99],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 312',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector 311',
                    parent: 4,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [76.5, 49.5, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [4.5, 49.5, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [9, 99],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 311',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector 310',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 20,
                                    s: [127, 648, 0],
                                    to: [0, -24, 0],
                                    ti: [0, 24, 0],
                                },
                                { t: 40, s: [127, 504, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [99, 66, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 132],
                                                [39.6, 0],
                                                [158.4, 0],
                                                [198, 132],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 310',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_1',
            nm: 'House',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Rectangle 600016',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -114, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [216, 60],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600016 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [216, 60],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600016 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Rectangle 600026',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, 33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [48, 48], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 7.5, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600026 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [48, 48], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 7.5, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600026 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Rectangle 600029',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-3, 132, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [150, 30],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600029 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [150, 30],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600029 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Rectangle 600024',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, 47.5, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [96, 138],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600024 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [96, 138],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600024 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Rectangle 600008',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-189, -399.25, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [45, 12, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [10.328, 0],
                                                [79.672, 0],
                                                [90, 24],
                                                [0, 24],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600008 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [10.328, 0],
                                                [79.672, 0],
                                                [90, 24],
                                                [0, 24],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600008 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Rectangle 600007',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-189, -381.25, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [54, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600007 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [54, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600007 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [219, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [75, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [150, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [246, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [192, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [219, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [162, 108],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [162, 108],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-217.531, 15, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [76.582, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [153.164, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-192, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-246, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-219, 15, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [162, 108],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [162, 108],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 15,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [240, -234, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [45, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [93, 0],
                                                [-3.5, 0.001],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 3,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 16,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [240, -234, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [64.5, 0],
                                                [-4, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 3,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 17,
                    ty: 4,
                    nm: 'Rectangle 600010',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [240, -234, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [75, 105],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600010 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [75, 105],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600010 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 18,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: {
                            a: 0,
                            k: [-240, -234, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [45, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [94, 0],
                                                [-3.5, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 3,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 19,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-240, -234, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [64.5, 0],
                                                [-4.5, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 3,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 20,
                    ty: 4,
                    nm: 'Rectangle 600010',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-240, -234, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [75, 105],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600010 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [75, 105],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600010 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 21,
                    ty: 4,
                    nm: 'Vector 145',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [0, -177.75, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 48.75, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, -4.5],
                                                [0, 97.5],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 3,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 145',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 22,
                    ty: 4,
                    nm: 'Rectangle 600016',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [0, -177.75, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [120, 112.5],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600016 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [120, 112.5],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600016 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 23,
                    ty: 4,
                    nm: 'Rectangle 600018',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-0.958, -221.875, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: {
                            a: 0,
                            k: [155.042, 104.125, 0],
                            ix: 1,
                            l: 2,
                        },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-3.443, -4.299],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [36, 114.99],
                                                [8.818, 137.226],
                                                [0, 126.219],
                                                [155.042, 0],
                                                [310.085, 126.219],
                                                [301.267, 137.226],
                                                [276, 116.557],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ind: 1,
                                    ty: 'sh',
                                    ix: 2,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [276, 116.557],
                                                [155.042, 17.612],
                                                [36, 114.99],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 2',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ind: 2,
                                    ty: 'sh',
                                    ix: 3,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [276, 116.557],
                                                [276, 208.25],
                                                [36, 208.25],
                                                [36, 114.99],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 3',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600018',
                            np: 5,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 24,
                    ty: 4,
                    nm: 'Rectangle 600006',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 0,
                            k: [-189, -360.25, 0],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [90, 30], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600006 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [90, 30], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600006 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 25,
                    ty: 4,
                    nm: 'Rectangle 600005',
                    parent: 26,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -240, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [390, 105, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [29.268, 0],
                                                [750.731, 0],
                                                [780, 210],
                                                [0, 210],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600005 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [29.268, 0],
                                                [750.731, 0],
                                                [780, 210],
                                                [0, 210],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600005 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 26,
                    ty: 4,
                    nm: 'Rectangle 600028',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [400, 1156, 0],
                                    to: [0, -96.667, 0],
                                    ti: [0, 96.667, 0],
                                },
                                { t: 30, s: [400, 576, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 147, 0], ix: 1, l: 2 },
                        s: {
                            a: 0,
                            k: [100, 100, 100],
                            ix: 6,
                            l: 2,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [696, 294],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600028 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: {
                                        a: 0,
                                        k: [696, 294],
                                        ix: 2,
                                    },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [1, 1, 1, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: {
                                        a: 0,
                                        k: [100, 100],
                                        ix: 3,
                                    },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600028 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Vector 163',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1890, 573, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 163',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 49,
                                s: [0],
                            },
                            { t: 63, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Vector 162',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3696, 510, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 162',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 100, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 21,
                                s: [100],
                            },
                            { t: 31, s: [0] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Vector 161',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3897, 510, 0], ix: 2, l: 2 },
                a: { a: 0, k: [123, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [246, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 161',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 31,
                                s: [100],
                            },
                            { t: 51, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Vector 160',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2700, 573, 0], ix: 2, l: 2 },
                a: { a: 0, k: [732, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1463.999, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 160',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [38],
                            },
                            { t: 60, s: [100] },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [38],
                            },
                            { t: 60, s: [0] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Vector',
            parent: 6,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.803, 89.726, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.572, 143.878, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.144, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.508, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.144, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.508, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.678431372549, 0.894117647059,
                                    0.850980392157, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3438, 477.379, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: {
                                x: [0.333, 0.333, 0.333],
                                y: [0, 0, 0],
                            },
                            t: 30,
                            s: [0, 0, 100],
                        },
                        { t: 50, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 100, ix: 1 },
                    e: { a: 0, k: 0, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Vector',
            parent: 10,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [15.642, 55.859, 0], ix: 2, l: 2 },
                a: { a: 0, k: [15.642, 15.885, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 31.769],
                                        [31.285, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Vector',
            parent: 10,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-9.434, 27.738, 0], ix: 2, l: 2 },
                a: { a: 0, k: [9.434, 9.561, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [18.867, 19.122],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Vector',
            parent: 10,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-16.299, 98.523, 0], ix: 2, l: 2 },
                a: { a: 0, k: [16.291, 16.543, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [32.582, 33.087],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3552.023, 451.297, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 195.433, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: {
                                x: [0.333, 0.333, 0.333],
                                y: [0, 0, 0],
                            },
                            t: 31,
                            s: [0, 0, 100],
                        },
                        { t: 51, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 195.433],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Vector',
            parent: 10,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.019, 155.341, 0], ix: 2, l: 2 },
                a: { a: 0, k: [109.367, 190.502, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -20.627],
                                        [19.275, -3.99],
                                        [0, -3.802],
                                        [23.352, 0],
                                        [7.636, 11.217],
                                        [14.419, 0],
                                        [0, 23.714],
                                        [-0.927, 3.501],
                                        [0, 20.627],
                                        [-19.127, 4.103],
                                        [0, 3.35],
                                        [-23.352, 0],
                                        [-7.636, -11.217],
                                        [-14.419, 0],
                                        [0, -23.714],
                                        [0.704, -3.124],
                                        [0, -20.627],
                                    ],
                                    o: [
                                        [0, 20.74],
                                        [0.927, 3.501],
                                        [0, 23.714],
                                        [-14.419, 0],
                                        [-7.599, 11.217],
                                        [-23.352, 0],
                                        [0, -3.802],
                                        [-19.275, -3.99],
                                        [0, -20.627],
                                        [-0.704, -3.124],
                                        [0, -23.714],
                                        [14.419, 0],
                                        [7.599, -11.217],
                                        [23.352, 0],
                                        [0, 3.35],
                                        [19.09, 4.141],
                                        [0, 0],
                                    ],
                                    v: [
                                        [218.733, 94.555],
                                        [184.928, 136.6],
                                        [186.374, 147.591],
                                        [144.117, 190.502],
                                        [109.348, 171.945],
                                        [74.579, 190.502],
                                        [32.323, 147.591],
                                        [33.768, 136.6],
                                        [0, 94.555],
                                        [33.435, 52.585],
                                        [32.323, 42.911],
                                        [74.579, 0],
                                        [109.348, 18.557],
                                        [144.117, 0],
                                        [186.374, 42.911],
                                        [185.261, 52.585],
                                        [218.696, 94.555],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3837.242, 438.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 39,
                                s: [100],
                            },
                            { t: 49, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3824.758, 438.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 39,
                                s: [100],
                            },
                            { t: 49, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 14,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2085.242, 468.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 28,
                                s: [100],
                            },
                            { t: 38, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2072.758, 468.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 28,
                                s: [100],
                            },
                            { t: 38, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 16,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [1893.242, 348.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 38,
                                s: [100],
                            },
                            { t: 48, s: [1] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 17,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1880.758, 348.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 38,
                                s: [100],
                            },
                            { t: 48, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 18,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3537.242, 534.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 27,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 19,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3524.758, 534.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 27,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 20,
            ty: 4,
            nm: 'Vector',
            parent: 21,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.031, 135.957, 0], ix: 2, l: 2 },
                a: { a: 0, k: [59.157, 167.055, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.026, 0.013],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 1.198],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.026, 0.013],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 1.198],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.960784313725, 0.588235294118,
                                    0.545098039216, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2181, 356.113, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 171.097, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: {
                                x: [0.667, 0.667, 0.667],
                                y: [1, 1, 1],
                            },
                            o: {
                                x: [0.333, 0.333, 0.333],
                                y: [0, 0, 0],
                            },
                            t: 29,
                            s: [0, 0, 100],
                        },
                        { t: 49, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 171.097],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.03, 173.903, 0], ix: 2, l: 2 },
                a: { a: 0, k: [115.132, 226.061, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [3.914, 54.411],
                                        [-33.018, 42.367],
                                        [-46.853, 19.17],
                                        [-43.675, -11.773],
                                        [-48.403, 1.781],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [48.364, 1.743],
                                        [43.636, -11.773],
                                        [46.853, 19.131],
                                        [32.979, 42.367],
                                        [-3.914, 54.411],
                                        [0, 0],
                                    ],
                                    v: [
                                        [87.656, 221.401],
                                        [28.789, 189.606],
                                        [11.738, 127.876],
                                        [24.875, 70.212],
                                        [63.202, 39.075],
                                        [115.132, 0],
                                        [167.061, 39.075],
                                        [205.388, 70.212],
                                        [218.526, 127.876],
                                        [201.474, 189.606],
                                        [142.608, 221.401],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 23,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [10.498, 18.478, 0], ix: 2, l: 2 },
                a: { a: 0, k: [10.522, 10.495, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 20.99],
                                        [21.043, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 24,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [21.388, 152.511, 0], ix: 2, l: 2 },
                a: { a: 0, k: [21.411, 21.416, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 42.832],
                                        [42.823, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 25,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [29.526, 97.811, 0], ix: 2, l: 2 },
                a: { a: 0, k: [29.549, 29.529, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 59.058],
                                        [59.099, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 26,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [17.028, 61.967, 0], ix: 2, l: 2 },
                a: { a: 0, k: [17.052, 17.04, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 34.08],
                                        [34.103, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 27,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-21.432, 134.949, 0], ix: 2, l: 2 },
                a: { a: 0, k: [21.411, 21.397, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [42.823, 42.793],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 28,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-29.572, 80.267, 0], ix: 2, l: 2 },
                a: { a: 0, k: [29.549, 29.529, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [59.099, 59.058],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 29,
            ty: 4,
            nm: 'Vector',
            parent: 30,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-17.058, 44.424, 0], ix: 2, l: 2 },
                a: { a: 0, k: [17.032, 17.04, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [34.064, 34.08],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 30,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2045.988, 360, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 219, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: {
                                x: [0.333, 0.333, 0.333],
                                y: [0, 0, 0],
                            },
                            t: 34,
                            s: [0, 0, 100],
                        },
                        { t: 54, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 219],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 31,
            ty: 0,
            nm: 'swing',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [682, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [259.054, 570.57],
                                [-5.927, 570.57],
                                [-5.927, 637.293],
                                [259.054, 637.293],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 1364,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 32,
            ty: 0,
            nm: 'House',
            refId: 'comp_1',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [400, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [821.853, 572.359],
                                [-21.102, 572.359],
                                [-21.102, 661.312],
                                [821.853, 661.312],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 800,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 33,
            ty: 4,
            nm: 'Vector',
            parent: 34,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.032, 135.957, 0], ix: 2, l: 2 },
                a: { a: 0, k: [59.157, 167.055, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.026, 0.013],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 1.198],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-0.026, 0.013],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 1.198],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 34,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3927, 356.113, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 171.097, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: {
                                x: [0.333, 0.333, 0.333],
                                y: [0, 0, 0],
                            },
                            t: 35,
                            s: [0, 0, 100],
                        },
                        { t: 55, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 171.097],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 35,
            ty: 4,
            nm: 'Vector 159',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1264, 360, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1268, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2536, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 159',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.129], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [0],
                            },
                            { t: 180, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 36,
            ty: 4,
            nm: 'Vector 158',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4446, 360, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1314, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2628, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0.4, 0.392156862745, 1],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 158',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.129], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 180, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};

export const SceneStep3 = {
    v: '5.9.1',
    fr: 60,
    ip: 0,
    op: 600,
    w: 5760,
    h: 600,
    nm: 'Scene_02',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            nm: 'door',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    parent: 3,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [120.547, 141.594, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [14.16, 14.16, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -7.82],
                                                [7.82, 0],
                                                [0, 7.82],
                                                [-7.82, 0],
                                            ],
                                            o: [
                                                [0, 7.82],
                                                [-7.82, 0],
                                                [0, -7.82],
                                                [7.82, 0],
                                            ],
                                            v: [
                                                [28.32, 14.16],
                                                [14.16, 28.32],
                                                [0, 14.16],
                                                [14.16, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 3,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [148.86, 139.796, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [77.88, 168.12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [155.76, 28.32],
                                                [155.76, 307.92],
                                                [0, 336.24],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [155.76, 28.32],
                                                [155.76, 307.92],
                                                [0, 336.24],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.224, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 46,
                                    s: [3623.097, 775.203, 0],
                                    to: [0, -86.667, 0],
                                    ti: [0, 86.667, 0],
                                },
                                { t: 76, s: [3623.097, 255.203, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [36.562, 139.8, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [27.686, 28.32],
                                                [27.686, 279.601],
                                                [0, 279.601],
                                                [0, 0],
                                                [73.124, 0],
                                                [73.124, 28.32],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_1',
            nm: 'Kitchen',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector 379',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [-89.132, 70.982, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [4.333, 17.28, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [8.667, 0],
                                                [8.667, 34.56],
                                                [0, 34.56],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 379',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector 384',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -180, ix: 10 },
                        p: { a: 0, k: [167.43, -202.021, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 17.333, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 34.667],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 384',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector 383',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -180, ix: 10 },
                        p: { a: 0, k: [167.43, -54.685, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 17.333, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 34.667],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 383',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector 382',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [83.665, -46.02, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 8.64, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 17.28],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 382',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector 381',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [49.102, -46.02, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 8.64, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 17.28],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 381',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector 386',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [201.982, -158.688, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 77.76, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 155.52],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 386',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector 380',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [14.54, -46.02, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 8.64, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 17.28],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 380',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector 378',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [-89.132, -24.354, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [4.333, 17.28, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [8.667, 0],
                                                [8.667, 34.56],
                                                [0, 34.56],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 378',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector 377',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [-89.143, 23.316, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 69.12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 138.24],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 377',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector 376',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [51.742, -19.352, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 72, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 144],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 376',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector 375',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-20.023, 23.312, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 91, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 182],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 375',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Rectangle 600239',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-17.258, 22.648, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [141, 96, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [282, 0],
                                                [282, 192],
                                                [0, 192],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600239 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [282, 0],
                                                [282, 192],
                                                [0, 192],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600239 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: 'Rectangle 600238',
                    parent: 14,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [201.979, -72.017, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [155.52, 381.333], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600238 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [155.52, 381.333], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600238 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [2870.117, 924.362, 0],
                                    to: [0, -99.333, 0],
                                    ti: [0, 99.333, 0],
                                },
                                { t: 30, s: [2870.117, 328.362, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [48.859, 49.01, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [97.718, 0],
                                                [97.718, 98.02],
                                                [0, 98.02],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [97.718, 0],
                                                [97.718, 98.02],
                                                [0, 98.02],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.678431372549, 0.894117647059,
                                            0.850980392157, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_2',
            nm: 'Emhætte',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector 385',
                    parent: 2,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [93.155, 103.996, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 95.04, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 190.08],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 385',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [2818.242, -77.769, 0],
                                    to: [0, 26.667, 0],
                                    ti: [0, -26.667, 0],
                                },
                                { t: 30, s: [2818.242, 82.231, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [95.515, 65.563, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [138.499, 0],
                                                [138.499, 53.127],
                                                [191.03, 101.573],
                                                [191.03, 131.127],
                                                [0, 131.127],
                                                [0, 101.573],
                                                [54.432, 53.127],
                                                [54.432, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_3',
            nm: 'Bath',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [201, 1.506, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [39, 37.506, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [6.6, 0],
                                                [-6.6, 0],
                                                [0, 0],
                                                [0, 13.26],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 6.6],
                                                [0, 6.6],
                                                [0, 0],
                                                [13.26, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [78, 0],
                                                [12, 0],
                                                [12, 51.012],
                                                [0, 63.012],
                                                [12, 75.012],
                                                [54, 75.012],
                                                [78, 51.012],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [6.6, 0],
                                                [-6.6, 0],
                                                [0, 0],
                                                [0, 13.26],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 6.6],
                                                [0, 6.6],
                                                [0, 0],
                                                [13.26, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [78, 0],
                                                [12, 0],
                                                [12, 51.012],
                                                [0, 63.012],
                                                [12, 75.012],
                                                [54, 75.012],
                                                [78, 51.012],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.960784313725, 0.588235294118,
                                            0.545098039216, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [264.41, 12, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [35.59, 12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 6.6],
                                                [6.6, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [6.6, 0],
                                                [0, -6.6],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 24],
                                                [59.18, 24],
                                                [71.18, 12],
                                                [59.18, 0],
                                                [35.18, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [84, 78, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 6, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 12],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [108, 78, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 6, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 12],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [60, 78, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 6, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 12],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [84, 120, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [24, 12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-13.26, 0],
                                                [0, 13.26],
                                            ],
                                            o: [
                                                [0, 13.26],
                                                [13.26, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 24],
                                                [48, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [54, 96, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 72, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [-16.56, 0],
                                                [0, 16.56],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 16.56],
                                                [16.56, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 114],
                                                [30, 144],
                                                [60, 114],
                                                [60, 108],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [228, -120, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 24],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [72, -120, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 24],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [150, -54, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [138, 54, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0.18],
                                                [0, 0],
                                                [33.12, 0],
                                                [0, 0],
                                                [0, -33.12],
                                                [0, 0],
                                                [-0.18, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, -33.12],
                                                [0, 0],
                                                [-33.12, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [275.64, 108],
                                                [276, 107.64],
                                                [276, 60],
                                                [216, 0],
                                                [60, 0],
                                                [0, 60],
                                                [0, 107.64],
                                                [0.36, 108],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector',
                    parent: 12,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [60, -54, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 18, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, -13.26],
                                                [0, 0],
                                            ],
                                            o: [
                                                [-13.26, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [24, 0],
                                                [0, 24],
                                                [0, 36],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 180, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 31,
                                    s: [2096, 764, 0],
                                    to: [0, -61, 0],
                                    ti: [0, 61, 0],
                                },
                                { t: 61, s: [2096, 398, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [87, 12, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, -6.6],
                                                [-6.6, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-6.6, 0],
                                                [0, 6.6],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [174, 0],
                                                [12, 0],
                                                [0, 12],
                                                [12, 24],
                                                [174, 24],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Vector 206',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [4412, 210, 0], ix: 2, l: 2 },
                a: { a: 0, k: [98, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [196, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 206',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 51,
                                s: [100],
                            },
                            { t: 71, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Vector 200',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [4512, 186, 0], ix: 2, l: 2 },
                a: { a: 0, k: [46, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [92, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 200',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 51,
                                s: [100],
                            },
                            { t: 71, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Vector 199',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [4544, 188, 0], ix: 2, l: 2 },
                a: { a: 0, k: [76, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [152, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 199',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 51,
                                s: [100],
                            },
                            { t: 71, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Vector 207',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4544, 208, 0], ix: 2, l: 2 },
                a: { a: 0, k: [52, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [104, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 207',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 51,
                                s: [100],
                            },
                            { t: 71, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Vector 198',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4476, 168, 0], ix: 2, l: 2 },
                a: { a: 0, k: [100, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [200, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 198',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 51,
                                s: [100],
                            },
                            { t: 71, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Vector 200',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [1372, 192, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [160, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 200',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 36,
                                s: [100],
                            },
                            { t: 56, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Vector 199',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [1468, 234, 0], ix: 2, l: 2 },
                a: { a: 0, k: [74, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [148, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 199',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 36,
                                s: [100],
                            },
                            { t: 56, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Vector 205',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1420, 244, 0], ix: 2, l: 2 },
                a: { a: 0, k: [144, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [288, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 205',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 36,
                                s: [0],
                            },
                            { t: 56, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Vector 198',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1410, 180, 0], ix: 2, l: 2 },
                a: { a: 0, k: [94, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [188, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 198',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 36,
                                s: [0],
                            },
                            { t: 56, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Vector 208',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [2483, 110, 0], ix: 2, l: 2 },
                a: { a: 0, k: [84, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [168, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 208',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 10,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Vector 202',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [2369, 173, 0], ix: 2, l: 2 },
                a: { a: 0, k: [123, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [246, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 202',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 10,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Vector 193',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2366, 68, 0], ix: 2, l: 2 },
                a: { a: 0, k: [225, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [450, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 193',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 10,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Vector 206',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -180, ix: 10 },
                p: { a: 0, k: [2351, 230, 0], ix: 2, l: 2 },
                a: { a: 0, k: [48, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [96, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 206',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 10,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 14,
            ty: 4,
            nm: 'Vector 204',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2417, 266, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 204',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 10,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: 'Vector 207',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2432, 146, 0], ix: 2, l: 2 },
                a: { a: 0, k: [105, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [210, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 207',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 10,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 16,
            ty: 4,
            nm: 'Vector 196',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [3419, 173, 0], ix: 2, l: 2 },
                a: { a: 0, k: [39, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [78, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 196',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 19,
                                s: [100],
                            },
                            { t: 39, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 17,
            ty: 4,
            nm: 'Vector 192',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [3323, 149, 0], ix: 2, l: 2 },
                a: { a: 0, k: [123, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [246, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 192',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 19,
                                s: [100],
                            },
                            { t: 39, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 18,
            ty: 4,
            nm: 'Vector 190',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -180, ix: 10 },
                p: { a: 0, k: [3347, 170, 0], ix: 2, l: 2 },
                a: { a: 0, k: [96, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [192, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 190',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 19,
                                s: [0],
                            },
                            { t: 39, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 19,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3296.234, 497.141, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 41.859, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 83.719],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 18,
                                s: [100],
                            },
                            { t: 23, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 20,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3346.172, 448.373, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 42.627, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 85.254],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 21,
                                s: [0],
                            },
                            { t: 25, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3396.008, 398.001, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 42, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 84],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 24,
                                s: [0],
                            },
                            { t: 28, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3246.57, 544.162, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 48.838, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 97.676],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 17,
                                s: [0],
                            },
                            { t: 20, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 23,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3445.844, 355.5, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 36.801, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 73.602],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 26,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 24,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3344.864, 407.06, 0], ix: 2, l: 2 },
                a: { a: 0, k: [113.136, 88.36, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [-1.419, 1.41],
                                        [0, 0],
                                        [-7.377, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [2.081, 0],
                                        [0, 0],
                                        [5.202, -5.17],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 176.72],
                                        [21.041, 176.72],
                                        [26.526, 174.464],
                                        [194.021, 7.99],
                                        [213.504, 0],
                                        [226.272, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 20,
                                s: [100],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 25,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3371.238, 494, 0], ix: 2, l: 2 },
                a: { a: 0, k: [89.762, 99, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 3.854],
                                        [0, 0],
                                        [0, 0],
                                        [0, 3.854],
                                        [0, 0],
                                        [0, 0],
                                        [0, 3.854],
                                        [0, 0],
                                        [0, 0],
                                        [0, 3.854],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [3.878, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.878, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.878, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.878, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 198],
                                        [22.908, 198],
                                        [30.001, 190.95],
                                        [29.999, 151.163],
                                        [72.747, 151.163],
                                        [79.84, 144.113],
                                        [79.84, 101.625],
                                        [122.589, 101.625],
                                        [129.682, 94.575],
                                        [129.682, 52.087],
                                        [172.431, 52.087],
                                        [179.524, 45.037],
                                        [179.524, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 18,
                                s: [100],
                            },
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 23,
                                s: [55.333],
                            },
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 25,
                                s: [29.524],
                            },
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 27,
                                s: [4.714],
                            },
                            { t: 30, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 26,
            ty: 0,
            nm: 'door',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [3943.003, 426.5],
                                [3503.501, 426.5],
                                [3503.501, 765.064],
                                [3943.003, 765.064],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 27,
            ty: 4,
            nm: 'Vector 191',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3383, 68, 0], ix: 2, l: 2 },
                a: { a: 0, k: [135, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [270, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 191',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 30,
                                s: [0],
                            },
                            { t: 40, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 28,
            ty: 4,
            nm: 'Vector 183',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4826, 540, 0], ix: 2, l: 2 },
                a: { a: 0, k: [462, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [924, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 183',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.148], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 53,
                                s: [100],
                            },
                            { t: 73, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 29,
            ty: 4,
            nm: 'Vector 187',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4286, 540, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 187',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 43,
                                s: [100],
                            },
                            { t: 53, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 30,
            ty: 4,
            nm: 'Vector 185',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [618, 540, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 185',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 55,
                                s: [0],
                            },
                            { t: 65, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 31,
            ty: 4,
            nm: 'Vector 186',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [926, 540, 0], ix: 2, l: 2 },
                a: { a: 0, k: [230, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [460, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 186',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 43,
                                s: [0],
                            },
                            { t: 55, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 32,
            ty: 4,
            nm: 'Vector 162',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2021, 542, 0], ix: 2, l: 2 },
                a: { a: 0, k: [168, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [336, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 162',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 21,
                                s: [0],
                            },
                            { t: 31, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 33,
            ty: 4,
            nm: 'Vector 185',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2168, 596, 0], ix: 2, l: 2 },
                a: { a: 0, k: [81, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [162, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 185',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 17,
                                s: [0],
                            },
                            { t: 27, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 34,
            ty: 4,
            nm: 'Vector 185',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2663, 596, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 185',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 7,
                                s: [0],
                            },
                            { t: 17, s: [100] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 0, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 35,
            ty: 4,
            nm: 'Vector 183',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2642, 494, 0], ix: 2, l: 2 },
                a: { a: 0, k: [219, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [438, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 183',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [0],
                            },
                            { t: 17, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 36,
            ty: 4,
            nm: 'Vector 186',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2939, 494, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 186',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [100],
                            },
                            { t: 10, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 37,
            ty: 4,
            nm: 'Vector 163',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [920, 396, 0], ix: 2, l: 2 },
                a: { a: 0, k: [916, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1832, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 163',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.089], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 20,
                                s: [0],
                            },
                            { t: 120, s: [100] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 0, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 38,
            ty: 4,
            nm: 'Vector 184',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4674, 395, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1214, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2428, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 184',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 100, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.089], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 30,
                                s: [100],
                            },
                            { t: 120, s: [0] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 39,
            ty: 4,
            nm: 'Vector 158',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2759, 395, 0], ix: 2, l: 2 },
                a: { a: 0, k: [522, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1044, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 158',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [50],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [50],
                            },
                            { t: 20, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 40,
            ty: 4,
            nm: 'Vector 187',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3671, 494, 0], ix: 2, l: 2 },
                a: { a: 0, k: [102, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [204, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 187',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 31,
                                s: [100],
                            },
                            { t: 41, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 41,
            ty: 4,
            nm: 'Vector 185',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3053, 596, 0], ix: 2, l: 2 },
                a: { a: 0, k: [312, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [624, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 185',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [100],
                            },
                            { t: 21, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 42,
            ty: 0,
            nm: 'Kitchen',
            refId: 'comp_1',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [3108.57, 395.711],
                                [2664, 396],
                                [2664, 850.711],
                                [3108.57, 850.422],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 43,
            ty: 0,
            nm: 'Emhætte',
            refId: 'comp_2',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [2928.672, -68.824],
                                [2709.789, -68.824],
                                [2709.789, 16.98],
                                [2928.672, 16.98],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 44,
            ty: 0,
            nm: 'Bath',
            refId: 'comp_3',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [2192.09, 539.5],
                                [1848, 539.5],
                                [1848, 634.047],
                                [2192.09, 634.047],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};

export const SceneStep4 = {
    v: '5.9.1',
    fr: 60,
    ip: 0,
    op: 600,
    w: 5760,
    h: 600,
    nm: 'Scene_03',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            nm: 'Water',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4582.1, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4540.827, 316.476, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4499.577, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4458.045, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4416.795, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4375.502, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4334.202, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4292.952, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4251.678, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4210.405, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4169.155, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4127.862, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5077.897, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5036.623, 316.476, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 15,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4995.373, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 16,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4953.842, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 17,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4912.592, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 18,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4871.299, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 19,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4829.998, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 20,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4788.748, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 21,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4747.475, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 22,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4706.202, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 23,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4664.952, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 24,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4623.659, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 25,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5573.694, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 26,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5532.42, 316.476, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 27,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5491.17, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 28,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5449.639, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 29,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5408.389, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 30,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5367.096, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 31,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5325.795, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 32,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5284.545, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 33,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5243.272, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 34,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5201.998, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 35,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5160.748, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 36,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5119.455, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 37,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5739.077, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 38,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5697.803, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 39,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5656.553, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 40,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5615.26, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 41,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4086.155, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 42,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4044.881, 316.476, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 43,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4003.631, 328.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 44,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3962.1, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 45,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3920.85, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 46,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3879.557, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 47,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3838.256, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 48,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3797.006, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 49,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3755.733, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 50,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3714.459, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 51,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3673.209, 327.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 52,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3631.916, 316.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 53,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5162.389, 508.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 54,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5120.858, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 55,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5079.256, 508.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 56,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5037.725, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 57,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4996.475, 507.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 58,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4955.182, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 59,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4913.881, 507.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 60,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4872.631, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 61,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5490.545, 448.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 62,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5449.014, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 63,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5407.413, 448.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 64,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5365.881, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 65,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5324.631, 447.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 66,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4670.389, 448.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 67,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4628.858, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 68,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4587.256, 448.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 69,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4545.725, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 70,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4504.475, 447.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 71,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4463.182, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 72,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4421.881, 447.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 73,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4380.631, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 74,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4419.413, 388.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 75,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4377.881, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 76,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4336.631, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 77,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4420.733, 508.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 78,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4379.202, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 79,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4337.952, 507.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 80,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4296.659, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 81,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [5036.299, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 82,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4994.998, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 83,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4953.748, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 84,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4912.475, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 85,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4871.202, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 86,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4829.952, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 87,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4788.659, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 88,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4129.233, 555.925, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 89,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4087.631, 568.097, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 90,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4046.1, 555.925, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 91,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4087.631, 508.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 92,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4046.1, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 93,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4004.85, 507.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 94,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3963.557, 496.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 95,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4130.764, 448.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 96,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4089.233, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 97,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4047.631, 448.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 98,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4006.1, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 99,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3964.85, 447.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 100,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3923.557, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 101,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3882.256, 447.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 102,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3841.006, 436.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 103,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4087.631, 388.296, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 104,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4046.1, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 105,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [4004.85, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 106,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3963.557, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 107,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3922.256, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.274, 0],
                                                [6.952, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.356, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 108,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3881.006, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 109,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3839.733, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-6.947, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 110,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3798.459, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.22, 0],
                                                [-6.947, -8.353],
                                            ],
                                            o: [
                                                [6.898, -7.701],
                                                [8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.302, 0],
                                                [41.263, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 111,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3757.209, 387.945, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.631, 5.898, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.22, 0],
                                                [6.898, 7.647],
                                            ],
                                            o: [
                                                [-7.002, 8.299],
                                                [-8.22, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [41.263, 0],
                                                [20.302, 11.796],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 112,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [3715.916, 376.124, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.659, 5.925, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-8.274, 0],
                                                [-7.002, -8.353],
                                            ],
                                            o: [
                                                [6.952, -7.701],
                                                [8.274, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 11.85],
                                                [20.356, 0],
                                                [41.317, 11.85],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_1',
            nm: 'complex',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-39, 159, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-39, 219, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-39, 99, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-39, 168, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [39, 120, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [78, 0],
                                                [78, 240],
                                                [0, 240],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [183, 159, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [183, 219, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [183, 99, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [183, 168, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [39, 120, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [78, 0],
                                                [78, 240],
                                                [0, 240],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [72, 264, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [24, 24, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [48, 0],
                                                [48, 48],
                                                [0, 48],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [72, 240.001, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 0.06, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [72, 0],
                                                [72, 0.12],
                                                [0, 0.12],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [99, 117, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [45, 117, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [99, 177, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [45, 177, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 15,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [99, 57, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 16,
                    ty: 4,
                    nm: 'Vector',
                    parent: 17,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [45, 57, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [12, 15, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [24, 0],
                                                [24, 30],
                                                [0, 30],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 17,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [2552, 528, 0],
                                    to: [0, -58.333, 0],
                                    ti: [0, 58.333, 0],
                                },
                                { t: 30, s: [2552, 178, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [72, 144, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [144, 0],
                                                [144, 288],
                                                [0, 288],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_2',
            nm: 'Train',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [1566.598, 350.668, 0],
                                    to: [63.833, 0, 0],
                                    ti: [-63.833, 0, 0],
                                },
                                { t: 40, s: [1949.598, 350.668, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [135.2, 77.5, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [-2.808, -4.753],
                                                [0, 0],
                                                [0, -3.668],
                                                [0, 0],
                                                [5.72, 0],
                                                [0, 0],
                                                [0, 0],
                                                [8.632, 0],
                                                [0, 0],
                                                [0, -8.577],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [5.512, 0],
                                                [0, 0],
                                                [1.872, 3.152],
                                                [0, 0],
                                                [0, 5.683],
                                                [0, 0],
                                                [0, 0],
                                                [0, -8.577],
                                                [0, 0],
                                                [-8.632, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [10.4, 0],
                                                [209.456, 0],
                                                [222.924, 7.647],
                                                [267.54, 82.977],
                                                [270.4, 93],
                                                [270.4, 144.667],
                                                [260, 155],
                                                [208, 155],
                                                [208, 149.833],
                                                [192.4, 134.333],
                                                [98.8, 134.333],
                                                [83.2, 149.833],
                                                [83.2, 155],
                                                [0, 155],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [119.592, 157.574, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [15.6, 7.75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.632, 0],
                                                [0, 8.577],
                                            ],
                                            o: [
                                                [0, 8.577],
                                                [-8.632, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [31.2, 0],
                                                [15.6, 15.5],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [171.6, 157.574, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [15.6, 7.75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [8.632, 0],
                                                [0, 8.577],
                                            ],
                                            o: [
                                                [0, 8.577],
                                                [-8.632, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [31.2, 0],
                                                [15.6, 15.5],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0.011, 93, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.8, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [41.6, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [238.818, 66.914, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30.779, 35.918, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.872, 0],
                                                [0, 0],
                                                [-12, -21],
                                                [0, -12],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [18, 29.836],
                                                [3, 6],
                                                [0, 0],
                                                [-5.2, -1.613],
                                                [0, 0],
                                                [0, -2.853],
                                            ],
                                            v: [
                                                [5.2, 0],
                                                [28.559, 0],
                                                [58.559, 50.836],
                                                [61.559, 71.836],
                                                [5.2, 53.363],
                                                [0, 46.5],
                                                [0, 5.167],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.872, 0],
                                                [0, 0],
                                                [-12, -21],
                                                [0, -12],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [18, 29.836],
                                                [3, 6],
                                                [0, 0],
                                                [-5.2, -1.613],
                                                [0, 0],
                                                [0, -2.853],
                                            ],
                                            v: [
                                                [5.2, 0],
                                                [28.559, 0],
                                                [58.559, 50.836],
                                                [61.559, 71.836],
                                                [5.2, 53.363],
                                                [0, 46.5],
                                                [0, 5.167],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [156.005, 56.829, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [31.2, 25.833, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                            ],
                                            v: [
                                                [5.2, 0],
                                                [57.2, 0],
                                                [62.4, 5.167],
                                                [62.4, 46.5],
                                                [57.2, 51.667],
                                                [5.2, 51.667],
                                                [0, 46.5],
                                                [0, 5.167],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                            ],
                                            v: [
                                                [5.2, 0],
                                                [57.2, 0],
                                                [62.4, 5.167],
                                                [62.4, 46.5],
                                                [57.2, 51.667],
                                                [5.2, 51.667],
                                                [0, 46.5],
                                                [0, 5.167],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-15.595, 123.996, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [15.6, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [31.2, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-15.595, 61.992, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [15.6, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [31.2, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0.011, 30.996, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [20.8, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [41.6, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [72.802, 56.829, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [31.2, 25.833, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                            ],
                                            v: [
                                                [5.2, 0],
                                                [57.2, 0],
                                                [62.4, 5.167],
                                                [62.4, 46.5],
                                                [57.2, 51.667],
                                                [5.2, 51.667],
                                                [0, 46.5],
                                                [0, 5.167],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [2.872, 0],
                                                [0, 0],
                                                [0, 2.853],
                                                [0, 0],
                                                [-2.872, 0],
                                                [0, 0],
                                                [0, -2.853],
                                            ],
                                            v: [
                                                [5.2, 0],
                                                [57.2, 0],
                                                [62.4, 5.167],
                                                [62.4, 46.5],
                                                [57.2, 51.667],
                                                [5.2, 51.667],
                                                [0, 46.5],
                                                [0, 5.167],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 0,
            nm: 'Water',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.373, y: 1 },
                                o: { x: 0.333, y: 0 },
                                t: 30,
                                s: [
                                    {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        v: [
                                            [5772, 273],
                                            [3601, 283],
                                            [3600.001, 588.5],
                                            [5772, 585],
                                        ],
                                        c: true,
                                    },
                                ],
                            },
                            {
                                t: 80,
                                s: [
                                    {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        v: [
                                            [5772, 273],
                                            [5765.001, 281.5],
                                            [5764.002, 587],
                                            [5772, 585],
                                        ],
                                        c: true,
                                    },
                                ],
                            },
                        ],
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Vector',
            parent: 3,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.758, 17.787, 0], ix: 2, l: 2 },
                a: { a: 0, k: [76.131, 71.939, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [16.259, 0],
                                        [5.368, 7.6],
                                        [9.523, 0],
                                        [5.368, 7.6],
                                        [9.549, 0],
                                        [0, 17.539],
                                        [-13.317, 3.035],
                                        [0, 2.478],
                                        [-14.633, 1.837],
                                        [0, 3.758],
                                        [-16.259, 0],
                                        [0, -17.539],
                                        [1.11, -3.341],
                                        [0, -16.259],
                                        [0.49, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-9.523, 0],
                                        [-5.368, 7.573],
                                        [-9.523, 0],
                                        [-5.368, 7.573],
                                        [-16.259, 0],
                                        [0, -15.256],
                                        [-0.49, -2.311],
                                        [0, -16.259],
                                        [-1.11, -3.369],
                                        [0, -17.539],
                                        [16.259, 0],
                                        [0, 3.758],
                                        [14.607, 1.837],
                                        [0, 2.478],
                                        [13.291, 3.062],
                                    ],
                                    v: [
                                        [152.263, 112.14],
                                        [122.842, 143.878],
                                        [99.487, 131.378],
                                        [76.131, 143.878],
                                        [52.776, 131.378],
                                        [29.42, 143.878],
                                        [0, 112.14],
                                        [23.278, 81.098],
                                        [22.504, 73.943],
                                        [48.466, 42.456],
                                        [46.711, 31.738],
                                        [76.131, 0],
                                        [105.551, 31.738],
                                        [103.797, 42.456],
                                        [129.759, 73.943],
                                        [128.984, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [16.259, 0],
                                        [5.368, 7.6],
                                        [9.523, 0],
                                        [5.368, 7.6],
                                        [9.549, 0],
                                        [0, 17.539],
                                        [-13.317, 3.035],
                                        [0, 2.478],
                                        [-14.633, 1.837],
                                        [0, 3.758],
                                        [-16.259, 0],
                                        [0, -17.539],
                                        [1.11, -3.341],
                                        [0, -16.259],
                                        [0.49, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-9.523, 0],
                                        [-5.368, 7.573],
                                        [-9.523, 0],
                                        [-5.368, 7.573],
                                        [-16.259, 0],
                                        [0, -15.256],
                                        [-0.49, -2.311],
                                        [0, -16.259],
                                        [-1.11, -3.369],
                                        [0, -17.539],
                                        [16.259, 0],
                                        [0, 3.758],
                                        [14.607, 1.837],
                                        [0, 2.478],
                                        [13.291, 3.062],
                                    ],
                                    v: [
                                        [152.263, 112.14],
                                        [122.842, 143.878],
                                        [99.487, 131.378],
                                        [76.131, 143.878],
                                        [52.776, 131.378],
                                        [29.42, 143.878],
                                        [0, 112.14],
                                        [23.278, 81.098],
                                        [22.504, 73.943],
                                        [48.466, 42.456],
                                        [46.711, 31.738],
                                        [76.131, 0],
                                        [105.551, 31.738],
                                        [103.797, 42.456],
                                        [129.759, 73.943],
                                        [128.984, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.960784313725, 0.588235294118,
                                    0.545098039216, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1058.268, 431.379, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 55,
                            s: [0, 0, 100],
                        },
                        { t: 75, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Vector',
            parent: 7,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [13.744, 48.9, 0], ix: 2, l: 2 },
                a: { a: 0, k: [13.744, 13.916, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 27.832],
                                        [27.488, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Vector',
            parent: 7,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-8.284, 24.303, 0], ix: 2, l: 2 },
                a: { a: 0, k: [8.286, 8.381, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [16.572, 16.761],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Vector',
            parent: 7,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-14.312, 86.277, 0], ix: 2, l: 2 },
                a: { a: 0, k: [14.313, 14.476, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [28.625, 28.952],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1011, 430.113, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 171.097, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 59,
                            s: [0, 0, 100],
                        },
                        { t: 79, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 171.097],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Vector',
            parent: 7,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.032, 52.434, 0], ix: 2, l: 2 },
                a: { a: 0, k: [59.157, 83.527, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0.4, -2.363],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                        [0.4, -2.363],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 2.799],
                                        [0, 0],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                        [98.912, 58.805],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Vector 164',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1016, 432, 0], ix: 2, l: 2 },
                a: { a: 0, k: [184, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [368, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 164',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 55,
                                s: [0],
                            },
                            { t: 75, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Vector 165',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1066, 572, 0], ix: 2, l: 2 },
                a: { a: 0, k: [186, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [372, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 165',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 56,
                                s: [0],
                            },
                            { t: 76, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Vector 185',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1330, 572, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 185',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 46,
                                s: [0],
                            },
                            { t: 56, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Vector',
            parent: 13,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.803, 17.787, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.572, 71.939, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.678431372549, 0.894117647059,
                                    0.850980392157, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2270, 445.379, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 10,
                            s: [0, 0, 100],
                        },
                        { t: 30, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 20,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3226.547, 455.046, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.08, 3.747, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.161, 7.494],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 35,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3182.08, 427.717, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.08, 3.717, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.161, 7.435],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 25,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Vector 161',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2234, 448, 0], ix: 2, l: 2 },
                a: { a: 0, k: [528, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1056, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 161',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 4,
                                s: [23],
                            },
                            { t: 20, s: [1] },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 4,
                                s: [23],
                            },
                            { t: 20, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 23,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3134.187, 391.596, 0], ix: 2, l: 2 },
                a: { a: 0, k: [90.304, 261.651, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 25,
                            s: [0, 0, 100],
                        },
                        { t: 45, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, -25.457],
                                        [-25.203, 0],
                                        [0, 25.517],
                                    ],
                                    o: [
                                        [-25.144, 0],
                                        [0, 25.457],
                                        [25.203, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [91.177, 46.156],
                                        [45.589, 92.312],
                                        [91.177, 138.469],
                                        [136.766, 92.312],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [12.708, -17.001],
                                        [2.761, 0],
                                        [1.586, 2.022],
                                        [0, 55.792],
                                        [-50.347, 0],
                                        [-8.298, -42.329],
                                    ],
                                    o: [
                                        [-20.218, 38.291],
                                        [-1.586, 2.022],
                                        [-2.761, 0],
                                        [-21.208, -28.372],
                                        [0, -50.974],
                                        [44.241, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [158.688, 166.827],
                                        [97.992, 258.32],
                                        [91.177, 261.651],
                                        [84.362, 258.32],
                                        [0, 92.312],
                                        [91.177, 0],
                                        [180.609, 74.249],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 24,
            ty: 4,
            nm: 'Subtract',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3135.626, 394, 0], ix: 2, l: 2 },
                a: { a: 0, k: [91.977, 264.001, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 25,
                            s: [0, 0, 100],
                        },
                        { t: 45, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.73, 0],
                                        [1.22, 0.618],
                                        [0.799, 1.027],
                                        [1.065, 1.422],
                                        [0, 0],
                                        [8.807, 12.599],
                                        [7.714, 13.258],
                                        [5.306, 13.965],
                                        [1.573, 7.037],
                                        [0, 6.477],
                                        [-1.239, 6.134],
                                        [-2.347, 5.62],
                                        [-3.32, 4.978],
                                        [-4.161, 4.214],
                                        [-4.87, 3.332],
                                        [-5.451, 2.335],
                                        [-5.907, 1.224],
                                        [-6.244, 0],
                                        [-6.061, -1.256],
                                        [-5.552, -2.378],
                                        [-4.916, -3.364],
                                        [-4.161, -4.215],
                                        [-3.289, -4.931],
                                        [-2.304, -5.517],
                                        [-1.208, -5.978],
                                        [0, -6.316],
                                        [1.694, -7.582],
                                        [3.028, -7.97],
                                        [9.727, -16.718],
                                        [10.527, -15.059],
                                        [5.925, -7.911],
                                        [0.888, -1.19],
                                    ],
                                    o: [
                                        [-1.363, 0],
                                        [-1.156, -0.585],
                                        [-0.925, -1.24],
                                        [0, 0],
                                        [-5.924, -7.909],
                                        [-10.527, -15.06],
                                        [-9.726, -16.715],
                                        [-3.028, -7.971],
                                        [-1.694, -7.582],
                                        [0, -6.316],
                                        [1.208, -5.978],
                                        [2.304, -5.518],
                                        [3.289, -4.932],
                                        [4.162, -4.215],
                                        [4.917, -3.364],
                                        [5.552, -2.378],
                                        [6.062, -1.256],
                                        [6.244, 0],
                                        [5.907, 1.224],
                                        [5.451, 2.335],
                                        [4.87, 3.332],
                                        [4.161, 4.215],
                                        [3.321, 4.979],
                                        [2.347, 5.619],
                                        [1.239, 6.134],
                                        [0, 6.477],
                                        [-1.573, 7.039],
                                        [-5.306, 13.964],
                                        [-7.716, 13.26],
                                        [-8.811, 12.605],
                                        [-1.153, 1.539],
                                        [-1.642, 2.158],
                                    ],
                                    v: [
                                        [91.977, 264.001],
                                        [88.085, 263.07],
                                        [85.097, 260.605],
                                        [82.1, 256.6],
                                        [82.092, 256.589],
                                        [59.249, 225.249],
                                        [32.141, 183.164],
                                        [9.487, 136.928],
                                        [2.553, 114.31],
                                        [0, 93.123],
                                        [1.867, 74.36],
                                        [7.224, 56.882],
                                        [15.701, 41.064],
                                        [26.929, 27.281],
                                        [40.539, 15.908],
                                        [56.163, 7.32],
                                        [73.432, 1.893],
                                        [91.977, 0],
                                        [110.522, 1.893],
                                        [127.791, 7.32],
                                        [143.415, 15.908],
                                        [157.026, 27.281],
                                        [168.254, 41.064],
                                        [176.73, 56.882],
                                        [182.087, 74.36],
                                        [183.955, 93.123],
                                        [181.401, 114.312],
                                        [174.467, 136.931],
                                        [151.811, 183.169],
                                        [124.701, 225.254],
                                        [101.85, 256.604],
                                        [98.857, 260.605],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [6.208, 0],
                                        [5.669, -2.432],
                                        [4.223, -4.284],
                                        [2.316, -5.554],
                                        [0, -6.297],
                                        [-2.398, -5.751],
                                        [-4.223, -4.284],
                                        [-5.476, -2.349],
                                        [-6.208, 0],
                                        [-5.669, 2.432],
                                        [-4.223, 4.284],
                                        [-2.316, 5.554],
                                        [0, 6.297],
                                        [2.398, 5.751],
                                        [4.223, 4.284],
                                        [5.476, 2.349],
                                    ],
                                    o: [
                                        [-6.208, 0],
                                        [-5.476, 2.349],
                                        [-4.223, 4.284],
                                        [-2.398, 5.751],
                                        [0, 6.297],
                                        [2.316, 5.554],
                                        [4.223, 4.284],
                                        [5.669, 2.432],
                                        [6.208, 0],
                                        [5.476, -2.349],
                                        [4.223, -4.284],
                                        [2.398, -5.751],
                                        [0, -6.297],
                                        [-2.316, -5.554],
                                        [-4.223, -4.284],
                                        [-5.669, -2.432],
                                    ],
                                    v: [
                                        [91.783, 46.692],
                                        [73.883, 50.358],
                                        [59.265, 60.354],
                                        [49.41, 75.181],
                                        [45.796, 93.337],
                                        [49.41, 111.494],
                                        [59.265, 126.32],
                                        [73.883, 136.317],
                                        [91.783, 139.982],
                                        [109.683, 136.317],
                                        [124.3, 126.32],
                                        [134.156, 111.494],
                                        [137.769, 93.337],
                                        [134.156, 75.181],
                                        [124.3, 60.354],
                                        [109.683, 50.358],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 4,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Subtract Stroke',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.73, 0],
                                        [1.22, 0.618],
                                        [0.799, 1.027],
                                        [1.065, 1.422],
                                        [0, 0],
                                        [8.807, 12.599],
                                        [7.714, 13.258],
                                        [5.306, 13.965],
                                        [1.573, 7.037],
                                        [0, 6.477],
                                        [-1.239, 6.134],
                                        [-2.347, 5.62],
                                        [-3.32, 4.978],
                                        [-4.161, 4.214],
                                        [-4.87, 3.332],
                                        [-5.451, 2.335],
                                        [-5.907, 1.224],
                                        [-6.244, 0],
                                        [-6.061, -1.256],
                                        [-5.552, -2.378],
                                        [-4.916, -3.364],
                                        [-4.161, -4.215],
                                        [-3.289, -4.931],
                                        [-2.304, -5.517],
                                        [-1.208, -5.978],
                                        [0, -6.316],
                                        [1.694, -7.582],
                                        [3.028, -7.97],
                                        [9.727, -16.718],
                                        [10.527, -15.059],
                                        [5.925, -7.911],
                                        [0.888, -1.19],
                                    ],
                                    o: [
                                        [-1.363, 0],
                                        [-1.156, -0.585],
                                        [-0.925, -1.24],
                                        [0, 0],
                                        [-5.924, -7.909],
                                        [-10.527, -15.06],
                                        [-9.726, -16.715],
                                        [-3.028, -7.971],
                                        [-1.694, -7.582],
                                        [0, -6.316],
                                        [1.208, -5.978],
                                        [2.304, -5.518],
                                        [3.289, -4.932],
                                        [4.162, -4.215],
                                        [4.917, -3.364],
                                        [5.552, -2.378],
                                        [6.062, -1.256],
                                        [6.244, 0],
                                        [5.907, 1.224],
                                        [5.451, 2.335],
                                        [4.87, 3.332],
                                        [4.161, 4.215],
                                        [3.321, 4.979],
                                        [2.347, 5.619],
                                        [1.239, 6.134],
                                        [0, 6.477],
                                        [-1.573, 7.039],
                                        [-5.306, 13.964],
                                        [-7.716, 13.26],
                                        [-8.811, 12.605],
                                        [-1.153, 1.539],
                                        [-1.642, 2.158],
                                    ],
                                    v: [
                                        [91.977, 264.001],
                                        [88.085, 263.07],
                                        [85.097, 260.605],
                                        [82.1, 256.6],
                                        [82.092, 256.589],
                                        [59.249, 225.249],
                                        [32.141, 183.164],
                                        [9.487, 136.928],
                                        [2.553, 114.31],
                                        [0, 93.123],
                                        [1.867, 74.36],
                                        [7.224, 56.882],
                                        [15.701, 41.064],
                                        [26.929, 27.281],
                                        [40.539, 15.908],
                                        [56.163, 7.32],
                                        [73.432, 1.893],
                                        [91.977, 0],
                                        [110.522, 1.893],
                                        [127.791, 7.32],
                                        [143.415, 15.908],
                                        [157.026, 27.281],
                                        [168.254, 41.064],
                                        [176.73, 56.882],
                                        [182.087, 74.36],
                                        [183.955, 93.123],
                                        [181.401, 114.312],
                                        [174.467, 136.931],
                                        [151.811, 183.169],
                                        [124.701, 225.254],
                                        [101.85, 256.604],
                                        [98.857, 260.605],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: 'sh',
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [6.208, 0],
                                        [5.669, -2.432],
                                        [4.223, -4.284],
                                        [2.316, -5.554],
                                        [0, -6.297],
                                        [-2.398, -5.751],
                                        [-4.223, -4.284],
                                        [-5.476, -2.349],
                                        [-6.208, 0],
                                        [-5.669, 2.432],
                                        [-4.223, 4.284],
                                        [-2.316, 5.554],
                                        [0, 6.297],
                                        [2.398, 5.751],
                                        [4.223, 4.284],
                                        [5.476, 2.349],
                                    ],
                                    o: [
                                        [-6.208, 0],
                                        [-5.476, 2.349],
                                        [-4.223, 4.284],
                                        [-2.398, 5.751],
                                        [0, 6.297],
                                        [2.316, 5.554],
                                        [4.223, 4.284],
                                        [5.669, 2.432],
                                        [6.208, 0],
                                        [5.476, -2.349],
                                        [4.223, -4.284],
                                        [2.398, -5.751],
                                        [0, -6.297],
                                        [-2.316, -5.554],
                                        [-4.223, -4.284],
                                        [-5.669, -2.432],
                                    ],
                                    v: [
                                        [91.783, 46.692],
                                        [73.883, 50.358],
                                        [59.265, 60.354],
                                        [49.41, 75.181],
                                        [45.796, 93.337],
                                        [49.41, 111.494],
                                        [59.265, 126.32],
                                        [73.883, 136.317],
                                        [91.783, 139.982],
                                        [109.683, 136.317],
                                        [124.3, 126.32],
                                        [134.156, 111.494],
                                        [137.769, 93.337],
                                        [134.156, 75.181],
                                        [124.3, 60.354],
                                        [109.683, 50.358],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 2',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Subtract Fill',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 25,
            ty: 4,
            nm: 'Subtract',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3345.623, 521.544, 0], ix: 2, l: 2 },
                a: { a: 0, k: [125.662, 360.684, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 31,
                            s: [0, 0, 100],
                        },
                        { t: 51, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 76.907],
                                        [-69.44, 0],
                                        [0, -70.245],
                                        [29.198, -39.138],
                                        [3.819, 0],
                                        [2.174, 2.796],
                                    ],
                                    o: [
                                        [0, -70.245],
                                        [69.44, 0],
                                        [0, 76.907],
                                        [-2.174, 2.855],
                                        [-3.819, 0],
                                        [-29.198, -39.138],
                                    ],
                                    v: [
                                        [0, 127.227],
                                        [125.662, 0],
                                        [251.324, 127.227],
                                        [135.062, 356.045],
                                        [125.662, 360.684],
                                        [116.263, 356.045],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Subtract Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 76.907],
                                        [-69.44, 0],
                                        [0, -70.245],
                                        [29.198, -39.138],
                                        [3.819, 0],
                                        [2.174, 2.796],
                                    ],
                                    o: [
                                        [0, -70.245],
                                        [69.44, 0],
                                        [0, 76.907],
                                        [-2.174, 2.855],
                                        [-3.819, 0],
                                        [-29.198, -39.138],
                                    ],
                                    v: [
                                        [0, 127.227],
                                        [125.662, 0],
                                        [251.324, 127.227],
                                        [135.062, 356.045],
                                        [125.662, 360.684],
                                        [116.263, 356.045],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.960784313725, 0.588235294118,
                                    0.545098039216, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Subtract Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 26,
            ty: 0,
            nm: 'complex',
            refId: 'comp_1',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [2729.143, 325],
                                [2379, 325],
                                [2379, 640.699],
                                [2729.143, 640.699],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 27,
            ty: 4,
            nm: 'Vector 165',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [891, 324, 0], ix: 2, l: 2 },
                a: { a: 0, k: [853, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [1706, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 165',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 41,
                                s: [100],
                            },
                            { t: 111, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 28,
            ty: 4,
            nm: 'Vector 159',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2834, 322, 0], ix: 2, l: 2 },
                a: { a: 0, k: [726, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [1452, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 159',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [64],
                            },
                            { t: 50, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 0,
                                s: [64],
                            },
                            { t: 50, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 29,
            ty: 0,
            nm: 'Train',
            refId: 'comp_2',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [2090.621, 259.391],
                                [1748.223, 259.391],
                                [1748.223, 449.082],
                                [2090.621, 449.082],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 30,
            ty: 4,
            nm: 'Vector 164',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2240, 526, 0], ix: 2, l: 2 },
                a: { a: 0, k: [96, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [192, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 164',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 8,
                                s: [0],
                            },
                            { t: 28, s: [100] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 0, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 31,
            ty: 4,
            nm: 'Vector 186',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2840, 448, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 186',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 9,
                                s: [100],
                            },
                            { t: 19, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 32,
            ty: 4,
            nm: 'Vector 185',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3050, 574, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 185',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 10,
                                s: [100],
                            },
                            { t: 20, s: [2] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 33,
            ty: 4,
            nm: 'Vector 162',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3554, 574, 0], ix: 2, l: 2 },
                a: { a: 0, k: [426, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [852, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 162',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 20,
                                s: [100],
                            },
                            { t: 50, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 34,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2645.242, 526.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 35,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2632.758, 526.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 36,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3587.242, 454.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 100, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 30,
                                s: [100],
                            },
                            { t: 50, s: [0] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 37,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3574.758, 454.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 100, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 30,
                                s: [100],
                            },
                            { t: 50, s: [1] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 38,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2987.242, 400.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 17,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 39,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2974.758, 400.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 17,
                                s: [100],
                            },
                            { t: 37, s: [1] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};

export const SceneStep5 = {
    v: '5.9.1',
    fr: 60,
    ip: 0,
    op: 600,
    w: 5760,
    h: 600,
    nm: 'Scene_04',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            nm: 'Fyr',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Rectangle 600258',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [102, 146.203, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [132, 30], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600258 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [132, 30], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600258 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [102, 74.203, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 33, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-7.488, 18.153],
                                                [-1.421, -2.556],
                                                [-3.7, -2.574],
                                                [-14.416, 10.087],
                                                [0, -2.798],
                                                [0.982, -14.681],
                                                [-3.507, 3.454],
                                                [-0.561, -2.677],
                                                [15.485, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [-17.993, 0],
                                                [1.122, -2.66],
                                                [0.018, 0.035],
                                                [-0.281, -8.895],
                                                [2.315, -1.624],
                                                [0, 18.326],
                                                [2.175, -0.812],
                                                [1.964, -1.934],
                                                [3.981, 19.224],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [26.276, 66],
                                                [2.092, 30.057],
                                                [8.405, 29.729],
                                                [14.263, 36.068],
                                                [30.081, 0.643],
                                                [35.623, 3.458],
                                                [44.707, 37.398],
                                                [53.406, 31.456],
                                                [59.316, 33.2],
                                                [33.782, 65.983],
                                                [26.258, 65.983],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-7.488, 18.153],
                                                [-1.421, -2.556],
                                                [-3.7, -2.574],
                                                [-14.416, 10.087],
                                                [0, -2.798],
                                                [0.982, -14.681],
                                                [-3.507, 3.454],
                                                [-0.561, -2.677],
                                                [15.485, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [-17.993, 0],
                                                [1.122, -2.66],
                                                [0.018, 0.035],
                                                [-0.281, -8.895],
                                                [2.315, -1.624],
                                                [0, 18.326],
                                                [2.175, -0.812],
                                                [1.964, -1.934],
                                                [3.981, 19.224],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [26.276, 66],
                                                [2.092, 30.057],
                                                [8.405, 29.729],
                                                [14.263, 36.068],
                                                [30.081, 0.643],
                                                [35.623, 3.458],
                                                [44.707, 37.398],
                                                [53.406, 31.456],
                                                [59.316, 33.2],
                                                [33.782, 65.983],
                                                [26.258, 65.983],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.960784313725, 0.588235294118,
                                            0.545098039216, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [157.5, 291.814, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 13.389, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 26.777],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [60, 291.703, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 13.5, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 27],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -26.625, ix: 10 },
                        p: { a: 0, k: [137.411, 204.71, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [4.556, 9.135, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [9.112, 18.271],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [141.894, 213.848, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [22.703, 22.839, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -12.613],
                                                [12.538, 0],
                                                [0, 12.613],
                                                [-12.538, 0],
                                            ],
                                            o: [
                                                [0, 12.613],
                                                [-12.538, 0],
                                                [0, -12.613],
                                                [12.538, 0],
                                            ],
                                            v: [
                                                [45.405, 22.839],
                                                [22.703, 45.677],
                                                [0, 22.839],
                                                [22.703, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [84, 215.203, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [6, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [12, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector',
                    parent: 9,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [54, 215.203, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [6, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [12, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 22,
                                    s: [2278, 744.272, 0],
                                    to: [0, -52.667, 0],
                                    ti: [0, 52.667, 0],
                                },
                                { t: 42, s: [2278, 428.272, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [102, 139.476, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [17.513, 0],
                                                [0, 0],
                                                [0, 17.413],
                                                [0, 0],
                                                [-17.52, 0],
                                                [0, 0],
                                                [0, -17.413],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-17.52, 0],
                                                [0, 0],
                                                [0, -17.413],
                                                [0, 0],
                                                [17.52, 0],
                                                [0, 0],
                                                [0, 17.413],
                                            ],
                                            v: [
                                                [172.231, 278.951],
                                                [31.769, 278.951],
                                                [0, 247.377],
                                                [0, 31.574],
                                                [31.769, 0],
                                                [172.231, 0],
                                                [204, 31.574],
                                                [204, 247.377],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [17.513, 0],
                                                [0, 0],
                                                [0, 17.413],
                                                [0, 0],
                                                [-17.52, 0],
                                                [0, 0],
                                                [0, -17.413],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-17.52, 0],
                                                [0, 0],
                                                [0, -17.413],
                                                [0, 0],
                                                [17.52, 0],
                                                [0, 0],
                                                [0, 17.413],
                                            ],
                                            v: [
                                                [172.231, 278.951],
                                                [31.769, 278.951],
                                                [0, 247.377],
                                                [0, 31.574],
                                                [31.769, 0],
                                                [172.231, 0],
                                                [204, 31.574],
                                                [204, 247.377],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_1',
            nm: 'House',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -180, ix: 10 },
                        p: { a: 0, k: [-106.2, 432, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [87, 75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [2.974, 0],
                                                [0, 0],
                                                [0, 7.138],
                                                [0, 0],
                                                [-7.077, 0],
                                                [0, 0],
                                                [-2.256, -1.862],
                                                [0, 0],
                                                [0, -3.879],
                                                [2.974, -2.483],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.077, 0],
                                                [0, 0],
                                                [0, -7.138],
                                                [0, 0],
                                                [2.974, 0],
                                                [0, 0],
                                                [2.974, 2.431],
                                                [0, 3.879],
                                                [0, 0],
                                                [-2.308, 1.862],
                                            ],
                                            v: [
                                                [85.846, 150],
                                                [12.821, 150],
                                                [0, 137.069],
                                                [0, 12.931],
                                                [12.821, 0],
                                                [85.846, 0],
                                                [93.949, 2.897],
                                                [169.282, 64.966],
                                                [174, 75],
                                                [169.282, 85.035],
                                                [93.949, 147.103],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [2.974, 0],
                                                [0, 0],
                                                [0, 7.138],
                                                [0, 0],
                                                [-7.077, 0],
                                                [0, 0],
                                                [-2.256, -1.862],
                                                [0, 0],
                                                [0, -3.879],
                                                [2.974, -2.483],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.077, 0],
                                                [0, 0],
                                                [0, -7.138],
                                                [0, 0],
                                                [2.974, 0],
                                                [0, 0],
                                                [2.974, 2.431],
                                                [0, 3.879],
                                                [0, 0],
                                                [-2.308, 1.862],
                                            ],
                                            v: [
                                                [85.846, 150],
                                                [12.821, 150],
                                                [0, 137.069],
                                                [0, 12.931],
                                                [12.821, 0],
                                                [85.846, 0],
                                                [93.949, 2.897],
                                                [169.282, 64.966],
                                                [174, 75],
                                                [169.282, 85.035],
                                                [93.949, 147.103],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.678431372549, 0.894117647059,
                                            0.850980392157, 1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -180, ix: 10 },
                        p: { a: 0, k: [-160.2, 252, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [141, 69, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [2.945, 0],
                                                [0, 0],
                                                [0, 6.567],
                                                [0, 0],
                                                [-7.008, 0],
                                                [0, 0],
                                                [-2.234, -1.713],
                                                [0, 0],
                                                [0, -3.569],
                                                [2.945, -2.284],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.008, 0],
                                                [0, 0],
                                                [0, -6.567],
                                                [0, 0],
                                                [2.945, 0],
                                                [0, 0],
                                                [2.945, 2.237],
                                                [0, 3.569],
                                                [0, 0],
                                                [-2.285, 1.713],
                                            ],
                                            v: [
                                                [194.703, 138],
                                                [12.696, 138],
                                                [0, 126.103],
                                                [0, 11.897],
                                                [12.696, 0],
                                                [194.703, 0],
                                                [202.727, 2.665],
                                                [277.328, 59.768],
                                                [282, 69],
                                                [277.328, 78.232],
                                                [202.727, 135.335],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [2.945, 0],
                                                [0, 0],
                                                [0, 6.567],
                                                [0, 0],
                                                [-7.008, 0],
                                                [0, 0],
                                                [-2.234, -1.713],
                                                [0, 0],
                                                [0, -3.569],
                                                [2.945, -2.284],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.008, 0],
                                                [0, 0],
                                                [0, -6.567],
                                                [0, 0],
                                                [2.945, 0],
                                                [0, 0],
                                                [2.945, 2.237],
                                                [0, 3.569],
                                                [0, 0],
                                                [-2.285, 1.713],
                                            ],
                                            v: [
                                                [194.703, 138],
                                                [12.696, 138],
                                                [0, 126.103],
                                                [0, 11.897],
                                                [12.696, 0],
                                                [194.703, 0],
                                                [202.727, 2.665],
                                                [277.328, 59.768],
                                                [282, 69],
                                                [277.328, 78.232],
                                                [202.727, 135.335],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -180, ix: 10 },
                        p: { a: 0, k: [-220.2, 72, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [201, 75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.023, 0],
                                                [0, 0],
                                                [0, 7.138],
                                                [0, 0],
                                                [-7.193, 0],
                                                [0, 0],
                                                [-2.293, -1.862],
                                                [0, 0],
                                                [0, -3.879],
                                                [3.023, -2.483],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.193, 0],
                                                [0, 0],
                                                [0, -7.138],
                                                [0, 0],
                                                [3.023, 0],
                                                [0, 0],
                                                [3.023, 2.431],
                                                [0, 3.879],
                                                [0, 0],
                                                [-2.345, 1.862],
                                            ],
                                            v: [
                                                [312.406, 150],
                                                [13.03, 150],
                                                [0, 137.069],
                                                [0, 12.931],
                                                [13.03, 0],
                                                [312.406, 0],
                                                [320.641, 2.897],
                                                [397.205, 64.966],
                                                [402, 75],
                                                [397.205, 85.035],
                                                [320.641, 147.103],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [3.023, 0],
                                                [0, 0],
                                                [0, 7.138],
                                                [0, 0],
                                                [-7.193, 0],
                                                [0, 0],
                                                [-2.293, -1.862],
                                                [0, 0],
                                                [0, -3.879],
                                                [3.023, -2.483],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-7.193, 0],
                                                [0, 0],
                                                [0, -7.138],
                                                [0, 0],
                                                [3.023, 0],
                                                [0, 0],
                                                [3.023, 2.431],
                                                [0, 3.879],
                                                [0, 0],
                                                [-2.345, 1.862],
                                            ],
                                            v: [
                                                [312.406, 150],
                                                [13.03, 150],
                                                [0, 137.069],
                                                [0, 12.931],
                                                [13.03, 0],
                                                [312.406, 0],
                                                [320.641, 2.897],
                                                [397.205, 64.966],
                                                [402, 75],
                                                [397.205, 85.035],
                                                [320.641, 147.103],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [86.402, 159.766, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [43.2, 54.255, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [86.4, 108.511],
                                                [86.4, 77.508],
                                                [86.4, 0],
                                                [0, 0],
                                                [0, 77.508],
                                                [0, 108.511],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [86.4, 108.511],
                                                [86.4, 77.508],
                                                [86.4, 0],
                                                [0, 0],
                                                [0, 77.508],
                                                [0, 108.511],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 180, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.196, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [2759.388, 926.325, 0],
                                    to: [0, -90, 0],
                                    ti: [0, 90, 0],
                                },
                                { t: 30, s: [2759.388, 386.325, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [105.412, 120.675, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [210.825, 241.351],
                                                [210.825, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [134.824, 356.647, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [134.824, 139.502, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [4.156, 4.038],
                                                [0, 0],
                                                [4.156, -3.711],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [4.268, -3.929],
                                                [0, 0],
                                                [-4.044, -3.929],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 279.005],
                                                [94.808, 194.25],
                                                [94.808, 241.946],
                                                [179.16, 241.946],
                                                [179.16, 115.449],
                                                [266.32, 34.028],
                                                [266.433, 34.028],
                                                [266.545, 19.403],
                                                [249.697, 3.031],
                                                [235.096, 2.704],
                                                [0, 208.473],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_2',
            nm: 'Vandhane',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector 187',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 27,
                                    s: [1808.5, 702, 0],
                                    to: [0, -75, 0],
                                    ti: [0, 75, 0],
                                },
                                { t: 47, s: [1808.5, 252, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [168, 13.5, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [336, 0],
                                                [336, 27],
                                                [0, 27],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 187',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.667, y: 1 },
                                    o: { x: 0.989, y: 0 },
                                    t: 27,
                                    s: [123.881, 205.124, 0],
                                    to: [-10.415, 0, 0],
                                    ti: [4.166, 0, 0],
                                },
                                { t: 47, s: [88.885, 205.124, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [16.124, 21.115, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -12.095],
                                                [8.182, 0],
                                                [0, 12.095],
                                                [0, 0],
                                                [-0.48, -0.358],
                                            ],
                                            o: [
                                                [0, 12.095],
                                                [-8.182, 0],
                                                [0, -12.095],
                                                [0.48, -0.358],
                                                [0, 0],
                                            ],
                                            v: [
                                                [32.248, 24.46],
                                                [16.124, 42.229],
                                                [0, 24.46],
                                                [15.255, 0.269],
                                                [16.963, 0.269],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -12.095],
                                                [8.182, 0],
                                                [0, 12.095],
                                                [0, 0],
                                                [-0.48, -0.358],
                                            ],
                                            o: [
                                                [0, 12.095],
                                                [-8.182, 0],
                                                [0, -12.095],
                                                [0.48, -0.358],
                                                [0, 0],
                                            ],
                                            v: [
                                                [32.248, 24.46],
                                                [16.124, 42.229],
                                                [0, 24.46],
                                                [15.255, 0.269],
                                                [16.963, 0.269],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [199.157, 42.578, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [15.578, 38.362, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [7.373, -22.727],
                                                [1.918, 0],
                                                [0, 0],
                                                [0.03, 1.971],
                                                [0, 0],
                                                [-1.948, 0],
                                                [0, 0],
                                                [-0.629, -1.882],
                                            ],
                                            o: [
                                                [-0.659, 1.911],
                                                [0, 0],
                                                [-1.948, 0],
                                                [0, 0],
                                                [0, -1.941],
                                                [0, 0],
                                                [1.948, 0],
                                                [7.373, 22.698],
                                            ],
                                            v: [
                                                [25.627, 73.289],
                                                [20.891, 76.724],
                                                [3.566, 76.724],
                                                [0, 73.17],
                                                [0, 3.524],
                                                [3.566, 0],
                                                [20.891, 0],
                                                [25.627, 3.434],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [195.335, 139.957, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [82.957, 52.757, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, -25.266],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [8.991, 0],
                                                [0, 0],
                                                [21.069, 17.949],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [28.142, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-0.509, -6.988],
                                                [0, 0],
                                                [-21.069, 17.949],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [95.155, 0],
                                                [97.283, 31.418],
                                                [114.905, 31.418],
                                                [165.914, 77.261],
                                                [165.914, 105.514],
                                                [130.609, 105.514],
                                                [130.609, 80.188],
                                                [114.905, 68.033],
                                                [103.187, 68.033],
                                                [30, 68.033],
                                                [0, 68.033],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [232.397, 76.016, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [19.016, 15.724, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [38.032, 0],
                                                [35.874, 31.448],
                                                [0, 31.448],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [260.187, 123.609, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [39.246, 12.066, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.35, 0],
                                                [0, 0],
                                                [0, -2.342],
                                                [0, 0],
                                                [2.35, 0],
                                                [0, 0],
                                                [0, 2.342],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [2.35, 0],
                                                [0, 0],
                                                [0, 2.342],
                                                [0, 0],
                                                [-2.35, 0],
                                                [0, 0],
                                                [0, -2.342],
                                            ],
                                            v: [
                                                [4.256, 0],
                                                [74.236, 0],
                                                [78.492, 4.241],
                                                [78.492, 19.89],
                                                [74.236, 24.131],
                                                [4.256, 24.131],
                                                [0, 19.89],
                                                [0, 4.241],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [168.079, 205.264, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [16.124, 21.115, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -12.095],
                                                [8.182, 0],
                                                [0, 12.095],
                                                [0, 0],
                                                [-0.48, -0.358],
                                            ],
                                            o: [
                                                [0, 12.095],
                                                [-8.182, 0],
                                                [0, -12.095],
                                                [0.48, -0.358],
                                                [0, 0],
                                            ],
                                            v: [
                                                [32.248, 24.46],
                                                [16.124, 42.229],
                                                [0, 24.46],
                                                [15.255, 0.269],
                                                [16.963, 0.269],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [142.608, 205.277, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [27.228, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [54.456, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [303.697, 123.602, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [13.223, 14.303, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [-7.313, 0.448],
                                                [-0.45, -7.287],
                                                [0, -0.538],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-0.48, -7.287],
                                                [7.283, -0.448],
                                                [0.03, 0.538],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0.958, 28.577],
                                                [0.029, 14.002],
                                                [12.407, 0.026],
                                                [26.432, 12.36],
                                                [26.432, 14.032],
                                                [25.503, 28.607],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [301.43, 151.51, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [14.67, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [29.341, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector',
                    parent: 1,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 90, ix: 10 },
                        p: { a: 0, k: [301.43, 95.705, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [14.67, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [29.341, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_3',
            nm: 'Windmill',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    parent: 4,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [131.514, 22.501, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [63.733, 19.6, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [-8.716, -2.526],
                                                [0, 0],
                                                [0, 0],
                                                [2.998, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [7.043, -5.684],
                                                [0, 0],
                                                [0, 0],
                                                [0, 3.017],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 21.096],
                                                [18.618, 6.15],
                                                [43.721, 1.098],
                                                [127.466, 25.166],
                                                [127.466, 33.727],
                                                [122.027, 39.2],
                                                [1.255, 39.2],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    parent: 4,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-8.799, 119.816, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [40.584, 58.556, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [6.485, -6.315],
                                                [0, 0],
                                                [0, 0],
                                                [-1.464, 2.596],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [1.395, 8.982],
                                                [0, 0],
                                                [0, 0],
                                                [-2.58, -1.474],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [77.281, 8.21],
                                                [80.837, 31.717],
                                                [72.679, 56.136],
                                                [10.062, 117.113],
                                                [2.67, 112.833],
                                                [0.718, 105.395],
                                                [61.174, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    parent: 4,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-13.697, -47.11, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [37.619, 59.039, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [2.162, 8.841],
                                                [0, 0],
                                                [0, 0],
                                                [-1.464, -2.596],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-8.368, -3.298],
                                                [0, 0],
                                                [0, 0],
                                                [2.58, -1.544],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60.177, 118.077],
                                                [38.003, 109.376],
                                                [21.128, 90.079],
                                                [0, 5.034],
                                                [7.391, 0.754],
                                                [14.783, 2.718],
                                                [75.238, 108.113],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [
                                            0.439215686275, 0.439215686275,
                                            0.439215686275, 1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 1, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: true,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0], y: [1] },
                                    o: { x: [0.333], y: [0] },
                                    t: 21,
                                    s: [-187],
                                },
                                { t: 51, s: [0] },
                            ],
                            ix: 10,
                        },
                        p: { a: 0, k: [0.002, -35.087, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [34.865, 35.085, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -19.377],
                                                [19.255, 0],
                                                [0, 19.377],
                                                [-19.255, 0],
                                            ],
                                            o: [
                                                [0, 19.377],
                                                [-19.255, 0],
                                                [0, -19.377],
                                                [19.255, 0],
                                            ],
                                            v: [
                                                [69.73, 35.085],
                                                [34.865, 70.169],
                                                [0, 35.085],
                                                [34.865, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 21,
                                    s: [3735.104, 776.715, 0],
                                    to: [0, -78.333, 0],
                                    ti: [0, 78.333, 0],
                                },
                                { t: 41, s: [3735.104, 306.715, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [10.459, 112.271, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, -11.648],
                                                [0, 0],
                                            ],
                                            o: [
                                                [11.575, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [20.919, 21.051],
                                                [20.919, 224.542],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector',
                    parent: 5,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-20.918, 161.385, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 63.153, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 126.305],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: true,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Vector 200',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2632, 507, 0], ix: 2, l: 2 },
                a: { a: 0, k: [96, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [327.25, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'tm',
                            s: { a: 0, k: 0, ix: 1 },
                            e: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 0,
                                        s: [0],
                                    },
                                    { t: 20, s: [100] },
                                ],
                                ix: 2,
                            },
                            o: { a: 0, k: 0, ix: 3 },
                            m: 1,
                            ix: 2,
                            nm: 'Trim Paths 1',
                            mn: 'ADBE Vector Filter - Trim',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 200',
                    np: 4,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Vector 203',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1276, 512, 0], ix: 2, l: 2 },
                a: { a: 0, k: [284, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [568, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 203',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 29,
                                s: [0],
                            },
                            { t: 49, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Vector 204',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [914, 512, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 204',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 49,
                                s: [0],
                            },
                            { t: 59, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Vector 208',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [1520, 180, 0], ix: 2, l: 2 },
                a: { a: 0, k: [64, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [128, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 208',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 30,
                                s: [100],
                            },
                            { t: 40, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Vector 202',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -90, ix: 10 },
                p: { a: 0, k: [1404, 248, 0], ix: 2, l: 2 },
                a: { a: 0, k: [100, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [200, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 202',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 31,
                                s: [100],
                            },
                            { t: 41, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Vector 206',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -180, ix: 10 },
                p: { a: 0, k: [1387, 282, 0], ix: 2, l: 2 },
                a: { a: 0, k: [48, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [96, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 206',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 31,
                                s: [100],
                            },
                            { t: 41, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Vector 204',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [1453, 318, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 204',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 30,
                                s: [100],
                            },
                            { t: 40, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Vector 207',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [1398, 200, 0], ix: 2, l: 2 },
                a: { a: 0, k: [174, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [348, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 207',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 29,
                                s: [100],
                            },
                            { t: 39, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Vector',
            parent: 10,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.803, 17.787, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.572, 71.939, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [
                                    0.439215686275, 0.439215686275,
                                    0.439215686275, 1,
                                ],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 1, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: true,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.960784313725, 0.588235294118,
                                    0.545098039216, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4452, 419.379, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 31,
                            s: [0, 0, 100],
                        },
                        { t: 51, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Vector',
            parent: 15,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [13.744, 48.9, 0], ix: 2, l: 2 },
                a: { a: 0, k: [13.744, 13.916, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 27.832],
                                        [27.488, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Vector',
            parent: 15,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-8.284, 24.303, 0], ix: 2, l: 2 },
                a: { a: 0, k: [8.286, 8.381, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [16.572, 16.761],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Vector',
            parent: 15,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-14.312, 86.277, 0], ix: 2, l: 2 },
                a: { a: 0, k: [14.313, 14.476, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [28.625, 28.952],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 14,
            ty: 4,
            nm: 'Vector',
            parent: 15,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.032, 52.434, 0], ix: 2, l: 2 },
                a: { a: 0, k: [59.157, 83.527, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0.4, -2.363],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                        [0.4, -2.363],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 2.799],
                                        [0, 0],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                        [98.912, 58.805],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4343, 418.113, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 171.097, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 30,
                            s: [0, 0, 100],
                        },
                        { t: 50, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 171.097],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 16,
            ty: 4,
            nm: 'Vector 201',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4644, 512, 0], ix: 2, l: 2 },
                a: { a: 0, k: [416, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [832, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 201',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 29,
                                s: [100],
                            },
                            { t: 49, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 17,
            ty: 0,
            nm: 'Fyr',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [2385.77, 597.297],
                                [2149.488, 597.297],
                                [2149.488, 648.172],
                                [2385.77, 648.172],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 18,
            ty: 0,
            nm: 'House',
            refId: 'comp_1',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [3327.719, -3.887],
                                [2554.656, -3.887],
                                [2554.328, 516.285],
                                [3327.39, 516.285],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 19,
            ty: 0,
            nm: 'Vandhane',
            refId: 'comp_2',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [2053.656, 417.531],
                                [1775.508, 417.531],
                                [1775.508, 745.188],
                                [2053.656, 745.188],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 20,
            ty: 0,
            nm: 'Windmill',
            refId: 'comp_3',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [3968.57, 423.158],
                                [3529.497, 423.254],
                                [3529.497, 736.191],
                                [3968.57, 736.096],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Vector 189',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2215, 594, 0], ix: 2, l: 2 },
                a: { a: 0, k: [321, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [642, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 189',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 21,
                                s: [0],
                            },
                            { t: 41, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Vector 159',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3229, 594, 0], ix: 2, l: 2 },
                a: { a: 0, k: [231, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [462, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 159',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 12,
                                s: [100],
                            },
                            { t: 32, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 23,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3563.027, 227.183, 0], ix: 2, l: 2 },
                a: { a: 0, k: [20.488, 16.364, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, -9.041],
                                        [9.056, 0],
                                        [2.95, 2.945],
                                    ],
                                    o: [
                                        [0, 0],
                                        [9.056, 0],
                                        [0, 9.041],
                                        [-4.548, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [24.585, 0],
                                        [40.976, 16.364],
                                        [24.585, 32.727],
                                        [12.989, 27.941],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 39,
                                s: [0],
                            },
                            { t: 49, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 24,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3483.126, 235.365, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.024, 24.545, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, -13.541],
                                        [13.563, 0],
                                        [0, 13.541],
                                    ],
                                    o: [
                                        [0, 0],
                                        [13.563, 0],
                                        [0, 13.541],
                                        [-13.563, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [61.463, 0],
                                        [86.049, 24.545],
                                        [61.463, 49.091],
                                        [36.878, 24.545],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 34,
                                s: [0],
                            },
                            { t: 44, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 25,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3515.907, 124.911, 0], ix: 2, l: 2 },
                a: { a: 0, k: [38.927, 36.818, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-20.365, 0],
                                        [0, -20.332],
                                        [20.365, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, -20.332],
                                        [20.365, 0],
                                        [0, 20.332],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.098, 36.818],
                                        [40.976, 0],
                                        [77.854, 36.818],
                                        [40.976, 73.636],
                                        [0, 73.636],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 37,
                                s: [100],
                            },
                            { t: 47, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 26,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3530.237, 161.727, 0], ix: 2, l: 2 },
                a: { a: 0, k: [69.659, 24.545, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, -13.541],
                                        [13.563, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [13.563, 0],
                                        [0, 13.541],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [114.732, 0],
                                        [139.317, 24.545],
                                        [114.732, 49.091],
                                        [0, 49.091],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 35,
                                s: [100],
                            },
                            { t: 45, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 27,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3292.746, 531.6, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 17,
                                s: [100],
                            },
                            { t: 27, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 28,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3280.25, 531.6, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 17,
                                s: [100],
                            },
                            { t: 27, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 29,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3577.242, 408.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 23,
                                s: [100],
                            },
                            { t: 33, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 30,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3564.758, 408.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 23,
                                s: [100],
                            },
                            { t: 33, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 31,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3967.242, 502.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 27,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 32,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3954.758, 502.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 27,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 33,
            ty: 4,
            nm: 'Vector 199',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1816, 594, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 199',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 41,
                                s: [0],
                            },
                            { t: 51, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 34,
            ty: 4,
            nm: 'Vector 202',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [5138, 512, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 202',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 49,
                                s: [100],
                            },
                            { t: 59, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 35,
            ty: 4,
            nm: 'Vector 198',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3574, 512, 0], ix: 2, l: 2 },
                a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [60, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 198',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 29,
                                s: [100],
                            },
                            { t: 39, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 36,
            ty: 4,
            nm: 'Vector 161',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3757, 512, 0], ix: 2, l: 2 },
                a: { a: 0, k: [105, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [210, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 161',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 39,
                                s: [100],
                            },
                            { t: 54, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 37,
            ty: 4,
            nm: 'Vector 197',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4516, 420, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1244, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2488, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 197',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.113], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 13,
                                s: [100],
                            },
                            { t: 79, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 38,
            ty: 4,
            nm: 'Vector 158',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1324, 420, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1324, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2648, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 3,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: true,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 158',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.086], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 13,
                                s: [0],
                            },
                            { t: 79, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};

export const SceneStep6 = {
    v: '5.9.1',
    fr: 60,
    ip: 0,
    op: 600,
    w: 5760,
    h: 600,
    nm: 'Scene_05',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            nm: 'House',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Rectangle 600021',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [426, 132, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 66], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600021 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 66], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600021 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Rectangle 600023',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [426, 60, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [24, 90], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600023 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [24, 90], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600023 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Rectangle 600025',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [426, 9, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 62.5, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600025 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600025 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Vector 403',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 135, ix: 10 },
                        p: { a: 0, k: [-18, -219, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 25.456, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 50.912],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 403',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Vector 404',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 180, ix: 10 },
                        p: { a: 0, k: [0, -225, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 24, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 48],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 404',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -135, ix: 10 },
                        p: { a: 0, k: [18, -219, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 25.456, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 50.912],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,
                    nm: 'Ellipse 1023',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -225, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [48, 24, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -26.51],
                                                [30.545, 0],
                                                [-26.51, 0],
                                            ],
                                            o: [
                                                [-34.909, 0],
                                                [0, -26.51],
                                                [26.51, 0],
                                            ],
                                            v: [
                                                [96, 48],
                                                [0, 48],
                                                [48, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -0.5, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Ellipse 1023 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, -26.51],
                                                [30.545, 0],
                                                [-26.51, 0],
                                            ],
                                            o: [
                                                [-34.909, 0],
                                                [0, -26.51],
                                                [26.51, 0],
                                            ],
                                            v: [
                                                [96, 48],
                                                [0, 48],
                                                [48, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Ellipse 1023 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,
                    nm: 'Vector 399',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -234, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [174, 57, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [348, 114],
                                                [0, 114],
                                                [174, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 399 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [348, 114],
                                                [0, 114],
                                                [174, 0],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 399 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,
                    nm: 'Vector 164',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-378, 129, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [72, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 164',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-378, 99, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [72, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,
                    nm: 'Vector 163',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-378, 39, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [72, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 163',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-378, 69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [72, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,
                    nm: 'Vector 162',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-366, 84, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 150],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 162',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-390, 84, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 150],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 15,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-378, 87, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 78, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 156],
                                                [0, 0],
                                                [72, 0],
                                                [72, 156],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 16,
                    ty: 4,
                    nm: 'Rectangle 600025',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [90, -133, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 70.833, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600025 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600025 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 17,
                    ty: 4,
                    nm: 'Rectangle 600024',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-90, -133, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 70.833, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600024 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600024 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 18,
                    ty: 4,
                    nm: 'Rectangle 600021',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [90, 132, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 66], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600021 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 66], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600021 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 19,
                    ty: 4,
                    nm: 'Rectangle 600020',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-90, 132, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 66], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600020 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [36, 66], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600020 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 20,
                    ty: 4,
                    nm: 'Rectangle 600023',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [90, -12, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 95.726, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [24, 234], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600023 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [24, 234], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600023 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 21,
                    ty: 4,
                    nm: 'Rectangle 600022',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-90, -12, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 96.581, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [24, 234], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600022 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [24, 234], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 2.861, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600022 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 22,
                    ty: 4,
                    nm: 'Vector 162',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [198, -87, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 162',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 23,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [198, -54, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 24,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [207, -69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 25,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [186, -69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 26,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [196.5, -70.5, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 27,
                    ty: 4,
                    nm: 'Vector 162',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-195, -87, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 162',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 28,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-195, -54, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 29,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-186, -69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 30,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-207, -69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 31,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-196.5, -70.5, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 32,
                    ty: 4,
                    nm: 'Vector 162',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-195, 51, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 162',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 33,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-195, 84, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 34,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-186, 69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 35,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-207, 69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 36,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-196.5, 67.5, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 37,
                    ty: 4,
                    nm: 'Vector 162',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [198, 51, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 162',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 38,
                    ty: 4,
                    nm: 'Vector 160',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [198, 84, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [60, 0],
                                                [0, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 160',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 39,
                    ty: 4,
                    nm: 'Vector 161',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [207, 69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 161',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 40,
                    ty: 4,
                    nm: 'Vector 152',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [186, 69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 48, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 96],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 152',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 41,
                    ty: 4,
                    nm: 'Rectangle 600032',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [196.5, 67.5, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [69, 105], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600032 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 42,
                    ty: 4,
                    nm: 'Rectangle 600016',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -162, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [240, 42], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600016 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [240, 42], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600016 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 43,
                    ty: 4,
                    nm: 'Rectangle 600005',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -195, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [342, 36, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [84, 0],
                                                [600, 0],
                                                [684, 72],
                                                [0, 72],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600005 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [84, 0],
                                                [600, 0],
                                                [684, 72],
                                                [0, 72],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600005 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 44,
                    ty: 4,
                    nm: 'Rectangle 600029',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, 153, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [120, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600029 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [120, 24], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600029 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 45,
                    ty: 4,
                    nm: 'Vector 392',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, 85, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 54, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 108],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 392',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 46,
                    ty: 4,
                    nm: 'Rectangle 600042',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, 85, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [36, 57, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [72, 0],
                                                [72, 114],
                                                [0, 114],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600042 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [72, 0],
                                                [72, 114],
                                                [0, 114],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600042 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 47,
                    ty: 4,
                    nm: 'Rectangle 600035',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-395.5, -93, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [96, 36, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [84, 0],
                                                [192, 0],
                                                [192, 72],
                                                [0, 72],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600035 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [84, 0],
                                                [192, 0],
                                                [192, 72],
                                                [0, 72],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600035 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 48,
                    ty: 4,
                    nm: 'Rectangle 600034',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [-378, 51, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [78, 114, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [156, 0],
                                                [156, 228],
                                                [0, 228],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600034 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [156, 0],
                                                [156, 228],
                                                [0, 228],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600034 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 49,
                    ty: 4,
                    nm: 'Vector 397',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [450, -33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [60, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 397',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 50,
                    ty: 4,
                    nm: 'Vector 402',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [426, -33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [60, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 402',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 51,
                    ty: 4,
                    nm: 'Vector 401',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [402, -33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [60, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 401',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 52,
                    ty: 4,
                    nm: 'Vector 400',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [378, -33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [60, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 400',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 53,
                    ty: 4,
                    nm: 'Vector 396',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [354, -33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [60, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 396',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 54,
                    ty: 4,
                    nm: 'Vector 395',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [330, -33, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [30, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [60, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 395',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 55,
                    ty: 4,
                    nm: 'Vector 398',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [381, -63, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [75, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [150, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 398',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 56,
                    ty: 4,
                    nm: 'Vector 393',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [381, 0, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [75, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [150, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 393',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 57,
                    ty: 4,
                    nm: 'Vector 411',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [54, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 411',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 58,
                    ty: 4,
                    nm: 'Vector 410',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [36, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 410',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 59,
                    ty: 4,
                    nm: 'Vector 409',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [0, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 409',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 60,
                    ty: 4,
                    nm: 'Vector 408',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [18, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 408',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 61,
                    ty: 4,
                    nm: 'Vector 407',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [-18, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 407',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 62,
                    ty: 4,
                    nm: 'Vector 406',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [-36, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 406',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 63,
                    ty: 4,
                    nm: 'Vector 405',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: -90, ix: 10 },
                        p: { a: 0, k: [-54, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [21, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [42, 0],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 405',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 64,
                    ty: 4,
                    nm: 'Rectangle 600041',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -18, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [93.452, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [168, 54], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600041 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [168, 54], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600041 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 65,
                    ty: 4,
                    nm: 'Vector 145',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -69, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 42, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [0, 0],
                                                [0, 84],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector 145',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 66,
                    ty: 4,
                    nm: 'Rectangle 600040',
                    parent: 67,
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [0, -54, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [72, 114], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600040',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 67,
                    ty: 4,
                    nm: 'Rectangle 600028',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0, y: 1 },
                                    o: { x: 0.333, y: 0 },
                                    t: 0,
                                    s: [2774, 839, 0],
                                    to: [0, -83.333, 0],
                                    ti: [0, 83.333, 0],
                                },
                                { t: 30, s: [2774, 339, 0] },
                            ],
                            ix: 2,
                            l: 2,
                        },
                        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [612, 330], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'op',
                                    nm: 'Offset Paths 1',
                                    a: { a: 0, k: -3, ix: 1 },
                                    lj: 1,
                                    ml: { a: 0, k: 4, ix: 3 },
                                    ix: 3,
                                    mn: 'ADBE Vector Filter - Offset',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600028 Stroke',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ty: 'rc',
                                    d: 1,
                                    s: { a: 0, k: [612, 330], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    r: { a: 0, k: 6, ix: 4 },
                                    nm: 'Rectangle Path 1',
                                    mn: 'ADBE Vector Shape - Rect',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Rectangle 600028 Fill',
                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 2,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
        {
            id: 'comp_1',
            nm: 'Lake',
            fr: 60,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 180, ix: 10 },
                        p: { a: 0, k: [3446.303, 506.996, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [34.869, 3.324, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [-20.095, -14.957],
                                                [0, 0],
                                                [-20.095, -14.957],
                                                [0, 0],
                                            ],
                                            v: [
                                                [69.737, 6.648],
                                                [69.499, 6.648],
                                                [34.845, 6.648],
                                                [34.654, 6.648],
                                                [0, 6.648],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 180, ix: 10 },
                        p: { a: 0, k: [3496.781, 554.871, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [52.172, 3.324, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [-20.095, -14.957],
                                                [0, 0],
                                                [-20.095, -14.957],
                                                [0, 0],
                                                [-20.095, -14.957],
                                                [0, 0],
                                            ],
                                            v: [
                                                [104.344, 6.648],
                                                [69.69, 6.648],
                                                [69.499, 6.648],
                                                [34.845, 6.648],
                                                [34.654, 6.648],
                                                [0, 6.648],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 6, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Vector',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 180, ix: 10 },
                        p: { a: 0, k: [3470.051, 534.005, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [174.066, 59.943, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    ind: 0,
                                    ty: 'sh',
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0.286, 3.388],
                                                [0, 0],
                                                [3.484, 6.486],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0.621, 9.052],
                                                [25.038, 3.069],
                                            ],
                                            o: [
                                                [-19.666, -2.833],
                                                [-0.764, -8.616],
                                                [55.704, -10.988],
                                                [-10.024, -18.491],
                                                [-189.26, 0],
                                                [0, 27.736],
                                                [39.618, 2.13],
                                                [-16.802, 12.779],
                                                [75.036, 13.795],
                                                [19.284, -2.662],
                                                [-0.764, -11.23],
                                                [0, 0],
                                            ],
                                            v: [
                                                [274.783, 68.783],
                                                [253.446, 58.715],
                                                [270.582, 47.63],
                                                [325.952, 20.911],
                                                [182.277, 0],
                                                [0.033, 46.904],
                                                [105.189, 76.77],
                                                [117.122, 96.519],
                                                [135.356, 114.719],
                                                [320.988, 110.315],
                                                [348.1, 93.131],
                                                [299.079, 71.881],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },
                                    nm: 'Path 1',
                                    mn: 'ADBE Vector Shape - Group',
                                    hd: false,
                                },
                                {
                                    ty: 'fl',
                                    c: {
                                        a: 0,
                                        k: [0, 0.4, 0.392156862745, 1],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,
                                    nm: 'Fill 1',
                                    mn: 'ADBE Vector Graphic - Fill',
                                    hd: false,
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [0, 0], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform',
                                },
                            ],
                            nm: 'Vector',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 600,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Vector',
            parent: 4,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [15.666, 53.724, 0], ix: 2, l: 2 },
                a: { a: 0, k: [15.642, 15.885, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 31.769],
                                        [31.285, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Vector',
            parent: 4,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-9.41, 25.604, 0], ix: 2, l: 2 },
                a: { a: 0, k: [9.434, 9.561, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [18.867, 19.122],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Vector',
            parent: 4,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-16.275, 96.389, 0], ix: 2, l: 2 },
                a: { a: 0, k: [16.291, 16.543, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [32.582, 33.087],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4590, 420, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 198, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1.063, 1.063, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 28,
                            s: [0, 0, 100],
                        },
                        { t: 48, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 198],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Vector',
            parent: 4,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.042, 57.956, 0], ix: 2, l: 2 },
                a: { a: 0, k: [109.367, 95.251, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -20.627],
                                        [19.275, -3.99],
                                        [0, -3.802],
                                        [23.352, 0],
                                        [7.636, 11.217],
                                        [14.419, 0],
                                        [0, 23.714],
                                        [-0.927, 3.501],
                                        [0, 20.627],
                                        [-19.127, 4.103],
                                        [0, 3.35],
                                        [-23.352, 0],
                                        [-7.636, -11.217],
                                        [-14.419, 0],
                                        [0, -23.714],
                                        [0.704, -3.124],
                                        [0, -20.627],
                                    ],
                                    o: [
                                        [0, 20.74],
                                        [0.927, 3.501],
                                        [0, 23.714],
                                        [-14.419, 0],
                                        [-7.599, 11.217],
                                        [-23.352, 0],
                                        [0, -3.802],
                                        [-19.275, -3.99],
                                        [0, -20.627],
                                        [-0.704, -3.124],
                                        [0, -23.714],
                                        [14.419, 0],
                                        [7.599, -11.217],
                                        [23.352, 0],
                                        [0, 3.35],
                                        [19.09, 4.141],
                                        [0, 0],
                                    ],
                                    v: [
                                        [218.733, 94.555],
                                        [184.928, 136.6],
                                        [186.374, 147.591],
                                        [144.117, 190.502],
                                        [109.348, 171.945],
                                        [74.579, 190.502],
                                        [32.323, 147.591],
                                        [33.768, 136.6],
                                        [0, 94.555],
                                        [33.435, 52.585],
                                        [32.323, 42.911],
                                        [74.579, 0],
                                        [109.348, 18.557],
                                        [144.117, 0],
                                        [186.374, 42.911],
                                        [185.262, 52.585],
                                        [218.696, 94.555],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Vector',
            parent: 9,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.333, 20.958, 0], ix: 2, l: 2 },
                a: { a: 0, k: [10.5, 10.479, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [10.5, 10.479],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Vector',
            parent: 9,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 44.942, ix: 10 },
                p: { a: 0, k: [10.5, 36.697, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 14.849, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 29.698],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6.006, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Vector',
            parent: 9,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.666, 52.396, 0], ix: 2, l: 2 },
                a: { a: 0, k: [21, 20.958, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [21, 20.958],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1268, 419.38, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 25,
                            s: [0, 0, 100],
                        },
                        { t: 45, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Vector',
            parent: 9,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.803, 17.786, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.572, 71.939, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [753.242, 408.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 35,
                                s: [100],
                            },
                            { t: 55, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [740.758, 408.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 35,
                                s: [100],
                            },
                            { t: 55, s: [1] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [5021.242, 488.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1.067] },
                                o: { x: [0.333], y: [0] },
                                t: 35,
                                s: [100],
                            },
                            { t: 55, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 14,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [5008.758, 488.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1.067] },
                                o: { x: [0.333], y: [0] },
                                t: 35,
                                s: [100],
                            },
                            { t: 55, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: 'Vector 201',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4688, 528, 0], ix: 2, l: 2 },
                a: { a: 0, k: [20, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [40, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 201',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 45,
                                s: [100],
                            },
                            { t: 55, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 16,
            ty: 4,
            nm: 'Vector 199',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1188, 596, 0], ix: 2, l: 2 },
                a: { a: 0, k: [20, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [40, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 199',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 27,
                                s: [0],
                            },
                            { t: 37, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 17,
            ty: 4,
            nm: 'Vector 200',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4492, 528, 0], ix: 2, l: 2 },
                a: { a: 0, k: [128, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [256, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 200',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                t: 25,
                                s: [100],
                            },
                            { t: 45, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 18,
            ty: 4,
            nm: 'Vector 159',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [800, 596, 0], ix: 2, l: 2 },
                a: { a: 0, k: [320, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [640, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 159',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 37,
                                s: [0],
                            },
                            { t: 57, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 19,
            ty: 4,
            nm: 'Vector',
            parent: 23,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [13.744, 48.902, 0], ix: 2, l: 2 },
                a: { a: 0, k: [13.744, 13.916, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 27.832],
                                        [27.488, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 20,
            ty: 4,
            nm: 'Vector',
            parent: 23,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-8.284, 24.301, 0], ix: 2, l: 2 },
                a: { a: 0, k: [8.286, 8.381, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [16.572, 16.761],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Vector',
            parent: 23,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-14.316, 86.277, 0], ix: 2, l: 2 },
                a: { a: 0, k: [14.313, 14.476, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [28.625, 28.952],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Vector',
            parent: 23,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.036, 52.432, 0], ix: 2, l: 2 },
                a: { a: 0, k: [59.157, 83.527, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0.4, -2.363],
                                        [0, -20.058],
                                        [32.684, 0],
                                        [0, 37.814],
                                        [-11.899, 18.814],
                                        [0, 2.83],
                                        [-22.353, 0],
                                        [0, -25.873],
                                        [0.4, -2.363],
                                    ],
                                    o: [
                                        [11.868, 18.814],
                                        [0, 37.814],
                                        [-32.684, 0],
                                        [0, -20.058],
                                        [-0.43, -2.332],
                                        [0, -25.842],
                                        [22.353, 0],
                                        [0, 2.799],
                                        [0, 0],
                                    ],
                                    v: [
                                        [98.974, 58.774],
                                        [118.313, 122.43],
                                        [59.157, 167.055],
                                        [0, 122.43],
                                        [19.34, 58.774],
                                        [18.663, 51.062],
                                        [59.126, 0],
                                        [99.589, 51.093],
                                        [98.912, 58.805],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 23,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1159, 418.113, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 171.097, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 27,
                            s: [0, 0, 100],
                        },
                        { t: 47, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 171.097],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 24,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2415.5, 57, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 51, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 24,
                            s: [0, 0, 100],
                        },
                        { t: 34, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-21.71, 0],
                                        [0, -21.487],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    o: [
                                        [-21.71, 0],
                                        [0, -21.527],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    v: [
                                        [87, 51],
                                        [43.5, 102],
                                        [0, 51],
                                        [43.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-21.71, 0],
                                        [0, -21.487],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    o: [
                                        [-21.71, 0],
                                        [0, -21.527],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    v: [
                                        [87, 51],
                                        [43.5, 102],
                                        [0, 51],
                                        [43.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.678431372549, 0.894117647059,
                                    0.850980392157, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 25,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2220.5, 252, 0], ix: 2, l: 2 },
                a: { a: 0, k: [28.5, 36, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 19,
                            s: [0, 0, 100],
                        },
                        { t: 29, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-14.224, 0],
                                        [0, -15.168],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    o: [
                                        [-14.224, 0],
                                        [0, -15.195],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    v: [
                                        [57, 36],
                                        [28.5, 72],
                                        [0, 36],
                                        [28.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-14.224, 0],
                                        [0, -15.168],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    o: [
                                        [-14.224, 0],
                                        [0, -15.195],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    v: [
                                        [57, 36],
                                        [28.5, 72],
                                        [0, 36],
                                        [28.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.678431372549, 0.894117647059,
                                    0.850980392157, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 26,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3174.5, 114, 0], ix: 2, l: 2 },
                a: { a: 0, k: [28.5, 36, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 24,
                            s: [0, 0, 100],
                        },
                        { t: 34, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-14.224, 0],
                                        [0, -15.168],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    o: [
                                        [-14.224, 0],
                                        [0, -15.195],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    v: [
                                        [57, 36],
                                        [28.5, 72],
                                        [0, 36],
                                        [28.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-14.224, 0],
                                        [0, -15.168],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    o: [
                                        [-14.224, 0],
                                        [0, -15.195],
                                        [14.224, 0],
                                        [0, 15.195],
                                    ],
                                    v: [
                                        [57, 36],
                                        [28.5, 72],
                                        [0, 36],
                                        [28.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.678431372549, 0.894117647059,
                                    0.850980392157, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 27,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3300.5, 219, 0], ix: 2, l: 2 },
                a: { a: 0, k: [43.5, 51, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 19,
                            s: [0, 0, 100],
                        },
                        { t: 29, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-21.71, 0],
                                        [0, -21.487],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    o: [
                                        [-21.71, 0],
                                        [0, -21.527],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    v: [
                                        [87, 51],
                                        [43.5, 102],
                                        [0, 51],
                                        [43.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [-21.71, 0],
                                        [0, -21.487],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    o: [
                                        [-21.71, 0],
                                        [0, -21.527],
                                        [21.71, 0],
                                        [0, 21.527],
                                    ],
                                    v: [
                                        [87, 51],
                                        [43.5, 102],
                                        [0, 51],
                                        [43.5, 0],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.678431372549, 0.894117647059,
                                    0.850980392157, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 28,
            ty: 0,
            nm: 'House',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: true,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            o: [
                                [0, 0],
                                [0, 0],
                                [0, 0],
                                [0, 0],
                            ],
                            v: [
                                [3264.203, 507.547],
                                [2271.91, 507.547],
                                [2271.91, 650.32],
                                [3264.203, 650.32],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: { a: 0, k: 0, ix: 4 },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 29,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [10.506, 18.475, 0], ix: 2, l: 2 },
                a: { a: 0, k: [10.522, 10.495, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 20.99],
                                        [21.043, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 30,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [21.396, 152.514, 0], ix: 2, l: 2 },
                a: { a: 0, k: [21.411, 21.416, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 42.832],
                                        [42.823, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 31,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [29.534, 97.81, 0], ix: 2, l: 2 },
                a: { a: 0, k: [29.549, 29.529, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 59.058],
                                        [59.099, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 32,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [17.036, 61.969, 0], ix: 2, l: 2 },
                a: { a: 0, k: [17.052, 17.04, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 34.08],
                                        [34.103, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 33,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-21.432, 134.951, 0], ix: 2, l: 2 },
                a: { a: 0, k: [21.411, 21.397, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [42.823, 42.793],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 34,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-29.568, 80.263, 0], ix: 2, l: 2 },
                a: { a: 0, k: [29.549, 29.529, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [59.099, 59.058],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 35,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-17.054, 44.426, 0], ix: 2, l: 2 },
                a: { a: 0, k: [17.032, 17.04, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [34.064, 34.08],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 36,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3607.984, 480, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 219, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 14,
                            s: [0, 0, 100],
                        },
                        { t: 34, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 219],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 37,
            ty: 4,
            nm: 'Vector',
            parent: 36,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.038, 60.87, 0], ix: 2, l: 2 },
                a: { a: 0, k: [115.132, 113.031, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [3.914, 54.411],
                                        [-33.018, 42.367],
                                        [-46.853, 19.17],
                                        [-43.675, -11.773],
                                        [-48.403, 1.781],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [48.364, 1.743],
                                        [43.636, -11.773],
                                        [46.853, 19.131],
                                        [32.979, 42.367],
                                        [-3.914, 54.411],
                                        [0, 0],
                                    ],
                                    v: [
                                        [87.656, 221.401],
                                        [28.789, 189.606],
                                        [11.738, 127.876],
                                        [24.875, 70.212],
                                        [63.202, 39.075],
                                        [115.132, 0],
                                        [167.061, 39.075],
                                        [205.388, 70.212],
                                        [218.526, 127.876],
                                        [201.474, 189.606],
                                        [142.608, 221.401],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 38,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2213.242, 468.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 11,
                                s: [100],
                            },
                            { t: 31, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 39,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2200.758, 468.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 11,
                                s: [100],
                            },
                            { t: 31, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 40,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3311.242, 408.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 14,
                                s: [100],
                            },
                            { t: 34, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 41,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3298.758, 408.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 14,
                                s: [100],
                            },
                            { t: 34, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 42,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3011.242, 540.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 43,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2998.758, 540.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 44,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [2453.242, 564.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 45,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2440.758, 564.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 20, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 46,
            ty: 4,
            nm: 'Vector 19',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 180, ix: 10 },
                p: { a: 0, k: [3671.242, 498.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, 9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 19.2],
                                        [12.5, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 19',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 17,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 47,
            ty: 4,
            nm: 'Vector 18',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3658.758, 498.002, 0], ix: 2, l: 2 },
                a: { a: 0, k: [6.25, 9.6, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-2.284, -9.013],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [12.5, 19.2],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 18',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 17,
                                s: [100],
                            },
                            { t: 37, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 48,
            ty: 0,
            nm: 'Lake',
            refId: 'comp_1',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2880, 300, 0], ix: 2, l: 2 },
                a: { a: 0, k: [2880, 300, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            hasMask: true,
            masksProperties: [
                {
                    inv: false,
                    mode: 'a',
                    pt: {
                        a: 0,
                        k: {
                            i: [
                                [62.999, 0],
                                [0, -20.5],
                                [-21.5, -3],
                                [0, 0],
                                [36.001, -2.5],
                                [0, -9],
                                [-149.5, 0],
                                [0, 17],
                                [69, 0],
                                [-18.5, 13.5],
                            ],
                            o: [
                                [-63, 0],
                                [0, 23.5],
                                [21.5, 3],
                                [0, 0],
                                [-36.001, 2.5],
                                [0, 9],
                                [149.5, 0],
                                [0, -17],
                                [-11.5, 0],
                                [18.5, -13.5],
                            ],
                            v: [
                                [3437.001, 469.5],
                                [3288.501, 498.5],
                                [3359.501, 526],
                                [3385.001, 534.5],
                                [3358.002, 546],
                                [3315.001, 568.5],
                                [3477, 596],
                                [3646, 544],
                                [3530.5, 514],
                                [3532, 496.5],
                            ],
                            c: true,
                        },
                        ix: 1,
                    },
                    o: { a: 0, k: 100, ix: 3 },
                    x: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 13,
                                s: [-64],
                            },
                            { t: 33, s: [0] },
                        ],
                        ix: 4,
                    },
                    nm: 'Mask 1',
                },
            ],
            w: 5760,
            h: 600,
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 49,
            ty: 4,
            nm: 'Vector 161',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -45, ix: 10 },
                p: { a: 0, k: [2702, 522, 0], ix: 2, l: 2 },
                a: { a: 0, k: [25.456, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [50.912, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 161',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.014], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 11,
                                s: [0],
                            },
                            { t: 31, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 50,
            ty: 4,
            nm: 'Vector 160',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -135, ix: 10 },
                p: { a: 0, k: [2846, 522, 0], ix: 2, l: 2 },
                a: { a: 0, k: [25.456, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [50.912, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 160',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.014], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 11,
                                s: [0],
                            },
                            { t: 31, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 51,
            ty: 4,
            nm: 'Vector 164',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -105.945, ix: 10 },
                p: { a: 0, k: [2780, 543, 0], ix: 2, l: 2 },
                a: { a: 0, k: [21.84, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [43.681, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 164',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 18,
                                s: [0],
                            },
                            { t: 28, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 52,
            ty: 4,
            nm: 'Vector 165',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -180, ix: 10 },
                p: { a: 0, k: [2783, 552, 0], ix: 2, l: 2 },
                a: { a: 0, k: [39, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [78, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 165',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 18,
                                s: [0],
                            },
                            { t: 28, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 53,
            ty: 4,
            nm: 'Vector 163',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -180, ix: 10 },
                p: { a: 0, k: [2795, 534, 0], ix: 2, l: 2 },
                a: { a: 0, k: [27, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [54, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 163',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 18,
                                s: [0],
                            },
                            { t: 28, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 54,
            ty: 4,
            nm: 'Vector 162',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: -123.69, ix: 10 },
                p: { a: 0, k: [2810, 531, 0], ix: 2, l: 2 },
                a: { a: 0, k: [10.817, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [21.633, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 162',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 18,
                                s: [0],
                            },
                            { t: 28, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 55,
            ty: 4,
            nm: 'Vector 166',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3956, 486, 0], ix: 2, l: 2 },
                a: { a: 0, k: [54, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [108, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 166',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 21,
                                s: [100],
                            },
                            { t: 36, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 56,
            ty: 4,
            nm: 'Vector 159',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1922, 504, 0], ix: 2, l: 2 },
                a: { a: 0, k: [138, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [276, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 159',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 14,
                                s: [0],
                            },
                            { t: 34, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 57,
            ty: 4,
            nm: 'Vector',
            parent: 58,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.803, 17.787, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.572, 71.939, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 58,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3938, 483.379, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 30,
                            s: [0, 0, 100],
                        },
                        { t: 50, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 59,
            ty: 4,
            nm: 'Vector',
            parent: 60,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-0.803, 17.787, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.572, 71.939, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'op',
                            nm: 'Offset Paths 1',
                            a: { a: 0, k: -0.5, ix: 1 },
                            lj: 1,
                            ml: { a: 0, k: 4, ix: 3 },
                            ix: 3,
                            mn: 'ADBE Vector Filter - Offset',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Stroke',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -15.256],
                                        [17.207, 0],
                                        [5.681, 7.6],
                                        [10.078, 0],
                                        [5.681, 7.6],
                                        [10.106, 0],
                                        [0, 17.539],
                                        [-14.093, 3.035],
                                        [0, 2.478],
                                        [-15.486, 1.837],
                                        [0, 3.758],
                                        [-17.207, 0],
                                        [0, -17.539],
                                        [1.174, -3.341],
                                        [0, -16.259],
                                        [0.519, -2.311],
                                    ],
                                    o: [
                                        [0, 17.539],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-10.078, 0],
                                        [-5.681, 7.573],
                                        [-17.207, 0],
                                        [0, -15.256],
                                        [-0.519, -2.311],
                                        [0, -16.259],
                                        [-1.174, -3.369],
                                        [0, -17.539],
                                        [17.207, 0],
                                        [0, 3.758],
                                        [15.459, 1.837],
                                        [0, 2.478],
                                        [14.066, 3.062],
                                    ],
                                    v: [
                                        [161.145, 112.14],
                                        [130.008, 143.878],
                                        [105.29, 131.378],
                                        [80.572, 143.878],
                                        [55.854, 131.378],
                                        [31.136, 143.878],
                                        [0, 112.14],
                                        [24.636, 81.098],
                                        [23.817, 73.943],
                                        [51.293, 42.456],
                                        [49.436, 31.738],
                                        [80.572, 0],
                                        [111.709, 31.738],
                                        [109.851, 42.456],
                                        [137.328, 73.943],
                                        [136.509, 81.098],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.960784313725, 0.588235294118,
                                    0.545098039216, 1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector Fill',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 60,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1820, 501.379, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 125.746, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 23,
                            s: [0, 0, 100],
                        },
                        { t: 43, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 125.746],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 61,
            ty: 4,
            nm: 'Vector',
            parent: 64,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [15.666, 53.724, 0], ix: 2, l: 2 },
                a: { a: 0, k: [15.642, 15.885, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 31.769],
                                        [31.285, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 62,
            ty: 4,
            nm: 'Vector',
            parent: 64,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-9.41, 25.604, 0], ix: 2, l: 2 },
                a: { a: 0, k: [9.434, 9.561, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [18.867, 19.122],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 63,
            ty: 4,
            nm: 'Vector',
            parent: 64,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [-16.271, 96.387, 0], ix: 2, l: 2 },
                a: { a: 0, k: [16.291, 16.543, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [32.582, 33.087],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 64,
            ty: 4,
            nm: 'Vector',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1934, 504, 0], ix: 2, l: 2 },
                a: { a: 0, k: [0, 198, 0], ix: 1, l: 2 },
                s: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                            o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                            t: 18,
                            s: [0, 0, 100],
                        },
                        { t: 38, s: [100, 100, 100] },
                    ],
                    ix: 6,
                    l: 2,
                },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [0, 0],
                                        [0, 198],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 65,
            ty: 4,
            nm: 'Vector',
            parent: 64,
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [0.039, 57.954, 0], ix: 2, l: 2 },
                a: { a: 0, k: [109.367, 95.251, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, -20.627],
                                        [19.275, -3.99],
                                        [0, -3.802],
                                        [23.352, 0],
                                        [7.636, 11.217],
                                        [14.419, 0],
                                        [0, 23.714],
                                        [-0.927, 3.501],
                                        [0, 20.627],
                                        [-19.127, 4.103],
                                        [0, 3.35],
                                        [-23.352, 0],
                                        [-7.636, -11.217],
                                        [-14.419, 0],
                                        [0, -23.714],
                                        [0.704, -3.124],
                                        [0, -20.627],
                                    ],
                                    o: [
                                        [0, 20.74],
                                        [0.927, 3.501],
                                        [0, 23.714],
                                        [-14.419, 0],
                                        [-7.599, 11.217],
                                        [-23.352, 0],
                                        [0, -3.802],
                                        [-19.275, -3.99],
                                        [0, -20.627],
                                        [-0.704, -3.124],
                                        [0, -23.714],
                                        [14.419, 0],
                                        [7.599, -11.217],
                                        [23.352, 0],
                                        [0, 3.35],
                                        [19.09, 4.141],
                                        [0, 0],
                                    ],
                                    v: [
                                        [218.733, 94.555],
                                        [184.928, 136.6],
                                        [186.374, 147.591],
                                        [144.117, 190.502],
                                        [109.348, 171.945],
                                        [74.579, 190.502],
                                        [32.323, 147.591],
                                        [33.768, 136.6],
                                        [0, 94.555],
                                        [33.435, 52.585],
                                        [32.323, 42.911],
                                        [74.579, 0],
                                        [109.348, 18.557],
                                        [144.117, 0],
                                        [186.374, 42.911],
                                        [185.262, 52.585],
                                        [218.696, 94.555],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 2,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 66,
            ty: 4,
            nm: 'Vector 168',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1177.25, 420, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1176.75, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2353.5, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 168',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.149], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [0],
                            },
                            { t: 90, s: [100] },
                        ],
                        ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 67,
            ty: 4,
            nm: 'Vector 167',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [4411, 420, 0], ix: 2, l: 2 },
                a: { a: 0, k: [1415, 0, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            ind: 0,
                            ty: 'sh',
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [2830, 0],
                                        [0, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: 'Path 1',
                            mn: 'ADBE Vector Shape - Group',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0, 0.4, 0.392156862745, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 6, ix: 5 },
                            lc: 2,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Vector 167',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'tm',
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.12], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [100],
                            },
                            { t: 90, s: [0] },
                        ],
                        ix: 1,
                    },
                    e: { a: 0, k: 100, ix: 2 },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                },
            ],
            ip: 0,
            op: 600,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};
