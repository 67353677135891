import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "m-barChart" }
const _hoisted_2 = { class: "m-barChart__wrapper" }
const _hoisted_3 = { class: "m-barChart__chartWrapper" }
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_horizontal_chart = _resolveComponent("horizontal-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.primaryDataset, (datum, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `horizontalChart-${$props.labels[i]}-${i.toString()}`,
            class: "m-barChart__barWrapper"
          }, [
            _createElementVNode("div", {
              class: "m-barChart__bar",
              style: _normalizeStyle({ height: (datum * 100) / $options.maxValue + 5 + '%' })
            }, [
              _createElementVNode("p", {
                class: "a-label a-label--white m-barChart__barValue",
                textContent: _toDisplayString(
                                String(
                                    (datum > 0 ? Math.round(datum) : datum) +
                                        $props.suffix,
                                )
                            )
              }, null, 8 /* PROPS */, _hoisted_4)
            ], 4 /* STYLE */),
            _createElementVNode("p", {
              class: "a-label a-label--white",
              textContent: _toDisplayString($props.labels[i])
            }, null, 8 /* PROPS */, _hoisted_5)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 512 /* NEED_PATCH */), [
        [_vShow, $data.isDesktop]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_horizontal_chart, {
          "primary-dataset": $props.primaryDataset,
          labels: $props.labels,
          suffix: $props.suffix
        }, null, 8 /* PROPS */, ["primary-dataset", "labels", "suffix"])
      ], 512 /* NEED_PATCH */), [
        [_vShow, !$data.isDesktop]
      ])
    ])
  ]))
}