import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "o-propertyEstimateTeaser__wrapper" }
const _hoisted_2 = { class: "o-propertyEstimateTeaser" }
const _hoisted_3 = { class: "o-propertyEstimateTeaser__content" }
const _hoisted_4 = { class: "a-heading1 a-heading1--white o-propertyEstimateTeaser__contentHeadline" }
const _hoisted_5 = { class: "o-propertyEstimateTeaser__contentDescription" }
const _hoisted_6 = { class: "a-paragraph a-paragraph--white" }
const _hoisted_7 = { class: "o-propertyEstimateTeaser__contentDescriptionIcon" }
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = { class: "o-propertyEstimateTeaser__contentInputWrapper" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["textContent"]
const _hoisted_12 = {
  key: 0,
  class: "o-propertyEstimateTeaser__splash"
}
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = { class: "o-propertyEstimateTeaser__disclaimer" }
const _hoisted_15 = ["textContent"]
const _hoisted_16 = { class: "o-panel" }
const _hoisted_17 = { class: "a-heading2 o-propertyEstimateTeaser__infoHeadline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_icon = _resolveComponent("a-icon")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_portal = _resolveComponent("portal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, [
          _renderSlot(_ctx.$slots, "headline")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, [
            _renderSlot(_ctx.$slots, "description")
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: "o-propertyEstimateTeaser__info",
              "aria-label": $props.texts.ariaLabel,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClickEstimateTeaserInfo && $options.onClickEstimateTeaserInfo(...args)))
            }, [
              _createVNode(_component_a_icon, { name: "info" })
            ], 8 /* PROPS */, _hoisted_8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_m_input, {
            value: $data.address,
            class: "m-input__field o-propertyEstimateTeaser__contentInput",
            name: "address‌_search",
            error: $data.error ? $props.texts.errorMessage : '',
            label: $props.texts.placeholder,
            theme: "dark",
            type: "dawa",
            onDawa: $options.enableSubmit,
            onDawaReset: $options.disableSubmit,
            onInput: $options.setAddressInfo,
            onFocus: _cache[1] || (_cache[1] = ($event: any) => ($options.setIsFocused(true))),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => ($options.setIsFocused(false)))
          }, null, 8 /* PROPS */, ["value", "error", "label", "onDawa", "onDawaReset", "onInput"]),
          _createElementVNode("button", {
            type: "submit",
            class: _normalizeClass(["m-button o-propertyEstimateTeaser__contentInputButton", { isLoading: $data.isLoading }]),
            disabled: !$data.addressFound || !$data.address,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onSubmit && $options.onSubmit(...args)))
          }, [
            _createElementVNode("span", {
              class: "m-button__text",
              textContent: _toDisplayString($props.texts.buttonText)
            }, null, 8 /* PROPS */, _hoisted_11)
          ], 10 /* CLASS, PROPS */, _hoisted_10)
        ])
      ]),
      ($props.texts.imageUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("img", {
              src: $props.texts.imageUrl,
              class: "o-propertyEstimateTeaser__splashImage",
              alt: $props.texts.imageAltText
            }, null, 8 /* PROPS */, _hoisted_13)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("span", {
        class: "a-small o-propertyEstimateTeaser__disclaimerText",
        textContent: _toDisplayString($props.texts.helpText)
      }, null, 8 /* PROPS */, _hoisted_15)
    ]),
    ($options.showEstimateTeaserInfo)
      ? (_openBlock(), _createBlock(_component_portal, {
          key: 0,
          to: "modal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("h2", _hoisted_17, [
                _renderSlot(_ctx.$slots, "info-headline")
              ]),
              _renderSlot(_ctx.$slots, "info-description")
            ])
          ], undefined, true),
          _: 3 /* FORWARDED */
        }))
      : _createCommentVNode("v-if", true)
  ]))
}