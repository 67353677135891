/* eslint-disable */
declare global {
    interface Window {
        dataLayer: any;
    }
}

export default {
    /**
     * Send click event to dataLayer.
     *
     * @param {string} action
     * @param {string} category
     * @param {string} label
     * @return {void}
     */
    click(action: string, category: string, label: string): void {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                category,
                action,
                label,
            });

            this.logToConsole();
        }
    },

    /**
     * replace the danish chracter "æ, ø and å"
     *
     * @param {string} input
     * @return {string}
     */
    replaceDanishCharacters(input: string): string {
        const mapObj: any = {
            æ: 'ae',
            ø: 'oe',
            å: 'aa',
            Æ: 'Ae',
            Ø: 'Oe',
            Å: 'Aa',
        };

        return input.replace(
            /æ|ø|å|Æ|Ø|Å/gi,
            function (matched: string): string {
                return mapObj[matched];
            },
        );
    },

    /**
     * Send any event to dataLayer.
     *
     * @param {string} eventName
     * @param {string} category
     * @param {string} action
     * @param {string} label
     * @return {void}
     */
    track(
        eventName: string,
        category: string,
        action?: string,
        label?: string,
        extraParameters?: object,
    ): void {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                event: eventName,
                eventData: {
                    category: this.replaceDanishCharacters(category),
                    action: action ? this.replaceDanishCharacters(action) : '',
                    label: label ? this.replaceDanishCharacters(label) : '',
                    ...extraParameters,
                },
            });

            this.logToConsole();
        }
    },

    /**
     * Send any event to dataLayer.
     *
     * @param {object} rawEventData
     * @return {void}
     */
    trackRawEvent(rawEventData: object): void {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push(rawEventData);

            this.logToConsole();
        }
    },

    logToConsole(): void {
        if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === 'dev.danbolig.dk' ||
            window.location.hostname === 'tst.danbolig.dk'
        ) {
            console.log(window.dataLayer);
        }
    },
};
