import { ActionContext } from 'vuex';
import State from './interface';

export default {
    /**
     * Close the overlay.
     *
     * @param {ActionContext} state
     * @return {void}
     */
    close({ commit }: ActionContext<any, State>): void {
        commit('close');
    },

    /**
     * Open the overlay.
     *
     * @param {ActionContext} state
     * @param {string} panel
     * @return {void}
     */
    open({ commit, dispatch }: ActionContext<any, State>, panel: string): void {
        commit('setCurrentPanel', panel);
        commit('setCurrentSubPanel', 1);
        dispatch('modal/open', 'employee', { root: true });
    },

    /**
     * Change the current panel.
     *
     * @param {ActionContext} state
     * @param {string} value
     * @return {void}
     */
    setCurrentPanel(
        { commit }: ActionContext<any, State>,
        value: string,
    ): void {
        commit('setCurrentPanel', value);
    },

    /**
     * Change the current sub panel.
     *
     * @param {ActionContext} state
     * @param {number} panel
     * @return {void}
     */
    setCurrentSubPanel(
        { commit }: ActionContext<any, State>,
        value: number,
    ): void {
        commit('setCurrentSubPanel', value);
    },
};
