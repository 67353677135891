/* eslint-disable */
export enum LeadTypes {
    ContactEmployee,
    ContactEmployeeSpecific,
    Contact,
    ContactSpecific,
    OpenHouse,
    Presentation,
    PurchaseOffer,
    SalesMaterial,
    SalesMaterialWithContact,
    SalesValuation,
    SalesValuationShop,
    SalesValuationShopWeekend,
    SalesValuationWeekend,
    SalesValuationDinGeo,
    HomeEstimate,
    BuyersAdvice,
    FindBuyer,
    SalesValuationHomeEstimate,
}
