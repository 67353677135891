
import { PropType } from 'vue';
import tracking from '@/functions/tracking';

interface Data {
    items: {
        icon: string;
        link: string;
        text: string;
        trackingName: string;
        trackingCategoryName: string;
        sortorder: string;
    }[];
}

export default {
    props: {
        trackingName: {
            type: String as PropType<string>,
            default: () => '',
        },
        trackingCategoryName: {
            type: String as PropType<string>,
            default: () => '',
        },
    },
    data(): Data {
        return {
            items: [],
        };
    },
    mounted(): void {
        document
            .querySelectorAll('[data-anchor-text]')
            .forEach((item: HTMLDivElement) => {
                if (item.hasAttribute('data-hide-anchor')) {
                    return;
                }
                this.items.push({
                    icon: item.getAttribute('data-anchor-icon') ?? '',
                    link: item.getAttribute('id') ?? '',
                    text: item.getAttribute('data-anchor-text') ?? '',
                    trackingName: this.$props.trackingName ?? '',
                    trackingCategoryName:
                        this.$props.trackingCategoryName ?? '',
                    sortorder: item.getAttribute('data-sort-order') ?? '100',
                });
            });
        this.items = this.items.sort(
            (a, b) => parseInt(a.sortorder) - parseInt(b.sortorder),
        );
    },

    methods: {
        trackAnchor(index: number): void {
            let anchor = this.items[index];
            if (
                anchor.trackingName !== '' &&
                anchor.trackingCategoryName !== '' &&
                anchor.text !== ''
            ) {
                tracking.track(
                    anchor.trackingName,
                    anchor.trackingCategoryName,
                    'Anchor',
                    anchor.text,
                );
            }
        },
    },
};
