import KeyValue from '@/interfaces/keyValue.interface';
import State from './interface';

export default {
    /**
     * Set the current panel.
     *
     * @param {State} state
     * @param {string} payload
     * @return {void}
     */
    setCurrentPanel(state: State, payload: string): void {
        state.overlay.currentPanel = payload;
    },

    /**
     * Set the current sub panel.
     *
     * @param {State} state
     * @param {number} payload
     * @return {void}
     */
    setCurrentSubPanel(state: State, payload: number): void {
        state.overlay.currentSubPanel = payload;
    },

    setTags(state: State, payload: KeyValue): void {
        if (state.filters.tags.some((e) => e.key === payload.key)) {
            state.filters.tags = state.filters.tags.filter(
                (item) => item.key !== payload.key,
            );
        } else {
            state.filters.tags = [...state.filters.tags, payload];
        }
    },

    resetTags(state: State): void {
        state.filters.tags = [];
    },

    setPrice(state: State, payload: [number, number]): void {
        state.filters.price = [payload[0], payload[1]];
    },

    setSize(state: State, payload: [number, number]): void {
        state.filters.size = [payload[0], payload[1]];
    },

    setZipcode(state: State, payload: string): void {
        state.filters.zipcode = payload;
    },

    setComment(state: State, payload: string): void {
        state.filters.comment = payload;
    },

    setName(state: State, payload: string): void {
        state.filters.name = payload;
    },
};
