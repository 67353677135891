import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "o-brokerContact" }
const _hoisted_2 = { class: "u-container o-brokerContact__container" }
const _hoisted_3 = { class: "u-col-12 lg:u-col-10 o-brokerContact__wrapper" }
const _hoisted_4 = {
  ref: "brokerRef",
  class: "o-brokerContact__responsibleBroker"
}
const _hoisted_5 = {
  key: "form",
  class: "o-brokerContact__formContent"
}
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "o-brokerContact__form" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["textContent"]
const _hoisted_11 = {
  key: "success",
  class: "o-brokerContact__formSuccessWrapper"
}
const _hoisted_12 = { class: "o-brokerContact__formSuccess" }
const _hoisted_13 = { class: "o-brokerContact__icon" }
const _hoisted_14 = ["textContent"]
const _hoisted_15 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_employee_card = _resolveComponent("o-employee-card")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_a_icon = _resolveComponent("a-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_o_employee_card, {
            class: "o-brokerContact__employeeCard",
            employee: $props.formData.brokerEmployee
          }, null, 8 /* PROPS */, ["employee"])
        ], 512 /* NEED_PATCH */),
        _createElementVNode("div", {
          class: "o-brokerContact__formWrapper",
          style: _normalizeStyle(
                        $data.isSignupSuccessful
                            ? { height: `${$data.formSuccessWraperHeight}px` }
                            : null
                    )
        }, [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (!$data.isSignupSuccessful)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", {
                      class: "a-heading3 o-brokerContact__headline",
                      textContent: _toDisplayString($props.formData.headline)
                    }, null, 8 /* PROPS */, _hoisted_6),
                    _createElementVNode("div", {
                      class: "a-base o-brokerContact__text",
                      textContent: _toDisplayString($props.formData.text)
                    }, null, 8 /* PROPS */, _hoisted_7),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_m_input, {
                        modelValue: $data.form.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.name) = $event)),
                        class: "o-brokerContact__formInput",
                        label: `${$props.formData.formSettings.namePlaceholder}*`,
                        type: "text",
                        name: "Name",
                        error: $data.errors.name,
                        required: "",
                        "use-bright-error": ""
                      }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                      _createVNode(_component_m_input, {
                        modelValue: $data.form.email,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.email) = $event)),
                        class: "o-brokerContact__formInput",
                        label: `${$props.formData.formSettings.mailPlaceholder}*`,
                        type: "email",
                        name: "Email",
                        error: $data.errors.mail,
                        required: "",
                        "use-bright-error": ""
                      }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                      _createVNode(_component_m_input, {
                        modelValue: $data.form.phone,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.phone) = $event)),
                        class: "o-brokerContact__formInput",
                        label: `${$props.formData.formSettings.phonePlaceholder}*`,
                        type: "text",
                        name: "Phone",
                        error: $data.errors.phone,
                        required: "",
                        "use-bright-error": ""
                      }, null, 8 /* PROPS */, ["modelValue", "label", "error"]),
                      _createVNode(_component_m_textarea, {
                        modelValue: $data.form.message,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.message) = $event)),
                        class: "o-brokerContact__textareaInput",
                        label: `${$props.formData.formSettings.msgPlaceholder}`,
                        error: $data.errors.message,
                        "use-bright-error": "",
                        name: "Message"
                      }, null, 8 /* PROPS */, ["modelValue", "label", "error"])
                    ]),
                    ($props.formData.consent)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "a-small a-small--allowBreak o-fields__field",
                          innerHTML: $props.formData.consent.purposeText
                        }, null, 8 /* PROPS */, _hoisted_9))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("button", {
                      type: "submit",
                      class: _normalizeClass(["m-button o-brokerContact__formSubmitButton", { isLoading: $data.isLoading }]),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.onSubmitForm()))
                    }, [
                      _createElementVNode("span", {
                        textContent: _toDisplayString($props.formData.ctaText)
                      }, null, 8 /* PROPS */, _hoisted_10)
                    ], 2 /* CLASS */)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_a_icon, { name: "check" })
                      ]),
                      _createElementVNode("div", {
                        class: "a-heading3 o-brokerContact__formSuccessHeadline",
                        textContent: _toDisplayString($props.formData.receiptHeadline)
                      }, null, 8 /* PROPS */, _hoisted_14),
                      _createElementVNode("div", {
                        class: "a-base o-brokerContact__formSuccessText",
                        textContent: _toDisplayString($props.formData.receiptText)
                      }, null, 8 /* PROPS */, _hoisted_15)
                    ])
                  ]))
            ], undefined, true),
            _: 1 /* STABLE */
          })
        ], 4 /* STYLE */)
      ])
    ])
  ]))
}