import PoiCategory from '@/interfaces/POICategory.interface';

const PoiCategories: PoiCategory[] = [
    {
        key: 'publicTransport',
        name: 'Offentlig transport',
        value: [
            { key: 'stop', title: 'Stoppested' },
            { key: 'train', title: 'Tog' },
            { key: 'strain', title: 'S-tog' },
            { key: 'metro', title: 'Metrostop' },
            { key: 'lightrail', title: 'Letbane' },
            { key: 'airport', title: 'Lufthavn' },
        ],
    },
    {
        key: 'shopping',
        name: 'Indkøb',
        value: [{ key: 'supermarket', title: 'Supermarked' }],
    },
    {
        key: 'health',
        name: 'Sundhed',
        value: [
            { key: 'pharmacy', title: 'Apotek' },
            { key: 'doctor', title: 'Læge' },
            { key: 'hospital', title: 'Hospital' },
        ],
    },
    {
        key: 'school',
        name: 'Skoler',
        value: [{ key: 'school', title: 'Skole' }],
    },
    {
        key: 'daycare',
        name: 'Daginstitutioner',
        value: [{ key: 'daycare', title: 'Daginstitution' }],
    },
    {
        key: 'leisureActivities',
        name: 'Fritidsfaciliteter',
        value: [
            { key: 'soccerfield', title: 'Fodboldbane' },
            { key: 'sportshall', title: 'Sportshal' },
            { key: 'library', title: 'Bibliotek' },
            { key: 'church', title: 'Kirke' },
            { key: 'publicbath', title: 'Svømmehal' },
        ],
    },
    {
        key: 'nature',
        name: 'Natur',
        value: [
            { key: 'bathinglake', title: 'Badesø' },
            { key: 'forest', title: 'Skov' },
            { key: 'coast', title: 'Kyst' },
            { key: 'naturesite', title: 'Natur-seværdighed' },
            { key: 'ancientlandmark', title: 'Historisk mindesmærke' },
            { key: 'builtheritage', title: 'Arkitekturarv' },
            { key: 'monument', title: 'Monument' },
            { key: 'park', title: 'Park' },
            { key: 'lake', title: 'Sø' },
        ],
    },
    {
        key: 'charger',
        name: 'Ladestander',
        value: [
            { key: 'chargingstation', title: 'Ladestander' },
            { key: 'supercharger', title: 'Lynlader' },
        ],
    },
];

export default PoiCategories;

/*
Type            På dansk
-----------------------------------
school          Skole
daycare         Daginstitution
metro           Metrostop
train           Tog
stop            Stoppested
strain          S-tog
lightrail       Letbane
supermarket     Supermarked
doctor          Læge
pharmacy        Apotek
hospital        Hospital
airport         Lufthavn
library         Bibliotek
coast           Kyst
lake            Sø
forest          Skov
sportshall      Sportshal
publicbath      Svømmehal
soccerfield     Fodboldbane
park            Park
church          Kirke
monument        Monument
builtheritage   Arkitekturarv
naturesite      Naturseværdighed
ancientlandmark Historisk mindesmærke
buildtheritage  Arkitekturarv
bathinglake     Badesø
chargingstation Ladestander
supercharger    Lynlader

*/
