import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "o-segmentationStep u-container" }
const _hoisted_2 = { class: "u-col-12 lg:u-col-10 o-segmentationStep__wrapper" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "o-segmentationStep__segments" }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_radio = _resolveComponent("m-radio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        class: "o-segmentationStep__headline a-heading2",
        textContent: _toDisplayString($props.segmentsStep.segmentHeadline)
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.segmentsStep.segments, (segment, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `segmentation-step-${index}`,
            class: "o-segmentationStep__segment"
          }, [
            _createElementVNode("div", {
              class: "o-segmentationStep__segmentText a-label",
              textContent: _toDisplayString(segment.text)
            }, null, 8 /* PROPS */, _hoisted_5),
            _createVNode(_component_m_radio, {
              modelValue: $data.selectedSegment,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedSegment) = $event)),
              label: 
                            $data.selectedSegment === segment.text
                                ? $props.segmentsStep.segmentSelectedText
                                : $props.segmentsStep.segmentCtaText
                        ,
              value: segment.text,
              onInput: ($event: any) => (
                            $options.handleSegmentStepClick(segment.text, segment.hash)
                        )
            }, null, 8 /* PROPS */, ["modelValue", "label", "value", "onInput"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}